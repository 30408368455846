import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Descriptions,Image,Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {errorN} from "../../general";
import {A, navigate} from "hookrouter";
import useFetch from "use-http";
import {
    BarChartOutlined,  
    PieChartOutlined
} from '@ant-design/icons'
import { Pie} from '@ant-design/charts';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import routes from "../../router"
import Store, {Context} from "../../store"
import moment from 'moment'

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function Summary(props) {
    const [state,dispatch] = useContext(Context)
    const { get, response, loading} = useFetch()   

    //const [form] = Form.useForm()
    //const [formLayout] = useState('vertical');
    // const handleFinish = useCallback(() => {
    //     //console.log('Submit: ', form.getFieldsValue(true));

    //     const values = form.getFieldsValue(true);

    // }, [form])

    const { height, width } = useWindowDimensions();

    const [roomStatusTable,setRoomStatusTable] = useState([]);
    const [roomStatusPie,setRoomStatusPie] = useState([]);

    const [dailyData1,setDailyData1] = useState([]);
    const [dailyData2,setDailyData2] = useState([]);
    const [dailyData3,setDailyData3] = useState([]);
    const [dailyData4,setDailyData4] = useState([]);

    const [yesData1,setYesData1] = useState([]);
    const [yesData2,setYesData2] = useState([]);
    const [yesData3,setYesData3] = useState([]);
    const [yesData4,setYesData4] = useState([]);

    const [monthToDateData1,setMonthToDateData1] = useState([]);
    const [monthToDateData2,setMonthToDateData2] = useState([]);
    const [monthToDateData3,setMonthToDateData3] = useState([]);
    const [monthToDateData4,setMonthToDateData4] = useState([]);

    const [lastMonthData1,setLastMonthData1] = useState([]);
    const [lastMonthData2,setLastMonthData2] = useState([]);
    const [lastMonthData3,setLastMonthData3] = useState([]);
    const [lastMonthData4,setLastMonthData4] = useState([]);
    
    useEffect(() => {

      
        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {         
            getRoomStatus();
            getDailyData();
            getYesterdayData();
            getMonthToDateData();
            getLastMonthData();
        }

        return () => {
            
        }
    },[])

    const getDailyData = async () => {
        try
        {
          let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let tim =  JSON.parse(localStorage.getItem('timesetting'));

            let sx = tim.data.shift.shiftStart.time;
            let sP = tim.data.shift.shiftStart.period;
            let ex = tim.data.shift.shiftEnd.time;
            let eP = tim.data.shift.shiftEnd.period;

            let x = sx.split(':');
            let y = ex.split(':');

          let d= new Date();
          let dt = new Date(d.getFullYear(), d.getMonth(), d.getDate(), parseInt(x[0]), parseInt(x[1]), parseInt(x[2]), 0)

          let d2= new Date();
          //d2.setDate(d2.getDate()+1);
          let dt2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate(), parseInt(y[0]), parseInt(y[1]), parseInt(y[2]), 0);

            //let d3= new Date();
            //let dt3 = new Date(d3.getFullYear(), d3.getMonth(), d3.getDate(), parseInt(y[0]), parseInt(y[1]), parseInt(y[2]), 0);

          let s = moment.utc(dt).add(parseInt(sP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');
          let e = moment.utc(dt2).add(parseInt(eP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');
          //let e2 = moment.utc(dt3).add(parseInt(eP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');

            //let s = moment.utc(d).format('YYYY-MM-DDTHH:mm:ss[Z]');
            //let e = moment.utc(d2).format('YYYY-MM-DDTHH:mm:ss[Z]');
            //let e2 = moment.utc(d).format('YYYY-MM-DDTHH:mm:ss[Z]');

          /*let s = moment.utc(dt).format('YYYY-MM-DDTHH:mm:ss[Z]');
          let e = moment.utc(dt2).add(1, 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');*/

          //console.log('itsutc', e, dt)
  
          let folioList = await get(`/api/hotels/${obj.id}/folios/payments?start=${s}`)
          if (response.ok)
          {
             //console.log('folio list', folioList)

             let ota = 0;
             let walkin = 0;

             let amount = 0;
             let paid = 0;
             let balance = 0;

             let open = 0;
             let closed = 0;
             let invoiced = 0;
             let cancelled = 0;

             let checkin = 0;
             let checkout = 0;

             let allocationStart = await get(`/api/hotels/${obj.id}/allocations?start=${s}`)
             if (response.ok)
             {

                let allocationEnd = await get(`/api/hotels/${obj.id}/allocations?end=${e}`)
                if (response.ok)
                {
                    //console.log('ini', e,allocationEnd)
                    checkin = allocationStart.length;
                    checkout =  allocationEnd.length;

                    for(let i of folioList)
                    {
                        if (i.no != null && i.created != null)
                        {
       
                           if(i.source === 'walkin')
                           {
                               walkin = walkin + 1;
                           }
                           else
                           {
                               ota = ota + 1;
                           }
       
                           amount = amount + parseFloat(i.amount);
                           paid = paid + parseFloat(i.paid);
                           balance = balance + (parseFloat(i.amount) - parseFloat(i.paid));
       
                           if(i.status === 'Open')
                           {
                               open = open + 1;
                           }
       
                           if(i.status === 'Closed')
                           {
                               closed = closed + 1;
                           }
       
                           if(i.status === 'Invoiced')
                           {
                               invoiced = invoiced + 1;
                           }
       
                           if(i.status === 'Cancelled')
                           {
                               cancelled = cancelled + 1;
                           }  
                                         
                        }
                    }

                    let ls1 =[]; 
                      ls1.push({id:1,type:'Walk-In',value:walkin})
                      ls1.push({id:2,type:'Travel Agencies',value:ota})                      
                      setDailyData1(ls1);

                    let ls2 =[]; 
                      ls2.push({id:1,type:'Amount',value:formatter.format(amount)})
                      ls2.push({id:2,type:'Paid',value:formatter.format(paid)})
                      ls2.push({id:3,type:'Balance',value:formatter.format(balance)})                      
                      setDailyData2(ls2);

                    let ls3 =[]; 
                      ls3.push({id:1,type:'Open',value:open})   
                      ls3.push({id:2,type:'Closed',value:closed})   
                      ls3.push({id:3,type:'Invoiced',value:invoiced})  
                      ls3.push({id:4,type:'Cancelled',value:cancelled})                          
                      setDailyData3(ls3);

                    let ls4 =[];                      
                      ls4.push({id:1,type:'Arrival',value:checkin})
                      ls4.push({id:2,type:'Departure',value:checkout})
                      setDailyData4(ls4);

                }
                else
                {            
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();    
                    }
                    else
                    {
                    errorN(`getDailyData:Allocation End- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                    
                }
             }
             else
            {            
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();    
                }
                else
                {
                errorN(`getDailyData:Allocation Start- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
                
            }
          }
          else
          {            
            if(response.status === 401)
            {
                localStorage.removeItem('session');
                window.location.reload();    
            }
            else
            {
              errorN(`getDailyData:Folio List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
              
          }
        }
        catch(err) 
        {
          errorN(`Error DD: ${err}. Please contact the administrator.`);
        }   
    }

    const getYesterdayData = async () => {
        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let tim =  JSON.parse(localStorage.getItem('timesetting'));

            let sx = tim.data.shift.shiftStart.time;
            let sP = tim.data.shift.shiftStart.period;
            let ex = tim.data.shift.shiftEnd.time;
            let eP = tim.data.shift.shiftEnd.period;

            let x = sx.split(':');
            let y = ex.split(':');

            let d= new Date();
            d.setDate(d.getDate()-1);
            let dt = new Date(d.getFullYear(), d.getMonth(), d.getDate(), parseInt(x[0]), parseInt(x[1]), parseInt(x[2]), 0);

            let d2= new Date();
            d2.setDate(d2.getDate()-1);
            let dt2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate(), parseInt(y[0]), parseInt(y[1]), parseInt(y[2]), 0);


            let s = moment.utc(dt).add(parseInt(sP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');
            let e = moment.utc(dt2).add(parseInt(eP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');


            console.log('itsdt', d)
            console.log('itsutc', e, dt)

            let folioList = await get(`/api/hotels/${obj.id}/folios/payments?start=${s}`)
            if (response.ok)
            {
                //console.log('folio list', folioList)

                let ota = 0;
                let walkin = 0;

                let amount = 0;
                let paid = 0;
                let balance = 0;

                let open = 0;
                let closed = 0;
                let invoiced = 0;
                let cancelled = 0;

                let checkin = 0;
                let checkout = 0;

                let allocationStart = await get(`/api/hotels/${obj.id}/allocations?start=${s}`)
                if (response.ok)
                {

                    let allocationEnd = await get(`/api/hotels/${obj.id}/allocations?end=${e}`)
                    if (response.ok)
                    {
                        checkin = allocationStart.length;
                        checkout =  allocationEnd.length;

                        for(let i of folioList)
                        {
                            if (i.no != null && i.created != null)
                            {

                                if(i.source === 'walkin')
                                {
                                    walkin = walkin + 1;
                                }
                                else
                                {
                                    ota = ota + 1;
                                }

                                amount = amount + parseFloat(i.amount);
                                paid = paid + parseFloat(i.paid);
                                balance = balance + (parseFloat(i.amount) - parseFloat(i.paid));

                                if(i.status === 'Open')
                                {
                                    open = open + 1;
                                }

                                if(i.status === 'Closed')
                                {
                                    closed = closed + 1;
                                }

                                if(i.status === 'Invoiced')
                                {
                                    invoiced = invoiced + 1;
                                }

                                if(i.status === 'Cancelled')
                                {
                                    cancelled = cancelled + 1;
                                }

                            }
                        }

                        let ls1 =[];
                        ls1.push({id:1,type:'Walk-In',value:walkin})
                        ls1.push({id:2,type:'Travel Agencies',value:ota})
                        setYesData1(ls1);

                        let ls2 =[];
                        ls2.push({id:1,type:'Amount',value:formatter.format(amount)})
                        ls2.push({id:2,type:'Paid',value:formatter.format(paid)})
                        ls2.push({id:3,type:'Balance',value:formatter.format(balance)})
                        setYesData2(ls2);

                        let ls3 =[];
                        ls3.push({id:1,type:'Open',value:open})
                        ls3.push({id:2,type:'Closed',value:closed})
                        ls3.push({id:3,type:'Invoiced',value:invoiced})
                        ls3.push({id:4,type:'Cancelled',value:cancelled})
                        setYesData3(ls3);

                        let ls4 =[];
                        ls4.push({id:1,type:'Arrival',value:checkin})
                        ls4.push({id:2,type:'Departure',value:checkout})
                        setYesData4(ls4);

                    }
                    else
                    {
                        if(response.status === 401)
                        {
                            localStorage.removeItem('session');
                            window.location.reload();
                        }
                        else
                        {
                            errorN(`getDailyData:Allocation End- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        }

                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`getDailyData:Allocation Start- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`getDailyData:Folio List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch(err)
        {
            errorN(`Error DD: ${err}. Please contact the administrator.`);
        }
    }

    const getMonthToDateData = async () => {
        try
        {
          let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let tim =  JSON.parse(localStorage.getItem('timesetting'));

            let sx = tim.data.shift.shiftStart.time;
            let sP = tim.data.shift.shiftStart.period;
            let ex = tim.data.shift.shiftEnd.time;
            let eP = tim.data.shift.shiftEnd.period;

            let x = sx.split(':');
            let y = ex.split(':');

          let d= new Date();
          let dt0 = new Date(d.getFullYear(), d.getMonth(), 1, parseInt(x[0]), parseInt(x[1]), parseInt(x[2]), 0)
          let dt = new Date(d.getFullYear(), d.getMonth(), d.getDate(), parseInt(y[0]), parseInt(y[1]), parseInt(y[2]), 0)

          let s = moment.utc(dt0).add(parseInt(sP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');
          let e = moment.utc(dt).add(parseInt(eP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');

          /*let s = moment.utc(dt0).format('YYYY-MM-DDTHH:mm:ss[Z]');
          let e = moment.utc(dt).add(1, 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');*/

          //console.log('itsutc', e, dt)
  
          let folioList = await get(`/api/hotels/${obj.id}/folios/payments?cstart=${s}&cend=${e}`)
          if (response.ok)
          {
             //console.log('folio list month', folioList)

             let ota = 0;
             let walkin = 0;

             let amount = 0;
             let paid = 0;
             let balance = 0;

             let open = 0;
             let closed = 0;
             let invoiced = 0;
             let cancelled = 0;

             let checkin = 0;
             let checkout = 0;

            let allocationArrivalList = await get(`/api/hotels/${obj.id}/allocations?from=${s}&to=${e}`)               
            if (response.ok)
            {
                let allocationDepartureList = await get(`/api/hotels/${obj.id}/allocations?from=${s}&to=${e}`)
                if (response.ok)
                {
                   
                    checkin = allocationArrivalList.length;
                    checkout =  allocationDepartureList.length;
                   
                    for(let i of folioList)
                    {
                        if (i.no != null && i.created != null)
                        {
       
                           if(i.source === 'walkin')
                           {
                               walkin = walkin + 1;
                           }
                           else
                           {
                               ota = ota + 1;
                           }
       
                           amount = amount + parseFloat(i.amount);
                           paid = paid + parseFloat(i.paid);
                           balance = balance + (parseFloat(i.amount) - parseFloat(i.paid));
       
                           if(i.status === 'Open')
                           {
                               open = open + 1;
                           }
       
                           if(i.status === 'Closed')
                           {
                               closed = closed + 1;
                           }
       
                           if(i.status === 'Invoiced')
                           {
                               invoiced = invoiced + 1;
                           }
       
                           if(i.status === 'Cancelled')
                           {
                               cancelled = cancelled + 1;
                           }  
                                         
                        }
                    }

                    let ls1 =[]; 
                      ls1.push({id:1,type:'Walk-In',value:walkin})
                      ls1.push({id:2,type:'Travel Agencies',value:ota})                      
                      setMonthToDateData1(ls1);

                    let ls2 =[]; 
                      ls2.push({id:1,type:'Amount',value:formatter.format(amount)})
                      ls2.push({id:2,type:'Paid',value:formatter.format(paid)})
                      ls2.push({id:3,type:'Balance',value:formatter.format(balance)})                      
                      setMonthToDateData2(ls2);

                    let ls3 =[]; 
                      ls3.push({id:1,type:'Open',value:open})   
                      ls3.push({id:2,type:'Closed',value:closed})   
                      ls3.push({id:3,type:'Invoiced',value:invoiced})  
                      ls3.push({id:4,type:'Cancelled',value:cancelled})                          
                      setMonthToDateData3(ls3);

                    let ls4 =[];                      
                      ls4.push({id:1,type:'Arrival',value:checkin})
                      ls4.push({id:2,type:'Departure',value:checkout})
                      setMonthToDateData4(ls4);

                }
                else
                {            
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();    
                    }
                    else
                    {
                    errorN(`getMonthToDateData:Allocation End - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                    
                }
            }
            else
            {            
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();    
                }
                else
                {
                errorN(`getMonthToDateData:Allocation Start - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }            
            }
            
          }
          else
          {            
            if(response.status === 401)
            {
                localStorage.removeItem('session');
                window.location.reload();    
            }
            else
            {
              errorN(`getDailyData:Folio List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
              
          }
        }
        catch(err) 
        {
          errorN(`Error DD: ${err}. Please contact the administrator.`);
        }   
    }

    const getLastMonthData = async () => {
        try
        {
          let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let tim =  JSON.parse(localStorage.getItem('timesetting'));

            let sx = tim.data.shift.shiftStart.time;
            let sP = tim.data.shift.shiftStart.period;
            let ex = tim.data.shift.shiftEnd.time;
            let eP = tim.data.shift.shiftEnd.period;

            let x = sx.split(':');
            let y = ex.split(':');

          let d= new Date();

          let de= new Date();
          de.setMonth(de.getMonth() - 1);

          let dt0 = new Date(de.getFullYear(), de.getMonth(), 1, parseInt(x[0]), parseInt(x[1]), parseInt(x[2]), 0)
          let dt = new Date(de.getFullYear(), de.getMonth() + 1, 0, parseInt(y[0]), parseInt(y[1]), parseInt(y[2]), 0)

          let s = moment.utc(dt0).add(parseInt(sP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');
          let e = moment.utc(dt).add(parseInt(eP), 'D').format('YYYY-MM-DDTHH:mm:ss[Z]');

          /*let s = moment.utc(dt0).format('YYYY-MM-DDTHH:mm:ss[Z]');
          let e = moment.utc(dt).format('YYYY-MM-DDTHH:mm:ss[Z]');*/
         
  
          let folioList = await get(`/api/hotels/${obj.id}/folios/payments?cstart=${s}&cend=${e}`)
          if (response.ok)
          {
             //console.log('folio list month', folioList)

             let ota = 0;
             let walkin = 0;

             let amount = 0;
             let paid = 0;
             let balance = 0;

             let open = 0;
             let closed = 0;
             let invoiced = 0;
             let cancelled = 0;

             let checkin = 0;
             let checkout = 0;

            let allocationArrivalList = await get(`/api/hotels/${obj.id}/allocations?from=${s}&to=${e}`)               
            if (response.ok)
            {
                let allocationDepartureList = await get(`/api/hotels/${obj.id}/allocations?from=${s}&to=${e}`)
                if (response.ok)
                {
                   
                    checkin = allocationArrivalList.length;
                    checkout =  allocationDepartureList.length;
                   
                    for(let i of folioList)
                    {
                        if (i.no != null && i.created != null)
                        {
       
                           if(i.source === 'walkin')
                           {
                               walkin = walkin + 1;
                           }
                           else
                           {
                               ota = ota + 1;
                           }
       
                           amount = amount + parseFloat(i.amount);
                           paid = paid + parseFloat(i.paid);
                           balance = balance + (parseFloat(i.amount) - parseFloat(i.paid));
       
                           if(i.status === 'Open')
                           {
                               open = open + 1;
                           }
       
                           if(i.status === 'Closed')
                           {
                               closed = closed + 1;
                           }
       
                           if(i.status === 'Invoiced')
                           {
                               invoiced = invoiced + 1;
                           }
       
                           if(i.status === 'Cancelled')
                           {
                               cancelled = cancelled + 1;
                           }  
                                         
                        }
                    }

                    let ls1 =[]; 
                      ls1.push({id:1,type:'Walk-In',value:walkin})
                      ls1.push({id:2,type:'Travel Agencies',value:ota})                      
                      setLastMonthData1(ls1);

                    let ls2 =[]; 
                      ls2.push({id:1,type:'Amount',value:formatter.format(amount)})
                      ls2.push({id:2,type:'Paid',value:formatter.format(paid)})
                      ls2.push({id:3,type:'Balance',value:formatter.format(balance)})                      
                      setLastMonthData2(ls2);

                    let ls3 =[]; 
                      ls3.push({id:1,type:'Open',value:open})   
                      ls3.push({id:2,type:'Closed',value:closed})   
                      ls3.push({id:3,type:'Invoiced',value:invoiced})  
                      ls3.push({id:4,type:'Cancelled',value:cancelled})                          
                      setLastMonthData3(ls3);

                    let ls4 =[];                      
                      ls4.push({id:1,type:'Arrival',value:checkin})
                      ls4.push({id:2,type:'Departure',value:checkout})
                      setLastMonthData4(ls4);

                }
                else
                {            
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();    
                    }
                    else
                    {
                    errorN(`getMonthToDateData:Allocation End - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                    
                }
            }
            else
            {            
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();    
                }
                else
                {
                errorN(`getMonthToDateData:Allocation Start - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }            
            }
            
          }
          else
          {            
            if(response.status === 401)
            {
                localStorage.removeItem('session');
                window.location.reload();    
            }
            else
            {
              errorN(`getDailyData:Folio List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
              
          }
        }
        catch(err) 
        {
          errorN(`Error DD: ${err}. Please contact the administrator.`);
        }   
    }

    const getRoomStatus = async () => {
        try
        {
          let vacant = 0;
          let vacantdirty = 0;
          let vacantcleaning = 0;
          let occupied = 0;
          let occupieddirty = 0;
          let occupiedcleaning = 0;
          let reserved = 0;
          let reserveddirty = 0;
          let reservedcleaning = 0;
          let blocked = 0;
  
            
          let obj = JSON.parse(localStorage.getItem('currentproperty'));
  
          let roomList = await get(`/api/hotels/${obj.id}/rooms`)
          if (response.ok)
          {
              let ls = roomList.sort((a, b) => (a.data.no > b.data.no) ? 1 : -1);  
  
              localStorage.setItem('roomlist', JSON.stringify(ls));
  
              //console.log('room roomList', ls);
  
              let date = new Date();
                  let a = date.toISOString();
                  let allocationLS = await get(`/api/hotels/${obj.id}/allocations?start=${a}`)
                  if (response.ok)
                  {
                      //('allocationLS', allocationLS);
  
                      for(let i of ls)
                      {                          
  
                          let flag = false;
  
                          
                          if(i.occupancy === 'vacant' && i.cleanliness === 'clean')
                          {
                              
  
                              for(let o of allocationLS)
                              {
                                  if (o.room != null)
                                  {
                                      if (o.status === "Blocked" && o.room === i.id)
                                      {
                                          flag = true; 
                                      }
                                  }
                                  
                              }
  
                              if(flag === false)
                              {
                                  vacant = vacant + 1;
                              }
                              else
                              {
                                  reserved = reserved + 1;
                              }
  
                          
                          }
                          else if (i.occupancy === "vacant" && i.cleanliness === "dirty")
                          {
                              for (let o in allocationLS)
                              {
                                  if (o.room != null)
                                  {
                                      if (o.status === "Blocked" && o.room === i.id)
                                      {
                                          flag = true;      
                                      }
                                  }
      
                              }
      
                              if (flag === false)
                              {
                                vacantdirty = vacantdirty + 1;
                              }
                              else
                              {
                                  reserveddirty = reserveddirty + 1;
                              }
                              
                          }
                          else if (i.occupancy === 'vacant' && i.cleanliness === 'cleaning')
                          {
                              for (let o of allocationLS)
                              {
                                  if (o.room != null)
                                  {
                                      if (o.status === "Blocked" && o.room === i.id)
                                      {
                                          flag = true;      
                                      }
                                  }
      
                              }
      
                              if (flag === false)
                              {
                                  vacantcleaning = vacantcleaning + 1;
                              }
                              else
                              {
                                  reservedcleaning = reservedcleaning + 1;
                              }
                              
                          }
                          else if (i.occupancy === 'occupied' && i.cleanliness === 'clean')
                          {
                              occupied = occupied + 1;
                          }
                          else if (i.occupancy === 'occupied' && i.cleanliness === 'dirty')
                          {
                              occupieddirty = occupieddirty + 1;
                          }
                          else if (i.occupancy === 'occupied' && i.cleanliness === 'cleaning')
                          {
                              occupiedcleaning = occupiedcleaning + 1;
                          }
                          else if (i.occupancy === 'reserved' && i.cleanliness === 'clean')
                          {
                              reserved = reserved + 1;
                          }
                          else if (i.occupancy === 'reserved' && i.cleanliness === 'dirty')
                          {
                               reserveddirty = reserveddirty + 1;
                          }
                          else if (i.occupancy === 'reserved' && i.cleanliness === 'cleaning')
                          {
                              reservedcleaning = reservedcleaning + 1;
                          }
                          else if (i.occupancy === 'blocked')
                          {
                              blocked = blocked + 1;
                          }
  
                      }
                        
                      

                      let ls1 =[];
                      ls1.push({id:1,type:'Vacant',value:vacant})
                      ls1.push({id:2,type:'Vacant Dirty',value:vacantdirty})
                      ls1.push({id:3,type:'Vacant Cleaning',value:vacantcleaning})
                      ls1.push({id:4,type:'Occupied',value:occupied})
                      ls1.push({id:5,type:'Occupied Dirty',value:occupieddirty})
                      ls1.push({id:6,type:'Occupied Cleaning',value:occupiedcleaning})
                      ls1.push({id:7,type:'Reserved',value:reserved})
                      ls1.push({id:8,type:'Reserved Dirty',value:reserveddirty})
                      ls1.push({id:9,type:'Reserved Cleaning',value:reservedcleaning})
                      ls1.push({id:10,type:'Blocked',value:blocked})                     

                      setRoomStatusPie(ls1);


                      let ls2 =[];                      
                     
                      ls2.push({id:1,type:'Vacant',value:vacant+vacantdirty+vacantcleaning})
                      ls2.push({id:2,type:'Occupied',value:occupied+occupieddirty+occupiedcleaning})
                      ls2.push({id:3,type:'Reserved',value:reserved+reserveddirty+reservedcleaning})
                      ls2.push({id:4,type:'Blocked',value:blocked})

                      setRoomStatusTable(ls2);
                    //   form.setFieldsValue({
                    //       rooms: table1
                    //   });
                  }
                  else
                  {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();    
                    }
                    else
                    {
                      errorN(`getRoomStatus:Allocation- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                      
                  }
          }
          else
          {
            //Room List- 401: Unauthorized. Please contact the administrator.
  
            if(response.status === 401)
            {
                localStorage.removeItem('session');
                window.location.reload();    
            }
            else
            {
              errorN(`getRoomStatus:Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
              
          }
        }
        catch(err) 
        {
          errorN(`Error LD: ${err}. Please contact the administrator.`);
        }   
    }

    const columns =[
        {
            width: '70%',
            title:"",
            dataIndex:"type",
            key:"id",            
        },       
        {
            width: '30%',
            title:"",
            dataIndex:"value",
            key:"id",
        },

    ]

    const config = {
        appendPadding: 10,
        data: roomStatusPie,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
          labelHeight: 28,
          content: '{name}\n{value}',
        },
        interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
      };

    const Charts = () => {
        return( 
            <>
             <div style={{backgroundcolor:'black', padding: '15px'}}>
                {/* <fieldset>
                    <legend>{`Current Unit Status (${moment(Date.now()).format('DD-MM-YYYY')})`}</legend>
                </fieldset> */}
                <Divider orientation="center"><PieChartOutlined />&nbsp;<b>{`Current Unit Status (${moment(Date.now()).format('DD/MM/YYYY')})`}</b></Divider>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{paddingRight: '10px'}}>
                        <Card  key="4"  size="small" style={{ width: '100%' }}>
                            <Table columns={columns} dataSource={roomStatusTable} key="id" showHeader={false} bordered={false} pagination={false}  size="small"/>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{paddingRight: '10px', height: '100%'}}>
                        <Pie {...config} />
                    </Col>
                    

                    
                </Row>
                
                
            </div> 
            </>
        )
    }

    const Cards = () => {
        return( 
            <>
            <div>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                    
                        <Card key="1" size="small" style={{ width: '100%', marginBottom: '20px'}}>
                        <Divider orientation="center"><PieChartOutlined />&nbsp;<b>{`Daily Statistic (${moment(Date.now()).format('DD/MM/YYYY')})`}</b></Divider>
                            <Divider plain orientation="left"><b>Booking Type</b></Divider>
                            <Table columns={columns} dataSource={dailyData1}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Revenue</b></Divider>
                            <Table columns={columns} dataSource={dailyData2}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Booking Status</b></Divider>
                            <Table columns={columns} dataSource={dailyData3}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Arivals & Departures</b></Divider>
                            <Table columns={columns} dataSource={dailyData4}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={6} xl={6} >

                        <Card key="2" size="small" style={{ width: '100%', marginBottom: '20px'}}>
                            <Divider orientation="center"><PieChartOutlined />&nbsp;<b>{`Day Before Statistic (${(moment(Date.now()).subtract(1, "days")).format('DD/MM/YYYY')})`}</b></Divider>
                            <Divider plain orientation="left"><b>Booking Type</b></Divider>
                            <Table columns={columns} dataSource={yesData1}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Revenue</b></Divider>
                            <Table columns={columns} dataSource={yesData2}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Booking Status</b></Divider>
                            <Table columns={columns} dataSource={yesData3}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Arivals & Departures</b></Divider>
                            <Table columns={columns} dataSource={yesData4}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                    <Card  key="3"  size="small" style={{ width: '100%', marginBottom: '20px'}}>
                    <Divider orientation="center"><PieChartOutlined />&nbsp;<b>{`To Date Statistic (01${moment(Date.now()).format('/MM')} - ${moment(Date.now()).format('DD/MM/YYYY')})`}</b></Divider>
                            <Divider plain orientation="left"><b>Booking Type</b></Divider>
                            <Table columns={columns} dataSource={monthToDateData1}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Revenue</b></Divider>
                            <Table columns={columns} dataSource={monthToDateData2}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Booking Status</b></Divider>
                            <Table columns={columns} dataSource={monthToDateData3}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Arivals & Departures</b></Divider>
                            <Table columns={columns} dataSource={monthToDateData4}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Card  key="4"  size="small"  style={{ width: '100%'}}>
                    <Divider orientation="center"><PieChartOutlined />&nbsp;<b>{`Last Month Statistic (${moment(Date.now()).add(-1, 'M').format('MM/YYYY')})`} </b></Divider>
                    <Divider plain orientation="left"><b>Booking Type</b></Divider>
                            <Table columns={columns} dataSource={lastMonthData1}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Revenue</b></Divider>
                            <Table columns={columns} dataSource={lastMonthData2}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Booking Status</b></Divider>
                            <Table columns={columns} dataSource={lastMonthData3}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                            <Divider plain orientation="left"><b>Arivals & Departures</b></Divider>
                            <Table columns={columns} dataSource={lastMonthData4}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                        </Card>
                    </Col>

                    {/* <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{paddingRight: '10px'}}>
                        <Card size="small" title={`Current Unit Status (${moment(Date.now()).format('DD-MM-YYYY')})`} extra={<A href="#">More</A>} style={{ width: '100%' }}>
                            <Table columns={columns} dataSource={roomStatusTable}  showHeader={false} bordered={false} pagination={false}  size="small"/>
                        </Card>
                    </Col> */}
                    
                </Row> 
            </div> 

           
            </>        
        )
    }

    return (
        <>
        <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        &nbsp;&nbsp;<BarChartOutlined  />
        &nbsp;&nbsp;Summary Report
        </div>    
        <Layout style={{height: isMobile === true ? '70vh':'74vh'}}>
           
            <Spin spinning={loading}>
            <Content> 
                {/* <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >    */}
                <div style={{padding: '20px', margin: '0px 0',height:'72vh',overflow:'auto'}}>
                    {/* <InfiniteScroll
                        pageStart={0}                        
                        hasMore={true || false}
                        useWindow={false}
                        loadMore={()=> {}}
                    > */}
                        <Cards />
                        <Charts />
                    {/* </InfiniteScroll>      */}
                </div>          
                    
                {/* </Form> */}
            </Content> 
            </Spin>           
        </Layout>
        {/*<div style={{ textAlign: 'center', fontSize: '12px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        {`©${state.year} Managed by Ratri Cloud`}
        </div>  */}
        
        </>
    );
}

export default Summary
