import React, {useState, useEffect, useCallback, useContext,useRef } from "react"
import {Button, Popconfirm, Form, Row, Col, Select, Input, Layout, Spin, Radio, Space, DatePicker} from "antd"
import ReactPlayer from 'react-player'

import {openNotificationSuccess, openNotificationError} from  "../../general"


import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    UserAddOutlined, SaveOutlined, VideoCameraOutlined, ProfileOutlined, FileExcelOutlined

} from '@ant-design/icons'
import {Context} from "../../store";
import InfiniteScroll from "react-infinite-scroller";
import {ShowReport} from "../Bookings/function";
import {Table} from "ant-table-extensions";
const {Sider, Content,Footer } = Layout;
const { Option} = Select;



function Videos(props) {
    const { get, post, response, loading, put } = useFetch();
    const playerRefCI = useRef(null);
    const playerRefCIB = useRef(null);
    const playerRefB = useRef(null);
    const playerRefE = useRef(null);
    const playerRefCU = useRef(null);
    const playerRefHK = useRef(null);
    const playerRefCO = useRef(null);

    const handleToggleFullscreen = (e) => {
        let player;

        if(e === 'ci')
        {
            player = playerRefCI.current;
        }

        if(e === 'cib')
        {
            player = playerRefCIB.current;
        }

        if(e === 'b')
        {
            player = playerRefB.current;
        }

        if(e === 'e')
        {
            player = playerRefE.current;
        }

        if(e === 'cu')
        {
            player = playerRefCU.current;
        }

        if(e === 'hk')
        {
            player = playerRefHK.current;
        }

        if(e === 'co')
        {
            player = playerRefCO.current;
        }



        if (player) {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                player.wrapper.requestFullscreen();
            }
        }
    };

    useEffect(() => {

        if(localStorage.getItem('session') === null)
        {
            navigate('/');
        }

        return () => {

        }
    }, [])



    return (

        <>
        <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
            &nbsp;&nbsp;<VideoCameraOutlined />
            &nbsp;&nbsp;User Guide Videos
        </div>
        <Layout>
            <Spin spinning={loading}>
            <Row>
                <Col xs={12} sm={12} md={24} lg={6} xl={6} >
                    <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px'}}>
                        <legend>Check-In</legend>
                        <ReactPlayer
                            ref={playerRefCI}
                            url="https://youtu.be/PlunB7qYJBw"
                            controls
                            width="100%"  // Adjust width as needed
                            height="35%" // Adjust height as needed
                        />
                        <br />
                        <Button type="primary" onClick={() => {handleToggleFullscreen('ci')}}>Play</Button>
                    </div>

                </Col>
                <Col xs={12} sm={12} md={24} lg={6} xl={6} >
                    <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px'}}>
                        <legend>Check-In Via Booking</legend>
                        <ReactPlayer
                            ref={playerRefCIB}
                            url="https://youtu.be/zjTQaMu66tA"
                            controls
                            width="100%"  // Adjust width as needed
                            height="35%" // Adjust height as needed
                        />
                        <br />
                        <Button type="primary" onClick={() => {handleToggleFullscreen('cib')}}>Play</Button>
                    </div>

                </Col>
                <Col xs={12} sm={12} md={24} lg={6} xl={6} >
                    <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px'}}>
                        <legend>Booking - How to make a booking</legend>
                        <ReactPlayer
                            ref={playerRefB}
                            url="https://youtu.be/wmuQ_g-mOak"
                            controls
                            width="100%"  // Adjust width as needed
                            height="35%" // Adjust height as needed
                        />
                        <br />
                        <Button type="primary" onClick={() => {handleToggleFullscreen('b')}}>Play</Button>
                    </div>

                </Col>
                <Col xs={12} sm={12} md={24} lg={6} xl={6} >
                    <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px'}}>
                        <legend>Extend Unit - How to extend a stay</legend>
                        <ReactPlayer
                            ref={playerRefE}
                            url="https://youtu.be/P1fyDPHo2z0"
                            controls
                            width="100%"  // Adjust width as needed
                            height="35%" // Adjust height as needed
                        />
                        <br />
                        <Button type="primary" onClick={() => {handleToggleFullscreen('e')}}>Play</Button>
                    </div>

                </Col>
            </Row>

            <Row>
                    <Col xs={12} sm={12} md={24} lg={6} xl={6} >
                        <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px'}}>
                            <legend>Change Unit - How to change a unit</legend>
                            <ReactPlayer
                                ref={playerRefCU}
                                url="https://youtu.be/u9KA5MQHTFI"
                                controls
                                width="100%"  // Adjust width as needed
                                height="35%" // Adjust height as needed
                            />
                            <br />
                            <Button type="primary" onClick={() => {handleToggleFullscreen('cu')}}>Play</Button>
                        </div>

                    </Col>
                    <Col xs={12} sm={12} md={24} lg={6} xl={6} >
                        <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px'}}>
                            <legend>Housekeeping - How to set housekeeping</legend>
                            <ReactPlayer
                                ref={playerRefHK}
                                url="https://youtu.be/KnS_0QS8T4U"
                                controls
                                width="100%"  // Adjust width as needed
                                height="35%" // Adjust height as needed
                            />
                            <br />
                            <Button type="primary" onClick={() => {handleToggleFullscreen('hk')}}>Play</Button>
                        </div>

                    </Col>
                    <Col xs={12} sm={12} md={24} lg={6} xl={6} >
                        <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px'}}>
                            <legend>Check Out</legend>
                            <ReactPlayer
                                ref={playerRefCO}
                                url="https://youtu.be/Xy9Zi053nVw"
                                controls
                                width="100%"  // Adjust width as needed
                                height="35%" // Adjust height as needed
                            />
                            <br />
                            <Button type="primary" onClick={() => {handleToggleFullscreen('co')}}>Play</Button>
                        </div>

                    </Col>
                    <Col xs={12} sm={12} md={24} lg={6} xl={6} >
                        <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px'}}>
                            <legend></legend>

                        </div>

                    </Col>
                </Row>

            </Spin>
        </Layout>
    </>
    )
}

export default Videos
