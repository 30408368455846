import React, {createContext,useReducer} from 'react'

const reducer = (state,action) => {
    switch (action.type) {
        case 'USER_SESSION':
            return {...state,session:action.payload}
        case 'USER_NAME':
            return {...state,name:action.payload}
        case 'USER_USERNAME':
            return {...state,username:action.payload}
        case 'USER_EMAIL':
            return {...state,email:action.payload}
        case 'USER_ROLE':
            return {...state,role:action.payload}
        case 'USER_INFO':
            return {...state,info:action.payload}
        case 'USER_REG':
            return {...state,register:action.payload}
        case 'USER_COMPANIES':
            return {...state,companies:action.payload}
        case 'USER_COMPANIESID':
            return {...state,companiesid:action.payload}
        case 'USER_ID':
            return {...state,id:action.payload}
        case 'USER_PROPERTIES':
            return {...state,properties:action.payload}
        case 'USER_CURRENTPROPERTY':
            return {...state,currentproperty:action.payload}
        case 'USER_DASHBOARDSTYLE':
            return {...state,dashboardstyle:action.payload}
        case 'SYS_AVAILABILITYMANUALOVERRIDE':
            return {...state,availabilitymanualoverride:action.payload}
        case 'SYS_STOPSALE':
            return {...state,stopsale:action.payload}
        case 'SYS_RATECONTROL':
            return {...state,ratecontrol:action.payload} 
        case 'SYS_DOORLOCK':
            return {...state,doorlock:action.payload}   
        case 'SYS_YEAR':
            return {...state,year:action.payload}
        case 'USER_RELOAD':
            return {...state,reload:action.payload}
        case 'RECONNECT':
            return {...state,reconnect:action.payload}
        default:
            return state
    }
}

let initialState = {
    username:'kuselan',
    reload: false,
    reconnect: false
}

const Store = ({children}) => {
    const [state,dispatch] = useReducer(reducer,initialState)

    return (
        <Context.Provider value={[state,dispatch]}>
            {children}
        </Context.Provider>
    )
}

export const Context = createContext(initialState)

export default Store
