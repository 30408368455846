import React, {useState,useEffect,useContext,useCallback} from 'react'

import {
    Divider,
    Spin,
    Avatar,
    Menu,
    Dropdown,
    Form,
    Card,
    Drawer,
    Layout,
    Tabs,
    Button,
    Input,
    Table,
    Space,
    Row,
    Col,
    Popconfirm,
    Popover,
    List,Tooltip
} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {openNotificationError, errorN, successN, version} from "../../general";
import { A,navigate} from "hookrouter";
import useFetch from "use-http";
import {
    BellOutlined,
    ProfileOutlined,
    SolutionOutlined,
    PhoneOutlined,
    HomeOutlined,
    ControlOutlined,
    DollarOutlined,
    ContainerOutlined,
    MinusSquareOutlined,
    BorderOutlined,
    RightCircleOutlined,
    LeftCircleOutlined,
    ReloadOutlined,
    CreditCardOutlined, StepForwardOutlined, GlobalOutlined, CalendarOutlined, EditOutlined


} from '@ant-design/icons'

import routes from "../../router"
import Store, {Context} from "../../store"
import moment from 'moment'
import FModal from "../Bookings/modal"
import RModal from "../Reports/modal"
import {ShowBooking} from "../Bookings/function";
import WhatsToday from "../Bookings/whatstoday";
import BookNow from "../Bookings/booknow";
import OTAModified from "../Reports/otamodified";
import EditBooking from "../Bookings/edit_booking";
import CheckInOut from "../Bookings/checkinout_room"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Dashboard(props) {

    const [state,dispatch] = useContext(Context)
    const { get, post, response, loading, error, del, put } = useFetch();
    const { get:getLocal, post:postLocal, response:responseLocal, loading:loadingLocal} = useFetch(`http://localhost:8023`);
    const [drawOpen, setDrawOpen] = useState(false);

    const [form] = Form.useForm()
    const [formLayout, setFormLayout] = useState('vertical');
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);

    }, [form])

    const [dataBooking, setDataBooking] = useState([]);
    const [dataArrival, setDataArrival] = useState([]);
    const [dataDeparture, setDataDeparture] = useState([]);

    const [channelOn, setChannelOn] = useState(false);

    const { height, width } = useWindowDimensions();

    const [cardstatus, setCardStatus] = useState(0);
    const [cardtype, setCardType] = useState('');
    const [cardData, setCardData] = useState([]);

    const [allowRewriteCard, setAllowRewriteCard] = useState(false);
    const [sysversion, setSysVersion] = useState(false);

    const [simply, setSimply] = useState(false);

    const [online, setOnline] = useState(true);

    const [page, setPage] = useState('');

    let isAlive = true;



    async function removeDuplicates(data) {
      let unique = data.reduce(function(a,b){
        if(a.indexOf(b) < 0) a.push(b);
        return a;
      },[]);
      return unique;
    };

    async function loadData() {
      loadDashboard();
      //loadArrival();
      //loadBooking();
      //loadDeparture();
    }

    async function loadDataX(){
        //do something
    }

    async function loadDashboard() {

      try
      {
        let vacant = 0;
        let vacantdirty = 0;
        let vacantcleaning = 0;
        let occupied = 0;
        let occupieddirty = 0;
        let occupiedcleaning = 0;
        let reserved = 0;
        let reserveddirty = 0;
        let reservedcleaning = 0;
        let blocked = 0;

        //localStorage.removeItem('taxlist');

        let obj = JSON.parse(localStorage.getItem('currentproperty'));

        let channelobj = JSON.parse(localStorage.getItem('channelsetting'));

          if(channelobj !== undefined && channelobj !== null)
          {
              if(channelobj.data !== undefined && channelobj.data !== null)
              {
                  if(channelobj.data.channelName !== undefined && channelobj.data.channelName !== null)
                  {
                      if(channelobj.data.channelName.trim() !== '')
                      {
                          setChannelOn(true);
                      }
                  }
              }
          }

        let customobj = JSON.parse(localStorage.getItem('customsetting'));

          if(customobj !== undefined && customobj !== null)
          {
              if(customobj.data !== undefined && customobj.data !== null)
              {
                  if(customobj.data.allowEditAccessCardDateTime !== undefined && customobj.data.allowEditAccessCardDateTime !== null)
                  {
                      setAllowRewriteCard(customobj.data.allowEditAccessCardDateTime);
                  }
              }
          }

        let roomList = await get(`/api/hotels/${obj.id}/rooms`)
        if (response.ok)
        {
            let ls = roomList.sort((a, b) => (a.data.no > b.data.no) ? 1 : -1);

            localStorage.setItem('roomlist', JSON.stringify(ls));


            let openAllocation = await get(`/api/hotels/${obj.id}/allocations?status=Occupied`)
            if (response.ok)
            {

                let date = new Date();
                let a = date.toISOString();
                let allocationLS = await get(`/api/hotels/${obj.id}/allocations?start=${a}`)
                if (response.ok)
                {
                    let t = moment(date).format('YYYY-MM-DD');

                    let table1 = [];

                    for(let i of ls)
                    {
                        let guestName = '';
                        let cin = '';
                        let cout = '';
                        let allo = 0;
                        let folioid = '';

                        if(i.allocation != null)
                        {
                            for(let x of openAllocation)
                            {
                                if(x.id === i.allocation)
                                {
                                    if(x.data.guest != null)
                                    {
                                        guestName = x.data.guest.name;
                                        cin = x.start;
                                        cout = x.end;
                                        folioid = x.folio;
                                    }
                                }
                            }

                        }

                        let flag = false;

                        for (let o of allocationLS)
                        {
                            if (o.room !== null)
                            {
                                if (o.status === "Blocked" && o.room === i.id)
                                {
                                    flag = true;

                                    if (o.data.guest != null)
                                    {
                                        guestName = o.data.guest.name;
                                        cin = o.start;
                                        cout = o.end;
                                        allo = o.id;
                                        folioid = o.folio;
                                    }

                                    if(flag === true && folioid === '')
                                    {
                                        i.occupancy = 'blocked';
                                        i.allocation = o.id;
                                    }
                                }
                            }

                        }

                        if(i.occupancy === 'vacant' && i.cleanliness === 'clean')
                        {
                            if(flag === false)
                            {
                                //System.Drawing.Image img = Properties.Resources.vacant;

                                table1.push({pic:'/images/vacant.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Available', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: '', housekeepingID: '', floor: i.data.floor, folioid:folioid});
                                vacant = vacant + 1;
                            }
                            else
                            {
                                //System.Drawing.Image img = Properties.Resources.reserved;

                                table1.push({pic:'/images/reserved.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Available', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: allo, housekeepingID: '', floor: i.data.floor, folioid:folioid});
                                vacant = vacant + 1;


                                //table1.push(await setBlocked(i,cin,cout,guestName,folioid,t));
                                //blocked = blocked + 1;
                            }

                        }
                        else if (i.occupancy === "vacant" && i.cleanliness === "dirty")
                        {
                            if (flag === false)
                            {
                                //System.Drawing.Image img = Properties.Resources.vacantHousekeeping;

                                table1.push({pic:'/images/vacantHousekeeping.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Available-Dirty', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: '', housekeepingID: '', floor: i.data.floor,folioid:folioid});
                                vacantdirty = vacantdirty + 1;
                            }
                            else
                            {
                                //System.Drawing.Image img = Properties.Resources.reservedHousekeeping;

                                table1.push({pic:'/images/reservedHousekeeping.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Available-Dirty', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: allo, housekeepingID: '', floor: i.data.floor, folioid:folioid});
                                vacantdirty = vacantdirty + 1;
                            }

                        }
                        else if (i.occupancy === 'vacant' && i.cleanliness === 'cleaning')
                        {
                            if (flag === false)
                            {
                                //System.Drawing.Image img = Properties.Resources.vacantHousekeepingCleaning;

                                table1.push({pic:'/images/vacantHousekeepingCleaning.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Available-Cleaning', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: '', housekeepingID: i.housekeeping, floor: i.data.floor,folioid:folioid});
                                vacantcleaning = vacantcleaning + 1;
                            }
                            else
                            {
                                //System.Drawing.Image img = Properties.Resources.reservedHousekeepingCleaning;

                                table1.push({pic:'/images/reservedHousekeepingCleaning.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Available-Cleaning', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: allo, housekeepingID: i.housekeeping, floor: i.data.floor, folioid:folioid});
                                vacantcleaning = vacantcleaning + 1;
                            }

                        }
                        else if (i.occupancy === 'occupied' && i.cleanliness === 'clean')
                        {
                            //System.Drawing.Image img = Properties.Resources.occupied;

                            if(t >= moment(cout).format('YYYY-MM-DD'))
                            {
                                table1.push({pic:'/images/occupieddue.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Occupied', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: '', floor: i.data.floor, folioid:folioid});
                                occupied = occupied + 1;
                            }
                            else
                            {
                                table1.push({pic:'/images/occupied.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Occupied', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: '', floor: i.data.floor, folioid:folioid});
                                occupied = occupied + 1;
                            }


                        }
                        else if (i.occupancy === 'occupied' && i.cleanliness === 'dirty')
                        {
                            //System.Drawing.Image img = Properties.Resources.occupiedHousekeeping;

                            table1.push({pic:'/images/occupiedHousekeeping.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Occupied-Dirty', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: '', housekeepingID: '', floor: i.data.floor, folioid:folioid});
                            occupieddirty = occupieddirty + 1;
                        }
                        else if (i.occupancy === 'occupied' && i.cleanliness === 'cleaning')
                        {
                            //System.Drawing.Image img = Properties.Resources.occupiedHousekeepingCleaning;

                            table1.push({pic:'/images/occupiedHousekeepingCleaning.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Occupied-Cleaning', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: i.housekeeping, floor: i.data.floor, folioid:folioid});
                            occupiedcleaning = occupiedcleaning + 1;
                        }
                        else if (i.occupancy === 'reserved' && i.cleanliness === 'clean')
                        {
                            //System.Drawing.Image img = Properties.Resources.reserved;

                            table1.push({pic:'/images/reserved.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Reserved', id: i.id, arrival: '', departure: '', smoking: i.data.smoking, action: '', name: '', bookingID: '', allocationID: '', housekeepingID: '', floor: i.data.floor, folioid:folioid});
                            reserved = reserved + 1;
                        }
                        else if (i.occupancy === 'reserved' && i.cleanliness === 'dirty')
                        {
                            //System.Drawing.Image img = Properties.Resources.reservedHousekeeping;

                            table1.push({pic:'/images/reservedHousekeeping.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Reserved-Dirty', id: i.id, arrival: '', departure: '', smoking: i.data.smoking, action: '', name: '', bookingID: '', allocationID: '', housekeepingID: '', floor: i.data.floor, folioid:folioid});
                            reserveddirty = reserveddirty + 1;
                        }
                        else if (i.occupancy === 'reserved' && i.cleanliness === 'cleaning')
                        {
                            //System.Drawing.Image img = Properties.Resources.reservedHousekeepingCleaning;

                            table1.push({pic:'/images/reservedHousekeepingCleaning.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Reserved-Cleaning', id: i.id, arrival: '', departure: '', smoking: i.data.smoking, action: '', name: '', bookingID: '', allocationID: '', housekeepingID: i.housekeeping, floor: i.data.floor, folioid:folioid});
                            reservedcleaning = reservedcleaning + 1;
                        }
                        else if (i.occupancy === 'blocked')
                        {
                            table1.push(await setBlocked(i,cin,cout,guestName,folioid,t));
                            blocked = blocked + 1;

                            /*let al = await get(`/api/allocations/${i.allocation}`)
                            if (response.ok)
                            {
                                let ss = al.start;
                                let ee = al.end;
                                //System.Drawing.Image img = Properties.Resources.housekeeping;
                                if(t >= moment(al.end).format('YYYY-MM-DD'))
                                {
                                    table1.push({pic:'/images/housekeepingdue.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Blocked', id: i.id, arrival: ss, departure: ee, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: '', floor: i.data.floor, folioid:folioid});
                                    blocked = blocked + 1;
                                }
                                else
                                {
                                    table1.push({pic:'/images/housekeeping.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Blocked', id: i.id, arrival: ss, departure: ee, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: '', floor: i.data.floor, folioid:folioid});
                                    blocked = blocked + 1;
                                }
                            }
                            else
                            {
                                table1.push({pic:'/images/housekeeping.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Blocked', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: '', floor: i.data.floor, folioid:folioid});
                                blocked = blocked + 1;
                            }*/

                        }

                    }


                    if(localStorage.getItem('dashboardstyle') === 'floor')
                    {
                      table1 = table1.sort((a, b)=> {
                        if (a.floor === b.floor){
                          return a.no < b.no ? -1 : 1
                        } else {
                          return a.floor < b.floor ? -1 : 1
                        }
                      })

                      let floorlist =[];
                      for(let i of table1)
                      {
                        floorlist.push(i.floor);
                      }
                      localStorage.setItem('dashboardorderlist', JSON.stringify(await removeDuplicates(floorlist)));
                    }
                    else if(localStorage.getItem('dashboardstyle') === 'roomType')
                    {
                      table1 = table1.sort((a, b)=> {
                        if (a.roomType === b.roomType){
                          return a.no < b.no ? -1 : 1
                        } else {
                          return a.roomType < b.roomType ? -1 : 1
                        }
                      })

                      let roomtypelist =[];
                      for(let i of table1)
                      {
                        roomtypelist.push(i.roomType);
                      }
                      localStorage.setItem('dashboardorderlist', JSON.stringify(await removeDuplicates(roomtypelist)));
                    }
                    else if(localStorage.getItem('dashboardstyle') === 'status')
                    {
                      table1 = table1.sort((a, b)=> {
                        if (a.status === b.status){
                          return a.no < b.no ? -1 : 1
                        } else {
                          return a.status < b.status ? -1 : 1
                        }
                      })

                      let statuslist =[];
                      for(let i of table1)
                      {
                        statuslist.push(i.status);
                      }
                      localStorage.setItem('dashboardorderlist', JSON.stringify(await removeDuplicates(statuslist)));
                    }
                    else // as roomtype
                    {
                        table1 = table1.sort((a, b)=> {
                            if (a.roomType === b.roomType){
                                return a.no < b.no ? -1 : 1
                            } else {
                                return a.roomType < b.roomType ? -1 : 1
                            }
                        })

                        let roomtypelist =[];
                        for(let i of table1)
                        {
                            roomtypelist.push(i.roomType);
                        }
                        localStorage.setItem('dashboardorderlist', JSON.stringify(await removeDuplicates(roomtypelist)));
                    }

                    form.setFieldsValue({
                        rooms: table1
                    });

                }
                else
                {
                  if(response.status === 401)
                  {
                    localStorage.removeItem('session');
                    window.location.reload();
                  }
                  else
                  {
                    errorN(`Open Allocation- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                  }

                }

            }
            else
            {
              if(response.status === 401)
              {
                localStorage.removeItem('session');
                window.location.reload();
              }
              else
              {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
              }

            }
        }
        else
        {
          //Room List- 401: Unauthorized. Please contact the administrator.

          if(response.status === 401)
          {
            localStorage.removeItem('session');
            window.location.reload();
          }
          else
          {
            errorN(`Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
          }

        }
      }
      catch(err)
      {
        errorN(`Error LD: ${err}. Please contact the administrator.`);
      }

    }

    async function setBlocked(i,cin,cout,guestName,folioid,t){

        let al = await get(`/api/allocations/${i.allocation}`)
        if (response.ok)
        {
            let ss = al.start;
            let ee = al.end;
            //System.Drawing.Image img = Properties.Resources.housekeeping;
            if(t >= moment(al.end).format('YYYY-MM-DD'))
            {
                return {pic:'/images/housekeepingdue.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Blocked', id: i.id, arrival: ss, departure: ee, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: '', floor: i.data.floor, folioid:folioid};

            }
            else
            {
                return {pic:'/images/housekeeping.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Blocked', id: i.id, arrival: ss, departure: ee, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: '', floor: i.data.floor, folioid:folioid};

            }
        }
        else
        {
            return {pic:'/images/housekeeping.png', roomNo: i.data.no, roomType: i.data.service.name, status: 'Blocked', id: i.id, arrival: cin, departure: cout, smoking: i.data.smoking, action: '', name: guestName, bookingID: '', allocationID: i.allocation, housekeepingID: '', floor: i.data.floor, folioid:folioid};

        }
    }

    async function loadBooking() {
        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));
            let date = new Date();
            let a = date.toISOString();
            let bookingList = await get(`/api/hotels/${obj.id}/bookings?start=${a}`)
            if (response.ok)
            {
                let bookingListPast = await get(`/api/hotels/${obj.id}/noshow`)
                if (response.ok)
                {
                    let ls = bookingList.filter(x => x.no !== null && x.status !== 'Cancelled' && x.status !== 'Closed');
                    let lsPast = bookingListPast.filter(x => x.no !== null && x.status !== 'Cancelled' && x.status !== 'Closed');

                    let table1 = [];

                    for(let i of ls)
                    {
                        if(i.no !== null)
                        {
                            table1.push({
                                id: i.id,
                                status: i.status,
                                chekedin: i.checkedin,
                                no: i.no,
                                name: i.data.payor.name,
                                source: i.source,
                                otaagency: i.data.payor.otaagency == null ? 'Walk In' : i.data.payor.otaagency.name,
                                refno: i.data.payor.refno,
                                start: i.data.stay.arrival,
                                end: i.data.stay.departure
                            })
                        }
                    }

                    for(let i of lsPast)
                    {
                        if(i.no !== null)
                        {
                            table1.push({
                                id: i.id,
                                status: i.status,
                                chekedin: i.checkedin,
                                no: i.no,
                                name: '(' + moment(i.data.stay.arrival).local().format('DD-MM-YYYY') + ') ' + i.data.payor.name,
                                source: i.source,
                                otaagency: i.data.payor.otaagency == null ? 'Walk In' : i.data.payor.otaagency.name,
                                refno: i.data.payor.refno,
                                start: i.data.stay.arrival,
                                end: i.data.stay.departure
                            })
                        }
                    }

                    setDataBooking(table1);

                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Past Booking List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Booking List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error LB: ${err}. Please contact the administrator.`);
        }
    }

    async function loadArrival() {
        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = await get(`/api/hotels/${obj.id}/rooms`)
            if (response.ok)
            {


                let date = new Date();
                let a = date.toISOString();
                let allocationList = await get(`/api/hotels/${obj.id}/allocations?start=${a}`)
                if (response.ok)
                {

                    for(let i of allocationList)
                    {
                        if (i.room === null)
                        {
                            i.data.tempID = `Unit No.`;
                        }
                        else
                        {
                            for(let x of roomList)
                            {
                                if (x.id === i.room)
                                {
                                    i.data.tempID = x.data.no;
                                }
                            }
                        }
                    }

                    let ls = allocationList.filter(x => x.folio !== null && x.status !== 'Closed');

                    let table1 =[];

                    for(let i of ls)
                    {
                        if(i.no !== null)
                        {
                            table1.push({
                                id: i.id,
                                status: i.status,
                                checkin: i.checkin,
                                checkout: i.checkout,
                                unitno: i.data.tempID,
                                unittype: i.data.item.name,
                                name: i.data.guest.name,
                                folioid: i.folio,
                                itemid: i.item,
                                start: i.start,
                                end: i.end
                            })
                        }
                    }

                   setDataArrival(table1);

                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error LA: ${err}. Please contact the administrator.`);
        }
    }

    async function loadDeparture() {
        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = await get(`/api/hotels/${obj.id}/rooms`)
            if (response.ok)
            {


                let date = new Date();
                let a = date.toISOString();
                let allocationList = await get(`/api/hotels/${obj.id}/allocations?end=${a}`)
                if (response.ok)
                {
                    let allocationListPast = await get(`/api/hotels/${obj.id}/late`)
                    if (response.ok)
                    {
                        for(let i of allocationList)
                        {
                            if (i.room === null)
                            {
                                i.data.tempID = `Unit No.`;
                            }
                            else
                            {
                                for(let x of roomList)
                                {
                                    if (x.id === i.room)
                                    {
                                        i.data.tempID = x.data.no;
                                    }
                                }
                            }
                        }

                        for(let i of allocationListPast)
                        {
                            if (i.room === null)
                            {
                                i.data.tempID = `Unit No.`;
                            }
                            else
                            {
                                for(let x of roomList)
                                {
                                    if (x.id === i.room)
                                    {
                                        i.data.tempID = x.data.no;
                                    }
                                }
                            }
                        }

                        let ls = allocationList.filter(x => x.folio !== null && x.data !== null);
                        let lsPast = allocationListPast.filter(x => x.folio !== null && x.data !== null);

                        let table1 =[];

                        for(let i of ls)
                        {
                           if(i.no !== null)
                            {
                                table1.push({
                                    id: i.id,
                                    status: i.status,
                                    checkin: i.checkin,
                                    checkout: i.checkout,
                                    unitno: i.data.tempID,
                                    unittype: i.data.item.name,
                                    name: i.data.guest !== null ? i.data.guest.name : '',
                                    folioid: i.folio,
                                    start: i.start,
                                    end: i.end
                                })
                            }
                        }

                        for(let i of lsPast)
                        {
                            if(i.no !== null)
                            {
                                table1.push({
                                    id: i.id,
                                    status: i.status,
                                    checkin: i.checkin,
                                    checkout: i.checkout,
                                    unitno: i.data.tempID,
                                    unittype: i.data.item.name,
                                    name: i.data.guest !== null ? '(' + i.data.departure + ') ' + i.data.guest.name : '',
                                    folioid: i.folio,
                                    start: i.start,
                                    end: i.end
                                })
                            }
                        }

                        setDataDeparture(table1);

                    }
                    else
                    {
                        if(response.status === 401)
                        {
                            localStorage.removeItem('session');
                            window.location.reload();
                        }
                        else
                        {
                            errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        }

                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error LD: ${err}. Please contact the administrator.`);
        }

    }

    async function circularReplacer() {

        // Creating new WeakSet to keep
        // track of previously seen objects
        const seen = new WeakSet();

        return (key, value) => {

            // If type of value is an
            // object or value is null
            if (typeof(value) === "object"
                && value !== null) {

                // If it has been seen before
                if (seen.has(value)) {
                    return;
                }

                // Add current value to the set
                seen.add(value);
            }

            // return the value
            return value;
        };
    }

    async function result(event) {

        let arrx = event.currentTarget.innerText.split('\n')
        localStorage.setItem('temproomno', arrx[0].trim());


        const values = form.getFieldsValue(true);

        for(let i of values.rooms)
        {
            if(arrx[0].trim() === i.roomNo)
            {
                localStorage.setItem('temproomdetails', JSON.stringify(i));
            }
        }

        /*let arr = event.target.innerText.split('\n')
        console.log("before3",event.currentTarget.innerText);
        if(arr[0].trim() !== '')
        {
            localStorage.setItem('temproomno', arr[0].trim());
            console.log("before check",arr[0].trim())
        }
        else
        {
           let arrx = event.currentTarget.innerText.split('\n')
            localStorage.setItem('temproomno', arrx[0].trim());
            console.log("before check 2",arrx[0].trim());
        }*/
    }

    function checkReload()
    {

        if(localStorage.getItem('openmodal') !== 'true' && localStorage.getItem('setreload') === 'true')
        {
            localStorage.setItem('setreload', 'false');
            loadData();
            dispatch({type:'USER_RELOAD',payload: false});
        }
    }

    useEffect(() => {


        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {
            //setTimeout(myFunction, 35000)
            //setInterval(function() {checkReload()}, 3000);

            setSysVersion(version());

            if(localStorage.getItem('page') === 'dashboard')
            {
                setPage('home');
            }

            loadData();
        }

        //loadData().then(r => {});


        return () => {

        }
    },[state.currentproperty,state.dashboardstyle])

    useEffect(() => {

        if(state.reload === true)
        {
            loadData();
            dispatch({type:'USER_RELOAD',payload: false});

            /*if(localStorage.getItem('openmodal') !== 'true')
            {
                loadData();
                dispatch({type:'USER_RELOAD',payload: false});
                localStorage.setItem('setreload', 'false');
            }
            else
            {
                localStorage.setItem('setreload', 'true');
            }*/
        }

        return () => {

        }
    },[state.reload])

    useEffect(() => {

        setInterval(myFunction, 10000);

        return () => {

        }
    },[])

    const myFunction = () => {

        //console.log('websocket',localStorage.getItem('websocket'))

        let obj = localStorage.getItem('websocket');
        let session = localStorage.getItem('session');
        let connected = localStorage.getItem('connected');

        isAlive = obj === 'alive' ? true : false;

        if(session === 'true')
        {
            if(isAlive === true)
            {
                //console.log("g1")
                setOnline(true);
            }
            else
            {
                //console.log("g2")
                if(connected === 'true')
                {
                    //dispatch({type:'RECONNECT',payload: true});
                    window.location.reload(true);  //<--undo this for production
                }
                setOnline(false);
            }
            //setTimeout(myFunction, 35000)
        }
    }

    const Cards = () => {
        return(
            <Form.List name="rooms" >
                {(fields, { add, remove }) => {

                    const values = form.getFieldsValue(true);
                    let dashboardOrderList= JSON.parse(localStorage.getItem('dashboardorderlist'));
                    let dashboardstyle = localStorage.getItem('dashboardstyle') !== 'undefined' ? localStorage.getItem('dashboardstyle') : 'roomType' ;

                    let nm = dashboardstyle === 'roomType' ? 'type' : dashboardstyle;

                    dashboardOrderList = dashboardOrderList !== null ? dashboardOrderList : [];


                    return (

                        <div style={{marginLeft: 5}}>

                              {dashboardOrderList.map((fieldX, indexX) => {
                                return (
                                  <Card title={nm !== undefined && nm !== null ? `${nm.toUpperCase()} : ${dashboardOrderList[indexX]}`  : ''}
                                  headStyle={{padding: 0, margin: 0}} bordered={false} style={{margin: 0}}
                                  >
                                  {fields.map((field, index) => {
                                      return (
                                          <Space direction="vertical" key={field.key} >
                                              {values.rooms[index][dashboardstyle] === dashboardOrderList[indexX] ?
                                              <>
                                                <Dropdown overlay={eval(`menu${values.rooms[index].status.replace('-','')}${values.rooms[index].allocationID !== '' && values.rooms[index].status.replace('-','') === 'Available' ? 'Reserved': ''}`)} placement="bottomCenter" trigger={['click']} arrow>
                                                    <Tooltip title={
                                                        <>
                                                            <div>{`${values.rooms[index].roomNo} - ${values.rooms[index].status}`}</div>

                                                            {values.rooms[index].name !== undefined && values.rooms[index].name !== null && values.rooms[index].name !== '' ?
                                                                <div><b>{`${values.rooms[index].name.toUpperCase()}`}</b></div>
                                                                :
                                                                null
                                                            }

                                                            {values.rooms[index].arrival !== undefined && values.rooms[index].arrival !== null && values.rooms[index].arrival !== '' ?
                                                                <div>{`${moment(values.rooms[index].arrival).format('DD-MM-YYYY')} - ${moment(values.rooms[index].departure).format('DD-MM-YYYY')}`}</div>
                                                                :
                                                                null
                                                            }

                                                        </>
                                                        }
                                                    >
                                                        <Card.Grid
                                                        onClick={result}
                                                        hoverable ={true}
                                                        style={{padding: '10px', marginRight: '5px' ,marginBottom: '5px',width: 150}}
                                                        >
                                                            <Meta
                                                                avatar={<Avatar src={`${values.rooms[index].pic}`} alt={`${values.rooms[index].roomNo}`}/>}
                                                                title= {`${values.rooms[index].roomNo}`}
                                                                //description={values.rooms[index].roomType}
                                                            />
                                                            <br />{titleCase(values.rooms[index].roomType)}
                                                        </Card.Grid>
                                                    </Tooltip>
                                                </Dropdown>
                                              </>
                                              : null
                                              }

                                          </Space>
                                      )})}
                                </Card>
                                )})}
                        </div>


                    );
                }}
            </Form.List>
        )
    }

    const menuOccupied = (
        <Menu>
            <Menu.Item>
                {/*<FModal formname={'checkinout'} loadData={loadDataX} />*/} {/*Check Out*/}
                <A href={'/dashboard'} onClick={() => {setPage('manage')}}>Manage</A>
            </Menu.Item>
          <Menu.Item>
              {/*<FModal formname={'update'} loadData={loadDataX} />*/} {/*Check Out*/}
              <A href={'/dashboard'} onClick={() => {setPage('update')}}>Update</A>
          </Menu.Item>
          <Menu.Item>
              <Popconfirm
                  title="Are you sure want to Set Dirty this unit?"
                  onConfirm={() => {setDirty().then(r => {})}}
                  /*onCancel={}*/
                  okText="Yes"
                  cancelText="No"
              >
                  Set Dirty
              </Popconfirm>
          </Menu.Item>
        </Menu>
    );
    const menuAvailable = (
        <Menu>
            <Menu.Item>
                <Popconfirm
                    title="Are you sure want to Set Dirty this unit?"
                    onConfirm={() => {setDirty().then(r => {})}}
                    /*onCancel={}*/
                    okText="Yes"
                    cancelText="No"
                >
                    Set Dirty
                </Popconfirm>
            </Menu.Item>
            <Menu.Item>
                <FModal formname={'blockroom'} loadData={loadDataX} /> {/*Check Out*/}
            </Menu.Item>
        </Menu>
    );

    const menuAvailableReserved = (
        <Menu>
            <Menu.Item>
                {/*<FModal formname={'checkinout'} loadData={loadDataX} />*/}
                <A href={'/dashboard'} onClick={() => {setPage('manage')}}>Manage</A>
            </Menu.Item>
            <Menu.Item>
                <Popconfirm
                    title="Are you sure want to Set Dirty this unit?"
                    onConfirm={() => {setDirty().then(r => {})}}
                    /*onCancel={}*/
                    okText="Yes"
                    cancelText="No"
                >
                    Set Dirty
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );
    const menuBlocked = (
        <Menu>
            <Menu.Item>
                <Popconfirm
                    title="Are you sure want to Release this unit?"
                    onConfirm={() => {releaseUnit().then(r => {})}}
                    /*onCancel={}*/
                    okText="Yes"
                    cancelText="No"
                >
                    Release Unit
                </Popconfirm>
            </Menu.Item>

        </Menu>
    );

    const menuAvailableDirty = (
        <Menu>
            <Menu.Item>
                <FModal formname={'sethousekeeper'} loadData={loadDataX} /> {/*Check Out*/}
            </Menu.Item>
        </Menu>
    );

    const menuAvailableCleaning = (
        <Menu>
            <Menu.Item>
                <Popconfirm
                    title="Are you sure want to Set Clean this unit?"
                    onConfirm={() => {setClean().then(r => {})}}
                    /*onCancel={}*/
                    okText="Yes"
                    cancelText="No"
                >
                    Set Clean
                </Popconfirm>
            </Menu.Item>
          {/*<Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
              Issue Card
            </a>
          </Menu.Item>   */}
        </Menu>
    );

    const menuOccupiedDirty = (
        <Menu>
            <Menu.Item>
               {/* <FModal formname={'update'} loadData={loadDataX} />*/} {/*Check Out*/}
                <A href={'/dashboard'} onClick={() => {setPage('update')}}>Update</A>
            </Menu.Item>

              <Menu.Item>
                  <FModal formname={'sethousekeeper'} loadData={loadDataX} /> {/*Check Out*/}
              </Menu.Item>

            {/*<Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    Issue Card
                </a>
            </Menu.Item>*/}
        </Menu>
    );

    const menuOccupiedCleaning = (
        <Menu>
            <Menu.Item>
                {/*<FModal formname={'update'} loadData={loadDataX} />*/}
                <A href={'/dashboard'} onClick={() => {setPage('update')}}>Update</A>
            </Menu.Item>
            <Menu.Item>
                <Popconfirm
                    title="Are you sure want to Set Clean this unit?"
                    onConfirm={() => {setClean().then(r => {})}}
                    /*onCancel={}*/
                    okText="Yes"
                    cancelText="No"
                >
                    Set Clean
                </Popconfirm>
            </Menu.Item>
          {/*<Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
              Issue Card
            </a>
          </Menu.Item>*/}
        </Menu>
    );

    /*const menuReserved = (
        <Menu>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
              Check In
            </a>
          </Menu.Item>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
              Set Dirty
            </a>
          </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    <FModal obj={senddata('blockroom')} formname={'blobkroom'} loadData={loadData} /> {/!*Check Out*!/}
                </a>
            </Menu.Item>
        </Menu>
    );

    const menuReservedDirty = (
        <Menu>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
              Start Cleaning
            </a>
          </Menu.Item>

        </Menu>
    );

    const menuReservedCleaning = (
        <Menu>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
              Set Clean
            </a>
          </Menu.Item>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
              Issue Card
            </a>
          </Menu.Item>
        </Menu>
    );*/

    function titleCase(str) {
        str = str.toLowerCase().split(' ');
        for (let i = 0; i < str.length; i++) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    };

    function senddata(e, id){
        let obj = {};
        if(e === 'showbooking')
        {
            obj = {
                bookingid: id

            }
        }

        return obj;
    }

    const columnsBooking = [
      {
        title: 'Booking No.',
        dataIndex: 'no',
        width: '30%',
      },
      {
        title: 'Guest Name',
        dataIndex: 'name',
        width: '45%',
      },
        {
            title: '',
            dataIndex: 'action1',
            key: 'action1',
            width: '15%',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Are you sure want to proceed this action?`}
                        onConfirm={() => createfolio(record.id)}
                        okText="Yes"
                        cancelText="No">
                        <a>Create Folio</a>
                    </Popconfirm>

                </>
            )
        },
        {
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            width: '10%',
            render: (text, record) => (
                <>
                     {/*<ShowBooking obj={senddata('showbooking',record.id)}/>*/}
                    <ShowBooking id={record.id}/>
                </>
            )
        },
    ];

    const columnsArrival = [
      {
        title: 'Unit Type',
        dataIndex: 'unittype',
        width: '25%',
      },
      {
        title: 'Unit No.',
        dataIndex: 'unitno',
        width: '15%',
      },
      {
        title: 'Guest Name',
        dataIndex: 'name',
        width: '35%',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '15%',
        render: (text, record) => (
            <>
                {record.unitno === 'Unit No.' ?
                    <FModal formname={'setroom'} obj={record} loadData={loadDataX} />
                    :
                    record.status === 'Blocked' || record.status === 'Occupied' ?
                        <FModal formname={'checkinout'} obj={record} loadData={loadDataX} />
                        :
                        null
                }
            </>
        )
      },
        {
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            width: '10%',
            render: (text, record) => (
                <FModal formname={'update'} obj={record} loadData={loadDataX} />
            )
        }

    ];

    const columnsDeparture =[
        {
            title: 'Unit Type',
            dataIndex: 'unittype',
            width: '25%',
        },
        {
            title: 'Unit No.',
            dataIndex: 'unitno',
            width: '15%',
        },
        {
            title: 'Guest Name',
            dataIndex: 'name',
            width: '35%',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '15%',
            render: (text, record) => (
                <>
                    <FModal formname={'checkinout'} obj={record} loadData={loadDataX} />
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            width: '10%',
            render: (text, record) => (
                <FModal formname={'update'} obj={record} loadData={loadDataX} />
            )
        }

    ];

    /*function senddata() {
        let globalObj = JSON.parse(localStorage.getItem('temproomdetails'));

        return globalObj;
    }*/

    async function setDirty() {
        let propsObj = JSON.parse(localStorage.getItem('temproomdetails'));

        try{
            let a = await post(`/api/rooms/${propsObj.id}/housekeeping`)
            if (response.ok)
            {
                successN('Set Dirty successfully');
                //loadData();
            }
            else
            {
                errorN(`Set Dirty - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Set Dirty: ${err}. Please contact the administrator.`);
        }
    }

    async function setClean() {
        let propsObj = JSON.parse(localStorage.getItem('temproomdetails'));

        try{
            let a = await put(`/api/housekeepings/${propsObj.housekeepingID}`)
            if (response.ok)
            {
                successN('Set Clean successfully');
                //loadData();
            }
            else
            {
                errorN(`Set Clean - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Set Clean: ${err}. Please contact the administrator.`);
        }
    }

    async function releaseUnit() {
        let propsObj = JSON.parse(localStorage.getItem('temproomdetails'));

        try{
            let a = await put(`/api/allocations/${propsObj.allocationID}`)
            if (response.ok)
            {
                successN('Unit released successfully');
                //loadData();
            }
            else
            {
                errorN(`Release Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Release Unit: ${err}. Please contact the administrator.`);
        }
    }

    async function readCard(){
        try {
            let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));

            if(devicesetting !== null)
            {
                if(devicesetting.data !== undefined)
                {
                    if(devicesetting.data.doorLock !== undefined)
                    {
                        let cardi = await getLocal(`/card_read?code=${devicesetting.data.doorLock.code}&type=${devicesetting.data.doorLock.type}`)
                        if (responseLocal.status === 200)
                        {
                            if(cardi === '' || cardi === null || cardi === undefined)
                            {
                                setCardStatus(2);
                            }
                            else
                            {
                                setCardStatus(1);

                                if ((cardi.room.room === '0' && cardi.room.floor === '0' && cardi.room.building === '0') || (cardi.room.room === 0 && cardi.room.floor === 0 && cardi.room.building === 0) )
                                {
                                    setCardType('A');

                                    let ls = [];

                                    ls.push({title: 'Door Code',desc: cardi.room.doorId !== undefined && cardi.room.doorId !== null ? cardi.room.doorId : 'No data'});
                                    ls.push({title: 'Guest SN.',desc: cardi.serial !== undefined && cardi.serial !== null ? cardi.serial : 'No data'});
                                    ls.push({title: 'Guest Index',desc: cardi.count !== undefined && cardi.count !== null ? cardi.count : 'No data'});
                                    ls.push({title: 'Check-Out',desc: cardi.checkout !== undefined && cardi.checkout !== null ? cardi.checkout : 'No data'});

                                    setCardData(ls);
                                }
                                else
                                {
                                    setCardType('B');

                                    let ls = [];

                                    ls.push({title: 'Unit No.',desc: cardi.room.room !== undefined && cardi.room.room !== null ? cardi.room.room : 'No data'});
                                    ls.push({title: 'Floor No.',desc: cardi.room.floor !== undefined && cardi.room.floor !== null ? cardi.room.floor : 'No data'});
                                    ls.push({title: 'Building No.',desc: cardi.room.building !== undefined && cardi.room.building !== null ? cardi.room.building : 'No data'});
                                    ls.push({title: 'Check-Out',desc: cardi.checkout !== undefined && cardi.checkout !== null ? moment(cardi.checkout).format('DD-MM-YYYY hh:mm:ss A') : 'No data'});

                                    setCardData(ls);
                                }
                            }
                        }
                        else
                        {
                            setCardStatus(0);
                        }
                    }
                }
            }
        }
        catch (e) {
            errorN(e.message);
        }
    }

    async function deleteCard(){
        try {
            let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));

            if(devicesetting !== null)
            {
                if(devicesetting.data !== undefined)
                {
                    if(devicesetting.data.doorLock !== undefined)
                    {
                        let a = await getLocal(`/card_remove?code=${devicesetting.data.doorLock.code}&type=${devicesetting.data.doorLock.type}`)
                        if (responseLocal.status === 200)
                        {
                            successN(`Deleted Successfully.`);
                        }
                        else
                        {
                            errorN(`Failed: Delete Access Card Unsuccessful.`);
                        }
                    }
                }
            }
        }
        catch (e) {
            errorN(e.message);
        }
    }

    const datas = [
        {
            title: 'Access Card Successful.',
            desc: 'No Data available.'
        },
    ];
    const dataerror = [
        {
            title: 'Failed: Read Access Card Unsuccessful.',
            desc: 'Please contact the administrator.'
        },
    ];
    const text = <span>Access Card Details</span>;
    const content = (
        <div>
            {cardstatus === 1 ?
                cardtype === 'A' ?
                    <>
                        <br />
                        <p>No Data available.</p>
                        <br />
                    </>
                    :
                    cardtype === 'B' ?
                        <>
                            <List
                                itemLayout="horizontal"
                                dataSource={cardData}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={item.title}
                                            description={item.desc}
                                        />
                                    </List.Item>
                                )}
                            />
                        </>
                        :
                        <>
                            <br />
                            <p>No Data available.</p>
                            <br />
                        </>
                :
                <>
                    <List
                        itemLayout="horizontal"
                        dataSource={cardstatus === 2 ? datas : dataerror}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={item.desc}
                                />
                            </List.Item>
                        )}
                    />
                </>
            }
        </div>
    );

    async function createfolio(bookingID) {
        //let bookingID = localStorage.getItem('tempbookingid');
        let customerID = '';
        let customerType = '';

        try
        {
            let a = await get(`/api/bookings/${bookingID}`)
            if (response.ok)
            {
                if(a.data.payor !== undefined && a.data.payor !== null)
                {
                    if(a.data.payor.guest !== undefined && a.data.payor.guest !== null)
                    {
                        if(a.data.payor.guest.id !== undefined && a.data.payor.guest.id !== null)
                        {
                            customerID = a.data.payor.guest.id;
                        }
                    }

                    if(a.data.payor.type !== undefined && a.data.payor.type !== null)
                    {
                        customerType = a.data.payor.type;
                    }
                }

                /*console.log('full booking', a);
                return;*/
                if(customerID === undefined || customerID === null || customerID === '' || customerID === 0 || customerID === 'undefined')
                {
                    let guest = {
                        designation: '',
                        gender: '',
                        type: customerType,
                        guestCompany: a.data.payor.guestCompany
                    };



                    let party = {
                        category : a.data.payor.category,
                        data : {
                            name : a.data.payor.name,
                            email : a.data.payor.email,
                            tel : a.data.payor.tel,
                            address : a.data.payor.address && Object.keys(a.data.payor.address).length !== 0 && a.data.payor.address.constructor === Object ? a.data.payor.address : {
                                line : '',
                                city : '',
                                postcode : '',
                                state : '',
                                country : ''
                            },
                            client : null,
                            company : null,
                            guest : guest,
                            staff : null,
                            supplier : null,
                            identificationType : a.data.payor.identification && Object.keys(a.data.payor.identification).length !== 0 && a.data.payor.identification.constructor === Object ? a.data.payor.identification.name : '',
                            identificationNo : a.data.payor.identification && Object.keys(a.data.payor.identification).length !== 0 && a.data.payor.identification.constructor === Object ? a.data.payor.identification.id : '',
                            nationality :a.data.payor.nationality.name,
                            belongsTo: null
                        }
                    };


                    let c = await post(`/api/parties`, party)
                    if (response.ok)
                    {
                        let x = response.headers.get('location');
                        customerID = x;
                    }
                    else
                    {
                        errorN(`Create Party - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        return;
                    }
                }

                let g = {id: customerID, name: a.data.payor.name};
                a.data.payor.guest = g;

                let json = {
                    party: customerID,
                    booking: a
                }


                let b = await post(`/api/bookings/${bookingID}/folio`, json)
                if (response.ok)
                {
                    //props.loadData();
                    //setFoliocreated(true);
                    //clearForm();
                    successN('Create Folio Successful');
                    //loadArrival();
                    //loadBooking();
                }
                else
                {
                    errorN(`Create Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
            else
            {
                errorN(`Get Booking - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error BOOK: ${err}. Please contact the administrator.`);
        }

    }

    const Header = (e) => {
        //console.log('niva', e.title)
        return (
            <>
                {(() => {

                    switch (e.title) {
                        case 'home':
                            return (
                                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                                    &nbsp;&nbsp;<HomeOutlined />
                                    &nbsp;&nbsp;Home
                                </div>
                            )
                        case 'whatsnew':
                            return (
                                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                                    &nbsp;&nbsp;<PhoneOutlined />
                                    &nbsp;&nbsp;What's Today!
                                </div>
                            )
                        case 'booknow':
                            return (
                                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                                    &nbsp;&nbsp;<PhoneOutlined />
                                    &nbsp;&nbsp;Book Now!
                                </div>
                            )
                        case 'otamodified':
                            return (
                                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                                    &nbsp;&nbsp;<GlobalOutlined />
                                    &nbsp;&nbsp;OTA Modified Entry
                                </div>
                            )
                        case 'manage':
                            return (
                                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                                    &nbsp;&nbsp;<RightCircleOutlined  />
                                    &nbsp;&nbsp;Manage Booking
                                </div>
                            )
                        case 'update':
                            return (
                                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                                    &nbsp;&nbsp;<EditOutlined />
                                    &nbsp;&nbsp;Update Folio
                                </div>
                            )
                        default:
                            return (
                                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                                    &nbsp;&nbsp;<HomeOutlined />
                                    &nbsp;&nbsp;Home
                                </div>
                            )
                    }

                })()}

            </>
        )
    }

    function loadBack() {
        setPage('home');
        loadData();
    }

    return (
        <>
        {/*<div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        &nbsp;&nbsp;<HomeOutlined />
        &nbsp;&nbsp;Home
        </div>*/}

        <Header title={page} />

        <Layout>

            <Sider style={{backgroundImage:`url("/header-bg3.png")`,height: "73vh"}}>
            <br />
                <Menu theme="default" mode="inline">
                    <Menu.Item key="booknow" style={{ color: 'red' }}>
                        <Button type="danger" style={{ width: '100%', height: '100%' }}><A href={'/dashboard'} onClick={() => {setPage('booknow')}}>Book Now!</A></Button>
                    </Menu.Item>

                    <Menu.Item key="home" icon={<HomeOutlined/>}>
                        <A href={'/dashboard'} onClick={() => {setPage('home');loadData();}}>Home</A>
                    </Menu.Item>
                    <Menu.Item key="whatsnew" icon={<BellOutlined/>}>
                        {/*<A href={'/dashboard'} onClick={() => {setDrawOpen(true)}}>What's Today!</A>*/}
                        <A href={'/dashboard'} onClick={() => {setPage('whatsnew')}}>What's Today!</A>
                    </Menu.Item>

                   {/* <Menu.Item key="whatsnew" icon={<BellOutlined/>}>
                        <FModal formname={'whatstoday'} loadData={loadDataX} />
                    </Menu.Item>*/}
                   {/* <Menu.Item key="scheduler" icon={<CalendarOutlined />}>
                        <FModal formname={'scheduler'}  />
                    </Menu.Item>*/}
                   {/* <Menu.Item key="booknow" icon={<PhoneOutlined />}>
                        <FModal formname={'booknow'} loadData={loadDataX} />
                    </Menu.Item>*/}

                    {/*{channelOn === true ?
                        <Menu.Item key="booking" icon={<ProfileOutlined />}>
                            <RModal formname={'bookings'} />
                        </Menu.Item>
                        :
                        null
                    }*/}

                    {/*<Menu.Item key="folio" icon={<SolutionOutlined />}>
                        <RModal formname={'folios'} />
                    </Menu.Item>*/}

                    {channelOn === true ?
                        <>
                            <Menu.Item key="otamodified" icon={<GlobalOutlined />}>
                               {/* <RModal formname={'otamodified'} />*/}
                                <A href={'/dashboard'} onClick={() => {setPage('otamodified')}}>OTA Modified Entry</A>
                            </Menu.Item>

                        </>
                        :
                        null
                    }

                    {simply === true ?
                        <>
                            {channelOn === true ?
                                state.role === 'administrator' || state.role === 'manager' || state.role === 'supervisor' ?
                                    <>

                                        <Menu.Item key="oamo" icon={<ControlOutlined />}>
                                            <A href={'/dashboard'} onClick={() => {localStorage.setItem('page', 'dashboard')}}>Online Availability Manual Override</A>
                                        </Menu.Item>
                                        <Menu.Item key="osc" icon={<ContainerOutlined />}>
                                            <A href={'/dashboard'} onClick={() => {localStorage.setItem('page', 'dashboard')}}>Online Sales Control</A>
                                        </Menu.Item>
                                        <Menu.Item key="orc" icon={<DollarOutlined />}>
                                            <A href={'/dashboard'} onClick={() => {localStorage.setItem('page', 'dashboard')}}>Online Rate Control</A>
                                        </Menu.Item>
                                    </>
                                    :
                                    <>
                                        {state.availabilitymanualoverride === true ?
                                            <Menu.Item key="oamo" icon={<ControlOutlined />}>
                                                <A href={'/dashboard'} onClick={() => {localStorage.setItem('page', 'dashboard')}}>Online Availability Manual Override</A>
                                            </Menu.Item>
                                            : null}

                                        {state.stopsale === true ?
                                            <Menu.Item key="osc" icon={<ContainerOutlined />}>
                                                <A href={'/dashboard'} onClick={() => {localStorage.setItem('page', 'dashboard')}}>Online Sales Control</A>
                                            </Menu.Item>
                                            : null}

                                        {state.ratecontrol === true ?
                                            <Menu.Item key="orc" icon={<DollarOutlined />}>
                                                <A href={'/dashboard'} onClick={() => {localStorage.setItem('page', 'dashboard')}}>Online Rate Control</A>
                                            </Menu.Item>
                                            : null}

                                    </>
                                :
                                null
                            }
                        </>

                        :
                        null
                    }


                    {state.doorlock === true ?
                    <>
                       {/* <Menu.Item key="readcard" icon={<BorderOutlined />}>
                            <A href={'/dashboard'} onClick={() => {readCard()}}>Read Access Card</A>
                        </Menu.Item>*/}

                        {allowRewriteCard === true ?
                        <>
                            <Menu.Item key="rewritecard" icon={<CreditCardOutlined />}>
                                <FModal formname={'rewritecard'} loadData={loadDataX} />
                            </Menu.Item>
                        </>
                            :
                            null
                        }

                        <Menu.Item key="readCard" icon={<BorderOutlined />}>
                            <Popover placement="right" title={text} content={content} trigger="click">
                                <A href={'/dashboard'} onClick={() => {readCard()}}>Read Access Card</A>
                            </Popover>
                        </Menu.Item>

                        <Menu.Item key="deletecard" icon={<MinusSquareOutlined />}>
                            <A href={'/dashboard'} onClick={() => {deleteCard()}}>Delete Access Card</A>
                        </Menu.Item>

                    </>
                    :
                    null}

                    <Menu.Item key="test" icon={<ReloadOutlined  />}>
                        <A href={''} onClick={() => {loadData()}}>Refresh Now!</A>
                    </Menu.Item>

                </Menu>
            </Sider>

            <Spin spinning={loading}>

                <Content style={{backgroundColor: 'white'}}>

                <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                    <div style={{backgroundColor: 'white', padding: '20px', margin: '0px 0',height:'73vh',overflow:'auto',width:`${width-210}px`}}>
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={true || false}
                        useWindow={false}
                    >

                        {(() => {

                            switch (page) {
                                case 'home':
                                    return (
                                        <Cards/>
                                    )
                                case 'whatsnew':
                                    return (
                                        <WhatsToday/>
                                    )
                                case 'booknow':
                                    return (
                                        <BookNow />
                                    )
                                case 'otamodified':
                                    return (
                                        <OTAModified/>
                                    )
                                case 'manage':
                                    return (
                                        <CheckInOut obj={props.obj} loadData={props.loadDataX} inhouse={true} loadBack={loadBack} />
                                    )
                                case 'update':
                                    return (
                                        <EditBooking obj={props.obj} loadData={props.loadDataX} inhouse={true} loadBack={loadBack} />
                                    )
                                default:
                                    return (
                                        <Cards/>
                                    )
                            }

                        })()}

                    </InfiniteScroll>

                    </div>
                </Form>
            </Content>
            </Spin>
        </Layout>
        <div style={{ textAlign: 'center', color: online === false ? 'red' : 'black', fontSize: '12px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        {/*{`©${state.year} Managed by Ratri Cloud`} &#9728; {`Ver. ${sysversion}`}*/}
            {online === false ?
                <b>
                    {`©${state.year} Managed by Ratri Cloud`} &#9728; {`Ver. ${sysversion}  [SYSTEM OFFLINE]`}
                </b>
                :
                <>
                    {`©${state.year} Managed by Ratri Cloud`} &#9728; {`Ver. ${sysversion}`}
                </>
            }
        </div>
        <Drawer
          title="What's Today!"
          placement="right"
          closable={false}
          width={650}
          onClose={() => {setDrawOpen(false)}}
          visible={drawOpen}
          bodyStyle={{ paddingBottom: 0 }}
        //   footer={
        //     <div
        //       style={{
        //         textAlign: 'right',
        //       }}
        //     >
        //       <Button onClick={this.onClose} style={{ marginRight: 8 }}>
        //         Cancel
        //       </Button>
        //       <Button onClick={this.onClose} type="primary">
        //         Submit
        //       </Button>
        //     </div>
        //   }
        >
        <div style={{backgroundColor: 'white', padding: '10px',paddingBottom: '20px', margin: '0px 0'}}>
        {/* <InfiniteScroll
            pageStart={0}
            hasMore={true || false}
            useWindow={false}
        > */}

            {channelOn === true ?
                <>
                    <Divider plain orientation="left"><RightCircleOutlined /> Bookings</Divider>
                    <Table columns={columnsBooking} pagination={false} dataSource={dataBooking} scroll={{ y: 150 }} size ={'small'} loading={loading} style={{ height: 177 }}/>
                </>
            :
                null
            }

          <Divider style={{ paddingTop: 10 }} plain orientation="left"><RightCircleOutlined /> Arrivals</Divider>
          <Table columns={columnsArrival} pagination={false} dataSource={dataArrival} scroll={{ y: 150 }} size ={'small'} loading={loading} style={{ height: 177}} />

          <Divider style={{ paddingTop: 10 }} plain orientation="left"><LeftCircleOutlined /> Departures</Divider>
          <Table columns={columnsDeparture} pagination={false} dataSource={dataDeparture} scroll={{ y: 150 }} size ={'small'} loading={loading} style={{ height: 177 }} />

          {/* </InfiniteScroll> */}
        </div>
        </Drawer>

        </>
    );
}

export default Dashboard
