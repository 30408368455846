import React, {useState,useEffect,useContext,useCallback} from 'react'

import {
    Tag,
    Image,
    Result,
    Checkbox,
    Descriptions,
    InputNumber,
    DatePicker,
    Modal,
    Popconfirm,
    Steps,
    Radio,
    Select,
    Divider,
    Spin,
    Avatar,
    Menu,
    Dropdown,
    Form,
    Card,
    Drawer,
    Layout,
    Tabs,
    Button,
    Input,
    Space,
    Row,
    Col,
    Upload
} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    VerticalAlignBottomOutlined,
    ReconciliationOutlined,
    ShoppingCartOutlined,
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined,
    LockOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined,
    FileExcelOutlined,
    SearchOutlined,
    ProfileOutlined,
    UploadOutlined,
    DollarOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN, errorN, openNotificationSuccess, openNotificationError} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import { Table } from "ant-table-extensions";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";

const { TextArea } = Input;
const { Option } = Select;
const {Sider, Content,Footer } = Layout;

function RatePlan(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [data, setData] = useState([]);
    const [dataR, setDataR] = useState([]);
    const [dataA, setDataA] = useState([]);

    const [propertyList, setPropertyList] = useState([]);


    const [serviceData, setServiceData] = useState([]);
    const [serviceDataA, setServiceDataA] = useState([]);

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');

    const [single, setSingle] = useState(true);

    const [edit, setEdit] = useState(false);

    const [fileList, setFileList] = useState([]);

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput=node;
                    }}
                    placeholder={`Search ${caption}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
        /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };

    useEffect(() => {

        loadProperty();

        return () => {

        }
    }, [])

    async function loadProperty() {
        try
        {
            let list = JSON.parse(localStorage.getItem('allproperties'));

            let ls = [];
            for(let i of list)
            {
                ls.push({id: i.id.toString(), name: i.data.name})
            }

            setPropertyList(ls);
        }
        catch (err)
        {
            errorN(`Error Load Property: ${err}. Please contact the administrator.`);
        }
    }

    const onSubmit = () => {

        form.validateFields().then(values => {

            if(values.type === 'single')
            {
                AssignNow(values).then(r => {});
            }
            else
            {
                saveMe(values).then(r => {});
            }

        })

    }

    async function saveMe(values) {

        if(values.property === undefined || values.property === null || values.property === '')
        {
            errorN(`Choose a property to proceed.`);
            return;
        }

       let itemListx;
        try
        {
            let itemList = await get(`/api/hotels/${values.property}/items`)
            if (response.ok)
            {
                for (let i of itemList)
                {
                    i.name = i.data.name
                }

                itemListx = itemList.filter(x => x.data.type.includes("reservation"));
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Services - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    return;
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
            return;
        }


        let objList=[];
        let cancelflg = false;

        let fx = values.upload !== undefined ? values.upload.map(o => o.originFileObj) : null;
        delete values.upload;

        let files = fx, f = files[0];
        let reader = new FileReader();
        reader.onload = async function (e) {
            let data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});


            for(let item of dataParse)
            {
                if(item.length !== 10) {
                    cancelflg = true;
                    break;
                }

                for(let j of item)
                {
                    if(j === undefined || j === null)
                    {
                        cancelflg = true;
                        break;
                    }
                }



                if(item[0] !== 'Service Type') {
                    let rooms = {};

                    if(await checkExistPlan(item[1].toString()) === false)
                    {
                        rooms.hotel = values.property;
                        rooms.item = (itemListx.find(obj => obj.data.name.toString() === item[0].toString())).id;

                        rooms.data = {
                            bed: '',
                            building: parseInt(item[4].toString()),
                            description: item[5].toString(),
                            doorCode: item[6].toString(),
                            floor: parseInt(item[3].toString()),
                            no: item[1].toString(),
                            room: parseInt(item[2].toString()),
                            service: {
                                id: (itemListx.find(obj => obj.data.name.toString() === item[0].toString())).id,
                                name: item[0].toString(),
                            },
                            type: '',
                            smoking: item[7],
                            guest: {
                                adult: parseInt(item[8].toString()),
                                child: parseInt(item[9].toString())
                            }
                        };

                        objList.push(rooms);
                    }
                }
            }


            //console.log('what', objList)


            if(cancelflg === false)
            {
                let y = 0;
                let x = 0;
                for(let roomx of objList)
                {
                    let n =await post(`/api/rooms`,roomx)
                    if (response.ok)
                    {
                        x = x+1;

                    }
                    else
                    {
                        y = y+1;
                    }
                }

                if(y === 0)
                {
                    let propertyid = values.property;
                    successN('Unit Updated successfully');
                    //getItemList(propertyid);
                    form.resetFields();
                    setFileList([]);

                    form.setFieldsValue({property: propertyid});
                    setSingle(true);
                }
                else
                {
                    if(x !== 0)
                    {
                        let propertyid = values.property;
                        errorN('Upload Unit successful partially. Please check for the missing units, and re-upload.');
                        //getItemList(propertyid);
                        form.resetFields();
                        setFileList([]);

                        form.setFieldsValue({property: propertyid});
                        setSingle(true);
                    }
                    else
                    {
                        errorN(`Upload failed. Please contact the administrator.`);
                    }
                }
            }
            else
            {
                errorN(`Upload failed - Data missing. Please make sure all columns and rows are filled with complete data.`);
            }



        };
        reader.readAsBinaryString(f)


    }

    async function AssignNow(values) {
        try {
            //const values = form.getFieldsValue(true); //console.log(values)

            if(values.property === undefined || values.property === null || values.property === '')
            {
                errorN(`Choose a property to proceed.`);
                return;
            }

            let itemListx;
            try
            {
                let itemList = await get(`/api/hotels/${values.property}/items`)
                if (response.ok)
                {
                    for (let i of itemList)
                    {
                        i.name = i.data.name
                    }

                    itemListx = itemList.filter(x => x.data.type.includes("reservation"));
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Get Services - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        return;
                    }

                }
            }
            catch (err)
            {
                errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
                return;
            }

            if(values.id === undefined || values.id === null || values.id === '')
            {
                if(await checkExistPlan(values.unitno) === true)
                {
                    errorN(`Display No. ${values.unitno} is Exist. Please change and try again.`);
                    return;
                }

                let rooms = {};

                rooms.hotel = values.property;
                rooms.item = values.service;

                rooms.data = {
                    bed: values.bed ? values.bed : '',
                    building: parseInt(values.building),
                    description: values.desc ? values.desc : '',
                    doorCode: values.doorCode ? values.doorCode : '',
                    floor: parseInt(values.floor),
                    no: values.unitno,
                    room: parseInt(values.no),
                    service: {
                        id: values.service,
                        name: (itemListx.find(obj => obj.id.toString() === values.service.toString())).data.name
                    },
                    type: values.unittype,
                    smoking: (values.smoking === 'true'),
                    guest: {
                        adult: values.adult ? parseInt(values.adult) : 0,
                        child: values.child ? parseInt(values.child) : 0
                    }
                };

                let a = await post(`/api/rooms`,rooms)
                if (response.ok)
                {
                    let propertyid = values.property;
                    successN('Unit Created successfully');
                    //await getItemList(propertyid);
                    form.resetFields();

                    form.setFieldsValue({property: propertyid});
                }
                else
                {
                    errorN(`Create Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                let rooms = await get(`/api/rooms/${values.id}`)
                if (response.ok)
                {
                    rooms.data = {
                        bed: values.bed ? values.bed : '',
                        building: parseInt(values.building),
                        description: values.desc ? values.desc : '',
                        doorCode: values.doorCode ? values.doorCode : '',
                        floor: parseInt(values.floor),
                        no: values.unitno,
                        room: parseInt(values.no),
                        service: {
                            id: values.service,
                            name: (itemListx.find(obj => obj.id.toString() === values.service.toString())).data.name
                        },
                        type: values.unittype,
                        smoking: (values.smoking === 'true'),
                        guest: {
                            adult: values.adult ? parseInt(values.adult) : 0,
                            child: values.child ? parseInt(values.child) : 0
                        }
                    };


                    let a = await put(`/api/rooms/${values.id}`,rooms)
                    if (response.ok)
                    {
                        let propertyid = values.property;
                        successN('Unit Updated successfully');
                        //getItemList(propertyid);
                        form.resetFields();

                        form.setFieldsValue({property: propertyid});
                    }
                    else
                    {
                        errorN(`Update Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Update Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }


        }
        catch (err)
        {
            errorN(`Error Get UserX: ${err}. Please contact the administrator.`);
        }
    }

    async function getRate(id) {
        try
        {
            let b = await get(`/api/rates/${id}`)
            if (response.ok)
            {
                if(b.package == false)
                {
                    b.idR = b.id;
                    b.subtype = 'single';
                    b.price = b.data.price;
                    setEdit(true);
                    setSingle(true);
                }
                else
                {
                    b.idR = b.id;
                    b.subtype = 'package';
                    b.price = b.data.price;
                    b.packagename = b.data.name;

                    setEdit(true);
                    setSingle(false);

                    try
                    {
                        let itemList = await get(`/api/rates/${id}/rates`)
                        if (response.ok)
                        {
                            for (let i of itemList)
                            {
                                i.name = i.data.name;
                                i.unit = i.data.unit;
                                i.revenue = i.data.revenue;
                                i.uom = i.data.uom;
                            }

                            setDataA(itemList);
                        }
                        else
                        {
                            if(response.status === 401)
                            {
                                localStorage.removeItem('session');
                                window.location.reload();
                            }
                            else
                            {
                                errorN(`Get Package - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                                return;
                            }

                        }
                    }
                    catch (err)
                    {
                        errorN(`Error Package List: ${err}. Please contact the administrator.`);
                        return;
                    }

                }

                form.setFieldsValue(b);

                getItemList().then(r => {});
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Unit: ${err}. Please contact the administrator.`);
        }
    }

    async function saveRate(){
        try
        {
            form.validateFields().then(values => {
                if(values.idR === undefined || values.idR === null || values.idR === '')  //save
                {
                    saveR();
                }
                else
                {
                    updateR();
                }
            });
        }
        catch (err)
        {
            errorN(`Error Save Rate: ${err}. Please contact the administrator.`);
        }
    }

    async function saveR(){
        try
        {
            const values = form.getFieldsValue(true);

            if(values.subtype === 'single')
            {
                const idExists = dataR.some(x => x.item === values.item);
                if(idExists)
                {
                    errorN(`Invalid: The Rate for this Item Exist. Please choose other Item to proceed.`);
                    return;
                }

                const selectedValue = values.item;
                let selectedObj = serviceData.find(item => item.id === selectedValue);

                let rtItem = {
                        type : values.rateplantype,
                        package : false,
                        item : values.item,
                        hotel : values.property,
                        plan : values.idP,
                        parent: null,
                        party: null,
                        data : {
                            plan : values.rateplan,
                            name : selectedObj.name,
                            price : values.price,
                            uom : selectedObj.uom,
                            wubook : '',
                            unit: 0.0,
                            revenue: 0.0,
                            pricePercentage: 0
                        }
                };

                //console.log('rtItem',rtItem)

                let a = await post(`/api/rates`,rtItem)
                if (response.ok)
                {
                    successN('Rate Created successfully');
                    await getRateList(values.rateplan,values.idP,values.rateplantype);
                    //form.resetFields();

                    form.setFieldsValue({packagename : undefined,
                        item : undefined,
                        price : undefined,
                        addon : undefined,
                        addonunit : undefined,
                        addonprice : undefined, idR: undefined });

                    setDataA([]);
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Save Rate Plan - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }



            }
            else
            {
                const idExists = dataR.some(x => x.data.name === values.packagename);
                if(idExists)
                {
                    errorN(`Invalid: The Package Name Exist. Please change the package name to proceed.`);
                    return;
                }

                const selectedValue = values.item;
                let selectedObj = serviceData.find(item => item.id === selectedValue);

                let rtItem = {
                    type : values.rateplantype,
                    package : true,
                    item : values.item,
                    hotel : values.property,
                    plan : values.idP,
                    parent: null,
                    party: null,
                    data : {
                        plan : values.rateplan,
                        name : values.packagename,
                        price : values.price,
                        uom : selectedObj.uom,
                        wubook : '',
                        unit: 0.0,
                        revenue: 0.0,
                        pricePercentage: 0
                    }
                };

                let a = await post(`/api/rates`,rtItem)
                if (response.ok)
                {
                    let locationHeader = response.headers.get('Location');
                    //console.log('Location:', locationHeader);

                    for(let i of dataA)
                    {
                        let rtItem2 = {
                            type : values.rateplantype,
                            package : true,
                            item : i.item,
                            hotel : values.property,
                            plan : values.idP,
                            parent: locationHeader,
                            party: null,
                            data : {
                                name : i.name,
                                price : 0,
                                uom : i.uom,
                                unit: i.unit,
                                revenue: i.revenue,
                                pricePercentage: 0,
                                wubook : '',
                            }
                        };

                        let b = await post(`/api/rates`,rtItem2)
                        if (response.ok)
                        {

                        }
                        else
                        {
                            if(response.status === 401)
                            {
                                localStorage.removeItem('session');
                                window.location.reload();
                            }
                            else
                            {
                                errorN(`Save Package Item Unsuccessful - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }

                        }
                    }

                    successN('Package Created successfully');
                    await getRateList(values.rateplan,values.idP,values.rateplantype);
                    //form.resetFields();

                    clearMe2();

                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Save Package - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }

            }
        }
        catch (err)
        {
            errorN(`Error Save Package: ${err}. Please contact the administrator.`);
        }
    }

    async function updateR(){
        try
        {
            const values = form.getFieldsValue(true);

            if(values.subtype === 'single')
            {
                try
                {
                    let item = await get(`/api/rates/${values.idR}`)
                    if (response.ok)
                    {
                        //const selectedValue = values.item;
                        //let selectedObj = serviceData.find(item => item.id === selectedValue);

                        item.data.price = values.price;
                        //item.data.uom = selectedObj.uom;
                        //item.data.wubook = '';

                        let a = await put(`/api/rates/${values.idR}`,item)
                        if (response.ok)
                        {
                            successN('Rate Updated successfully');
                            await getRateList(values.rateplan,values.idP,values.rateplantype);
                            //form.resetFields();

                            form.setFieldsValue({packagename : undefined,
                                item : undefined,
                                price : undefined,
                                addon : undefined,
                                addonunit : undefined,
                                addonprice : undefined, idR: undefined });

                            setDataA([]);
                        }
                        else
                        {
                            if(response.status === 401)
                            {
                                localStorage.removeItem('session');
                                window.location.reload();
                            }
                            else
                            {
                                errorN(`Update Rate - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }

                        }
                    }
                    else
                    {
                        if(response.status === 401)
                        {
                            localStorage.removeItem('session');
                            window.location.reload();
                        }
                        else
                        {
                            errorN(`Update Rate - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        }

                    }
                }
                catch (err)
                {
                    errorN(`Error Update Rate: ${err}. Please contact the administrator.`);
                }
            }
            else
            {
                try
                {
                    let item = await get(`/api/rates/${values.idR}`)
                    if (response.ok)
                    {
                        item.data.price = values.price;

                        let a = await put(`/api/rates/${values.idR}`,item)
                        if (response.ok)
                        {
                            for(let i of dataA)
                            {
                                if(i.id === -1) // new item
                                {
                                    let rtItem2 = {
                                        type : values.rateplantype,
                                        package : true,
                                        item : i.item,
                                        hotel : values.property,
                                        plan : values.idP,
                                        parent: values.idR,
                                        party: null,
                                        data : {
                                            name : i.name,
                                            price : 0,
                                            uom : i.uom,
                                            unit: i.unit,
                                            revenue: i.revenue,
                                            pricePercentage: 0,
                                            wubook : '',
                                        }
                                    };

                                    let b = await post(`/api/rates`,rtItem2)
                                    if (response.ok)
                                    {

                                    }
                                    else
                                    {
                                        if(response.status === 401)
                                        {
                                            localStorage.removeItem('session');
                                            window.location.reload();
                                        }
                                        else
                                        {
                                            errorN(`Update Package Item Unsuccessful - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                                        }

                                    }
                                }
                                else
                                {
                                    //not doing any update
                                }

                            }

                            successN('Package Updated successfully');
                            await getRateList(values.rateplan,values.idP,values.rateplantype);
                            clearMe2();
                        }
                        else
                        {
                            if(response.status === 401)
                            {
                                localStorage.removeItem('session');
                                window.location.reload();
                            }
                            else
                            {
                                errorN(`Update Rate - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }

                        }
                    }
                    else
                    {
                        if(response.status === 401)
                        {
                            localStorage.removeItem('session');
                            window.location.reload();
                        }
                        else
                        {
                            errorN(`Update Rate - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        }

                    }
                }
                catch (err)
                {
                    errorN(`Error Update Package: ${err}. Please contact the administrator.`);
                }
            }
        }
        catch (err)
        {
            errorN(`Error Update Rate1: ${err}. Please contact the administrator.`);
        }
    }

    async function removeRate(id, skip) {
        try
        {
            const values = form.getFieldsValue(true);
            let obj = JSON.parse(localStorage.getItem('channelsetting'));

            if(obj !== undefined && obj !== null)
            {
                if(obj.data.channelName !== '')
                {
                    errorN(`NOT ALLOWED: This property has been synced to a Channel Manager`);
                    return;
                }
            }

            if (id === undefined || id === null || id === '')
            {
                errorN("ID not found. Please contact the administrator.");
                return;
            }


            let a = await del(`/api/rates/${id}`)
            if (response.ok)
            {
                //let propertyid = values.property;
                successN('Rate Removed successfully');

                //form.resetFields();

                if(skip !== true)
                {
                    await getRateList(values.rateplan,values.idP,values.rateplantype);
                    clearMe2();
                }

            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Remove Plan - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Remove Plan: ${err}. Please contact the administrator.`);
        }
    }

    const columns = [

        {
            width: '15%',
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <a  onClick={() => getRateList(record.name,record.id,record.type)}>
                        <EditOutlined twoToneColor="blue"/>
                        &nbsp;
                        Edit
                    </a>
                </>
            )
        },
        {
            width: '45%',
            title:"Rate Plan",
            dataIndex:"name",
            key:"name",
            /*...getColumnSearchProps('name','No'),
            sorter: (a, b) => a.name.localeCompare(b.name),*/
        },
        {
            width: '30%',
            title:"Type",
            dataIndex:"type",
            key:"type",
            /*...getColumnSearchProps('name','No'),
            sorter: (a, b) => a.name.localeCompare(b.name),*/
        },
        {
            width: '10%',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Do you want to remove this?`}
                        onConfirm={() => removeRatePlan(record.id)}
                        okText="Yes"
                        cancelText="No">
                        <a>
                            <CloseCircleOutlined style={{color: 'red'}}/>
                        </a>
                    </Popconfirm>

                </>
            )
        },
    ]

    const columnsR = [

        {
            width: '15%',
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <a  onClick={() => getRate(record.id)}>
                        <EditOutlined twoToneColor="blue"/>
                        &nbsp;
                        Edit
                    </a>
                </>
            )
        },
        {
            width: '45%',
            title:"No",
            dataIndex:"name",
            key:"name",
            ...getColumnSearchProps('name','No'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            width: '10%',
            title:"Unit",
            dataIndex:"uom",
            key:"uom",
            ...getColumnSearchProps('uom','Unit'),
            sorter: (a, b) => a.uom.localeCompare(b.uom),
        },
        {
            width: '10%',
            title:"Price",
            dataIndex:"price",
            key:"price",
            ...getColumnSearchProps('price','Price'),
            sorter: (a, b) => a.price.localeCompare(b.price),
        },
        {
            width: '10%',
            title:"Type",
            dataIndex:"type",
            key:"type",
            ...getColumnSearchProps('type','Type'),
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            width: '10%',
            title:"Package",
            dataIndex:"packagex",
            key:"packagex",
            ...getColumnSearchProps('packagex','Package'),
            sorter: (a, b) => a.packagex.localeCompare(b.packagex),
        },
        {
            width: '10%',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Do you want to remove this?`}
                        onConfirm={() => removeRate(record.id)}
                        okText="Yes"
                        cancelText="No">
                        <a>
                            <CloseCircleOutlined style={{color: 'red'}}/>
                        </a>
                    </Popconfirm>

                </>
            )
        },
    ]

    const columnsA = [
        {
            width: '40%',
            title:"Item",
            dataIndex:"name",
            key:"name",
            /*...getColumnSearchProps('name','No'),
            sorter: (a, b) => a.name.localeCompare(b.name),*/
        },
        {
            width: '15%',
            title:"Unit",
            dataIndex:"unit",
            key:"unit",
            /*...getColumnSearchProps('name','No'),
            sorter: (a, b) => a.name.localeCompare(b.name),*/
        },
        {
            width: '15%',
            title:"Price",
            dataIndex:"revenue",
            key:"revenue",
            /*...getColumnSearchProps('name','No'),
            sorter: (a, b) => a.name.localeCompare(b.name),*/
        },
        {
            width: '15%',
            title:"UOM",
            dataIndex:"uom",
            key:"uom",
            /*...getColumnSearchProps('name','No'),
            sorter: (a, b) => a.name.localeCompare(b.name),*/
        },
        {
            width: '15%',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Do you want to remove this?`}
                        onConfirm={() => removeAddon(record.item, record.id)}
                        okText="Yes"
                        cancelText="No">
                        <a>
                            <CloseCircleOutlined style={{color: 'red'}}/>
                        </a>
                    </Popconfirm>

                </>
            )
        },
    ]

    async function getRatePlanList(e){
        let id = e;

        await getItemList();

        try
        {
            let roomList = await get(`/api/hotels/${id}/plans`)
            if (response.ok)
            {
                /*for (let i of roomList)
                {
                    i.name = i.data.no
                }*/

                setData(roomList);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Plans - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Plans List: ${err}. Please contact the administrator.`);
        }

    }

    async function getRateList(plan,id, type){

        const values = form.getFieldsValue(true);

        try
        {
            let rateList = await get(`/api/hotels/${values.property}/rates?plan=${plan}`)
            if (response.ok)
            {
                for (let i of rateList)
                {
                    i.name = i.data.name;
                    i.uom = i.data.uom;
                    i.price = i.data.price;
                    i.packagex = i.package.toString();
                }

                setDataR(rateList);

                form.setFieldsValue({idP: id, rateplan: plan, rateplantype: type});
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Units - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
        }


    }

    async function checkExistPlan(str) {
        let b = false;

        for (let i of data) {
            if (str === i.data.name.trim()) {
                b = true;
                break; // Exit the loop early if a match is found
            }
        }

        return b;
    }

    function loadType (e){
        setSingle(e === 'single' ? true : false);
        getItemList().then(r => {});
    }

    async function getItemList(){

        const values = form.getFieldsValue(true);
        let id = values.property;

        try
        {
            let itemList = await get(`/api/hotels/${id}/items`)
            if (response.ok)
            {
                for (let i of itemList)
                {
                    i.name = i.data.name

                    let idx = i.data.type.indexOf("reservation");
                    i.uom = idx > -1 ? 'Day' : 'Each';
                }

                let clonedArray = [...itemList];

                if(values.subtype === 'package')
                {
                    itemList = itemList.filter(x => x.data.type.includes("reservation"));
                    clonedArray = clonedArray.filter(x => x.data.type.includes("roomservice"));
                }

                itemList = itemList.sort((a, b) => a.name.localeCompare(b.name));
                setServiceData(itemList);
                setServiceDataA(clonedArray);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Services - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
        }

    }

    function clearMe(){
        form.resetFields();
        setData([]);
        setDataR([]);
        setSingle(true);
        setEdit(false);
        setDataA([]);
    }

    function clearMe2(){
        form.setFieldsValue({packagename : undefined,
            item : undefined,
            price : undefined,
            addon : undefined,
            addonunit : undefined,
            addonprice : undefined, idR: undefined });

        setDataA([]);
        setEdit(false);
    }

    async function saveRatePlan(){
        const values = form.getFieldsValue(true);
        if(values.property === undefined || values.property === null || values.property === '')
        {
            errorN(`Choose a Property to proceed.`);
            return;
        }

        if(values.type === undefined || values.type === null || values.type === '')
        {
            errorN(`Choose a Plan Type to proceed.`);
            return;
        }

        if(values.newrateplan === undefined || values.newrateplan === null || values.newrateplan === '')
        {
            errorN(`Choose a New Rate Plan to proceed.`);
            return;
        }

        try
        {
            let adds = {
                name: values.newrateplan,
                type: values.type,
                hotel: values.property
            };

            let a = await post(`/api/plans`,adds)
            if (response.ok)
            {
                let propertyid = values.property;
                successN('Rate Plan Created successfully');
                await getRatePlanList(values.property);
                form.resetFields();

                form.setFieldsValue({property: propertyid});
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Save Rate Plan - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Save Rate Plan: ${err}. Please contact the administrator.`);
        }
    }

    async function removeRatePlan(id) {
        try
        {
            const values = form.getFieldsValue(true);
            let obj = JSON.parse(localStorage.getItem('channelsetting'));

            if(obj !== undefined && obj !== null)
            {
                if(obj.data.channelName !== '')
                {
                    errorN(`NOT ALLOWED: This property has been synced to a Channel Manager`);
                    return;
                }
            }

            if (id === undefined || id === null || id === '')
            {
                errorN("ID not found. Please contact the administrator.");
                return;
            }


            let a = await del(`/api/plans/${id}`)
            if (response.ok)
            {

                let propertyid = values.property;
                successN('Plan Removed successfully');
                await getRatePlanList(propertyid);
                form.resetFields();

                form.setFieldsValue({property: propertyid});
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Remove Plan - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Remove Plan: ${err}. Please contact the administrator.`);
        }
    }

    async function addAddon(){
        const values = form.getFieldsValue(true);
        if(values.property === undefined || values.property === null || values.property === '')
        {
            errorN(`Choose a Property to proceed.`);
            return;
        }

        if(values.addon === undefined || values.addon === null || values.addon === '')
        {
            errorN(`Choose an Add-On Item to proceed.`);
            return;
        }

        if(values.addonunit === undefined || values.addonunit === null || values.addonunit === '')
        {
            errorN(`Input an Add-On Unit to proceed.`);
            return;
        }

        if(values.addonprice === undefined || values.addonprice === null || values.addonprice === '')
        {
            errorN(`Input an Add-On Price to proceed.`);
            return;
        }

        try
        {
            const selectedValue = values.addon;
            /*const option = form.getFieldValue('addon');*/
            let selectedObj = serviceDataA.find(item => item.id === selectedValue);
            /*if (option) {
                const selectedOption = form.getFieldValue('addon');
                selectedText = selectedOption.children;
            }*/

            let child = {
                id: -1,
                item: selectedValue,
                name: selectedObj.name,
                price: 0,
                revenue: parseFloat(values.addonprice),
                unit: parseFloat(values.addonunit),
                uom: 'Each',
                pricePercentage: 0
            };

            //let newObjects = [];
            //newObjects.push(child);

            let idExists = dataA.some(item => item.item === selectedValue);

            if(idExists === true)
            {
                let updatedArray = dataA.map(item =>
                    item.item === selectedValue ? child : item
                );
                setDataA(updatedArray);
            }
            else
            {
                setDataA(prevDataA => [...prevDataA, child]);
            }

            form.setFieldsValue({addon : undefined,
                addonunit : undefined,
                addonprice : undefined });

        }
        catch (err)
        {
            errorN(`Error Add Add-On: ${err}. Please contact the administrator.`);
        }
    }

    async function removeAddon(item, id){
        try
        {
            const updatedArray = dataA.filter(x => x.item !== item);
            setDataA(updatedArray);

            if(id !== -1)
            {
                await removeRate(id, true);
            }

        }
        catch (err)
        {
            errorN(`Error Remove Add-On: Please contact the administrator.`);
        }
    }

    return (
        <>
            <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                &nbsp;&nbsp;<DollarOutlined />
                &nbsp;&nbsp;Rate Plan
            </div>
            <Layout>

                <Spin spinning={loading}>
                    <Content style={{backgroundColor: 'white'}}>
                        <Form
                            layout={formLayout}
                            form={form}
                            // style={{ width: '100%'}}
                            onFinish={handleFinish}
                        >
                            <Form.Item
                                style={{ display: 'none' }}
                                label="idP"
                                name="idP"
                                rules={[]}
                            >
                                <InputNumber />
                            </Form.Item>

                            <Form.Item
                                style={{ display: 'none' }}
                                label="idR"
                                name="idR"
                                rules={[]}
                            >
                                <InputNumber />
                            </Form.Item>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={single ? 8 : 6} xl={single ? 8 : 6} style={{padding: '20px'}}>
                                    <legend>Rate Plans</legend>
                                    <Form.Item
                                        label="Property"
                                        name="property"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose the Property',
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange ={getRatePlanList}
                                            placeholder="Property"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {propertyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <legend>Rate Plans</legend>

                                    <Form.Item
                                        label="Plan Type"
                                        name="type"
                                        rules={[

                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            //onChange = {loadType}
                                            placeholder="Type"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='base'>Base</Option>
                                            <Option value='daily'>Daily</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="New Rate Plan"
                                        name="newrateplan"
                                        rules={[

                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Rate Plan" />
                                    </Form.Item>

                                    <Button type="danger" onClick={saveRatePlan}  loading={loading} >
                                        <StepForwardOutlined /> Add
                                    </Button>
                                    <br />
                                    <Table
                                        defaultExpandAllRows={true}
                                        columns={columns}
                                        dataSource={data}
                                        bordered
                                        size="small"
                                        scroll={{ x: 'max-content',y: 320 }}
                                        //scroll={{ y: 370 }}
                                        pagination={{ pageSize: 5 }}
                                    />

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{padding: '20px'}}>
                                    <legend>Rates</legend>

                                    <Space>
                                        <Form.Item
                                            label="Rate Plan"
                                            name="rateplan"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please choose Unit No.',
                                                },
                                            ]}
                                        >
                                            <Input  disabled />
                                        </Form.Item>

                                        <Form.Item
                                            label="Type"
                                            name="rateplantype"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please choose Plan Type',
                                                },
                                            ]}
                                        >
                                            <Input  disabled />
                                        </Form.Item>
                                    </Space>


                                    <Form.Item
                                        label="SubType"
                                        name="subtype"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Subtype',
                                            },
                                        ]}
                                    >
                                        <Select disabled={edit}
                                            style={{ width: '50%' }}
                                            onChange = {loadType}
                                            placeholder="Type"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='single'>Single</Option>
                                            <Option value='package'>Package</Option>
                                        </Select>
                                    </Form.Item>



                                    {single ?
                                        <>
                                            <legend>Single Item</legend>

                                        </>
                                    :
                                        <>
                                            <legend>Package Item</legend>

                                            <Form.Item
                                                label="Package Name"
                                                name="packagename"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input Package name',
                                                    },
                                                ]}
                                            >
                                                <Input disabled={edit} style={{ width: '50%' }} placeholder="Package" />
                                            </Form.Item>
                                        </>

                                    }

                                    <Form.Item
                                        label="Item"
                                        name="item"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Item',
                                            },
                                        ]}
                                    >
                                        <Select disabled={edit}
                                            style={{ width: '50%' }}
                                            allowClear
                                            placeholder="Item"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {serviceData.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Price"
                                        name="price"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Price',
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>

                                    {edit  ?
                                        <Button type="primary" onClick={clearMe2}  loading={loading} >
                                            <StepForwardOutlined /> Cancel Edit
                                        </Button>
                                    :
                                        null
                                    }

                                </Col>
                                {!single ?
                                    <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{padding: '20px'}}>
                                        <legend>Package Add-Ons</legend>

                                        <Form.Item
                                            label="Add-Ons"
                                            name="addon"
                                            rules={[

                                            ]}
                                        >
                                            <Select
                                                style={{ width: '50%' }}
                                                allowClear
                                                placeholder="Add-Ons"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                showSearch
                                                optionFilterProp="children">
                                                {serviceDataA.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            label="Unit"
                                            name="addonunit"
                                            rules={[

                                            ]}
                                        >
                                            <InputNumber />
                                        </Form.Item>

                                        <Form.Item
                                            label="Price"
                                            name="addonprice"
                                            rules={[

                                            ]}
                                        >
                                            <InputNumber />
                                        </Form.Item>

                                        <Button type="danger" onClick={addAddon}  loading={loading} >
                                            <StepForwardOutlined /> Add
                                        </Button>

                                        <br />
                                        <Table
                                            defaultExpandAllRows={true}
                                            columns={columnsA}
                                            dataSource={dataA}
                                            bordered
                                            size="small"
                                            scroll={{ x: 'max-content',y: 320 }}
                                            //scroll={{ y: 370 }}
                                            pagination={{ pageSize: 5 }}
                                        />

                                    </Col>
                                :
                                    null
                                }
                                <Col xs={24} sm={24} md={24} lg={single ? 10 : 6} xl={single ? 10 : 6} style={{padding: '20px'}}>
                                    <Table
                                        defaultExpandAllRows={true}
                                        columns={columnsR}
                                        dataSource={dataR}
                                        /* exportable
                                         exportableProps={{ showColumnPicker: true, fileName: "In-House Guest-List",btnProps: {
                                                 type: "primary",
                                                 icon: <FileExcelOutlined />,
                                                 /!*children: <span>Export</span>,*!/
                                             } }}*/
                                        searchable
                                        searchableProps={{
                                            // dataSource,
                                            // setDataSource: setSearchDataSource,
                                            inputProps: {
                                                placeholder: "Search this table...",
                                                prefix: <SearchOutlined />,
                                            },
                                        }}
                                        bordered
                                        size="small"
                                        scroll={{ x: 'max-content',y: 320 }}
                                        //scroll={{ y: 370 }}
                                        pagination={false}
                                    />

                                    <br /><br />
                                    <Space>
                                        <Button type="primary" onClick={clearMe}  loading={loading} >
                                            <StepForwardOutlined /> Clear
                                        </Button>
                                        <Button type="danger" onClick={saveRate}  loading={loading} >
                                            <StepForwardOutlined /> Confirm
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Content>
                </Spin>
            </Layout>


        </>
    );
}

export default RatePlan
