import React, {useState,useEffect,useContext,useCallback} from 'react'

import {
    Tag,
    Image,
    Result,
    Checkbox,
    Descriptions,
    InputNumber,
    DatePicker,
    Modal,
    Popconfirm,
    Steps,
    Radio,
    Select,
    Divider,
    Spin,
    Avatar,
    Menu,
    Dropdown,
    Form,
    Card,
    Drawer,
    Layout,
    Tabs,
    Button,
    Input,
    Space,
    Row,
    Col,
    Upload
} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    VerticalAlignBottomOutlined,
    ReconciliationOutlined,
    ShoppingCartOutlined,
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined,
    LockOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined,
    FileExcelOutlined,
    SearchOutlined,
    ProfileOutlined,
    UploadOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN, errorN, openNotificationSuccess, openNotificationError} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import { Table } from "ant-table-extensions";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";

const { TextArea } = Input;
const { Option } = Select;
const {Sider, Content,Footer } = Layout;

function Resource(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [data, setData] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [rateList, setRateList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [feeList, setFeeList] = useState([]);
    const [image, setImage] = useState('');

    const [serviceData, setServiceData] = useState([]);

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');

    const [single, setSingle] = useState(true);

    const [fileList, setFileList] = useState([]);

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput=node;
                    }}
                    placeholder={`Search ${caption}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
        /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };


    useEffect(() => {

        loadProperty();

        return () => {

        }
    }, [])

    async function loadProperty() {
        try
        {
            let list = JSON.parse(localStorage.getItem('allproperties'));

            let ls = [];
            for(let i of list)
            {
                ls.push({id: i.id.toString(), name: i.data.name})
            }

            setPropertyList(ls);
        }
        catch (err)
        {
            errorN(`Error Load Property: ${err}. Please contact the administrator.`);
        }
    }

    const onSubmit = () => {

        form.validateFields().then(values => {

            if(values.type === 'single')
            {
                AssignNow(values).then(r => {});
            }
            else
            {
                saveMe(values).then(r => {});
            }

        })

    }

    async function saveMe(values) {

        if(values.property === undefined || values.property === null || values.property === '')
        {
            errorN(`Choose a property to proceed.`);
            return;
        }

       let itemListx;
        try
        {
            let itemList = await get(`/api/hotels/${values.property}/items`)
            if (response.ok)
            {
                for (let i of itemList)
                {
                    i.name = i.data.name
                }

                itemListx = itemList.filter(x => x.data.type.includes("reservation"));
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Services - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    return;
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
            return;
        }


        let objList=[];
        let cancelflg = false;

        let fx = values.upload !== undefined ? values.upload.map(o => o.originFileObj) : null;
        delete values.upload;

        let files = fx, f = files[0];
        let reader = new FileReader();
        reader.onload = async function (e) {
            let data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});


            for(let item of dataParse)
            {
                if(item.length !== 10) {
                    cancelflg = true;
                    break;
                }

                for(let j of item)
                {
                    if(j === undefined || j === null)
                    {
                        cancelflg = true;
                        break;
                    }
                }



                if(item[0] !== 'Service Type') {
                    let rooms = {};

                    if(await checkExistRoom(item[1].toString()) === false)
                    {
                        rooms.hotel = values.property;
                        rooms.item = (itemListx.find(obj => obj.data.name.toString() === item[0].toString())).id;

                        rooms.data = {
                            bed: '',
                            building: parseInt(item[4].toString()),
                            description: item[5].toString(),
                            doorCode: item[6].toString(),
                            floor: parseInt(item[3].toString()),
                            no: item[1].toString(),
                            room: parseInt(item[2].toString()),
                            service: {
                                id: (itemListx.find(obj => obj.data.name.toString() === item[0].toString())).id,
                                name: item[0].toString(),
                            },
                            type: '',
                            smoking: item[7],
                            guest: {
                                adult: parseInt(item[8].toString()),
                                child: parseInt(item[9].toString())
                            }
                        };

                        objList.push(rooms);
                    }
                }
            }


            //console.log('what', objList)


            if(cancelflg === false)
            {
                let y = 0;
                let x = 0;
                for(let roomx of objList)
                {
                    let n =await post(`/api/rooms`,roomx)
                    if (response.ok)
                    {
                        x = x+1;

                    }
                    else
                    {
                        y = y+1;
                    }
                }

                if(y === 0)
                {
                    let propertyid = values.property;
                    successN('Unit Updated successfully');
                    getItemList(propertyid);
                    form.resetFields();
                    setFileList([]);

                    form.setFieldsValue({property: propertyid});
                    setSingle(true);
                }
                else
                {
                    if(x !== 0)
                    {
                        let propertyid = values.property;
                        errorN('Upload Unit successful partially. Please check for the missing units, and re-upload.');
                        getItemList(propertyid);
                        form.resetFields();
                        setFileList([]);

                        form.setFieldsValue({property: propertyid});
                        setSingle(true);
                    }
                    else
                    {
                        errorN(`Upload failed. Please contact the administrator.`);
                    }
                }
            }
            else
            {
                errorN(`Upload failed - Data missing. Please make sure all columns and rows are filled with complete data.`);
            }



        };
        reader.readAsBinaryString(f)


    }

    async function AssignNow(values) {
        try {
            //const values = form.getFieldsValue(true); //console.log(values)

            if(values.property === undefined || values.property === null || values.property === '')
            {
                errorN(`Choose a property to proceed.`);
                return;
            }

            let itemListx;
            try
            {
                let itemList = await get(`/api/hotels/${values.property}/items`)
                if (response.ok)
                {
                    for (let i of itemList)
                    {
                        i.name = i.data.name
                    }

                    itemListx = itemList.filter(x => x.data.type.includes("reservation"));
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Get Services - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        return;
                    }

                }
            }
            catch (err)
            {
                errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
                return;
            }

            if(values.id === undefined || values.id === null || values.id === '')
            {
                if(await checkExistRoom(values.unitno) === true)
                {
                    errorN(`Display No. ${values.unitno} is Exist. Please change and try again.`);
                    return;
                }

                let rooms = {};

                rooms.hotel = values.property;
                rooms.item = values.service;

                rooms.data = {
                    bed: values.bed ? values.bed : '',
                    building: parseInt(values.building),
                    description: values.desc ? values.desc : '',
                    doorCode: values.doorCode ? values.doorCode : '',
                    floor: parseInt(values.floor),
                    no: values.unitno,
                    room: parseInt(values.no),
                    service: {
                        id: values.service,
                        name: (itemListx.find(obj => obj.id.toString() === values.service.toString())).data.name
                    },
                    type: values.unittype,
                    smoking: (values.smoking === 'true'),
                    guest: {
                        adult: values.adult ? parseInt(values.adult) : 0,
                        child: values.child ? parseInt(values.child) : 0
                    }
                };

                let a = await post(`/api/rooms`,rooms)
                if (response.ok)
                {
                    let propertyid = values.property;
                    successN('Unit Created successfully');
                    await getItemList(propertyid);
                    form.resetFields();

                    form.setFieldsValue({property: propertyid});
                }
                else
                {
                    errorN(`Create Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                let rooms = await get(`/api/rooms/${values.id}`)
                if (response.ok)
                {
                    rooms.data = {
                        bed: values.bed ? values.bed : '',
                        building: parseInt(values.building),
                        description: values.desc ? values.desc : '',
                        doorCode: values.doorCode ? values.doorCode : '',
                        floor: parseInt(values.floor),
                        no: values.unitno,
                        room: parseInt(values.no),
                        service: {
                            id: values.service,
                            name: (itemListx.find(obj => obj.id.toString() === values.service.toString())).data.name
                        },
                        type: values.unittype,
                        smoking: (values.smoking === 'true'),
                        guest: {
                            adult: values.adult ? parseInt(values.adult) : 0,
                            child: values.child ? parseInt(values.child) : 0
                        }
                    };


                    let a = await put(`/api/rooms/${values.id}`,rooms)
                    if (response.ok)
                    {
                        let propertyid = values.property;
                        successN('Unit Updated successfully');
                        getItemList(propertyid);
                        form.resetFields();

                        form.setFieldsValue({property: propertyid});
                    }
                    else
                    {
                        errorN(`Update Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Update Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }


        }
        catch (err)
        {
            errorN(`Error Get UserX: ${err}. Please contact the administrator.`);
        }
    }

    async function getItem(id) {
        try
        {
            let roomObj = await get(`/api/rooms/${id}`)
            if (response.ok)
            {
                roomObj.type = 'single';

                roomObj.unittype = roomObj.data.type;
                roomObj.service = parseInt(roomObj.data.service.id);
                roomObj.unitno = roomObj.data.no;

                roomObj.no = roomObj.data.room;
                roomObj.floor = roomObj.data.floor;
                roomObj.building = roomObj.data.building;
                roomObj.desc = roomObj.data.description;

                roomObj.doorCode = roomObj.data.doorCode;
                roomObj.bed = roomObj.data.bed;
                roomObj.smoking = roomObj.data.smoking.toString();
                roomObj.adult = roomObj.data.guest.adult;
                roomObj.child = roomObj.data.guest.child;

                form.setFieldsValue(roomObj);
                setSingle(true);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Unit: ${err}. Please contact the administrator.`);
        }
    }

    async function removeItem(id) {
        try
        {
            const values = form.getFieldsValue(true);
            let obj = JSON.parse(localStorage.getItem('channelsetting'));

            if(obj !== undefined && obj !== null)
            {
                if(obj.data.channelName !== '')
                {
                    errorN(`NOT ALLOWED: This property has been synced to a Channel Manager`);
                    return;
                }
            }

            if (id === undefined || id === null || id === '')
            {
                errorN("ID not found. Please contact the administrator.");
                return;
            }


            let a = await del(`/api/rooms/${id}`)
            if (response.ok)
            {

                let propertyid = values.property;
                successN('Unit Removed successfully');
                await getItemList(propertyid);
                form.resetFields();

                form.setFieldsValue({property: propertyid});
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Remove Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Remove Unit: ${err}. Please contact the administrator.`);
        }
    }

    const columns = [

        {
            width: '20%',
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <a  onClick={() => getItem(record.id)}>
                        <EditOutlined twoToneColor="blue"/>
                        &nbsp;
                        Edit
                    </a>
                </>
            )
        },
        {
            width: '70%',
            title:"No",
            dataIndex:"name",
            key:"name",
            ...getColumnSearchProps('name','No'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            width: '10%',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Do you want to remove this?`}
                        onConfirm={() => removeItem(record.id)}
                        okText="Yes"
                        cancelText="No">
                        <a>
                            <CloseCircleOutlined style={{color: 'red'}}/>
                        </a>
                    </Popconfirm>

                </>
            )
        },
    ]

    async function getItemList(e){
        let id = e;

        try
        {
            let itemList = await get(`/api/hotels/${id}/items`)
            if (response.ok)
            {
                for (let i of itemList)
                {
                    i.name = i.data.name
                }

                itemList = itemList.filter(x => x.data.type.includes("reservation"));

                setServiceData(itemList);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Services - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
        }

        try
        {
            let roomList = await get(`/api/hotels/${id}/rooms`)
            if (response.ok)
            {
                for (let i of roomList)
                {
                    i.name = i.data.no
                }

                setData(roomList);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Units - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
        }


    }

    async function checkExistRoom(str) {
        let b = false;

        for (let i of data) {
            if (str === i.data.no.trim()) {
                b = true;
                break; // Exit the loop early if a match is found
            }
        }

        return b;
    }

    function loadType (e){
        setSingle(e === 'single' ? true : false);
    }

    return (
        <>
            <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                &nbsp;&nbsp;<ReconciliationOutlined />
                &nbsp;&nbsp;Resource
            </div>
            <Layout>

                <Spin spinning={loading}>
                    <Content style={{backgroundColor: 'white'}}>
                        <Form
                            layout={formLayout}
                            form={form}
                            // style={{ width: '100%'}}
                            onFinish={handleFinish}
                        >
                            <Form.Item
                                style={{ display: 'none' }}
                                label="id"
                                name="id"
                                rules={[]}
                            >
                                <InputNumber />
                            </Form.Item>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{padding: '20px'}}>
                                    <Form.Item
                                        label="Property"
                                        name="property"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Property',
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange ={getItemList}
                                            placeholder="Property"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {propertyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Entry Type"
                                        name="type"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Type',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            onChange = {loadType}
                                            placeholder="Type"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='single'>Single Unit Entry</Option>
                                            <Option value='multiple'>Multiple Unit Entry</Option>
                                        </Select>
                                    </Form.Item>

                                    {single ?
                                        <>
                                            <Form.Item
                                                label="Unit Type"
                                                name="unittype"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please choose Unit Type',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: '50%' }}
                                                    placeholder="Unit Type"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    <Option value='Room'>Room</Option>
                                                    <Option value='Bed'>Bed</Option>
                                                    <Option value='Apartment'>Apartment</Option>
                                                    <Option value='Homestay'>Homestay</Option>
                                                    <Option value='Meeting Room'>Meeting Room</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Service"
                                                name="service"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please choose Service',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: '50%' }}
                                                    allowClear
                                                    placeholder="Service"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    {serviceData.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Display No."
                                                name="unitno"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input Unit No.',
                                                    },
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }} placeholder="Unit No." />
                                            </Form.Item>
                                        </>
                                     :
                                        null
                                    }

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{padding: '20px'}}>
                                    {single ?
                                        <>
                                            <Form.Item
                                                label="Unit No."
                                                name="no"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please choose Unit No.',
                                                    },
                                                ]}
                                            >
                                                <InputNumber />
                                            </Form.Item>

                                            <Form.Item
                                                label="Floor No."
                                                name="floor"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please choose Floor No.',
                                                    },
                                                ]}
                                            >
                                                <InputNumber />
                                            </Form.Item>

                                            <Form.Item
                                                label="Building No."
                                                name="building"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please choose Building No.',
                                                    },
                                                ]}
                                            >
                                                <InputNumber />
                                            </Form.Item>

                                            <Form.Item
                                                name="desc"
                                                label="Description"
                                                // hasFeedback
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Please enter Customer Background',
                                                //     },
                                                // ]}
                                            >
                                                <TextArea placeholder="Description" rows={5}/>
                                            </Form.Item>
                                        </>
                                    :
                                        <>
                                            <a href={'/multiple_unit_upload.xlsx'}>
                                                <Tag icon={<VerticalAlignBottomOutlined />} color="#f50">
                                                    Download <b>Multiple Unit Entry</b> form here.
                                                </Tag>
                                            </a>
                                            <br></br>
                                        </>

                                    }
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{padding: '20px'}}>
                                    {single ?
                                        <>
                                            <Form.Item
                                                label="Door Code"
                                                name="doorCode"
                                                rules={[
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }} placeholder="Door Code" />
                                            </Form.Item>

                                            <Form.Item
                                                label="Smoking Allowed"
                                                name="smoking"
                                                rules={[
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: '50%' }}
                                                    placeholder="Yes/No"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    <Option value='true'>Yes</Option>
                                                    <Option value='false'>No</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Bedding"
                                                name="bed"
                                                rules={[
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: '50%' }}
                                                    placeholder="Bedding"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    <Option value='Standard Double'>Standard Double</Option>
                                                    <Option value='Standard Single'>Standard Single</Option>
                                                    <Option value='Queen Bed'>Queen Bed</Option>
                                                    <Option value='King Bed'>King Bed</Option>
                                                    <Option value='Bunk'>Bunk</Option>
                                                    <Option value='California King'>California King</Option>
                                                    <Option value='Crib'>Crib</Option>
                                                    <Option value='Daybed'>Daybed</Option>
                                                    <Option value='Futon'>Futon</Option>
                                                    <Option value='Full/Double XL'>Full/Double XL</Option>
                                                    <Option value='Grand King'>Grand King</Option>
                                                    <Option value='King Bed'>King Bed</Option>
                                                    <Option value='Modern Cot'>YModern Cotes</Option>
                                                    <Option value='Murphy'>Murphy</Option>
                                                    <Option value='Olympic Queen'>Olympic Queen</Option>
                                                    <Option value='Rollaway'>Rollaway</Option>
                                                    <Option value='Sofa'>Sofa</Option>
                                                    <Option value='Super King'>Super King</Option>
                                                    <Option value='Toddler Bed'>Toddler Bed</Option>
                                                    <Option value='Trundle'>Trundle</Option>
                                                    <Option value='Twin Bed'>Twin Bed</Option>
                                                    <Option value='Twin XL'>Twin XL</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Guest - Adult"
                                                name="adult"
                                                rules={[]}
                                            >
                                                <InputNumber />
                                            </Form.Item>

                                            <Form.Item
                                                label="Guest - Child"
                                                name="child"
                                                rules={[]}
                                            >
                                                <InputNumber />
                                            </Form.Item>
                                        </>
                                        :

                                        <Form.Item
                                            name="upload"
                                            label=""
                                            //valuePropName="fileList"
                                            getValueFromEvent={normFile}
                                            extra=""
                                            labelAlign={"left"}
                                            rules={[{required: true, message: 'Upload file is required'}]}
                                        >

                                            <Upload
                                                //accept={"image/jpeg,image/png"}
                                                multiple={false}
                                                /*disabled={fileList.length < 3 ? false : true}*/
                                                beforeUpload={file => {

                                                    return false;
                                                }}
                                                onChange={({fileList}) => {
                                                    setFileList(fileList);

                                                }}
                                                fileList={fileList}
                                            >
                                                {fileList.length >= 1 ? <span className="ant-form-text">File :</span> : <div>
                                                    <Button>
                                                        <UploadOutlined/> Select File

                                                    </Button>
                                                </div>}
                                                {/*&nbsp;&nbsp;&nbsp;<Text>(max 3 files)</Text>*/}
                                            </Upload>

                                        </Form.Item>
                                    }

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{padding: '20px'}}>
                                    <Table
                                        defaultExpandAllRows={true}
                                        columns={columns}
                                        dataSource={data}
                                        /* exportable
                                         exportableProps={{ showColumnPicker: true, fileName: "In-House Guest-List",btnProps: {
                                                 type: "primary",
                                                 icon: <FileExcelOutlined />,
                                                 /!*children: <span>Export</span>,*!/
                                             } }}*/
                                        searchable
                                        searchableProps={{
                                            // dataSource,
                                            // setDataSource: setSearchDataSource,
                                            inputProps: {
                                                placeholder: "Search this table...",
                                                prefix: <SearchOutlined />,
                                            },
                                        }}
                                        bordered
                                        size="small"
                                        scroll={{ x: 'max-content',y: 320 }}
                                        //scroll={{ y: 370 }}
                                        pagination={false}
                                    />
                                    <br /><br />
                                    <Space>
                                        <Button type="primary" onClick={() => {form.resetFields(); setData([]);setImage('');}}  loading={loading} >
                                            <StepForwardOutlined /> Clear
                                        </Button>
                                        <Button type="danger" onClick={() => form.submit()}  loading={loading} >
                                            <StepForwardOutlined /> Confirm
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Content>
                </Spin>
            </Layout>


        </>
    );
}

export default Resource
