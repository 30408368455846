import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"


const { Option } = Select;


function ChangePassword(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [disabled, setDisabled] = useState(false);
    

    useEffect(() => {

        loadData();

        return () => {

        }
    }, [])

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const setData = () => {


        /*let obj = props.obj;

        console.log('pund', props.obj)
        if(obj.roomNo !== null && obj.roomNo !== undefined && obj.roomNo !== '')
        {
            let d = obj.roomNo.split(',');

            obj.roomNox = d;
        }
        else
        {
            obj.roomNox = [];
        }

        form.setFieldsValue(obj);

        setAvailCount(props.getAllo(obj.itemID));

        setIsBackDate(props.isBackDate);*/
    }

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {
            const values = form.getFieldsValue(true);

            let userObj = await get(`/api/users/${values.id}`)
            if (response.ok)
            {
                userObj.pwd = values.pwd;

                let a = await put(`/api/users/${values.id}`,userObj)
                if (response.ok)
                {
                    successN('Password changed successfully');
                }
                else
                {
                    errorN(`Change Password - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Change Password - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get User: ${err}. Please contact the administrator.`);
        }
    }

    const handleChange = () => {

        // if(props.isBackDate !== true)
        // {
           /* const values = form.getFieldsValue(true);
            console.log('why me', values.roomNox)
            let x = '';
                
            for(let i of values.roomNox)
            {
                console.log('why me x', i)
                if(x === '')
                {
                    x = i;
                }
                else
                {
                    x = x + ',' + i;
                }
            }      
            
            values.roomNo = x;

            props.reloadMe(values);
    
            let obj = props.obj;
            setAvailCount(props.getAllo(obj.itemID));*/
        // }
        
    }

    async function loadData() {
        try
        {
           let userid = localStorage.getItem('id');

            let userObj = await get(`/api/users/${userid}`)
            if (response.ok)
            {
                userObj.name = userObj.data.name;

                form.setFieldsValue(userObj);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get User: ${err}. Please contact the administrator.`);
        }
    }

    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                        <Form.Item
                            style={{ display: 'none' }}
                            label="id"
                            name="id"
                            rules={[]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[]}
                        >
                            <Input disabled placeholder="Name" />
                        </Form.Item>

                        <Form.Item
                            label="Username"
                            name="usr"
                            rules={[]}
                        >
                            <Input disabled placeholder="User Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>

                        <Form.Item
                            label="Password"
                            name="pwd"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>

                        <br />
                        <Button type="danger" onClick={() => form.submit()}  loading={loading} >
                            <StepForwardOutlined /> Confirm
                        </Button>
                    </Col>
                </Row>

            </Form>

        </>
    );
}

export default ChangePassword
