import React, {useState,useEffect} from 'react';
import {Modal} from "antd";

import {A} from "hookrouter";

import ChangePassword from "../Settings/changepassword";
import SystemUser from "../Settings/systemuser";
import Item from "../Settings/item";
import Property from "../Settings/property"
import Folios from "../Reports/folios"

import {errorN} from "../../general";



function SModal(props) {

    const [visible, setVisible] = useState(false);


    useEffect(() => {

        return () => {

        }
    }, [])


    const handleOk = e => {
                setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);

            switch (props.formname) {
                case 'booknow':
                    props.loadData();
                    break;
                case 'bookings':
                    return null
                case 'folios':
                    return null
                case 'update':
                    props.loadData();

                    if(props.loadAllocation !== undefined)
                        props.loadAllocation();

                    break;
                case 'setroom':
                    props.loadData();
                    break;
                case 'checkinout':
                    props.loadData();
                    break;
                case 'extendstay':
                    props.loadData();
                    break;
                case 'changeroom':
                    props.loadData();
                    break;
                case 'editlines':
                    props.loadData();
                    break;
                case 'updateallocation':
                    return null
                case 'cancelfolio':
                    return null
                case 'blockroom':
                    props.loadData();
                    break;
                default:
                    return (
                        <div/>
                    )
            }
    };

    const processMe = e => {

        if(e === 'cancelfolio')
        {
            let dataRoom = props.dataRoom;
            let dataPayment = props.dataPayment;

            let customSettings = JSON.parse(localStorage.getItem('customsetting'));
            let role = localStorage.getItem('role');

            if(role === 'cashier')
            {
                if( customSettings !== undefined && customSettings !== null && customSettings !== '')
                {
                    if( customSettings.data !== undefined && customSettings.data !== null && customSettings.data !== '')
                    {
                        if( customSettings.data.allowUserCancelFolio !== undefined && customSettings.data.allowUserCancelFolio !== null && customSettings.data.allowUserCancelFolio !== '')
                        {
                            if( customSettings.data.allowUserCancelFolio === true)
                            {

                            }
                            else
                            {
                                errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                                return;
                            }
                        }
                        else
                        {
                            errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                            return;
                        }
                    }
                    else
                    {
                        errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                        return;
                    }

                }
                else
                {
                    errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                    return;
                }
            }

            for(let item of dataRoom)
            {
                if (item.status == "Occupied")
                {
                    errorN(`Invalid: Please check out the unit(s) before CANCEL this folio.`);
                    return;
                }
            }


            let paid = 0;
            let refund = 0;

            for(let i of dataPayment)
            {
                if(i.type === 'receipt')
                {
                    paid = paid + i.amount;
                }
                else
                {
                    refund = refund + i.amount;
                }
            }


            if ((paid - refund) > 0) {
                errorN(`Invalid: You need Refund the payment(s) before cancel the folio.`);
                return;
            }

            setVisible(true);
        }

    }


    return (
        <>

            {(() => {

            switch (props.formname) {
                case 'changepassword':
                    return (
                        <A href={''} onClick={() => {setVisible(true);}}>Change Password</A>
                    )
                case 'systemuser':
                    return (
                        <A href={''} onClick={() => {setVisible(true);}}>System User</A>
                    )
                case 'item':
                    return (
                        <A href={''} onClick={() => {setVisible(true);}}>Item</A>
                    )
                case 'property':
                    return (
                        <A href={''} onClick={() => {setVisible(true);}}>Property</A>
                    )
                default:
                    return (
                        <div></div>
                    )
            }

            })()}
            
            <Modal
                centered ={true}
                width={props.formname === 'changepassword' ? "35%" :
                    props.formname === 'systemuser' || props.formname === 'item' || props.formname === 'property' ? "60%" : "100%"}
                destroyOnClose={true}
                visible={visible}
                //title={props.formname}
                onOk={handleOk}
                closable = {true}
                onCancel={handleCancel}
                maskClosable={false}
                footer =""
            >
                {(() => {

                switch (props.formname) {
                    case 'changepassword':
                        return (
                            <ChangePassword/>
                        )
                    case 'systemuser':
                        return (
                            <SystemUser/>
                        )
                    case 'item':
                        return (
                            <Item/>
                        )
                    case 'property':
                        return (
                            <Property/>
                        )
                    default:
                        return (
                            <div></div>
                        )
                }

                })()}
                
            </Modal>
        </>
    );
}

export default SModal
