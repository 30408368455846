import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Typography,DatePicker,Select,Radio,Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';
import Highlighter from 'react-highlight-words';
import {errorN} from "../../general";
import { A,navigate} from "hookrouter";
import useFetch from "use-http";
import {
    BellOutlined ,
    ProfileOutlined ,
    SolutionOutlined ,
    PhoneOutlined ,
    HomeOutlined,
    ControlOutlined ,
    DollarOutlined ,
    ContainerOutlined ,
    MinusSquareOutlined ,
    BorderOutlined ,
    RightCircleOutlined,
    SearchOutlined,
    FileExcelOutlined


} from '@ant-design/icons'

import routes from "../../router"
import Store, {Context} from "../../store"
import moment from 'moment'
import FModal from "../Bookings/modal";

import { Table } from "ant-table-extensions";
import {ShowReport} from "../Bookings/function";

const {Sider, Content,Footer } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const formatter = new Intl.NumberFormat('en-MY', {
    //style: 'currency',
    //currency: 'MYR',
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

function Tax(props) {
    const [state,dispatch] = useContext(Context)
    const { get, post, response, loading, error, del, put } = useFetch()
    const [drawOpen, setDrawOpen] = useState(false);

    const [form] = Form.useForm()
    const [formLayout, setFormLayout] = useState('vertical');
    const handleFinish = useCallback(() => {
        console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);

    }, [form])

    const [dateRange, setDateRange] = useState(false);
    const [keyword, setKeyword] = useState(false);
    const [arrivalDate, setArrivalDate] = useState(false);
    const [arrivalDateRange, setArivalDateRange] = useState(false);

    const [data, setData] = useState([]);

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');

    const [sourceFilters,setSourceFilters] = useState([]);
    const [statusFilters,setStatusFilters] = useState([]);
    const [agencyFilters,setAgencyFilters] = useState([]);
    const [nationalityFilters,setNationalityFilters] = useState([]);

    const labelCol = 22;
    const wrapperCol = 22;

    const [filteredData, setFilteredData] = useState([]);
    const [range, setRange] = useState('');

    async function removeDuplicates(data) {
        // let unique = data.reduce(function(a,b){
        //   if(a.indexOf(b) < 0) a.push(b);
        //   return a;
        // },[]);

        const unique = data.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === data.findIndex(obj => {
              return JSON.stringify(obj) === _thing;
            });
          });

        return unique;
      };

    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={node => {
                    searchInput=node;
                }}
                placeholder={`Search ${caption}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.select(), 100);
        }
    },
    render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
});

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
            setsearchText(selectedKeys[0]);
            setsearchedColumn(dataIndex);
       /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };

    async function loadData() {
        form.validateFields().then(async values => {
            try
            {
                setRange(`From ${moment(values.dtDateRange[0]).format('DD-MM-YYYY')} to ${moment(values.dtDateRange[1]).format('DD-MM-YYYY')}`);

                let obj = JSON.parse(localStorage.getItem('currentproperty'));

                let s = '';
                let e = '';
                let parameter = '';

                s = moment.utc(values.dtDateRange[0]).format('YYYY-MM-DD');
                e = moment.utc(values.dtDateRange[1]).format('YYYY-MM-DD');

                parameter = `?start=${s}T00:00:00Z&end=${e}T23:59:59Z`


                let rList = await get(`/api/hotels/${obj.id}/invoices/tax${parameter}`)
                if (response.ok){
                    let table1 =[];

                    for(let i of rList)
                    {
                        table1.push({
                            id: i.id,
                            date: moment(i.created).format('YYYY-MM-DD hh:mm:ss A'),
                            no: i.no,
                            subtotal: formatter.format(i.subtotal),
                            tax: formatter.format(i.tax),
                            amount: formatter.format(i.amount),
                            rounding: formatter.format(i.rounding),
                            folioid: i.folio
                        });
                    }


                    setData(table1);
                    setFilteredData(table1);
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                    errorN(`Load Data - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            catch(err)
            {
                errorN(`Error Load Data: ${err}. Please contact the administrator.`);
            }

        })
    }

    useEffect(() => {


        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {
            loadData();
        }

        //loadData().then(r => {});


        return () => {

        }
    },[])

    const columns = [

        /*{
            width: '100px',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <Space>
                        {record.folioid !== '' && record.folioid !== null && record.folioid !== undefined ?
                            <FModal formname={'update'} obj={{...record}} loadData={loadData} />
                            :
                            null
                        }
                    </Space>

                </>
            )
        },*/
        {
            width: '110px',
            title:"Date",
            dataIndex:"date",
            key:"date",
            sorter: (a, b) => a.date.localeCompare(b.date),
        },
        {
            width: '100px',
            title:"Invoice No.",
            dataIndex:"no",
            key:"no",
            sorter: (a, b) => a.no.localeCompare(b.no),
        },
        {
            width: '100px',
            title:"Subtotal",
            dataIndex:"subtotal",
            key:"subtotal",
            sorter: (a, b) => a.subtotal.localeCompare(b.subtotal),
        },
        {
            width: '100px',
            title:"Tax",
            dataIndex:"tax",
            key:"tax",
            sorter: (a, b) => a.tax.localeCompare(b.tax),
        },
        {
            width: '100px',
            title:"Rounding",
            dataIndex:"rounding",
            key:"rounding",
            sorter: (a, b) => a.rounding.localeCompare(b.rounding),
        },
        {
            width: '100px',
            title:"Amount",
            dataIndex:"amount",
            key:"amount",
            sorter: (a, b) => a.amount.localeCompare(b.amount),
        },
    ]

    const handleTableChange = (pagination, filters, sorter) => {

        let filteredData = data.filter(item => {
            return Object.entries(filters).every(([key, values]) => {
                const itemValue = item[key] ? item[key].toLowerCase() : null;
                values = values ? values.map(val => val.toLowerCase()) : null;

                // Include the item if the criterion for the key is null or undefined
                if (values === null || values === undefined) {
                    return true;
                }

                // Check if the item's property contains any of the specified values for that property
                return values.some(filterVal => itemValue.includes(filterVal));
            });
        });

        if (sorter.field) {
            filteredData.sort((a, b) => {
                if (sorter.order === 'ascend') {
                    return a[sorter.field].localeCompare(b[sorter.field]);
                } else if (sorter.order === 'descend') {
                    return b[sorter.field].localeCompare(a[sorter.field]);
                }
                return 0;
            });
        }
        // Update the filteredData state with the filtered and sorted data
        setFilteredData([...filteredData]);
    };

    return (
        <>
        <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        &nbsp;&nbsp;<ProfileOutlined />
        &nbsp;&nbsp;Tax
        </div>
        <Layout>

            <Spin spinning={loading}>
            <Content style={{backgroundColor: 'white'}}>
                <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                    <div style={{backgroundColor: 'white', padding: '20px', margin: '0px 0',height:'73vh',overflow:'auto'}}>
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={true || false}
                        useWindow={false}
                    >
                    <div style={{backgroundColor: 'white', paddingRight: '20px',paddingLeft: '20px', paddingTop: '20px',marginRight: '20px'}}>

                        <Space>
                            <Form.Item
                                label="Arrival - Departure"
                                name="dtDateRange"
                                rules={[{ required: true, message: 'Dates missing!' }]}
                            >
                                <RangePicker />
                            </Form.Item>

                            <Button style={{marginTop:'7px'}} onClick={() => {loadData()}} key=""type="primary">Load</Button>
                            <ShowReport data={filteredData} name={'Tax List'} range={range} no={'9'}   />
                        </Space>

                    </div>

                    <div rowKey="id" style={{backgroundColor: 'white', paddingRight: '20px',paddingLeft: '20px',paddingBottom: '20px', marginRight: '20px'}}>
                        <Table
                            onChange={handleTableChange} // Handle filtering and sorting
                            defaultExpandAllRows={true}
                            columns={columns}
                            dataSource={data}
                            exportable
                            exportableProps={{ showColumnPicker: true, fileName: "Tax-List",btnProps: {
                                    type: "primary",
                                    icon: <FileExcelOutlined />,
                                    /*children: <span>Export</span>,*/
                                } }}
                            /*searchable
                            searchableProps={{
                                // dataSource,
                                // setDataSource: setSearchDataSource,
                                inputProps: {
                                    placeholder: "Search this table...",
                                    prefix: <SearchOutlined />,
                                },
                            }}*/
                            //expandable={{expandedRowRender}}
                            bordered
                            size="small"
                            //expandRowByClick={false}
                            scroll={{ x: 1500,y: 360 }}
                            pagination={false}

                            footer={pageData => {
                                let totalAmount = 0;
                                let totalTax = 0;
                                let totalRounding = 0;
                                let totalSubtotal = 0;

                                pageData.forEach(({ tax, amount, subtotal,rounding }) => {
                                    totalAmount += parseFloat(amount);
                                    totalTax += parseFloat(tax);
                                    totalRounding += parseFloat(rounding);
                                    totalSubtotal += parseFloat(subtotal);
                                });

                                return (
                                  <>
                                    <Text><b>Total Subtotal: {formatter.format(totalSubtotal)}</b></Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text><b>Total Tax: {formatter.format(totalTax)}</b></Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text ><b>Total Rounding: {formatter.format(totalRounding)}</b></Text>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Text><b>Total Amount: {formatter.format(totalAmount)}</b></Text>

                                  </>
                                );
                              }}

                        />
                    </div>

                    </InfiniteScroll>
                    </div>
                </Form>
            </Content>
            </Spin>
        </Layout>
        {/* <div style={{ textAlign: 'center', fontSize: '14px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        {`©${state.year} Managed by Ratri Cloud`}
        </div>   */}

        </>
    );
}

export default Tax
