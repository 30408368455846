import Dashboard from './containers/Dashboard/dashboard'

import Bookings from './containers/Reports/bookings';
import Folios from './containers/Reports/folios';
import Invoices from './containers/Reports/invoices';
import Payments from './containers/Reports/payments';
import DailyShift from './containers/Reports/dailyshift';
import Availability from './containers/Reports/availability_list';
import Summary from './containers/Reports/summary';
import Tax from './containers/Reports/tax';
import TaxnCharges from './containers/Reports/taxncharges';
import Guests from './containers/Reports/guests';
import Housekeeping from './containers/Reports/housekeeping';
import RoomStatus from './containers/Reports/roomstatus';

import Videos from './containers/Users/usr_guide_videos'

import React from "react";
import Property from "./containers/Settings/property";
import Item from "./containers/Settings/item";
import SystemUser from "./containers/Settings/systemuser";
import Resource from "./containers/Settings/resource";
import RatePlan from "./containers/Settings/rateplan";
import Party from "./containers/Settings/party";

const routes = {
    '/dashboard': () => <Dashboard />,
   /* '/user/:id': ({id}) => <Users id={id}/>,*/
   /* '/user/:id/:param1': ({id,param1}) => <Users id={id} param1={param1}/>*/
   '/bookings': () => <Bookings />,
   '/folios': () => <Folios />,
   '/invoices': () => <Invoices />,
    '/payments': () => <Payments />,
    '/dailyshift': () => <DailyShift />,
    '/summary': () => <Summary />,
    '/availability': () => <Availability />,
    '/fax': () => <Tax />,
    '/taxncharges': () => <TaxnCharges />,
    '/guests': () => <Guests />,
    '/housekeeping': () => <Housekeeping />,
    '/roomstatus': () => <RoomStatus />,

    '/videos': () => <Videos />,

    '/property': () => <Property/>,
    '/item': () => <Item/>,
    '/systemuser': () => <SystemUser/>,
    '/resource': () => <Resource/>,
    '/rateplan': () => <RatePlan/>,
    '/party': () => <Party/>,
}

export default routes
