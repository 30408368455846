import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {A, navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PlusCircleOutlined,
    SwapOutlined,
    FormOutlined,
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined,
    DollarCircleOutlined,
    RightCircleOutlined,
    LeftCircleOutlined,
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
    ProfileOutlined,
    FileOutlined,
    SolutionOutlined,
    UnorderedListOutlined,
    UsergroupDeleteOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
    FileDoneOutlined,
    FileSyncOutlined,
    FileProtectOutlined,
    EyeOutlined,
    IdcardOutlined, CreditCardOutlined,BackwardOutlined,WarningTwoTone


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import FModal from "./modal";

import {ShowFolio, ShowInvoice, ShowPayment,ShowReceipt, ShowBooking} from "./function";

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;
const {SubMenu} = Menu;

const steps = [
    {
        title: 'Guest Info',
        //content: 'First-content',
    },
    {
        title: 'Resource Management',
        //content: 'First-content',
    },
    {
        title: 'Orders',
        //content: 'Second-content',
    },
    {
        title: 'Payment & Receipt',
        //content: 'Last-content',
    },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function ChooseRoomModal(props) {
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);


    useEffect(() => {

        return () => {

        }
    }, [])

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const setData = () => {
        let obj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));

        if(obj.roomNo !== null && obj.roomNo !== undefined && obj.roomNo !== '')
        {
            let d = obj.roomNo.split(',');

            obj.roomNox = d;
        }
        else
        {
            obj.roomNox = [];
        }

        form.setFieldsValue(obj);

        setAvailCount(props.getAllo(obj.itemID));

        setIsBackDate(props.isBackDate);
    }

    const onSubmit = () => {
        const values = form.getFieldsValue(true);

        let x = '';


        for(let i of values.roomNox)
        {
            if(x === '')
            {
                x = i;
            }
            else
            {
                x = x + ',' + i;
            }
        }

        values.roomNo = x;

        props.reloadMe(values);
        setVisible(false);
    }

    const handleChange = () => {

        // if(props.isBackDate !== true)
        // {
            const values = form.getFieldsValue(true);

            let x = '';

            for(let i of values.roomNox)
            {
                if(x === '')
                {
                    x = i;
                }
                else
                {
                    x = x + ',' + i;
                }
            }

            values.roomNo = x;

            props.reloadMe(values);

            let obj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));
            setAvailCount(props.getAllo(obj.itemID));
        // }

    }

    return (
        <>
            {props.type === 'Danger' ?
                <Button type="danger" size="small" onClick={() => {setVisible(true); setData();}}  loading={loading}>
                    <PlayCircleOutlined  /> Select
                </Button>
            :
                <Button type="primary" size="small" onClick={() => {setVisible(true); setData();}}  loading={loading}>
                    <PlayCircleOutlined  /> Select
                </Button>
            }


            <Modal
                centered ={true}
                width="30%"
                destroyOnClose={true}
                visible={visible}
                title="Choose Service"
                onOk={handleOk}
                closable = {true}
                onCancel={handleCancel}
                maskClosable={false}
                footer =""
            >
               <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                            <Form.Item
                                label="Rate Plan"
                                name="rate"
                                rules={[]}
                            >
                                <Input disabled placeholder="Rate Plan" />
                            </Form.Item>

                            <Form.Item
                                label="Price"
                                name="price"
                                rules={[]}
                            >
                                <InputNumber disabled style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                            {isBackDate === true ?
                                <Form.Item
                                name="roomNox"
                                label= 'Select Unit'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please choose a Quantity',
                                //     },
                                // ]}
                                >
                                    <Select
                                        mode= 'multiple'
                                        onChange={handleChange}
                                        allowClear
                                        placeholder="Qty"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch
                                        optionFilterProp="children">
                                        {availCount.map(value => <Option key={value.count} value={value.count}>{value.count}</Option>)}
                                    </Select>
                                </Form.Item>
                            :
                            <Form.Item
                                name="availability"
                                label= 'Select Qty'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please choose a Quantity',
                                //     },
                                // ]}
                                >
                                    <Select
                                        onChange={handleChange}
                                        allowClear
                                        placeholder="Qty"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch
                                        optionFilterProp="children">
                                        {availCount.map(value => <Option key={value.count} value={value.count}>{value.count}</Option>)}
                                    </Select>
                                </Form.Item>
                            }


                            <Form.Item
                                label="Open Price"
                                name="open"
                                rules={[]}
                            >
                                <InputNumber style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                            <Form.Item
                                label="Discount"
                                name="discount"
                                rules={[]}
                            >
                                <InputNumber style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                            <br />
                            <Button type="primary" onClick={() => form.submit()}  loading={loading}>
                                <StepForwardOutlined /> Confirm
                            </Button>
                        </Col>
                    </Row>

                </Form>

            </Modal>
        </>
    );
}

function ChooseAddItemModal(props) {
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);


    useEffect(() => {

        return () => {

        }
    }, [])

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const setData = () => {
        let obj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));

        form.setFieldsValue(obj);
    }

    const onSubmit = () => {
        const values = form.getFieldsValue(true);

        values.indicator = 'additem';
        props.reloadMe(values);
        setVisible(false);
    }

    const handleChange = () => {


       /* const values = form.getFieldsValue(true);
        values.indicator = 'additem';
        props.reloadMe(values);*/

        //let obj = props.obj;
        //setAvailCount(props.getAddItem(obj.itemID));


    }

    return (
        <>
            {props.type === 'Danger' ?
                <Button type="danger" size="small" onClick={() => {setVisible(true); setData();}}  loading={loading}>
                    <PlayCircleOutlined  /> Select
                </Button>
                :
                <Button type="primary" size="small" onClick={() => {setVisible(true); setData();}}  loading={loading}>
                    <PlayCircleOutlined  /> Select
                </Button>
            }


            <Modal
                centered ={true}
                width="30%"
                destroyOnClose={true}
                visible={visible}
                title="Choose Service"
                onOk={handleOk}
                closable = {true}
                onCancel={handleCancel}
                maskClosable={false}
                footer =""
            >
                <Form
                    layout={formLayout}
                    form={form}
                    // style={{ width: '100%'}}
                    onFinish={handleFinish}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                            <Form.Item
                                label="Rate Plan"
                                name="rate"
                                rules={[]}
                            >
                                <Input disabled placeholder="Rate Plan" />
                            </Form.Item>

                            <Form.Item
                                label="Price"
                                name="price"
                                rules={[]}
                            >
                                <InputNumber disabled style={{width: '100%'}}  min={0} max={10000000000}
                                             formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>

                            <Form.Item
                                label="Select Qty"
                                name="availability"
                                rules={[]}
                            >
                                <InputNumber onChange={handleChange} style={{width: '100%'}}  min={0} max={10000000000}
                                             formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                            <br />
                            <Button type="primary" onClick={() => form.submit()}  loading={loading}>
                                <StepForwardOutlined /> Confirm
                            </Button>
                        </Col>
                    </Row>

                </Form>

            </Modal>
        </>
    );
}

function EditBooking(props) {
    const [state,dispatch] = useContext(Context)
    const { get, post, response, loading, error, del, put } = useFetch();
    const { get:getLocal, post:postLocal, response:responseLocal, loading:loadingLocal} = useFetch(`http://localhost:8023`);

    const [form] = Form.useForm()
    const [formLayout, setFormLayout] = useState('vertical');
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);

        //booknow();

    }, [form])

    const [bookingType, setBookingType] = useState('walkin');
    const [nationalityList, setNationalityList] = useState([]);
    const [roomtypeList, setRoomtypeList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);

    const labelCol = 22;
    const wrapperCol = 22;

    const [current, setCurrent] = useState(0);
    const [current2, setCurrent2] = useState(0);
    const [visibleSubmit, setVisibleSubmit] = useState(false);
    const [isPaying, setIsPaying] = useState(false);
    const [isCash, setIsCash] = useState(false);
    const [isCard, setIsCard] = useState(false);
    const [calculated, setCalculated] = useState({price: 0, tax: 0, fee:0, discount:0,rounding:0,amount:0});

    const [customerExistTel, setCustomerExistTel] = useState(false);
    const [customerExistName, setCustomerExistName] = useState(false);
    const [customerExistEmail, setCustomerExistEmail] = useState(false);

    const [allowBackDate, setAllowBackDate] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [isBackDate, setIsBackDate] = useState(false);
    const [foliocreated, setFoliocreated] = useState(false);

    const [dataRoom, setDataRoom] = useState([]);
    const [dataPayment, setDataPayment] = useState([]);
    const [dataLines, setDataLines] = useState([]);

    const [bookedRoomList, setBookedRoomList] = useState([]);

    const [additionalItemList, setAdditionalItemList] = useState([]);

    const [createInvoice, setCreateInvoice] = useState(false);
    const [viewInvoice, setViewInvoice] = useState(false);
    const [reopenFolio, setReopenFolio] = useState(false);
    const [closeFolio, setCloseFolio] = useState(false);
    const [noShow, setNoShow] = useState(false);
    const [cancelFolio, setCancelFolio] = useState(false);
    const [endFolio, setEndFolio] = useState(false);

    const [disableUpdate, setDisableUpdate] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);

    const [mykadAvailable, setMykadAvailable] = useState(false);

    const [inhouse, setInhouse] = useState(false);

    const [locked, setLocked] = useState(false);

    const [toinvoice, setToinvoice] = useState('');
    const [status, setStatus] = useState('');

    let statusx = true;
    function toggleStatus() {
        statusx = !statusx;

        if(statusx === false)
        {
            setToinvoice('');
        }
        else
        {
            setToinvoice('All the units are now closed. Please proceed to CREATE INVOICE');
        }

    }

    const clearForm = () => {
        form.resetFields();

        setBookingType('walkin');
        setRoomtypeList([]);
        setClientList([]);
        setSupplierList([]);
        setStateList([]);
        setCurrent(0);
        setVisibleSubmit(false);
        setIsPaying(false);
        setIsCash(false);
        setIsCard(false);
        setCalculated({price: 0, tax: 0, fee:0, discount:0,rounding:0,amount:0});
        setCustomerExistTel(false);
        setCustomerExistName(false);
        setCustomerExistEmail(false);
    }

    const next = () => {
        form.validateFields().then(values => {
            setCurrent(current + 1);
        })

    };

    const prev = () => {
        setCurrent(current - 1);
    };

    async function loadData() {

        try
        {
            let customSettings = JSON.parse(localStorage.getItem('customsetting'));
            let role = localStorage.getItem('role');

            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails')) ;

            let booking = await get(`/api/folios/${propsObj.folioid}`)
            if (response.ok)
            {
                if(booking !== null)
                {
                    setStatus(booking.status);
                    //console.log('full obj', booking)

                    if(booking.data.locked === true)
                    {
                        setLocked(true);
                    }

                    let obj = {
                        folioid:booking.id,
                        no:booking.no,
                        status:booking.status,
                        type:booking.source,
                        id:booking.data.payor.guest.id,
                        name:booking.data.payor.guest.name,
                        nationality: booking.data.payor.nationality !== null ? booking.data.payor.nationality.name : undefined,
                        supplier: booking.data.payor.otaagency.name,
                        supplierID: booking.data.payor.otaagency.id,
                        client: booking.data.payor.otaagency.name,
                        clientID: booking.data.payor.otaagency.id,
                        //refno: booking.data.bcode != null ? (booking.data.bcode + "/" + booking.data.rcode) : booking.data.rcode , //booking.data.payor.refno,
                        refno: booking.data.rcode != undefined && booking.data.rcode != null && booking.data.rcode != '' ? (booking.data.bcode != null && booking.data.bcode != '' ? (booking.data.rcode.toString().indexOf('/') == -1 ? (booking.data.bcode + '/' + booking.data.rcode): booking.data.rcode) : booking.data.rcode) : (booking.data.payor.refno != null && booking.data.payor.refno != '' ? booking.data.payor.refno:''),
                        contactPerson: booking.data.payor.contactPerson,
                        gender: booking.data.payor.gender,
                        designation: booking.data.payor.designation,
                        tel:booking.data.payor.tel,
                        email:booking.data.payor.email,
                        identificationtype:booking.data.payor.identification.name,
                        identificationno:booking.data.payor.identification.id,
                        guesttype: booking.data.payor.type,
                        line:booking.data.payor.address.line,
                        city:booking.data.payor.address.city,
                        postcode:booking.data.payor.address.postcode,
                        country:booking.data.payor.address.country,
                        state:booking.data.payor.address.state,
                        category:booking.data.payor.category,
                        guestCompany:booking.data.payor.guestCompany,
                        requests:booking.data.requests,
                        paymenttype: undefined,
                        method: undefined,
                        toPay: undefined,
                        cardNo: undefined,
                        expiry: undefined,
                        slipNo: undefined,
                        paymentFor: undefined,
                        bookingid: booking.booking
                    };

                    let a  ={};

                    a.price = booking.breakdown.price;

                    let tx = 0;
                    let fe = 0;
                    let dc = 0;

                    for(let x of booking.breakdown.tax)
                    {
                        tx = tx + x.rate;
                    }
                    for(let x of booking.breakdown.fee)
                    {
                        fe = fe + x.rate;
                    }

                    for (let x of booking.breakdown.discount)
                    {
                        dc = dc + x.rate;
                    }

                    a.tax = tx;
                    a.fee = fe;
                    a.discount = dc;

                    a.rounding = booking.rounding;
                    a.amount = booking.amount;
                    a.paid = booking.paid;
                    a.balance = booking.amount - booking.paid;

                    setCalculated(a);

                    if (booking.status !== "Open")
                    {
                        if (booking.status === "Closed")
                        {
                            setCreateInvoice(true);
                            setViewInvoice(false);
                            setReopenFolio(true);
                            setCloseFolio(false);
                            setNoShow(false);
                            setCancelFolio(true);
                            setEndFolio(false);

                            setDisableUpdate(true);
                        }
                        else if (booking.status === "Invoiced")
                        {
                            setCreateInvoice(false);
                            setViewInvoice(true);
                            setReopenFolio(false);
                            setCloseFolio(false);
                            setNoShow(false);
                            setCancelFolio(false);
                            setEndFolio(false);
                            setDisableUpdate(true);
                        }
                        else if (booking.status === "Cancelled")
                        {
                            setCreateInvoice(false);
                            setViewInvoice(false);
                            setReopenFolio(false);
                            setCloseFolio(false);
                            setNoShow(false);
                            setCancelFolio(false);
                            setEndFolio(false);

                            setDisableUpdate(true);
                            setIsCancelled(true);
                        }
                        else if (booking.status === "Reopened")
                        {
                            setCloseFolio(true);
                            setViewInvoice(false);
                            setCreateInvoice(false);
                            setCancelFolio(true);
                            setReopenFolio(false);
                            setNoShow(false);
                            setEndFolio(false);

                            setDisableUpdate(false);
                        }

                    }
                    else
                    {

                        setViewInvoice(false);
                        setCreateInvoice(false);
                        setCancelFolio(true);
                        setReopenFolio(false);
                        setCloseFolio(false);
                        setNoShow(true);

                        setDisableUpdate(false);

                        if(role === 'supervisor' || role === 'administrator')
                        {
                            if( customSettings !== undefined && customSettings !== null && customSettings !== '')
                            {
                                if( customSettings.data !== undefined && customSettings.data !== null && customSettings.data !== '')
                                {
                                    if( customSettings.data.endFolio !== undefined && customSettings.data.endFolio !== null && customSettings.data.endFolio !== '')
                                    {
                                        if( customSettings.data.endFolio === true)
                                        {
                                            setEndFolio(true);
                                        }
                                        else
                                        {
                                            setEndFolio(false);
                                        }
                                    }
                                    else
                                    {
                                        setEndFolio(false);
                                    }
                                }
                                else
                                {
                                    setEndFolio(false);
                                }

                            }
                            else
                            {
                                setEndFolio(false);
                            }
                        }
                    }



                    onChange(booking.source);

                    form.setFieldsValue(obj);

                    if (booking.status === "Invoiced")
                    {
                        loadInvoiceInfo();
                    }




                }

            }
            else
            {
                errorN(`Get Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

            let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));
            if(devicesetting !== null)
            {
                if(devicesetting.data !== undefined)
                {
                    if(devicesetting.data.mykad !== undefined)
                    {
                        setMykadAvailable(devicesetting.data.mykad);
                    }
                }
            }

            let timesettingObj = JSON.parse(localStorage.getItem('timesetting'));

            if(timesettingObj !== null)
            {
                if(timesettingObj.data !== undefined)
                {
                    if(timesettingObj.data.cutOut !== undefined)
                    {
                        let h1 = timesettingObj.data.cutOut;
                        let h = 0;
                        let m = 0;
                        if(h1 !== null && h1 !== undefined)
                        {
                            let i = h1.split(':');
                            h = parseInt(i[0]);
                            m = parseInt(i[1]);
                        }
                        let date = new Date();
                        let n = moment(date).format('YYYY-MM-DD');
                        let nw = moment(n).add(h, 'h').add(m, 'm').format('YYYY-MM-DD HH:mm:ss');
                        let todaynow = moment(date).format('YYYY-MM-DD HH:mm:ss');


                        if(todaynow <= nw)
                        {
                            let arr = [];
                            arr.push(moment(todaynow).add(-1, 'd'))
                            //arr.push(moment(todaynow))
                            arr.push(undefined)
                            form.setFieldsValue({
                                dt: arr
                            });
                        }
                        else
                        {
                            let arr = [];
                            arr.push(moment(todaynow))
                            //arr.push(moment(todaynow).add(1, 'd'))
                            arr.push(undefined)
                            form.setFieldsValue({
                                dt: arr
                            });
                        }
                    }
                }
            }

            loadLines();
        }
        catch (err)
        {
            errorN(`Error Get Folio: ${err}. Please contact the administrator.`);
        }
    }

    async function loadCbo() {
        let obj = JSON.parse(localStorage.getItem('countrylist'));

        setNationalityList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setCountryList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    }

    async function loadInvoiceInfo(){
        try {
            const values = form.getFieldsValue(true);
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));

            let parameter = `?folio=${values.folioid}`;
            let a = await get(`/api/hotels/${globalObj.id}/invoices${parameter}`)
            if (response.ok)
            {

                if(a.length > 0)
                {
                    form.setFieldsValue({
                        invoiceid: a[0].id
                    });
                }
                else
                {
                    form.setFieldsValue({
                        invoiceid: undefined
                    });
                }
            }
            else
            {
                errorN(`Get Invoice - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Get Invoice: ${err}. Please contact the administrator.`);
        }
    }

    const getState = (e) => {

        try
        {
            setStateList([]);
            form.setFieldsValue({
                state: undefined
            });

            let obj = JSON.parse(localStorage.getItem('countrylist'));

            let ls = obj.data.filter(x => x.name === e);

            if(ls.length > 0)
            {
                setStateList(ls[0].states.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)));
            }

        }
        catch(err)
        {
            errorN(`Error Get States: ${err}. Please contact the administrator.`);
        }

    }

    useEffect(() => {


        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {

            if(props.inhouse === true)
            {
                setInhouse(true);
            }

            loadCbo();
            loadData();
            loadAllocation();
            loadPayment();
            //loadLines(); -> calling inside loadData();
            loadAdditionalItem();

            setInterval(toggleStatus, 500);
        }

        //loadData().then(r => {});

        return () => {

        }
    },[])

    async function getClientSupplier(e) {
        try
        {
            let alist = await get(`/api/parties?type=${e}`)
            if (response.ok)
            {
                if(e === 'client')
                {
                    setClientList(alist);

                    form.setFieldsValue({
                        clientlist: alist
                    });

                }
                else
                {
                    setSupplierList(alist);

                    form.setFieldsValue({
                        supplierlist: alist
                    });
                }
            }
            else
            {
                errorN(`Supplier List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Supplier List: ${err}. Please contact the administrator.`);
        }
    }

    const onChange = (e) => {

        let a = e.target !== undefined ? e.target.value : e;
        setBookingType(a);

        if(a === 'client')
        {
            getClientSupplier('client');
        }
        else if(a === 'agency')
        {
            getClientSupplier('supplier');
        }
    }

    const expandedRowRender = (e) => {

        const values = form.getFieldsValue(true);
        let ls = [];

        if(e.indicator !== undefined)
        {
            if(e.indicator === 'additem')
            {
                ls = values.availabilitylist2.filter(x => x.itemID === e.itemID);

                ls = ls.sort((a,b) => (a.rateID > b.rateID) ? 1 : ((b.rateID > a.rateID) ? -1 : 0))

                return <Table columns={columnsB} size="small" dataSource={ls} pagination={false} />;
            }
        }
        else
        {
            ls = values.availabilitylist.filter(x => x.itemID === e.itemID);

            ls = ls.sort((a,b) => (a.rateID > b.rateID) ? 1 : ((b.rateID > a.rateID) ? -1 : 0))

            return <Table columns={columnsA} size="small" dataSource={ls} pagination={false} />;
        }



    };

    const columnsA =[

        {
            width: '50%',
            title:"Rate Plan",
            dataIndex:"rate",
            key:"rate",
        },
        {
            width: '10%',
            title:"Price",
            dataIndex:"price",
            key:"price",
        },
        {
            width: '10%',
            title: isBackDate === false ? 'Qty' : 'Unit(s)',
            dataIndex:isBackDate === false ? 'availability' : 'roomNo',
            key:isBackDate === false ? 'availability' : 'roomNo',
        },
        {
            width: '10%',
            title:"Open Price",
            dataIndex:"open",
            key:"open",
        },
        {
            width: '10%',
            title:"Discount",
            dataIndex:"discount",
            key:"discount",
        },
        {
            width: '10%',
            title:"",
            dataIndex:"action",
            key:"action",
            render:(text, record) => (
               <ChooseRoomModal
               obj={record}
               reloadMe={reloadMe}
               getAllo={getAllo}
               type={isBackDate === false ? (record.availability !== '' && record.availability !== null && record.availability !== undefined ? 'Danger' : 'Primary') : (record.roomNo !== '' && record.roomNo !== null && record.roomNo !== undefined ? 'Danger' : 'Primary') }
               isBackDate={isBackDate}
               />
            )
        },
    ]

    const columnsB =[

        {
            width: '50%',
            title:"Rate Plan",
            dataIndex:"rate",
            key:"rate",
        },
        {
            width: '10%',
            title:"Price",
            dataIndex:"price",
            key:"price",
        },
        {
            width: '10%',
            title: isBackDate === false ? 'Qty' : 'Unit(s)',
            dataIndex:isBackDate === false ? 'availability' : 'roomNo',
            key:isBackDate === false ? 'availability' : 'roomNo',
        },
        {
            width: '10%',
            title:"",
            dataIndex:"action",
            key:"action",
            render:(text, record) => (
                <ChooseAddItemModal
                    obj={record}
                    reloadMe={reloadMe}
                    /*getAllo={getAddItem}*/
                    type={isBackDate === false ? (record.availability !== '' && record.availability !== null && record.availability !== undefined ? 'Danger' : 'Primary') : (record.roomNo !== '' && record.roomNo !== null && record.roomNo !== undefined ? 'Danger' : 'Primary') }
                    isBackDate={isBackDate}
                />
            )
        },
    ]

    const columns = [
        { title: 'Type', dataIndex: 'item', key: 'item' },
    ]

    async function reloadMe (obj) {

        /*console.log('reload',obj)
        return;*/
        if(obj.indicator === undefined)
        {
            setRoomtypeList([]);

            const values = form.getFieldsValue(true);

            let ls = values.availabilitylist.filter(x => x.rateID !== obj.rateID);

            ls.push(obj);
            form.setFieldsValue({
                availabilitylist: ls
            });

            setRoomtypeList(values.roomtypelist);

            calculate();
        }
        else
        {
            setAdditionalItemList([]);

            const values = form.getFieldsValue(true);
            /*console.log('reload',values)
                    return;*/

            let ls = values.availabilitylist2.filter(x => x.rateID !== obj.rateID);

            ls.push(obj);
            form.setFieldsValue({
                availabilitylist2: ls
            });

            setAdditionalItemList(values.additionalItemlist);
        }


    }

    const getAllo = (itemid) => {

        const values = form.getFieldsValue(true);

        let roomls = JSON.parse(localStorage.getItem('roomlist'));
        let marks = [];

        if(values.allowbackdate === true)
        {
            try{

                let used = [];

                for(let i of values.availabilitylist)
                {
                    if(i.itemID === itemid)
                    {
                        if(i.roomNo !== null && i.roomNo !== undefined && i.roomNo !== '')
                        {
                            let d = i.roomNo.split(',');

                           for(let i of d)
                           {
                               used.push(i);
                           }
                        }
                    }
                }

                let total = [];

                for(let i of roomls)
                {
                    if(i.item === itemid)
                    {
                        total.push(i.data.no);
                    }
                }

                for(let i of used)
                {
                    let index = total.indexOf(i);
                    if (index > -1) {
                        total.splice(index, 1);
                    }
                }

                for(let j of total)
                {
                    marks.push({count:j});
                }


            }
            catch (err) {
                errorN(`Error GAT: ${err}. Please contact the administrator.`);
            }
        }
        else
        {
            let used = 0;
            let total = 0;

            try{

                for(let i of values.availabilitylist)
                {
                    if(i.itemID === itemid)
                    {
                        if(i.availability !== null && i.availability !== undefined && i.availability !== '')
                        {
                            used = used + i.availability;
                        }
                    }
                }


                for(let i of values.roomtypelist)
                {
                    if(i.itemID === itemid)
                    {
                        total = i.total;
                    }
                }

                for(let j=0; j<= total-used; j++)
                {
                    if(j !== 0)
                    {
                        marks.push({count:j})
                    }
                }
            }
            catch (err) {
                errorN(`Error GAF: ${err}. Please contact the administrator.`);
            }
        }

        return marks;
    }

    /*const getAddItem = (itemid) => {

        const values = form.getFieldsValue(true);

        let roomls = JSON.parse(localStorage.getItem('roomlist'));
        let marks = [];

        if(values.allowbackdate === true)
        {
            try{

                let used = [];

                for(let i of values.availabilitylist)
                {
                    if(i.itemID === itemid)
                    {
                        if(i.roomNo !== null && i.roomNo !== undefined && i.roomNo !== '')
                        {
                            let d = i.roomNo.split(',');

                            for(let i of d)
                            {
                                used.push(i);
                            }
                        }
                    }
                }

                let total = [];

                for(let i of roomls)
                {
                    if(i.item === itemid)
                    {
                        total.push(i.data.no);
                    }
                }

                for(let i of used)
                {
                    let index = total.indexOf(i);
                    if (index > -1) {
                        total.splice(index, 1);
                    }
                }

                for(let j of total)
                {
                    marks.push({count:j});
                }


            }
            catch (err) {
                errorN(`Error GAT: ${err}. Please contact the administrator.`);
            }
        }
        else
        {
            let used = 0;
            let total = 0;

            try{

                for(let i of values.availabilitylist)
                {
                    if(i.itemID === itemid)
                    {
                        if(i.availability !== null && i.availability !== undefined && i.availability !== '')
                        {
                            used = used + i.availability;
                        }
                    }
                }


                for(let i of values.roomtypelist)
                {
                    if(i.itemID === itemid)
                    {
                        total = i.total;
                    }
                }

                for(let j=0; j<= total-used; j++)
                {
                    if(j !== 0)
                    {
                        marks.push({count:j})
                    }
                }
            }
            catch (err) {
                errorN(`Error GAF: ${err}. Please contact the administrator.`);
            }
        }

        return marks;
    }*/

    const onPayment = (e) => {
        try{

            if(e === null || e === undefined || e === '')
            {
                setIsPaying(false);
                setIsCash(false);
                setIsCard(false);

                form.setFieldsValue({
                    amount: undefined,
                    cardNo: undefined,
                    expiry: undefined,
                    slipNo: undefined

                });
            }
            else
            {
                setIsPaying(true);

                form.setFieldsValue({
                    cardNo: undefined,
                    expiry: undefined,
                    slipNo: undefined

                });
            }

            if(e === 'Cash')
            {
                setIsCash(true);
                setIsCard(false);
            }
            else if(e === "Credit Card" || e === "Debit Card" || e === "Master Card" || e === "Visa" || e === "Amex")
            {
                setIsCash(false);
                setIsCard(true);
            }
            else
            {
                setIsCash(false);
                setIsCard(false);
            }

        }
        catch (err)
        {
            errorN(`Error onPayment: ${err}. Please contact the administrator.`);
        }
    }

    async function calculate() {
        const values = form.getFieldsValue(true);

        if(values.dt === undefined || values.dt === null )
        {
            return;
        }

        if(values.dt[0] === undefined || values.dt[0] === null )
        {
            return;
        }

        if(values.dt[1] === undefined || values.dt[1] === null)
        {
            return;
        }

        let source = '';
        if(values.type === 'Client')
        {
            source = 'client';
        }
        else if(values.type === 'Travel Agency')
        {
            source = 'agency';
        }
        else
        {
            source = 'walkin';
        }

        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let e = moment(values.dt[1]).format('YYYY-MM-DD');
            let s = moment(values.dt[0]).format('YYYY-MM-DD');

            //let s = dtStart.toISOString();
            //let e = dtEnd.toISOString();

            let n = null;

            if(values.nationality !== undefined)
            {
                let objx = JSON.parse(localStorage.getItem('countrylist'));

                for(let i of objx.data)
                {
                    if(i.name === values.nationality)
                    {
                        n = {id: i.code3, name: i.name}
                    }
                }
            }



            let bookingObj = {
                booking: {
                    hotel: obj.id,
                    source: source,
                    start: s,
                    data: {
                        stay: {
                            arrival: s,
                            departure: e
                        },
                        payor: {
                            nationality:  n,
                            type: values.guesttype
                        }
                    }
                },
                lines: await getBookingLine()
            }

            let a = await post(`/api/bookings/calculate`, bookingObj)
            if (response.ok)
            {
                a.price = a.breakdown.price;

                let tx = 0;
                let fe = 0;
                let dc = 0;

                for(let x of a.breakdown.tax)
                {
                    tx = tx + x.rate;
                }
                for(let x of a.breakdown.fee)
                {
                    fe = fe + x.rate;
                }

                for (let x of a.breakdown.discount)
                {
                    dc = dc + x.rate;
                }

                a.tax = tx;
                a.fee = fe;
                a.discount = dc;

                setCalculated(a);
            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error CALCULATE: ${err}. Please contact the administrator.`);
        }
    }

    async function getBookingLine() {
        try
        {
            const values = form.getFieldsValue(true);
            let roomls = JSON.parse(localStorage.getItem('roomlist'));

            let ls =[];

            let e = moment(values.dt[1]).format('DD/MM/YYYY');
            let s = moment(values.dt[0]).format('DD/MM/YYYY');

            //let s = dtStart.toISOString('dd/MM/yyyy');
            //let e = dtEnd.toISOString('dd/MM/yyyy');

            const date1 = new Date(values.dt[0]);
            const date2 = new Date(values.dt[1]);
            const diffTime = Math.abs(date2 - date1);
            let duration = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


            for(let i of values.availabilitylist)
            {
                if((i.availability !== null && i.availability !== undefined && i.availability !== '') || (i.roomNo !== null && i.roomNo !== undefined && i.roomNo !== ''))
                {
                    let avail = i.availability;

                    if(values.allowbackdate === true)
                    {
                        let c = i.roomNo.split(',');
                        avail = c.length;
                    }

                    let rate = i.rateID;

                    let requestedDiscount = 0;
                    let open = 0;

                    if (i.discount !== null && i.discount !== undefined && i.discount !== '' && i.discount !== 0)
                    {
                        requestedDiscount = i.discount;
                    }

                    if (i.open !== null && i.open !== undefined && i.open !== '' && i.open !== 0)
                    {
                        open = i.open;
                    }


                    let op = {
                        price: open
                    };

                    let d = i.roomNo.split(',');

                    for (var j = 0; j < avail; j++)
                    {
                        let n = '';

                        if(values.allowbackdate === true)
                        {
                            for(let x of roomls)
                            {
                                if(x.data.no === d[j])
                                {
                                    n = x.id;
                                }
                            }
                        }

                        ls.push({unit: duration, rate: rate,
                            data: {
                                arrival: s,
                                departure: e,
                                roomID: n
                            },
                            requestedDiscount: requestedDiscount, open: op});
                    }
                }
            }

            return ls;

        }
        catch (err)
        {
            errorN(`Error GetLines: ${err}. Please contact the administrator.`);
        }
    }

    async function checkCustomer(e, typ){

        let str = '';
        if(typeof e === 'string')
        {
            str = e;
        }
        else
        {
            str = e.target.value;
        }

        if(str === '' ||  str === null ||  str === undefined || str.length <= 3)
        {
            if(typ === 'tel')
            {
                setCustomerExistTel(false);
            }
            else if(typ === 'name')
            {
                setCustomerExistName(false);
            }
            else if(typ === 'email')
            {
                setCustomerExistEmail(false);
            }

            return;
        }

        try
        {
            let s = '';
            if(typ === 'tel')
            {
                s='tel=' + str;
            }
            else if(typ === 'name')
            {
                s='name=' + str;
            }
            else if(typ === 'email')
            {
                s='email=' + str;
            }


            let countx = await get(`/api/parties?${s}`)
            if (response.ok)
            {
               if(countx.length > 0)
               {
                    if(typ === 'tel')
                    {
                        setCustomerExistTel(true);
                    }
                    else if(typ === 'name')
                    {
                        setCustomerExistName(true);
                    }
                    else if(typ === 'email')
                    {
                        setCustomerExistEmail(true);
                    }

               }
               else
               {
                    if(typ === 'tel')
                    {
                        setCustomerExistTel(false);
                    }
                    else if(typ === 'name')
                    {
                        setCustomerExistName(false);
                    }
                    else if(typ === 'email')
                    {
                        setCustomerExistEmail(false);
                    }
               }
            }
            else
            {
                errorN(`Customer List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Check Customer: ${err}. Please contact the administrator.`);
        }
    }

    const setCustomer = (type) => {
        const values = form.getFieldsValue(true);

        return {tel: values.tel , name: values.name, email: values.email, code: type}

    }

    async function loadCustData(customerID){

        if(customerID === '' || customerID === null || customerID === undefined)
        {
            return;
        }

        setCustomerExistEmail(false);
        setCustomerExistName(false);
        setCustomerExistTel(false);

        try
        {
            let profile = await get(`/api/parties/${customerID}`)
            if (response.ok)
            {
                form.setFieldsValue({
                    id : profile.id,
                    tel : profile.data.tel,
                    name : profile.data.name,
                    identificationtype : profile.data.identificationType,
                    identificationno : profile.data.identificationNo,
                    email : profile.data.email,
                    nationality : profile.data.nationality,
                    line : profile.data.address.line,
                    city : profile.data.address.city,
                    postcode : profile.data.address.postcode,
                    country : profile.data.address.country,
                    state : profile.data.address.state,
                    category : profile.category,
                    company : profile.data.guest.guestCompany,
                    guesttype: profile.data.guest.type,
                });

                calculate();
            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Load Customer: ${err}. Please contact the administrator.`);
        }
    }

    async function getCardType(number){

        if(number === '' || number === null || number === undefined)
        {
            return '';
        }

        try
        {
            // visa
            let re = new RegExp("^4");
            if (number.match(re) != null)
                return "Visa";

            // Mastercard
            // Updated for Mastercard 2017 BINs expansion
            if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
                return "Mastercard";

            // AMEX
            re = new RegExp("^3[47]");
            if (number.match(re) != null)
                return "AMEX";

            // Discover
            re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
            if (number.match(re) != null)
                return "Discover";

            // Diners
            re = new RegExp("^36");
            if (number.match(re) != null)
                return "Diners";

            // Diners - Carte Blanche
            re = new RegExp("^30[0-5]");
            if (number.match(re) != null)
                return "Diners - Carte Blanche";

            // JCB
            re = new RegExp("^35(2[89]|[3-8][0-9])");
            if (number.match(re) != null)
                return "JCB";

            // Visa Electron
            re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
            if (number.match(re) != null)
                return "Visa Electron";

            return ''

        }
        catch (err)
        {
            errorN(`Error Card Type: ${err}. Please contact the administrator.`);
            return '';
        }
    }

    const onAllowBackDate = (e) => {

        setIsBackDate(e.target.checked);


        setRoomtypeList([]);

        form.setFieldsValue({
            dt: undefined,
            roomtypelist: [],
            availabilitylist: [],
            allowbackdate: e.target.checked
        });
    }

    async function updatefolio() {
        try
        {
            //const values = form.getFieldsValue(true);

            form.validateFields().then(async values => {



                let foliox = await get(`/api/folios/${values.folioid}`)
                if (response.ok)
                {
                    //console.log("BEFORE: ", foliox)

                    if (foliox != null)
                    {
                        let guest = null;
                        let nationality = null;

                        if(values.id !== undefined && values.id !== null && values.id !== '')
                        {
                            guest = {id:values.id, name:values.name};
                        }

                        if(values.nationality !== undefined)
                        {
                            let objx = JSON.parse(localStorage.getItem('countrylist'));

                            for(let i of objx.data)
                            {
                                if(i.name === values.nationality)
                                {
                                    nationality = {id: i.code3, name: i.name}
                                }
                            }
                        }


                        //let otaagencyName = '';
                        //let otaagencyID = '';

                        //console.log("clientList",values.clientlist)
                        //console.log("supplierList",values.supplierlist)

                        /*if(values.type === 'client')
                        {
                            if(values.clientlist !== undefined)
                            {
                                if(values.clientlist !== null)
                                {
                                    if(values.clientlist.length > 0)
                                    {
                                        for(let i of values.clientlist)
                                        {
                                            if(i.id.toString() === values.clientID)
                                            {
                                                otaagencyName = i.data.name;
                                                otaagencyID = i.id;
                                            }
                                        }
                                    }
                                }
                            }

                        }*/

                        /*if(values.type === 'agency')
                        {
                            if(values.supplierlist !== undefined)
                            {
                                if(values.supplierlist !== null)
                                {
                                    if(values.supplierlist.length > 0)
                                    {
                                        for(let i of values.supplierlist)
                                        {
                                            if(i.id.toString() === values.supplierID)
                                            {
                                                otaagencyName = i.data.name;
                                                otaagencyID = i.id;
                                            }
                                        }
                                    }
                                }
                            }
                        }*/

                        //console.log("otaagencyName",otaagencyName)
                        //console.log("otaagencyID",otaagencyID)
                        //console.log("values.supplierID",values.supplierID)


                        foliox.data.payor.tel = values.tel;
                        foliox.data.payor.name = values.name;

                        if (foliox.source == "walkin" || foliox.source == "web")
                        {

                        }
                        else if (foliox.source == "client" || foliox.source == "agency")
                        {
                            /*foliox.data.payor.otaagency = {
                                id : otaagencyID,
                                name: otaagencyName
                            };*/

                            foliox.data.payor.contactPerson = values.contactPerson;
                            foliox.data.payor.refno = values.refno;
                        }


                        foliox.data.payor.identification = {
                            name :  values.identificationtype,
                            id :  values.identificationno
                        }


                        foliox.data.payor.email =values.email;
                        foliox.data.payor.nationality = nationality;


                        foliox.data.payor.address = {
                            line: values.line,
                            city: values.city,
                            postcode: values.postcode,
                            country: values.country,
                            state: values.state
                        };

                        foliox.data.payor.type = values.guesttype;
                        foliox.data.requests = values.requests;

                        foliox.data.payor.category = values.category;
                        foliox.data.payor.gender = values.gender;
                        foliox.data.payor.designation = values.designation;
                        foliox.data.payor.guestCompany = values.guestCompany;

                        foliox.data.payor.guest = guest;
                        foliox.data.rcode = values.refno;

                        //foliox.allowCreatedChange =  false;

                        //foliox.data.locked = true;
                    }

                    //console.log("thats it", JSON.stringify(foliox));

                    //console.log("AFTER: ", foliox)


                    let b = await put(`/api/folios/${values.folioid}`,foliox)
                    if (response.ok)
                    {

                        if(foliox.data.locked === true)
                        {
                            successN('Folio Updated Successfully');
                            loadData();
                        }
                        else
                        {

                            let source = values.type;

                            let otaagencyName = '';
                            let otaagencyID = '';

                            if(values.type === 'client')
                            {
                                otaagencyName = values.client;
                                otaagencyID = values.clientID;
                            }

                            if(values.type === 'agency')
                            {
                                otaagencyName = values.supplier;
                                otaagencyID = values.supplierID;
                            }

                            let obj = {
                                source: source,
                                rcode: source == "walkin" ? "" : values.refno,
                                otaagency :{
                                    name: source == "walkin" ? "" : otaagencyName,
                                    id: source == "walkin" ? "" : otaagencyID
                                },
                                refno: source == "walkin" ? "" : values.refno,
                                agent: source == "walkin" ? 0 : otaagencyID
                            };

                            //console.log("AT END: ", obj)

                            let b = await put(`/api/folios/${values.folioid}/data`,obj)
                            if (response.ok)
                            {
                                successN('Folio Updated Successfully');
                                loadData();
                            }
                            else
                            {
                                errorN(`Update Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }
                        }

                    }
                    else
                    {
                        errorN(`Update Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
                else
                {
                    errorN(`Get Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            });

        }
        catch (err)
        {
            errorN(`Error GET/UPDATE Folio: ${err}. Please contact the administrator.`);
        }

    }

    async function createpayment(){
        try
        {
            //console.log("here")
            form.validateFields().then(async values => {


                //let propsObj = props.obj;
                let obj = JSON.parse(localStorage.getItem('currentproperty'));
                //const values = form.getFieldsValue(true);

                if(values.method !== undefined)
                {
                    form.validateFields().then(async values => {

                        let foliox = await get(`/api/folios/${values.folioid}`)
                        if (response.ok)
                        {
                            let paytype = '';

                            if (values.paymenttype == 'Payment')
                            {
                                paytype = 'receipt';
                            }
                            else
                            {
                                paytype = 'remittance';
                            }

                            let cardtype = await getCardType(values.cardNo !== undefined && values.cardNo !== null && values.cardNo !== '' ? values.cardNo : '');

                            let payment = {
                                hotel : obj.id,
                                amount : values.toPay,
                                type : paytype,
                                folio : values.folioid,
                                collector : localStorage.getItem('id'),
                                data : {
                                    cardNo : values.cardNo !== undefined && values.cardNo !== null && values.cardNo !== '' ? values.cardNo : '',
                                    expiry : values.expiry !== undefined && values.expiry !== null && values.expiry !== '' ? values.expiry : '',
                                    paymentFor : values.paymentFor,
                                    slipNo : values.slipNo !== undefined && values.slipNo !== null && values.slipNo !== '' ? values.slipNo : '',
                                    cardType : cardtype,
                                    by : {
                                        id : localStorage.getItem('id'),
                                        name : localStorage.getItem('name')
                                    },
                                    method : values.method !== undefined && values.method !== null && values.method !== '' ? values.method : '',
                                    payor : foliox.data.payor
                                }
                            };



                            let a = await post(`/api/payments`,payment)
                            if (response.ok)
                            {
                                successN('Payment Successful');
                                loadData();
                                loadPayment();
                            }
                            else
                            {
                                errorN(`Payment - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }
                        }
                        else
                        {
                            if(response.status === 401)
                            {
                                localStorage.removeItem('session');
                                window.location.reload();
                            }
                            else
                            {
                                errorN(`Payment- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }

                        }

                    });
                }

            });

        }
        catch (err)
        {
            errorN(`Error Make Payment: ${err}. Please contact the administrator.`);
        }


    }

    async function loadAllocation() {
        try
        {
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let globalRoomList = JSON.parse(localStorage.getItem('roomlist'));

            let parameter = `?folio=${propsObj.folioid}`;

            let allocationList = await get(`/api/hotels/${globalObj.id}/allocations${parameter}`)
            if (response.ok)
            {
                //console.log("allocationList",allocationList)
                let table = [];
                let ls =[];
                let flg = false;

                for(let i of allocationList)
                {
                    if(i.room === null)
                    {
                        table.push({
                            id: i.id,
                            status: i.status,
                            unitno: 'Unit No.',
                            unittype: i.data.item.name,
                            name: i.data.guest.name,
                            folioid: i.folio,
                            itemid: i.item,
                            start: i.start,
                            end: i.end,
                            arrival: moment(i.start).local().format('DD-MM-YYYY'),
                            departure: moment(i.end).local().format('DD-MM-YYYY'),
                            room: i.room,
                            sdate: i.sdate,
                            edate: i.edate
                        });
                    }
                    else
                    {
                        let roomNo = "";

                        for(let x of globalRoomList)
                        {
                            if(x.id == i.room)
                            {
                                roomNo = x.data.no;
                            }
                        }

                        table.push({
                            id: i.id,
                            status: i.status,
                            unitno: roomNo,
                            unittype: i.data.item.name,
                            name: i.data.guest.name,
                            folioid: i.folio,
                            itemid: i.item,
                            start: i.start,
                            end: i.end,
                            arrival: moment(i.start).local().format('DD-MM-YYYY'),
                            departure: moment(i.end).local().format('DD-MM-YYYY'),
                            room: i.room,
                            sdate: i.sdate,
                            edate: i.edate
                        });

                        ls.push({id:i.id, no:roomNo});
                    }
                }

                setDataRoom(table);
                setBookedRoomList(ls);

                await loadLines();
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Load Allocation: ${err}. Please contact the administrator.`);
        }
    }

    async function loadPayment() {
        try
        {
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));

            let paymentList = await get(`/api/folios/${propsObj.folioid}/payments`)
            if (response.ok)
            {
                let table = [];
                for(let i of paymentList)
                {
                    table.push({
                        id: i.id,
                        no: i.no,
                        type: i.type,
                        amount:i.amount,
                        date: moment(i.date).local().format('DD-MM-YYYY'), //('DD-MM-YYYY hh:mm:ss a'),
                        folioid: i.folio,
                        by: i.data.by.name,
                        method: i.data.method,
                        end: i.end,
                        arrival: i.data.arrival,
                        departure: i.data.departure,
                        room: i.room
                    });
                }

                setDataPayment(table);

            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Payment List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Load Payment: ${err}. Please contact the administrator.`);
        }
    }

    async function loadLines() {
        try
        {
            const values = form.getFieldsValue(true);
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));

            let lineList = await get(`/api/folios/${propsObj.folioid}/lines`)
            if (response.ok)
            {
                let table = [];
                for(let i of lineList)
                {
                    let accDiscount = 0;

                    if (i.breakdown.discount != null)
                    {
                        for (let k of i.breakdown.discount)
                        {
                            accDiscount = accDiscount + k.rate;
                        }
                    }

                    table.push({
                        id: i.id,
                        folioid: i.folio,
                        rateid: i.rate,
                        date: moment(i.date).local().format('DD-MM-YYYY'), //('DD-MM-YYYY hh:mm:ss a'),
                        allocationid: i.allocation,
                        itemid:i.item,
                        tempID: '',
                        tempSTR: accDiscount,
                        rate:i.data.rate.plan,
                        price:i.breakdown.price,
                        item: i.data.item.name,
                        uom: i.data.rate.uom,
                        folioStatus: values.status
                    });
                }

                setDataLines(table);

            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Load Allocation: ${err}. Please contact the administrator.`);
        }
    }

    async function loadAvailability(){
        setRoomtypeList([]);
        form.setFieldsValue({
            roomtypelist: [],
            availabilitylist: []
        });
        //setCalculated({price: 0, tax: 0, fee:0, discount:0,rounding:0,amount:0});

        let timesettingObj = JSON.parse(localStorage.getItem('timesetting'));

        const values = form.getFieldsValue(true);

        if(values.dt === undefined || values.dt === null)
        {
            return;
        }
        else
        {
            if(values.dt[0] === undefined || values.dt[1] === undefined || values.dt[0] === null || values.dt[1] === null)
            {
                return;
            }
        }

        let date = new Date();
        let todaynow = moment(date).format('YYYY-MM-DD HH:mm:ss');
        if(timesettingObj !== null)
        {
            if(timesettingObj.data !== undefined)
            {
                if(timesettingObj.data.cutOut !== undefined)
                {
                    let h1 = timesettingObj.data.cutOut;
                    let h = 0;
                    let m = 0;
                    if(h1 !== null && h1 !== undefined)
                    {
                        let i = h1.split(':');
                        h = parseInt(i[0]);
                        m = parseInt(i[1]);
                    }

                    let n = moment(date).format('YYYY-MM-DD');
                    let nw = moment(n).add(h, 'h').add(m, 'm').format('YYYY-MM-DD HH:mm:ss');


                    if(todaynow <= nw)
                    {
                        todaynow = (moment(todaynow).add(-1, 'd'))
                    }
                }
            }
        }


        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let e = moment(values.dt[1]).format('YYYY-MM-DD');
            let s = moment(values.dt[0]).format('YYYY-MM-DD');
            let t = moment(todaynow).format('YYYY-MM-DD');

            if(e === s)
            {
                errorN('Departure date should greater than Arrival date.');

                let arr = [];
                arr.push(moment(todaynow));
                arr.push(undefined);
                form.setFieldsValue({
                    dt: arr
                });

                /*if(timesettingObj !== null)
                {
                    if(timesettingObj.data !== undefined)
                    {
                        if(timesettingObj.data.cutOut !== undefined)
                        {
                            let h1 = timesettingObj.data.cutOut;
                            let h = 0;
                            let m = 0;
                            if(h1 !== null && h1 !== undefined)
                            {
                                let i = h1.split(':');
                                h = parseInt(i[0]);
                                m = parseInt(i[1]);
                            }
                            let date = new Date();
                            let n = moment(date).format('YYYY-MM-DD');
                            let nw = moment(n).add(h, 'h').add(m, 'm').format('YYYY-MM-DD HH:mm:ss');
                            let todaynow = moment(date).format('YYYY-MM-DD HH:mm:ss');


                            if(todaynow <= nw)
                            {
                                let arr = [];
                                arr.push(moment(todaynow).add(-1, 'd'))
                                //arr.push(moment(todaynow))
                                arr.push(undefined)
                                form.setFieldsValue({
                                    dt: arr
                                });
                            }
                            else
                            {
                                let arr = [];
                                arr.push(moment(todaynow))
                                //arr.push(moment(todaynow).add(1, 'd'))
                                arr.push(undefined)
                                form.setFieldsValue({
                                    dt: arr
                                });
                            }
                        }
                    }
                }*/

                return;
            }

            if(t > s)
            {
                errorN('Back Date booking is NOT ALLOWED');

                let arr = [];
                arr.push(moment(todaynow));
                arr.push(undefined);
                form.setFieldsValue({
                    dt: arr
                });

                return;
            }

            //let dtNow = moment(Date.now());

            if(values.allowbackdate === true)
            {
                //if(s >= dtNow || e >= dtNow)
                if(s >= t || e >= t)
                {
                    errorN('Back Date entry Arrival & Departure should not greater than current date.');

                    let arr = [];
                    arr.push(moment(todaynow));
                    arr.push(undefined);
                    form.setFieldsValue({
                        dt: arr
                    });

                    /*form.setFieldsValue({
                        dt: undefined
                    });*/

                    /*if(timesettingObj !== null)
                    {
                        if(timesettingObj.data !== undefined)
                        {
                            if(timesettingObj.data.cutOut !== undefined)
                            {
                                let h1 = timesettingObj.data.cutOut;
                                let h = 0;
                                let m = 0;
                                if(h1 !== null && h1 !== undefined)
                                {
                                    let i = h1.split(':');
                                    h = parseInt(i[0]);
                                    m = parseInt(i[1]);
                                }
                                let date = new Date();
                                let n = moment(date).format('YYYY-MM-DD');
                                let nw = moment(n).add(h, 'h').add(m, 'm').format('YYYY-MM-DD HH:mm:ss');
                                let todaynow = moment(date).format('YYYY-MM-DD HH:mm:ss');


                                if(todaynow <= nw)
                                {
                                    let arr = [];
                                    arr.push(moment(todaynow).add(-1, 'd'))
                                    //arr.push(moment(todaynow))
                                    arr.push(undefined)
                                    form.setFieldsValue({
                                        dt: arr
                                    });
                                }
                                else
                                {
                                    let arr = [];
                                    arr.push(moment(todaynow))
                                    //arr.push(moment(todaynow).add(1, 'd'))
                                    arr.push(undefined)
                                    form.setFieldsValue({
                                        dt: arr
                                    });
                                }
                            }
                        }
                    }*/

                    return;
                }
            }

            //let s = dtStart.toISOString();
            //let e = dtEnd.toISOString();

            let availabilityList = await get(`/api/hotels/${obj.id}/availability_rates?start=${s}&end=${e}`)
            if (response.ok)
            {
                let table1 =[];
                let table2 =[];

                for(let i of availabilityList)
                {
                    for(let j of i.rates)
                    {
                        if (j.data.wubook === "" || j.data.wubook === null || j.data.wubook === undefined)
                        {
                            table1.push({
                                key: j.id,
                                item: i.data.name + ": " + i.total + " unit(s)",
                                rate:  j.data.plan + ": " + j.data.name,
                                availability: '',
                                roomNo: '',
                                price: j.type === 'base' ? j.data.price : 'Daily',
                                rateType: j.type,
                                itemID: i.item,
                                rateID: j.id,
                                discount: '',
                                open: ''
                            })
                        }

                    }

                    table2.push({key: i.item,itemID: i.item,item: i.data.name + ": " + i.total + " unit(s)", total:i.total})
                }


                setRoomtypeList(table2);
                //setAvailabilityList(table1);

                form.setFieldsValue({
                    roomtypelist: table2,
                    availabilitylist: table1
                });
            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error LAV: ${err}. Please contact the administrator.`);
        }
    }

    async function loadAdditionalItem(){
        setRoomtypeList([]);
        form.setFieldsValue({
            additionalItemlist: [],
            availabilitylist2: []
        });

        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));


            let availabilityList = await get(`/api/hotels/${obj.id}/availability_rates_addon`)
            if (response.ok)
            {
                let table1 =[];
                let table2 =[];

                for(let i of availabilityList)
                {
                    for(let j of i.rates)
                    {
                        table1.push({
                            key: j.id,
                            item: i.data.name,
                            rate:  j.data.plan + ": " + j.data.name,
                            availability: '',
                            roomNo: '',
                            price: j.data.price,
                            rateType: j.type,
                            itemID: i.item,
                            rateID: j.id,
                            discount: '',
                            open: ''
                        })

                    }

                    table2.push({key: i.item,itemID: i.item,item: i.data.name, total:i.total, indicator: 'additem'})
                }


                setAdditionalItemList(table2);


                form.setFieldsValue({
                    additionalItemlist: table2,
                    availabilitylist2: table1

                });

            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error LAV: ${err}. Please contact the administrator.`);
        }
    }

    const columnsRoom = [
        {
            title: 'Type',
            dataIndex: 'unittype',
            width: '20%',
        },
        {
            title: 'Arrival',
            dataIndex: 'arrival',
            width: '20%',
        },
        {
            title: 'Departure',
            dataIndex: 'departure',
            width: '20%',
        },
        {
            title: 'Unit No.',
            dataIndex: 'unitno',
            width: '15%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '12%',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '18%',
            render: (text, record) => (
                <>
                    {record.status === 'Blocked' && disableUpdate === false  ?
                        record.room  === null ?
                            <>
                                <StepForwardOutlined   twoToneColor="green"/>&nbsp;
                                <FModal formname={'setroom'} obj={record} loadData={loadAllocation} />
                            </>
                            :
                            <Popconfirm
                                title={`Are you sure want to proceed this action?`}
                                onConfirm={() => checkin(record.id, record.room)}
                                okText="Yes"
                                cancelText="No">
                                <StepForwardOutlined   twoToneColor="green"/>&nbsp;
                                <a>Check In</a>
                            </Popconfirm>
                        :
                        record.status === 'Occupied' && disableUpdate === false ?
                            <Popconfirm
                                title={`Are you sure want to proceed this action?`}
                                onConfirm={() => checkout(record.id, record.room)}
                                okText="Yes"
                                cancelText="No">
                                <StepForwardOutlined   twoToneColor="green"/>&nbsp;
                                <a>Check Out</a>
                            </Popconfirm>
                            :
                            null
                    }

                </>
            )
        },
        {
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            width: '18%',
            render: (text, record) => (
                <>
                    {(record.status === 'Blocked' || record.status === 'Occupied') && disableUpdate === false ?
                        <>
                            <PlusCircleOutlined    twoToneColor="green"/>&nbsp;
                            <FModal formname={'extendstay'} obj={record} loadData={loadAllocation} />
                        </>
                            :
                            null
                    }

                </>
            )
        },
        {
            title: '',
            dataIndex: 'action3',
            key: 'action3',
            width: '18%',
            render: (text, record) => (
                <>
                    {(record.status === 'Blocked' || record.status === 'Occupied') && disableUpdate === false ?
                        <>
                            {record.status === 'Blocked' || record.status === 'Occupied' ?
                                <>
                                    <SwapOutlined    twoToneColor="green"/>&nbsp;
                                    <FModal formname={'changeroom'} obj={record} loadData={loadAllocation} />
                                </>
                                :
                                null
                            }
                        </>
                        :
                        null
                    }

                </>
            )
        },
        {
            title: '',
            dataIndex: 'action4',
            key: 'action4',
            width: '18%',
            render: (text, record) => (
                <>
                    {(record.status === 'Blocked' || record.status === 'Occupied')  && disableUpdate === false ?
                        <>
                            <FormOutlined    twoToneColor="green"/>&nbsp;
                            <FModal formname={'updateallocation'} obj={record} />
                        </>
                        :
                        null
                    }

                </>
            )
        },
        {
            title: '',
            dataIndex: 'action6',
            key: 'action6',
            width: '15%',
            render: (text, record) => (
                <>
                    {record.status === 'Blocked' ?
                        <>
                        </>
                        :
                        record.status === 'Occupied' ?
                            <Popconfirm
                                title={`Are you sure want to proceed this action?`}
                                onConfirm={() => writecard(record.id, record.room, record.sdate, record.edate)}
                                okText="Yes"
                                cancelText="No">
                                <CreditCardOutlined    twoToneColor="green"/>&nbsp;
                                <a>Write Card</a>
                            </Popconfirm>
                            :
                            null
                    }

                </>
            )
        },
        {
            title: '',
            dataIndex: 'action5',
            key: 'action5',
            width: '18%',
            render: (text, record) => (
                <>
                    {record.status === 'Blocked' && disableUpdate === false ?
                        <>
                            <Popconfirm
                                title={`Are you sure want to remove this unit?`}
                                onConfirm={() => removeunit(record.id)}
                                okText="Yes"
                                cancelText="No">
                                <CloseCircleOutlined   twoToneColor="green"/>&nbsp;
                                <a>Remove Unit</a>
                            </Popconfirm>
                        </>
                        :
                        null
                    }

                </>
            )
        },

    ];

    const columnsPayment = [
        {
            title: 'No.',
            dataIndex: 'no',
            width: '18%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            width: '20%',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: '18%',
        },
        {
            title: 'Method',
            dataIndex: 'method',
            width: '18%',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: '18%',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '18%',
            render: (text, record) => (
                <>
                    <ShowReceipt obj={record}  />
                </>
            )
        },


    ];

    const columnsLines = [
        {
            title: 'Date',
            dataIndex: 'date',
            width: '20%',
        },
        {
            title: 'Item',
            dataIndex: 'item',
            width: '18%',
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            width: '18%',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width: '18%',
        },
        {
            title: 'Discounted',
            dataIndex: 'tempSTR',
            width: '18%',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '18%',
            render: (text, record) => (
                <>
                    {record.folioStatus !== 'Invoiced' && record.folioStatus !== 'Cancelled' && disableUpdate === false ?
                        <>
                            <StepForwardOutlined   twoToneColor="green"/>&nbsp;
                            <FModal formname={'editlines'} obj={record} loadData={loadData} />
                        </>
                        :
                        null
                    }
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action5',
            key: 'action5',
            width: '18%',
            render: (text, record) => (
                <>
                    {record.uom !== 'Day' && record.uom !== 'Hour' && record.folioStatus !== 'Invoiced' && record.folioStatus !== 'Cancelled' ?
                        <>
                            <Popconfirm
                                title={`Are you sure want to remove this item?`}
                                onConfirm={() => removeitem(record.id)}
                                okText="Yes"
                                cancelText="No">
                                <CloseCircleOutlined twoToneColor="green"/>&nbsp;
                                <a>Remove</a>
                            </Popconfirm>
                        </>
                        :
                        null
                    }

                </>
            )
        },

    ];

    async function checkin(allocationid, roomid) {
        try
        {
            const values = form.getFieldsValue(true);
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let userid = localStorage.getItem('id');
            let username = localStorage.getItem('name');
            let timesettingObj = JSON.parse(localStorage.getItem('timesetting'));

            let date = new Date();
            let start = '';
            let end = '';
            let now = '';

            let h1 = timesettingObj.data.end;
            let h =12;
            if(h1 !== null && h1 !== undefined)
            {
                let i = h1.split(':');
                h = parseInt(i[0]);
            }

            start = moment(propsObj.start).local().format('YYYY-MM-DD');
            end = moment(propsObj.end).local().add(h, 'h').format('YYYY-MM-DD HH:mm:ss');
            now = moment(date).format('YYYY-MM-DD HH:mm:ss');

            if (end <= now)
            {
                errorN(`Invalid: Check-In NOT ALLOWED. This allocation is already due Check Out.`);
                return;
            }

            let room = {room: roomid};

            let a = await post(`/api/allocations/${allocationid}/checkin`,room)
            if (response.ok)
            {
                let ob = await get(`/api/allocations/${allocationid}`)
                if (response.ok)
                {
                    ob.data.checkedInBy = {id:userid , name:username };

                    let b = await put(`/api/allocations/data/${allocationid}`,ob)
                    if (response.ok)
                    {
                        successN('Check In Successful');
                        //props.loadData();
                        loadAllocation();
                    }
                    else
                    {
                        errorN(`Update Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    errorN(`Get Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                if(response.status === 503)
                {
                    errorN(`Check In - Unit not available for check-in because the unit is currently not in Available-Clean.`);
                }
                else
                {
                    errorN(`Check In - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }

        }
        catch (err)
        {
            errorN(`Error Check In: ${err}. Please contact the administrator.`);
        }
    }

    async function checkout(allocationid, roomid) {
        try
        {
            const values = form.getFieldsValue(true);
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let userid = localStorage.getItem('id');
            let username = localStorage.getItem('name');

            let room = {room: roomid};

            let a = await post(`/api/allocations/${allocationid}/checkout`,room)
            if (response.ok)
            {
                let ob = await get(`/api/allocations/${allocationid}`)
                if (response.ok)
                {
                    ob.data.checkedOutBy = {id:userid , name:username };

                    let b = await put(`/api/allocations/data/${allocationid}`,ob)
                    if (response.ok)
                    {
                        successN('Check Out Successful');
                        loadData();
                        loadAllocation();
                    }
                    else
                    {
                        errorN(`Update Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    errorN(`Get Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                errorN(`Check Out - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Check In: ${err}. Please contact the administrator.`);
        }
    }

    async function removeunit(allocationid) {
        try
        {
            let ls = [];

            ls.push(allocationid);

            let a = await post(`/api/allocations/delete`,ls)
            if (response.ok)
            {
                successN('Remove Unit Successful');
                loadData();
                loadAllocation();
                loadLines();
            }
            else
            {
                errorN(`Remove Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Remove Unit: ${err}. Please contact the administrator.`);
        }
    }

    async function removeitem(id) {
        try
        {
            let ls = [];

            ls.push(id);

            let a = await post(`/api/folio_lines/delete`,ls)
            if (response.ok)
            {
                successN('Remove Item Successful');
                loadData();
                loadLines();
            }
            else
            {
                errorN(`Remove Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Remove Unit: ${err}. Please contact the administrator.`);
        }
    }

    async function booknow()  {

        try
        {
            const values = form.getFieldsValue(true);

            if(values.availabilitylist === undefined)
            {
                errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                return;
            }
            else
            {
                if(values.availabilitylist.length === 0)
                {
                    errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                    return;
                }
                else
                {
                    let count  = 0;
                    for(let i of values.availabilitylist)
                    {
                        if(i.availability !== null && i.availability !== undefined && i.availability !== '')
                        {
                            count = count + i.availability;
                        }
                    }

                    if(count === 0)
                    {
                        errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                        return;
                    }
                }
            }

            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let e = moment(values.dt[1]).format('YYYY-MM-DD');
            let s = moment(values.dt[0]).format('YYYY-MM-DD');

            let dtNow = moment(Date.now());

            const date1 = new Date(values.dt[0]);
            const date2 = new Date(values.dt[1]);
            const diffTime = Math.abs(date2 - date1);
            let duration = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if(values.allowbackdate === true)
            {
                if(s >= dtNow || e >= dtNow)
                {
                    errorN('Back Date entry Arrival & Departure should not greater than current date.');
                    form.setFieldsValue({
                        dt: undefined
                    });
                    return;
                }
            }

            let availabilityList = await get(`/api/hotels/${obj.id}/availability_rates?start=${s}&end=${e}`)
            if (response.ok)
            {
                let itemList = await get(`/api/hotels/${obj.id}/items`)
                if (response.ok)
                {
                    let ls =[];
                    let requestedDiscount = 0;
                    let open = 0;

                    let item = {};
                    let ratex = {};
                    let itemID = 0;


                    for(let i of values.availabilitylist) {
                        if ((i.availability !== null && i.availability !== undefined && i.availability !== '') || (i.roomNo !== null && i.roomNo !== undefined && i.roomNo !== '')) {

                            itemID = i.itemID

                            for(let val of availabilityList) {
                                for(let valx of val.rates)
                                {
                                    if (i.rateID == valx.id)
                                    {
                                        ratex = {
                                            name: valx.data.name,
                                            plan: valx.data.plan,
                                            package: valx.package,
                                            price: valx.data.price,
                                            type: valx.type,
                                            uom: valx.data.uom,
                                            revenue: 0.0
                                        };

                                        for (let y of itemList)
                                        {
                                            if (y.id == itemID)
                                            {
                                                item = {
                                                    name: y.data.name,
                                                    taxes: null,
                                                    fees: null,
                                                    discounts: null
                                                };
                                            }
                                        }
                                    }
                                }
                            }

                            let data = {
                                arrival:  moment(values.dt[0]).format('DD/MM/YYYY').toString(),
                                departure: moment(values.dt[1]).format('DD/MM/YYYY').toString(),
                                item: item,
                                rate: ratex,
                                tempID: null,
                                tempSTR: null,
                                roomID: null,
                                open: null
                            };


                            if (i.discount !== null && i.discount !== undefined && i.discount !== '' && i.discount !== 0)
                            {
                                requestedDiscount = i.discount;
                            }

                            if (i.open !== null && i.open !== undefined && i.open !== '' && i.open !== 0)
                            {
                                open = i.open;
                            }

                            let op = {
                                price: open
                            };

                            for (let j = 0; j < i.availability; j++)
                            {
                                ls.push({
                                    id: 0,
                                    unit: duration,
                                    rate: i.rateID,
                                    data: data,
                                    date: moment(values.dt[0]),
                                    item: itemID,
                                    folio: values.folioid,
                                    requestedDiscount: requestedDiscount,
                                    open:op,
                                    breakdown: null
                                });
                            }

                        }
                    }



                    let a = await post(`/api/folio/allocations`,ls)
                    if (response.ok)
                    {
                        successN('Unit added successfully');
                        loadData();
                        loadAllocation();
                        loadLines();

                        form.setFieldsValue({
                            dt: undefined
                        });

                        setRoomtypeList([]);
                    }
                    else
                    {
                        errorN(`Add Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    errorN(`Item List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }



           /* let a = await post(`/api/bookings/lines`, bookingObj)
            if (response.ok)
            {
                props.loadData();
                setIsDone(true);

            }
            else
            {
                errorN(`Book Now - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }*/



        }
        catch (err)
        {
            errorN(`Error BOOK: ${err}. Please contact the administrator.`);
        }

    }

    async function addnow()  {

        try
        {
            const values = form.getFieldsValue(true);

            if(values.availabilitylist2 === undefined)
            {
                errorN(`Invalid: Choose at least 1 item to proceed.`);
                return;
            }
            else
            {
                if(values.availabilitylist2.length === 0)
                {
                    errorN(`Invalid: Choose at least 1 item to proceed.`);
                    return;
                }
                else
                {
                    let count  = 0;
                    for(let i of values.availabilitylist2)
                    {
                        if(i.availability !== null && i.availability !== undefined && i.availability !== '')
                        {
                            count = count + i.availability;
                        }
                    }

                    if(count === 0)
                    {
                        errorN(`Invalid: Choose at least item to proceed.`);
                        return;
                    }
                }
            }

            let obj = JSON.parse(localStorage.getItem('currentproperty'));


            let availabilityList = await get(`/api/hotels/${obj.id}/availability_rates_addon`)
            if (response.ok)
            {
                let itemList = await get(`/api/hotels/${obj.id}/items`)
                if (response.ok) {
                    let ls =[];

                    let item = {};
                    let ratex = {};
                    let itemID = 0;

                    let dtNow = moment(Date.now());

                    for(let i of values.availabilitylist2) {
                        if ((i.availability !== null && i.availability !== undefined && i.availability !== '') || (i.roomNo !== null && i.roomNo !== undefined && i.roomNo !== '')) {

                            itemID = i.itemID

                            for(let val of availabilityList) {
                                for(let valx of val.rates)
                                {
                                    if (i.rateID == valx.id)
                                    {
                                        ratex = {
                                            name: valx.data.name,
                                            plan: valx.data.plan,
                                            package: valx.package === undefined ? valx.package : false,
                                            price: valx.data.price,
                                            type: valx.type,
                                            uom: valx.data.uom,
                                            revenue: 0.0
                                        };

                                        for (let y of itemList)
                                        {
                                            if (y.id == itemID)
                                            {
                                                item = {
                                                    name: y.data.name,
                                                    taxes: null,
                                                    fees: null,
                                                    discounts: null
                                                };
                                            }
                                        }
                                    }
                                }
                            }

                            let data = {
                                arrival:  '',
                                departure: '',
                                item: item,
                                rate: ratex,
                                tempID: null,
                                tempSTR: null,
                                roomID: null,
                                open: null
                            };


                            for (let j = 0; j < i.availability; j++)
                            {
                                ls.push({
                                    id: 0,
                                    unit: 1,
                                    rate: i.rateID,
                                    data: data,
                                    date: dtNow,
                                    item: itemID,
                                    folio: values.folioid,
                                    requestedDiscount: 0,
                                    open: null,
                                    breakdown: null
                                });
                            }

                        }
                    }

                    /*console.log('ape jadi', ls)
                    return;*/
                    let a = await post(`/api/folio_lines`,ls)
                    if (response.ok)
                    {
                        successN('Unit added successfully');
                        loadData();
                        loadLines();
                        loadAdditionalItem();
                    }
                    else
                    {
                        errorN(`Add Additional Item - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    errorN(`Add Additional Item Get Item - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                errorN(`Add Additional Item Get Availability - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Add Item: ${err}. Please contact the administrator.`);
        }

    }

    const onChangeStep = (e) => {
        setCurrent(e);
    }

    /*async function cancelfolio(){
        try {


            let customSettings = JSON.parse(localStorage.getItem('customsetting'));
            let role = JSON.parse(localStorage.getItem('role'));

            if(role === 'cashier')
            {
                if( customSettings !== undefined && customSettings !== null && customSettings !== '')
                {
                    if( customSettings.data !== undefined && customSettings.data !== null && customSettings.data !== '')
                    {
                        if( customSettings.data.allowUserCancelFolio !== undefined && customSettings.data.allowUserCancelFolio !== null && customSettings.data.allowUserCancelFolio !== '')
                        {
                            if( customSettings.data.allowUserCancelFolio === true)
                            {

                            }
                            else
                            {
                                errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                                return;
                            }
                        }
                        else
                        {
                            errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                            return;
                        }
                    }
                    else
                    {
                        errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                        return;
                    }

                }
                else
                {
                    errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                    return;
                }
            }

            for(let item of dataRoom)
            {
                if (item.status == "Occupied")
                {
                    errorN(`Invalid: Please check out the unit(s) before CANCEL this folio.`);
                    return;
                }
            }


            let paid = 0;
            let refund = 0;

            for(let i of dataPayment)
            {
                if(i.type === 'receipt')
                {
                    paid = paid + i.amount;
                }
                else
                {
                    refund = refund + i.amount;
                }
            }


            if ((paid - refund) > 0) {
                errorN(`Invalid: You need Refund the payment(s) before cancel the folio.`);
                return;
            }




        }
        catch (err)
        {
            errorN(`Error Cancel Folio: ${err}. Please contact the administrator.`);
        }
    }*/

    async function noshow(){
        try {
            const values = form.getFieldsValue(true);
            for(let item of dataRoom)
            {
                if (item.status == "Occupied")
                {
                    errorN(`Invalid: Please check out the unit(s) before CANCEL this folio.`);
                    return;
                }
            }

            let id = localStorage.getItem('id');
            let name = localStorage.getItem('name');

            let bk = {
                id: parseInt(id),
                name: name,
                reason: '',
                date: moment(new Date())
            };

            let a = await post(`/api/folios/${values.folioid}/noshow`,bk)
            if (response.ok)
            {
                successN('Marked as No Show Successfully');
                loadData();
                loadAllocation();
            }
            else
            {
                errorN(`No Show - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error No Show: ${err}. Please contact the administrator.`);
        }
    }

    async function closefolio(){
        try {
            const values = form.getFieldsValue(true);
            let flg = false;
            let cnt = 0;

            for(let i of dataRoom)
            {
                if(i.status !== 'Closed')
                {
                    cnt = cnt + 1;
                }
            }

            if(cnt !== 0)
            {
                flg = true;
            }

            if(flg === true)
            {
                errorN('Allocation active. Close folio NOT allowed.');
                return;
            }

            let obj ={
                status: "Closed"
            }

            let a = await put(`/api/folios/${values.folioid}/status`,obj)
            if (response.ok)
            {
                successN('Close Folio Successful');
                loadData();
            }
            else
            {
                errorN(`Close Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Close Folio: ${err}. Please contact the administrator.`);
        }
    }

    async function reopenfolio(){
        try {
            const values = form.getFieldsValue(true);
            let obj ={
                status: "Reopened"
            }

            let a = await put(`/api/folios/${values.folioid}/status`,obj)
            if (response.ok)
            {
                successN('Re-Open Folio Successful');
                loadData();
            }
            else
            {
                errorN(`Re-Open Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Re-Open Folio: ${err}. Please contact the administrator.`);
        }
    }

    async function createinvoice(){
        try {
            const values = form.getFieldsValue(true);

            let a = await post(`/api/folios/${values.folioid}/invoice`)
            if (response.ok)
            {
                successN('Create Invoice Successful');
                loadData();
            }
            else
            {
                errorN(`RCreate Invoice - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Create Invoice: ${err}. Please contact the administrator.`);
        }
    }

    async function endfolio(){
        try {
            const values = form.getFieldsValue(true);

            let a = await post(`/api/folios/${values.folioid}/end`)
            if (response.ok)
            {
                successN('End Folio Successful');
                loadData();
                loadAllocation();
            }
            else
            {
                if(response.status === 504)
                {
                    errorN(`End Folio - This folio is not valid for End Folio. Please make sure all the allocations are past check out due date and checked out if checked in.`);
                }
                else
                {
                    errorN(`End Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error End Folio: ${err}. Please contact the administrator.`);
        }
    }

    function senddata(e){
        const values = form.getFieldsValue(true);

        let obj = {};
        if(e === 'showinvoice')
        {
            obj = {
                invoiceid: values.invoiceid,
                folioid: values.folioid,
                foliono: values.no,

            }
        }
        else if(e === 'showbooking')
        {
            obj = {
                bookingid: values.bookingid

            }
        }
        else if(e === 'showslip')
        {
            obj = {
                folioid: values.folioid,
                foliono: values.no
            }
        }
        else
        {
            obj = {
                folioid: values.folioid,
                foliono: values.no
            }
        }

        return obj;
    }

    async function getIC(){
        try {
            let a = await getLocal(`/read_ic`)
            if (responseLocal.status === 200)
            {
                if(a.status === 'The smart card has been removed, so further communication is not possible.')
                {
                    errorN(a.status);
                }
                else if(a.status === 'Sequence contains no elements')
                {
                    errorN(a.status);
                }
                else
                {
                    /*let re = /_\\n/gi;
                    let b = JSON.stringify(a);
                    console.log(b)
                    b = b.replace(re,', ');

                    a = JSON.parse(b);*/

                    form.setFieldsValue({name : a.name,
                        identificationtype : 'Identification Card',
                        identificationno : a.ic,
                        line : a.address.replace(/(\r\n|\n|\r)/gm, ", "),
                        city : a.city,
                        postcode : a.postcode,
                        country : 'Malaysia',
                        state : a.state,
                        gender: a.gender,
                        nationality: 'Malaysia'
                    });

                    checkCustomer(a.name,'name');
                }
            }
            else
            {
                errorN(`Read IC is not working properly. Please contact the administrator.`);
            }
        }
        catch (e) {
            errorN(e.message);
        }

    }

    async function writecard(allocationid, roomid, sdate, edate) {
        try
        {
            let roomList = JSON.parse(localStorage.getItem('roomlist'));
            let gvTimeSetting = JSON.parse(localStorage.getItem('timesetting'));
            let gvCustomSetting = JSON.parse(localStorage.getItem('customsetting'));
            let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));
            let userid = localStorage.getItem('id');
            let username = localStorage.getItem('name');

            let roomID = roomid;

            let room = 0;
            let floor = 0;
            let building = 0;
            let roomNo = '';
            let doorCode = '';

            for(let x of roomList)
            {
                if (x.id === roomID)
                {
                    roomNo = x.data.no;
                    room = x.data.room;
                    floor = x.data.floor;
                    building = x.data.building;
                    doorCode = x.data.doorCode;
                }
            }

            let tmBuffer = gvTimeSetting.data.buffer !== null && gvTimeSetting.data.buffer !== undefined ? gvTimeSetting.data.buffer : '00:00';

            let splitme = tmBuffer.split(':');

            let H = parseInt(splitme[0]);
            let M = parseInt(splitme[1]);

            let dStart = moment(sdate).format('YYYY-MM-DDTHH:mm:SS');
            let dEnd = moment(edate).add(H, 'hours').add(M, 'minutes').format('YYYY-MM-DDTHH:mm:SS')

            //console.log('start',dStart)
            //console.log('end',dEnd)

            let proceed = true;
            let useGlobal = false;

            /*if (gvCustomSetting !== null && gvCustomSetting !== undefined)
            {
                if (gvCustomSetting.data !== null && gvCustomSetting.data !== undefined)
                {
                    if (gvCustomSetting.data.allowEditAccessCardDateTime === true)
                    {
                        let frm = {
                            dStart: dStart,
                            dEnd: dEnd,
                            roomNo: roomNo
                        }

                        proceed = MyGlobal.gvOKIssueCard;
                        useGlobal = true;
                    }
                }
            }*/

            if (proceed === true)
            {

                let dtStart = useGlobal === true ? '' : dStart;
                let dtEnd = useGlobal === true ? '' : dEnd;

                //let tmStart = useGlobal === true ? MyGlobal.gvTStart.ToString("hh:mm:ss tt") : dStart.ToString("hh:mm:ss tt");
                //let tmEnd = useGlobal === true ? MyGlobal.gvTEnd.ToString("hh:mm:ss tt") : dEnd.ToString("hh:mm:ss tt");

                let card = {
                    checkin: dtStart,
                    checkout: dtEnd,
                    room: {
                        no: roomNo,
                        room: parseInt(room),
                        floor: parseInt(floor),
                        building: parseInt(building),
                        doorId: doorCode
                    },
                    count: 0,
                    serial: 0
                };

                //console.log('card', JSON.stringify(card))

                if(devicesetting !== null)
                {
                    if(devicesetting.data !== undefined)
                    {
                        if(devicesetting.data.doorLock !== undefined)
                        {
                            let a = await postLocal(`/card_issue?code=${devicesetting.data.doorLock.code}&type=${devicesetting.data.doorLock.type}&allocation=${allocationid}`, card)
                            if (responseLocal.status === 200)
                            {
                                successN("Write Card Successful");
                            }
                            else
                            {
                                errorN(`Write Card - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }
                        }
                    }
                }

            }


        }
        catch (err)
        {
            errorN(`Error Write Card: ${err}. Please contact the administrator.`);
        }
    }

    function getDefaultDeposit(e){

        let val = '0.00';
        if(e === 'Deposit')
        {
            let a = JSON.parse(localStorage.getItem('systemparametersetting'));

            if(a !== undefined && a !== null && a !== '' && a !== 'null')
            {
                if(a.data !== undefined && a.data !== null && a.data !== '' && a.data !== 'null')
                {
                    if(a.data.defaultDeposit !== undefined && a.data.defaultDeposit !== null && a.data.defaultDeposit !== '' && a.data.defaultDeposit !== 'null')
                    {
                        val = a.data.defaultDeposit;
                    }
                }
            }
        }

        form.setFieldsValue({
            toPay: val
        });


    }

    const getClient = (e) => {
        //console.log('getClient',e)
        form.setFieldsValue({
            clientID: e
        });
    }

    const getSupplier = (e) => {
        //console.log('getSupplier',e)
        form.setFieldsValue({
            supplierID: e
        });
    }

    return (
        <>
            {inhouse === false ?
                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                    &nbsp;&nbsp;<EditOutlined />
                    &nbsp;&nbsp;Update Folio
                </div>
            :
                null
            }

        <Layout>
            <Spin spinning={loading}>
            <Content style={{backgroundColor: 'white'}}>
                <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                    <Menu theme="default" defaultSelectedKeys={['mail']} mode="horizontal">
                        {inhouse === true ?
                            <Menu.Item key="back" >
                                <Button type="danger" loading={loading} onClick={() => {props.loadBack();}}>BACK</Button>
                            </Menu.Item>
                        :
                            null
                        }

                        <SubMenu key="sub1" icon={<FileOutlined/>} title="Action">
                            <Menu.Item disabled={!cancelFolio} key="cancelfolio" icon={<CloseCircleOutlined  />}>
                                <FModal formname={'cancelfolio'} obj={senddata('cancelfolio')} dataRoom={dataRoom} dataPayment={dataPayment} loadData={loadData} />
                            </Menu.Item>


                            <Menu.Item disabled={!noShow} key="noshow" icon={<UsergroupDeleteOutlined  />}>
                                <Popconfirm disabled={!noShow}
                                    title="Are you sure want to No Show this folio?"
                                    placement="right"
                                    onConfirm={noshow}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    Mark No Show
                                </Popconfirm>
                            </Menu.Item>



                            <Menu.Item disabled={!closeFolio} key="closefolio" icon={<FileDoneOutlined   />}>
                                <Popconfirm disabled={!closeFolio}
                                    title="Are you sure want to Close this folio?"
                                    placement="right"
                                    onConfirm={closefolio}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    Close Folio
                                </Popconfirm>
                            </Menu.Item>

                            <Menu.Item disabled={!reopenFolio} key="reopenfolio" icon={<FileSyncOutlined  />}>
                                <Popconfirm disabled={!reopenFolio}
                                    title="Are you sure want to Re-Open this folio?"
                                    placement="right"
                                    onConfirm={reopenfolio}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    Re-Open Folio
                                </Popconfirm>
                            </Menu.Item>

                            <Menu.Item disabled={!createInvoice} key="createinvoice" icon={<FileProtectOutlined   />}>
                                <Popconfirm disabled={!createInvoice}
                                    title="Are you sure want to INVOICE this folio? Warning: This action is irreversible."
                                    placement="right"
                                    onConfirm={createinvoice}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                   Create Invoice
                                </Popconfirm>
                            </Menu.Item>

                            <Menu.Item disabled={!endFolio} key="endfolio" icon={<FileProtectOutlined   />}>
                                <Popconfirm disabled={!endFolio}
                                    title="Are you sure want to END this folio? Warning: This action is irreversible."
                                    placement="right"
                                    onConfirm={endfolio}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    End Folio
                                </Popconfirm>
                            </Menu.Item>

                        </SubMenu>
                        <SubMenu key="sub2" icon={<EyeOutlined />} title="View">
                            <Menu.Item key="booking" icon={<FileOutlined  />}>
                                <ShowBooking obj={senddata}  />
                            </Menu.Item>
                            <Menu.Item key="folio" icon={<FileOutlined  />}>
                                <ShowFolio obj={senddata}  />
                            </Menu.Item>

                            <Menu.Item disabled={!viewInvoice} key="invoice" icon={<FileOutlined  />}>
                                <ShowInvoice obj={senddata}  />
                            </Menu.Item>

                            <Menu.Item key="payslip" icon={<FileOutlined  />}>
                                <ShowPayment obj={senddata}  />
                            </Menu.Item>
                        </SubMenu>

                        {status !== 'Closed' ?
                            null
                            :
                            <span style={{ fontWeight: 'bold', color: 'red', fontSize: '16px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<WarningTwoTone twoToneColor="orange" />&nbsp;&nbsp;{toinvoice.toUpperCase()}</span>

                        }

                    </Menu>

                    <div style={{backgroundColor: 'white', padding: '20px', margin: '0px 0',height:'73vh'}}>

                    <InfiniteScroll
                        pageStart={0}
                        hasMore={true || false}
                        useWindow={false}
                    >
                        <>
                            <div style={{backgroundColor: 'white', padding: '20px', marginRight: '5px', height: '69vh'}}>


                                <Form.Item
                                    style={{ display: 'none' }}
                                    label="id"
                                    name="id"
                                    labelCol={{ span: labelCol }}
                                    wrapperCol={{ span: wrapperCol }}
                                    rules={[]}
                                >
                                    <InputNumber />
                                </Form.Item>

                                <Form.Item
                                    style={{ display: 'none' }}
                                    label="Booking ID."
                                    name="bookingid"
                                    labelCol={{ span: labelCol }}
                                    wrapperCol={{ span: wrapperCol }}
                                    rules={[]}
                                >
                                    <Input disabled placeholder="Booking No." />
                                </Form.Item>

                                <Form.Item
                                    style={{ display: 'none' }}
                                    label="Folio ID."
                                    name="folioid"
                                    labelCol={{ span: labelCol }}
                                    wrapperCol={{ span: wrapperCol }}
                                    rules={[]}
                                >
                                    <Input disabled placeholder="Folio No." />
                                </Form.Item>

                                <Form.Item
                                    style={{ display: 'none' }}
                                    label="Invoice ID."
                                    name="invoiceid"
                                    labelCol={{ span: labelCol }}
                                    wrapperCol={{ span: wrapperCol }}
                                    rules={[]}
                                >
                                    <Input disabled placeholder="Invoice No." />
                                </Form.Item>

                                <Steps current={current} onChange={onChangeStep}>
                                    {steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                                <div style={{padding: '20px'}}>
                                    {(() => {
                                        switch (current) {
                                            case 0:
                                                return (
                                                    <>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                                                                <Space>
                                                                    <Form.Item
                                                                        label="Folio No."
                                                                        name="no"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        rules={[]}
                                                                    >
                                                                        <Input disabled placeholder="Folio No." />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Status"
                                                                        name="status"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        rules={[]}
                                                                    >
                                                                        <Input disabled placeholder="Status" />
                                                                    </Form.Item>
                                                                </Space>

                                                                <Form.Item
                                                                    name="type"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please choose a Booking Type',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Radio.Group onChange={onChange} disabled={setLocked}>
                                                                        <Radio value='walkin'>Walk-In</Radio>
                                                                        <Radio value='client'>Client</Radio>
                                                                        <Radio value='agency'>Agency</Radio>

                                                                    </Radio.Group>
                                                                </Form.Item>

                                                                {mykadAvailable === true ?
                                                                    <>
                                                                        <Button type="danger" onClick={() => getIC()}  loading={loadingLocal}>
                                                                            <IdcardOutlined  /> Read IC
                                                                        </Button>
                                                                        <br /><br />
                                                                    </>
                                                                    :
                                                                    null
                                                                }

                                                                <Form.Item
                                                                    onBlur={(e) => checkCustomer(e,'tel')}
                                                                    label="Contact No."
                                                                    name="tel"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Contact No.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Contact Number" />
                                                                </Form.Item>
                                                                {customerExistTel === true ?
                                                                    <>
                                                                        <ModalCustomerExist setCustomer={() => setCustomer('tel')} loadme={loadCustData} />
                                                                        <br /><br />
                                                                    </>
                                                                    : null
                                                                }
                                                                <Form.Item
                                                                    onBlur={(e) => checkCustomer(e,'name')}
                                                                    label="Name"
                                                                    name="name"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Name',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Name" />
                                                                </Form.Item>
                                                                {customerExistName === true ?
                                                                    <>
                                                                        <ModalCustomerExist setCustomer={() => setCustomer('name')} loadme={loadCustData} />
                                                                        <br /><br />
                                                                    </>
                                                                    : null
                                                                }

                                                                {bookingType === 'walkin' ?
                                                                    <>
                                                                        <Form.Item
                                                                            label="Company Name"
                                                                            name="guestCompany"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Company Name" />
                                                                        </Form.Item>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {bookingType === 'client' ?
                                                                            <>
                                                                                <Form.Item
                                                                                    label='Client Name'
                                                                                    name="client"
                                                                                    labelCol={{ span: labelCol }}
                                                                                    wrapperCol={{ span: wrapperCol }}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: `Please choose Client Name`,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        onChange={getClient}
                                                                                        placeholder="Client"
                                                                                        /*style={{width: '300px'}}*/
                                                                                        filterOption={(input, option) =>
                                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                        showSearch
                                                                                        optionFilterProp="children">
                                                                                        {clientList.map(value => <Option key={value.id} value={value.id}>{value.data.name}</Option>)}
                                                                                    </Select>
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    style={{ display: 'none' }}
                                                                                    label="clientID"
                                                                                    name="clientID"
                                                                                    labelCol={{ span: labelCol }}
                                                                                    wrapperCol={{ span: wrapperCol }}
                                                                                    rules={[]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </>

                                                                            :
                                                                            <>
                                                                                <Form.Item
                                                                                    label='Travel Agency Name'
                                                                                    name="supplier"
                                                                                    labelCol={{ span: labelCol }}
                                                                                    wrapperCol={{ span: wrapperCol }}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: `Please choose Travel Agency Name`,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        disabled={setLocked}
                                                                                        onChange={getSupplier}
                                                                                        placeholder="Travel Agency"
                                                                                        /*style={{width: '300px'}}*/
                                                                                        filterOption={(input, option) =>
                                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                        showSearch
                                                                                        optionFilterProp="children">
                                                                                        {supplierList.map(value => <Option key={value.id} value={value.id}>{value.data.name}</Option>)}
                                                                                    </Select>
                                                                                </Form.Item>

                                                                                <Form.Item
                                                                                    style={{ display: 'none' }}
                                                                                    label="supplierID"
                                                                                    name="supplierID"
                                                                                    labelCol={{ span: labelCol }}
                                                                                    wrapperCol={{ span: wrapperCol }}
                                                                                    rules={[]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </>

                                                                        }


                                                                    </>
                                                                }

                                                                {bookingType !== 'walkin' ?
                                                                    <>
                                                                        <Form.Item
                                                                            label="Booking / Ref. No."
                                                                            name="refno"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter Ref. No.',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Ref. No." />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                                }

                                                            </Col>
                                                            <Col xs={12} sm={12} md={24} lg={6} xl={6}>
                                                                <Form.Item
                                                                    onBlur={(e) => checkCustomer(e,'email')}
                                                                    label="Email"
                                                                    name="email"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Email',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="Email" />
                                                                </Form.Item>
                                                                {customerExistEmail === true ?
                                                                    <>
                                                                        <ModalCustomerExist setCustomer={() => setCustomer('email')} loadme={loadCustData} />
                                                                        <br /><br />
                                                                    </>
                                                                    : null
                                                                }
                                                                <Form.Item
                                                                    label="Nationality"
                                                                    name="nationality"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please choose Nationality',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        onChange={calculate}
                                                                        placeholder="Nationality"
                                                                        /*style={{width: '300px'}}*/
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children">
                                                                        {nationalityList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Category"
                                                                    name="category"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please choose Category',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        placeholder="Category"
                                                                        /*style={{width: '300px'}}*/
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children">
                                                                        <Option value='individual'>individual</Option>
                                                                        <Option value='organisation'>organisation</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Guest Type"
                                                                    name="guesttype"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please choose Guest Type',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        onChange={calculate}
                                                                        placeholder="Guest Type"
                                                                        /*style={{width: '300px'}}*/
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children">
                                                                        <Option value='Tourist'>Tourist</Option>
                                                                        <Option value='Tourist-OTA'>Tourist-OTA</Option>
                                                                        <Option value='Business'>Business</Option>
                                                                        <Option value='Government'>Government</Option>
                                                                        <Option value='Private Sector'>Private Sector</Option>
                                                                        <Option value='Contractor'>Contractor</Option>
                                                                        <Option value='Salesman'>Salesman</Option>
                                                                        <Option value='Driver'>Driver</Option>
                                                                        <Option value='Others'>Others</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                                                <Form.Item
                                                                    label="Designation"
                                                                    name="designation"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: 'Please choose Identification Type',
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Select
                                                                        placeholder="Designation"
                                                                        /*style={{width: '300px'}}*/
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children">
                                                                        <Option value='Mr.'>Mr.</Option>
                                                                        <Option value='Ms.'>Ms.</Option>
                                                                        <Option value='Mrs.'>Mrs.</Option>
                                                                        <Option value='En.'>En.</Option>
                                                                        <Option value='Cik.'>Cik.</Option>
                                                                        <Option value='Puan'>Puan</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Gender"
                                                                    name="gender"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: 'Please choose Identification Type',
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Select
                                                                        placeholder="Gender"
                                                                        /*style={{width: '300px'}}*/
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children">
                                                                        <Option value='Male'>Male</Option>
                                                                        <Option value='Female'>Female</Option>
                                                                        <Option value='Other'>Other</Option>
                                                                    </Select>
                                                                </Form.Item>

                                                                <Form.Item
                                                                    label="Identification Type"
                                                                    name="identificationtype"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: 'Please choose Identification Type',
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Select
                                                                        placeholder="Identification Type"
                                                                        /*style={{width: '300px'}}*/
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children">
                                                                        <Option value='Identification Card'>Identification Card</Option>
                                                                        <Option value='Passport'>Passport</Option>
                                                                        <Option value='Permanent Resident'>Permanent Resident</Option>
                                                                        <Option value='Working Permit'>Working Permit</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Identification No."
                                                                    name="identificationno"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: 'Please enter Address',
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Input placeholder="Identification No." />
                                                                </Form.Item>

                                                                {bookingType === 'client' ?
                                                                    <>
                                                                        <Form.Item
                                                                            label={`Contact Person`}
                                                                            name="contactPerson"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            // rules={[
                                                                            //     {
                                                                            //         required: true,
                                                                            //         message: 'Please enter Contact Person Name',
                                                                            //     },
                                                                            // ]}
                                                                        >
                                                                            <Input placeholder="Contact Person" />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                                }

                                                                <Form.Item
                                                                    name="requests"
                                                                    label="Special Request"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // hasFeedback
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: 'Please enter Customer Background',
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <TextArea placeholder="Request (if any)" rows={4}/>
                                                                </Form.Item>

                                                            </Col>
                                                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                                                                <Form.Item
                                                                    label="Address"
                                                                    name="line"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: 'Please enter Address',
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Input placeholder="Address" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="City"
                                                                    name="city"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: 'Please enter City',
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Input placeholder="City" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Postcode"
                                                                    name="postcode"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: 'Please enterPostcode',
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Input placeholder="Postcode" />
                                                                </Form.Item>

                                                                <Form.Item

                                                                    label='Country'
                                                                    name="country"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: `Please choose Country`,
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Select
                                                                        onChange={getState}
                                                                        placeholder="Country"
                                                                        /*style={{width: '300px'}}*/
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children">
                                                                        {countryList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label='State'
                                                                    name="state"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    // rules={[
                                                                    //     {
                                                                    //         required: true,
                                                                    //         message: `Please choose State`,
                                                                    //     },
                                                                    // ]}
                                                                >
                                                                    <Select
                                                                        placeholder="State"
                                                                        /*style={{width: '300px'}}*/
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        showSearch
                                                                        optionFilterProp="children">
                                                                        {stateList.map(value => <Option key={value} value={value}>{value}</Option>)}
                                                                    </Select>
                                                                </Form.Item>



                                                            </Col>
                                                            {/*<Col xs={12} sm={12} md={24} lg={12} xl={12}>*/}

                                                                {/*<Row>*/}

                                                                {/*</Row>*/}
                                                           {/* </Col>*/}
                                                            {/*<Col xs={12} sm={12} md={24} lg={12} xl={12}>*/}
                                                                {/*<Row>*/}


                                                                {/*</Row>*/}
                                                           {/* </Col>*/}
                                                        </Row>

                                                    </>
                                                )
                                            case 1:
                                                return (
                                                    <>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={24} lg={24} xl={24}>
                                                                <div style={{backgroundColor: 'white', paddingLeft: '1px', paddingRight: '1px'}}>
                                                                    <Table columns={columnsRoom} pagination={false} dataSource={dataRoom} scroll={{ y: 150 }} size ={'small'} loading={loading} style={{ height: 158 }} bordered/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                                                <div style={{backgroundColor: 'white', padding: '1px', marginRight: '20px'}}>
                                                                    <legend>Add Unit(s)</legend>
                                                                    <Row>
                                                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                                            <Form.Item
                                                                                label="Arrival - Departure"
                                                                                name="dt"
                                                                                //rules={[{ required: true, message: 'Dates missing!' }]}
                                                                            >
                                                                                <RangePicker onChange={loadAvailability} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{paddingTop: '35px'}}>
                                                                            {allowBackDate === true ?
                                                                                <Checkbox onChange={onAllowBackDate}>Create Back Dated Booking</Checkbox>
                                                                                : null
                                                                            }

                                                                        </Col>
                                                                    </Row>

                                                                    <Table
                                                                        defaultExpandAllRows={true}
                                                                        columns={columns}
                                                                        dataSource={roomtypeList}
                                                                        expandable={{expandedRowRender}}
                                                                        bordered
                                                                        size="small"
                                                                        //expandRowByClick={false}
                                                                        scroll={{ y: 150 }}
                                                                        pagination={false}
                                                                        style={{ height: 168 }}
                                                                    />
                                                                    <br />
                                                                    <Popconfirm disabled={disableUpdate}
                                                                        placement="topRight"
                                                                        title="Are you sure to add unit to this folio?"
                                                                        onConfirm={disableUpdate === false ? booknow : null}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button disabled={disableUpdate}  type="danger" loading={loading}>
                                                                            <CloudUploadOutlined  /> Add Unit(s)
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                                                <div style={{backgroundColor: 'white', padding: '1px', marginRight: '0px'}}>
                                                                    <legend>Add Additional Item(s)</legend>

                                                                    <Table
                                                                        defaultExpandAllRows={true}
                                                                        /*columns={columns}
                                                                        dataSource={roomtypeList}
                                                                        expandable={{expandedRowRender}}*/
                                                                        columns={columns}
                                                                        dataSource={additionalItemList}
                                                                        expandable={{expandedRowRender}}
                                                                        bordered
                                                                        size="small"
                                                                        //expandRowByClick={false}
                                                                        scroll={{ y: 220 }}
                                                                        pagination={false}
                                                                        style={{ height: 235 }}
                                                                    />
                                                                    <br />
                                                                    <Popconfirm disabled={disableUpdate}
                                                                        placement="topRight"
                                                                        title="Are you sure to add unit to this folio?"
                                                                        onConfirm={disableUpdate === false ? addnow : null}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button disabled={disableUpdate}  type="danger" loading={loading}>
                                                                            <CloudUploadOutlined  /> Add Item(s)
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </div>

                                                            </Col>
                                                        </Row>

                                                    </>
                                                )
                                            case 2:
                                                return (
                                                    <>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={24} lg={24} xl={24}>
                                                                <div style={{backgroundColor: 'white', paddingLeft: '20px', paddingRight: '20px'}}>
                                                                    <Table columns={columnsLines} pagination={false} dataSource={dataLines} scroll={{ y: 150 }} size ={'small'} loading={loading} style={{ height: 177 }} bordered/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )
                                            case 3:
                                                return (
                                                    <>
                                                        <Row>
                                                            <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{paddingTop: '0px', paddingRight: '20px'}}>
                                                                {/* <Divider plain>Amount</Divider> */}
                                                                <Descriptions column={1} layout={'horizontal'} bordered size='small'>
                                                                    <Descriptions.Item label="Subtotal"><b>{formatter.format(calculated.price)}</b></Descriptions.Item>
                                                                    <Descriptions.Item label="Tax"><b>{formatter.format(calculated.tax)}</b></Descriptions.Item>
                                                                    <Descriptions.Item label="Fee"><b>{formatter.format(calculated.fee)}</b></Descriptions.Item>
                                                                    <Descriptions.Item label="Discount"><b>{formatter.format(calculated.discount)}</b></Descriptions.Item>
                                                                    <Descriptions.Item label="Rounding"><b>{formatter.format(calculated.rounding)}</b></Descriptions.Item>
                                                                    <Descriptions.Item label="TOTAL"><b>{formatter.format(calculated.amount)}</b></Descriptions.Item>
                                                                </Descriptions>
                                                                <br />
                                                                <Descriptions column={1} layout={'horizontal'} bordered size='small'>
                                                                    <Descriptions.Item label="Paid"><b>{formatter.format(calculated.paid)}</b></Descriptions.Item>
                                                                    <Descriptions.Item label="Balance"><b>{formatter.format(calculated.balance)}</b></Descriptions.Item>
                                                                </Descriptions>

                                                            </Col>
                                                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                                {/* <Divider plain>Pay</Divider> */}

                                                                <Form.Item
                                                                    name="paymenttype"
                                                                    labelCol={{ span: labelCol }}
                                                                    wrapperCol={{ span: wrapperCol }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please choose a Type',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value='Payment'>Payment</Radio>
                                                                        <Radio value='Refund'>Refund</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>

                                                                <Row>
                                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                                        <Form.Item
                                                                            name="method"
                                                                            label="Payment Method"
                                                                            //labelCol={{ span: labelCol }}
                                                                            //wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: isPaying,
                                                                                    message: 'Please enter Method',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select style={{width: '90%'}} allowClear placeholder="Payment Method" onChange={onPayment}>
                                                                                <Option value="Cash">Cash</Option>
                                                                                <Option value="Agoda">Agoda</Option>
                                                                                <Option value="Alipay">Alipay</Option>
                                                                                <Option value="Amex">Amex</Option>
                                                                                <Option value="Bank Transfer">Bank Transfer</Option>
                                                                                <Option value="Booking.com">Booking.com</Option>
                                                                                <Option value="Cheque">Cheque</Option>
                                                                                <Option value="Credit Card">Credit Card</Option>
                                                                                <Option value="Debit Card">Debit Card</Option>
                                                                                <Option value="EPass">EPass</Option>
                                                                                <Option value="Expedia">Expedia</Option>
                                                                                <Option value="Master Card">Master Card</Option>
                                                                                <Option value="Online Banking">Online Banking</Option>
                                                                                <Option value="Paypal">Paypal</Option>
                                                                                <Option value="Ticket.com">Ticket.com</Option>
                                                                                <Option value="TouchnGo eWallet">TouchnGo eWallet</Option>
                                                                                <Option value="Traveloka">Traveloka</Option>
                                                                                <Option value="Visa">Visa</Option>
                                                                                <Option value="WeChat Pay">WeChat Pay</Option>
                                                                                <Option value="Maybank QRPay">Maybank QRPay</Option>
                                                                            </Select>
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            label="Amount"
                                                                            name="toPay"
                                                                            rules={[
                                                                                {
                                                                                    required: isPaying,
                                                                                    message: 'Please enter Amount',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumber disabled={!isPaying} style={{width: '90%'}}  min={0.05} max={10000000000}
                                                                                         formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                         parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            label="Expiry (mm/yy)"
                                                                            name="expiry"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: isCard,
                                                                                    message: 'Please enter Expiry Date',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input  disabled={!isCard} placeholder="Expiry Date" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                                        <Form.Item
                                                                            name="paymentFor"
                                                                            label="Payment For"
                                                                            //labelCol={{ span: labelCol }}
                                                                            //wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: isPaying,
                                                                                    message: 'Please enter For',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select style={{width: '90%'}} allowClear placeholder="Payment For" onChange={getDefaultDeposit}>
                                                                                <Option value="Deposit">Deposit</Option>
                                                                                <Option value="Payment">Payment</Option>
                                                                            </Select>
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            label="Card / Doc No."
                                                                            name="cardNo"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: isCard,
                                                                                    message: 'Please enter Card / Doc No.',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input disabled={!isCard} placeholder="Card / Doc No." />
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            label="Slip No."
                                                                            name="slipNo"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: (isCash === false && isPaying === true),
                                                                                    message: 'Please enter Slip No.',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input disabled={!(isCash === false && isPaying === true)} placeholder="Slip No." />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>


                                                        <Table columns={columnsPayment} pagination={false} dataSource={dataPayment} scroll={{ y: 150 }} size ={'small'} loading={loading} style={{ height: 177 }} bordered/>

                                                    </>
                                                )
                                            default:
                                                return (
                                                    <div></div>
                                                )
                                        }
                                    })()}
                                </div>

                                <div className="form-footer" style={{paddingRight: '40px', textAlign: 'right'}}>

                                    {(current === 0) && (
                                        <Popconfirm disabled={disableUpdate}
                                            placement="topRight"
                                            title="Are you sure to update this folio?"
                                            onConfirm={disableUpdate === false ? updatefolio : null}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button disabled={disableUpdate} type="danger" loading={loading}>
                                                <CloudUploadOutlined  /> Update
                                            </Button>
                                        </Popconfirm>
                                    )}
                                    {(current === 3) && (
                                        <Popconfirm disabled={disableUpdate && isCancelled}
                                            placement="topRight"
                                            title="Are you sure to make this payment?"
                                            onConfirm={createpayment}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button  disabled={disableUpdate && isCancelled} type="danger" loading={loading}>
                                                <DollarCircleOutlined  /> Make Payment
                                            </Button>
                                        </Popconfirm>
                                    )}

                                </div>
                            </div>
                        </>

                    </InfiniteScroll>

                    </div>
                </Form>
            </Content>
            </Spin>
        </Layout>


        </>
    );
}

export default EditBooking
