import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input,  Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    UserOutlined,
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined, FileExcelOutlined, SearchOutlined, ProfileOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import { Table } from "ant-table-extensions";
import Highlighter from "react-highlight-words";

const { Option } = Select;
const {Sider, Content,Footer } = Layout;

function SystemUser(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [data, setData] = useState([]);
    const [propertyList, setPropertyList] = useState([]);

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');


    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput=node;
                    }}
                    placeholder={`Search ${caption}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
        /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };

    useEffect(() => {

        loadUser();

        return () => {

        }
    }, [])

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {
            const values = form.getFieldsValue(true);

            if(values.id === undefined || values.id === null || values.id === '')
            {
                let user ={};

                user.usr = values.usr;
                user.pwd = values.pwd;
                user.auth = values.auth;
                user.active = values.active === 'true';

                user.data = {
                    name: values.name,
                    tel: values.tel,
                    email: values.email,
                    identificationNo: values.identificationNo
                };

                let ls = [];
                let list = JSON.parse(localStorage.getItem('allproperties'));

                if(values.property !== undefined)
                {
                    for(let i of values.property)
                    {
                        for(let j of list)
                        {
                            if(i === j.id.toString())
                            {
                                ls.push({id: j.id.toString(), name: j.data.name});
                            }
                        }
                    }
                }
                user.data.propertyAccess = ls;

                let a = await post(`/api/users`,user)
                if (response.ok)
                {
                    successN('User Created successfully');
                    loadUser();
                    form.resetFields();
                }
                else
                {
                    errorN(`Create User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                let userObj = await get(`/api/users/${values.id}`)
                if (response.ok)
                {
                    userObj.data.name = values.name;
                    userObj.data.tel = values.tel;
                    userObj.data.email = values.email;
                    userObj.data.identificationNo = values.identificationNo;

                    userObj.active = values.active === 'true';

                    let l = [];
                    let list = JSON.parse(localStorage.getItem('allproperties'));
                    if(values.property !== undefined)
                    {
                        for(let i of values.property)
                        {
                            for(let j of list)
                            {
                                if(i === j.id.toString())
                                {
                                    l.push({id: j.id.toString(), name: j.data.name});
                                }
                            }
                        }
                    }

                    userObj.data.propertyAccess = l;

                    userObj.usr = values.usr;
                    userObj.pwd = values.pwd;
                    userObj.auth = values.auth;

                    let a = await put(`/api/users/${values.id}`,userObj)
                    if (response.ok)
                    {
                        successN('User Update successful');
                        loadUser();
                    }
                    else
                    {
                        errorN(`Update User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Update User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }


        }
        catch (err)
        {
            errorN(`Error Get User: ${err}. Please contact the administrator.`);
        }
    }

    const handleChange = () => {

        // if(props.isBackDate !== true)
        // {
           /* const values = form.getFieldsValue(true);
            console.log('why me', values.roomNox)
            let x = '';

            for(let i of values.roomNox)
            {
                console.log('why me x', i)
                if(x === '')
                {
                    x = i;
                }
                else
                {
                    x = x + ',' + i;
                }
            }

            values.roomNo = x;

            props.reloadMe(values);

            let obj = props.obj;
            setAvailCount(props.getAllo(obj.itemID));*/
        // }

    }

    async function loadUser() {
        try
        {
           let userList = await get(`/api/users`)
            if (response.ok)
            {
                for(let i of userList)
                {
                    i.name = i.data.name;
                }

                setData(userList);

                let list = JSON.parse(localStorage.getItem('allproperties'));

                let ls = [];
                for(let i of list)
                {
                    ls.push({id: i.id.toString(), name: i.data.name})
                }

                setPropertyList(ls);

                //form.setFieldsValue(userObj);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get User: ${err}. Please contact the administrator.`);
        }
    }

    async function getUser(id) {
        try
        {
            let userObj = await get(`/api/users/${id}`)
            if (response.ok)
            {
                userObj.name = userObj.data.name;
                userObj.tel = userObj.data.tel;
                userObj.email = userObj.data.email;
                userObj.identificationNo = userObj.data.identificationNo;

                userObj.active = userObj.active.toString();

                let l = [];
                for(let i of userObj.data.propertyAccess)
                {
                    l.push(i.id.toString());
                }

                userObj.property = l;


                form.setFieldsValue(userObj);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get User: ${err}. Please contact the administrator.`);
        }
    }

    const columns = [

        {
            width: '20%',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <a  onClick={() => getUser(record.id)}>
                        <EditOutlined twoToneColor="blue"/>
                        &nbsp;
                        Edit
                    </a>
                </>
            )
        },
        {
            width: '40%',
            title:"Name",
            dataIndex:"name",
            key:"name",
            ...getColumnSearchProps('name','Name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            width: '40%',
            title:"Role",
            dataIndex:"auth",
            key:"auth",
            ...getColumnSearchProps('auth','Role'),
            sorter: (a, b) => a.auth.localeCompare(b.auth),
        },
    ]


    return (
        <>
            <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                &nbsp;&nbsp;<ProfileOutlined />
                &nbsp;&nbsp;System User
            </div>
            <Layout>

                <Spin spinning={loading}>
                    <Content style={{backgroundColor: 'white'}}>
                        <Form
                            layout={formLayout}
                            form={form}
                            // style={{ width: '100%'}}
                            onFinish={handleFinish}
                        >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7} style={{padding: '20px'}}>
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        label="id"
                                        name="id"
                                        rules={[]}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input name!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Contact No."
                                        name="tel"
                                        rules={[
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Tel" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                        ]}
                                    >
                                        <Input placeholder="Email" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Identification No."
                                        name="identificationNo"
                                        rules={[
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="ID No." />
                                    </Form.Item>

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7} style={{padding: '20px'}}>

                                    <Form.Item
                                        label="Property"
                                        name="property"
                                        rules={[
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            placeholder="Property"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {propertyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Username"
                                        name="usr"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input username!',
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="User Name" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="pwd"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            style={{ width: '50%' }}
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            placeholder="Password"
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Role"
                                        name="auth"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Role',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            placeholder="Role"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='administrator'>Administrator</Option>
                                            <Option value='manager'>Manager</Option>
                                            <Option value='supervisor'>Supervisor</Option>
                                            <Option value='cashier'>Cashier</Option>
                                            <Option value='housekeeper'>Housekeeper</Option>
                                            <Option value='accountant'>Accountant</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Status"
                                        name="active"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Status',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            placeholder="Status"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='true'>Active</Option>
                                            <Option value='false'>Inactive</Option>
                                        </Select>
                                    </Form.Item>

                                    <br />
                                    <Space>
                                        <Button type="primary" onClick={() => form.resetFields()}  loading={loading} >
                                            <StepForwardOutlined /> Clear
                                        </Button>
                                        <Button type="danger" onClick={() => form.submit()}  loading={loading} >
                                            <StepForwardOutlined /> Confirm
                                        </Button>
                                    </Space>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10} xl={10} style={{padding: '20px'}}>
                                    <Table
                                        defaultExpandAllRows={true}
                                        columns={columns}
                                        dataSource={data}
                                        /* exportable
                                         exportableProps={{ showColumnPicker: true, fileName: "In-House Guest-List",btnProps: {
                                                 type: "primary",
                                                 icon: <FileExcelOutlined />,
                                                 /!*children: <span>Export</span>,*!/
                                             } }}*/
                                        searchable
                                        searchableProps={{
                                            // dataSource,
                                            // setDataSource: setSearchDataSource,
                                            inputProps: {
                                                placeholder: "Search this table...",
                                                prefix: <SearchOutlined />,
                                            },
                                        }}
                                        bordered
                                        size="small"
                                        scroll={{ x: 'max-content',y: 290 }}
                                        //scroll={{ y: 370 }}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>

                        </Form>
                    </Content>
                </Spin>
            </Layout>


        </>
    );
}

export default SystemUser
