import React, {useState,useEffect} from 'react';
import {Modal} from "antd";

import {A} from "hookrouter";
import BookNow from "../Bookings/booknow"
/*import Bookings from "../Bookings/bookings"
import Folios from "../Bookings/folios"*/
import EditBooking from "../Bookings/edit_booking"
import AssignRoom from "../Bookings/assign_room"
import CheckInOut from "../Bookings/checkinout_room"
import ExtendStay from "../Bookings/extend_stay";
import ChangeRoom from "../Bookings/change_room";
import EditLines from "../Bookings/edit_lines";
import UpdateAllocation from "../Bookings/update_allocation";
import CancelFolio from "../Bookings/cancel_folio";
import BlockRoom from "../Bookings/block_room";
import SetHousekeeper from "../Bookings/set_housekeeper"
import RewriteCard from "../Bookings/rewrite_card";
import {errorN} from "../../general";
import Calendar from "../Bookings/scheduler";
import WhatsToday from "../Bookings/whatstoday"



function FModal(props) {

    const [visible, setVisible] = useState(false);


    useEffect(() => {

        //localStorage.setItem('openmodal', 'true');

        //console.log('prps', props)
        return () => {

        }
    }, [])

    const handleOk = e => {
                setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);

            switch (props.formname) {
                case 'booknow':
                    localStorage.setItem('openmodal', 'false');
                    //props.loadData();
                    break;
                case 'bookings':
                    localStorage.setItem('openmodal', 'false');
                    return null
                case 'folios':
                    localStorage.setItem('openmodal', 'false');
                    return null
                case 'update':
                    localStorage.setItem('openmodal', 'false');
                    props.loadData();

                    if(props.loadAllocation !== undefined)
                        props.loadAllocation();

                    break;
                case 'setroom':
                    props.loadData();
                    break;
                case 'checkinout':
                    localStorage.setItem('openmodal', 'false');
                    //props.loadData();
                    break;
                case 'extendstay':
                    props.loadData();
                    break;
                case 'changeroom':
                    props.loadData();
                    break;
                case 'editlines':
                    props.loadData();
                    break;
                case 'updateallocation':
                    return null
                case 'cancelfolio':
                    return null
                case 'blockroom':
                    localStorage.setItem('openmodal', 'false');
                    //props.loadData();
                    break;
                case 'sethousekeeper':
                    localStorage.setItem('openmodal', 'false');
                    break;
                default:
                    localStorage.setItem('openmodal', 'false');
                    return (
                        <div/>
                    )
            }
    };

    const processMe = e => {

        if(e === 'cancelfolio')
        {
            let dataRoom = props.dataRoom;
            let dataPayment = props.dataPayment;

            let customSettings = JSON.parse(localStorage.getItem('customsetting'));
            let role = localStorage.getItem('role');

            if(role === 'cashier')
            {
                if( customSettings !== undefined && customSettings !== null && customSettings !== '')
                {
                    if( customSettings.data !== undefined && customSettings.data !== null && customSettings.data !== '')
                    {
                        if( customSettings.data.allowUserCancelFolio !== undefined && customSettings.data.allowUserCancelFolio !== null && customSettings.data.allowUserCancelFolio !== '')
                        {
                            if( customSettings.data.allowUserCancelFolio === true)
                            {

                            }
                            else
                            {
                                errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                                return;
                            }
                        }
                        else
                        {
                            errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                            return;
                        }
                    }
                    else
                    {
                        errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                        return;
                    }

                }
                else
                {
                    errorN(`Invalid: Cashier is not authorized to cancel folio. Please contact the supervisor or administrator.`);
                    return;
                }
            }

            for(let item of dataRoom)
            {
                if (item.status == "Occupied")
                {
                    errorN(`Invalid: Please check out the unit(s) before CANCEL this folio.`);
                    return;
                }
            }


            let paid = 0;
            let refund = 0;

            for(let i of dataPayment)
            {
                if(i.type === 'receipt')
                {
                    paid = paid + parseFloat(i.amount);
                }
                else
                {
                    refund = refund + parseFloat(i.amount);
                }
            }

                //console.log("paid",parseFloat(paid), parseFloat(refund), dataPayment)

            if ((paid - refund) > 0) {
                errorN(`Invalid: You need Refund the payment(s) before cancel the folio.`);
                return;
            }

            setVisible(true);
        }

    }


    return (
        <>

            {(() => {

            switch (props.formname) {
                case 'booknow':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Book Now!</A>
                    )
                case 'update':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>{props.obj !== undefined ? (props.obj.status !== 'Cancelled' ? `${props.label !== undefined ? props.label : 'Update' }` : 'View') : `${props.label !== undefined ? props.label : 'Update' }`}</A>
                    )
                case 'setroom':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Assign Unit</A>
                    )
                case 'checkinout':
                    return (
                        /*<A href={''} onClick={() => {setVisible(true);}}>{props.obj.status === 'Blocked' ? 'Check In' : 'Check Out'}</A>*/
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Manage</A>
                    )
                case 'extendstay':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Extend Stay</A>
                    )
                case 'changeroom':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Change Unit</A>
                    )
                case 'editlines':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Update</A>
                    )
                case 'updateallocation':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Update Guest</A>
                    )
                case 'cancelfolio':
                    return (
                        <A href={''} onClick={() => {processMe('cancelfolio');}}>Cancel Folio</A>
                    )
                case 'blockroom':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Block Unit</A>
                    )
                case 'sethousekeeper':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Start Cleaning</A>
                    )
                case 'rewritecard':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Rewrite Access Card</A>
                    )
                case 'scheduler':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>Calendar</A>
                    )
                case 'whatstoday':
                    return (
                        <A href={''} onClick={() => {setVisible(true);localStorage.setItem('openmodal', 'true');}}>What's Today!</A>
                    )
                default:
                    return (
                        <div></div>
                    )

            }

            })()}

            <Modal
                centered ={true}
                width={props.formname === 'setroom' || props.formname === 'extendstay' || props.formname === 'cancelfolio' || props.formname === 'blockroom' ? "30%"
                    : props.formname === 'update' ? "75%"
                        : props.formname === 'changeroom' || props.formname === 'editlines' || props.formname === 'sethousekeeper' || props.formname === 'rewritecard' ? "20%"
                            :  props.formname === 'updateallocation' ? "50%"
                                : "100%"}
                destroyOnClose={true}
                visible={visible}
                //title={props.formname}
                onOk={handleOk}
                closable = {true}
                onCancel={handleCancel}
                maskClosable={false}
                footer =""
                bodyStyle={{overflowX: 'scroll'}}
            >
                {(() => {

                switch (props.formname) {
                    case 'booknow':
                        return (
                            <BookNow loadData={props.loadData} />
                        )
                    case 'update':
                        return (
                            <EditBooking obj={props.obj} loadData={props.loadData}  />
                        )
                    case 'setroom':
                        return (
                            <AssignRoom obj={props.obj} loadData={props.loadData} />
                        )
                    case 'checkinout':
                        return (
                            <CheckInOut obj={props.obj} loadData={props.loadData} />
                        )
                    case 'extendstay':
                        return (
                            <ExtendStay obj={props.obj} loadData={props.loadData} />
                        )
                    case 'changeroom':
                        return (
                            <ChangeRoom obj={props.obj} loadData={props.loadData} />
                        )
                    case 'editlines':
                        return (
                            <EditLines obj={props.obj} loadData={props.loadData} />
                        )
                    case 'updateallocation':
                        return (
                            <UpdateAllocation obj={props.obj} />
                        )
                    case 'cancelfolio':
                        return (
                            <CancelFolio setVisible={setVisible} obj={props.obj} dataRoom={props.dataRoom} dataPayment={props.dataPayment} loadData={props.loadData}/>
                        )
                    case 'blockroom':
                        return (
                            <BlockRoom loadData={props.loadData} setVisible={setVisible}/>
                        )
                    case 'sethousekeeper':
                        return (
                            <SetHousekeeper loadData={props.loadData}  setVisible={setVisible}/>
                        )
                    case 'rewritecard':
                        return (
                            <RewriteCard loadData={props.loadData}  setVisible={setVisible}/>
                        )
                    case 'scheduler':
                        return (
                            <Calendar  setVisible={setVisible}/>
                        )
                    case 'whatstoday':
                        return (
                            <WhatsToday loadData={props.loadData} />
                        )
                    default:
                        return (
                            <div></div>
                        )
                }

                })()}

            </Modal>
        </>
    );
}

export default FModal
