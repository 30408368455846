import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,    
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function CancelFolio(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [disabled, setDisabled] = useState(false);
    

    useEffect(() => {

        loadRoomNo();

        return () => {

        }
    }, [])

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {

            const values = form.getFieldsValue(true);
            let propsObj = props.obj;

            let id = localStorage.getItem('id');
            let name = localStorage.getItem('name');

            let bk = {
                id: parseInt(id),
                name: name,
                reason: values.reason,
                date: moment(new Date())
            };


            let a = await post(`/api/folios/${propsObj.folioid}/cancel`,bk)
            if (response.ok)
            {
                successN('Folio Cancelled successfully');
                props.setVisible(false);
                props.loadData();
            }
            else
            {
                errorN(`Folio Cancel - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Folio Cancel: ${err}. Please contact the administrator.`);
        }

    }

    async function loadRoomNo() {
        try
        {
            let obj = props.obj;
            form.setFieldsValue(obj);
        }
        catch (err)
        {
            errorN(`Error LA: ${err}. Please contact the administrator.`);
        }
    }

    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                        <Form.Item
                            label="Folio No."
                            name="foliono"
                            rules={[]}
                        >
                            <Input disabled placeholder="No." />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>

                        <Form.Item
                            label="Reason to Cancel Folio"
                            name="reason"
                            // hasFeedback
                             rules={[
                                 {
                                     required: true,
                                     message: 'Please enter Reason for Cancel',
                                 },
                             ]}
                        >
                            <TextArea placeholder="Reason for Cancel" rows={5}/>
                        </Form.Item>

                        <br />
                        <Button type="primary" onClick={() => form.submit()}  loading={loading} disabled={disabled}>
                            <StepForwardOutlined /> Confirm
                        </Button>
                    </Col>
                </Row>

            </Form>

        </>
    );
}

export default CancelFolio
