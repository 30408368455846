import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Image,Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input,  Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    HomeOutlined,
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined, FileExcelOutlined, SearchOutlined, ProfileOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import { Table } from "ant-table-extensions";

const { TextArea } = Input;
const { Option } = Select;
const {Sider, Content,Footer } = Layout;

function Property(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [data, setData] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [image, setImage] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);


    useEffect(() => {

        loadProperty();
        loadCompany();
        loadCbo();

        return () => {

        }
    }, [])

    async function loadCbo() {
        let obj = JSON.parse(localStorage.getItem('countrylist'));

        setCountryList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    }

    async function getState (e){

        try
        {
            setStateList([]);
            form.setFieldsValue({
                state: undefined
            });

            let obj = JSON.parse(localStorage.getItem('countrylist'));

            let ls = obj.data.filter(x => x.name === e);

            if(ls.length > 0)
            {
                setStateList(ls[0].states.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)));
            }

        }
        catch(err)
        {
            errorN(`Error Get States: ${err}. Please contact the administrator.`);
        }

    }

    async function loadProperty() {
        try
        {
            let list = JSON.parse(localStorage.getItem('allproperties'));

            let ls = [];
            for(let i of list)
            {
                ls.push({id: i.id.toString(), name: i.data.name})
            }

            setPropertyList(ls);
        }
        catch (err)
        {
            errorN(`Error Load Property: ${err}. Please contact the administrator.`);
        }
    }

    async function loadCompany() {
        try
        {
            let alist = await get(`/api/parties?type=company`)
            if (response.ok)
            {
                for(let i of alist)
                {
                    i.id = i.id.toString();
                    i.name = i.data.name;
                }
                setCompanyList(alist);
            }
            else
            {
                errorN(`Company List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Company List: ${err}. Please contact the administrator.`);
        }
    }

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {
            const values = form.getFieldsValue(true);

            if(values.property === undefined || values.property === null || values.property === '')
            {
                errorN(`Choose a property to proceed.`);
                return;
            }

            let list = JSON.parse(localStorage.getItem('allproperties'));

            for(let i of list)
            {
                if(i.data.name.toLowerCase() === values.name.toLowerCase() && i.id.toString() !== values.property.toString())
                {
                    errorN(`Not Valid: The property name is Exist for another property.`);
                    return;
                }

            }

            let propertyObj = await get(`/api/hotels/${values.id}`)
            if (response.ok)
            {
                let alist = await get(`/api/parties?type=company`)
                if (response.ok)
                {
                    let compname = '';
                    for(let i of alist)
                    {
                        if(i.id.toString() === values.company)
                        {
                            compname = i.data.name;
                        }
                    }

                    propertyObj.data.name = values.name.trim();
                    propertyObj.data.company = { name: compname, id: values.company};

                    propertyObj.data.address = { line : values.line, city: values.city, postcode: values.postcode, state: values.state, country: values.country };

                    propertyObj.data.rating = values.rating;
                    propertyObj.data.propertyType = values.propertyType;

                    propertyObj.data.tel = values.tel;
                    propertyObj.data.fax = values.fax;
                    propertyObj.data.website = values.website;
                    propertyObj.data.email = values.email;

                    let a = await put(`/api/hotels/${values.id}`,propertyObj)
                    if (response.ok)
                    {
                        successN('Property Updated successfully');
                    }
                    else
                    {
                        errorN(`Update Property - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
                else
                {
                    errorN(`Company List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Update Property - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }


        }
        catch (err)
        {
            errorN(`Error Update Property: ${err}. Please contact the administrator.`);
        }
    }

    async function getPropertyInfo(e){
        let id = e;

        try
        {
            let propertyObj = await get(`/api/hotels/${id}`)
            if (response.ok)
            {
                await getState(propertyObj.data.address !== undefined && propertyObj.data.address !== null ? propertyObj.data.address.country : '');

                propertyObj.fax = propertyObj.data.fax;
                propertyObj.tel = propertyObj.data.tel;
                propertyObj.name = propertyObj.data.name;
                propertyObj.email = propertyObj.data.email;
                propertyObj.rating = propertyObj.data.rating;

                propertyObj.city =  propertyObj.data.address !== undefined && propertyObj.data.address !== null ? propertyObj.data.address.city : '';
                propertyObj.line =  propertyObj.data.address !== undefined && propertyObj.data.address !== null ? propertyObj.data.address.line : '';
                propertyObj.state =  propertyObj.data.address !== undefined && propertyObj.data.address !== null ? propertyObj.data.address.state : '';
                propertyObj.country =  propertyObj.data.address !== undefined && propertyObj.data.address !== null ? propertyObj.data.address.country : '';
                propertyObj.postcode =  propertyObj.data.address !== undefined && propertyObj.data.address !== null ? propertyObj.data.address.postcode : '';

                propertyObj.company = propertyObj.data.company !== undefined && propertyObj.data.company !== null ? propertyObj.data.company.id : '';

                propertyObj.website = propertyObj.data.website;
                propertyObj.propertyType = propertyObj.data.propertyType;

                form.setFieldsValue(propertyObj);



            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Property List - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }


            let picList = await get(`/api/files/?hotel=${id}`)
            if (response.ok) {
                let picid = 0;

                for (let i of picList) {
                    if (i.data !== undefined) {
                        let n = i.data.name;
                        let s = n.split(".");

                        if (s[0] === 'logo') {
                            picid = i.id;
                        }
                    }
                }

                if (picid !== 0) {
                    let a = window.location.origin;
                    setImage(a + `/api/files/${picid}?action=download`);
                } else {
                    setImage('');
                }
            }
        }
        catch (err)
        {
            errorN(`Error Get Property Info: ${err}. Please contact the administrator.`);
        }


    }

    return (
        <>
            <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                &nbsp;&nbsp;<HomeOutlined />
                &nbsp;&nbsp;Property
            </div>
            <Layout>
                <Spin spinning={loading}>
                    <Content style={{backgroundColor: 'white'}}>
                        <Form
                            layout={formLayout}
                            form={form}
                            // style={{ width: '100%'}}
                            onFinish={handleFinish}
                        >
                            <Form.Item
                                style={{ display: 'none' }}
                                label="id"
                                name="id"
                                rules={[]}
                            >
                                <InputNumber />
                            </Form.Item>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding: '20px'}}>
                                    <Form.Item
                                        label="Property"
                                        name="property"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Property',
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange ={getPropertyInfo}
                                            placeholder="Property"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {propertyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input name',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Company"
                                        name="company"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Company',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            placeholder="Company"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {companyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Property Type"
                                        name="propertyType"
                                        rules={[

                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            placeholder="Type"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='Hotel'>Hotel</Option>
                                            <Option value='Homestay'>Homestay</Option>
                                            <Option value='Healthcare'>Healthcare</Option>
                                            <Option value='Hostel'>Hostel</Option>
                                            <Option value='Room'>Room</Option>
                                            <Option value='Dorm'>Dorm</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Tel. No."
                                        name="tel"
                                        rules={[
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Tel. No." />
                                    </Form.Item>

                                    <Form.Item
                                        label="Fax No."
                                        name="fax"
                                        rules={[
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Fax No." />
                                    </Form.Item>

                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Email" />
                                    </Form.Item>

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding: '20px'}}>

                                    <Form.Item
                                        label="Website"
                                        name="website"
                                        rules={[
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Website" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Address"
                                        name="line"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please enter Address',
                                        //     },
                                        // ]}
                                    >
                                        <TextArea placeholder="Address" rows={4}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="City"
                                        name="city"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please enter City',
                                        //     },
                                        // ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="City" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Postcode"
                                        name="postcode"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please enterPostcode',
                                        //     },
                                        // ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Postcode" />
                                    </Form.Item>

                                    <Form.Item

                                        label='Country'
                                        name="country"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: `Please choose Country`,
                                        //     },
                                        // ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            onChange={getState}
                                            placeholder="Country"
                                            /*style={{width: '300px'}}*/
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {countryList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ width: '50%' }}
                                        label='State'
                                        name="state"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: `Please choose State`,
                                        //     },
                                        // ]}
                                    >
                                        <Select
                                            placeholder="State"
                                            /*style={{width: '300px'}}*/
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {stateList.map(value => <Option key={value} value={value}>{value}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding: '20px'}}>

                                    <Form.Item
                                        label="Rating"
                                        name="rating"
                                        rules={[

                                        ]}
                                    >
                                        <Select
                                            style={{ width: '25%' }}
                                            placeholder="Rating"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='1'>1</Option>
                                            <Option value='2'>2</Option>
                                            <Option value='3'>3</Option>
                                            <Option value='4'>4</Option>
                                            <Option value='5'>5</Option>
                                            <Option value='6'>6</Option>
                                        </Select>
                                    </Form.Item>
                                    <br />
                                    <Image
                                        width={200}
                                        src={image}
                                    />

                                    <br /><br />

                                    <Button type="danger" onClick={() => form.submit()}  loading={loading} >
                                        <StepForwardOutlined /> Confirm
                                    </Button>

                                </Col>

                            </Row>

                        </Form>
                    </Content>
                </Spin>
            </Layout>


        </>
    );
}

export default Property
