import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Image,Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input,  Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    ShoppingCartOutlined,
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined, FileExcelOutlined, SearchOutlined, ProfileOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import { Table } from "ant-table-extensions";
import Highlighter from "react-highlight-words";

const { TextArea } = Input;
const { Option } = Select;
const {Sider, Content,Footer } = Layout;

function Item(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [data, setData] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [rateList, setRateList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [feeList, setFeeList] = useState([]);
    const [image, setImage] = useState('');

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');


    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput=node;
                    }}
                    placeholder={`Search ${caption}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
        /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };


    useEffect(() => {

        loadProperty();
        loadTax();
        loadFee();

        return () => {

        }
    }, [])

    async function loadProperty() {
        try
        {
            let list = JSON.parse(localStorage.getItem('allproperties'));

            let ls = [];
            for(let i of list)
            {
                ls.push({id: i.id.toString(), name: i.data.name})
            }

            setPropertyList(ls);
        }
        catch (err)
        {
            errorN(`Error Load Property: ${err}. Please contact the administrator.`);
        }
    }

    async function loadTax(){
        try
        {
            let list = JSON.parse(localStorage.getItem('taxlist'));
//console.log('list',list)
            let ls = [];
            for(let i of list.data)
            {
                ls.push({id: i, name: i})
            }

            setTaxList(ls);
        }
        catch (err)
        {
            errorN(`Error Load Tax: ${err}. Please contact the administrator.`);
        }
    }

    async function loadFee(){
        try
        {
            let list = JSON.parse(localStorage.getItem('feesetting'));

            let ls = [];
            if(list.data !== undefined)
            {
                if(list.data.fees !== undefined)
                {
                    for(let i of list.data.fees)
                    {
                        ls.push({id: i.name, name: i.name})
                    }
                }
            }

            setFeeList(ls);
        }
        catch (err)
        {
            errorN(`Error Load Fee: ${err}. Please contact the administrator.`);
        }
    }

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {
            const values = form.getFieldsValue(true);

            if(values.property === undefined || values.property === null || values.property === '')
            {
                errorN(`Choose a property to proceed.`);
                return;
            }

            if(values.id === undefined || values.id === null || values.id === '')
            {
                let item = {
                    hotel: values.property,
                    rate: null,
                    parent: null,
                    package: false,
                    data: {
                        type: values.type,
                        name: values.name,
                        desc: values.desc,
                        accCode: values.accCode,
                        taxes: values.taxes,
                        fees: values.fees,
                        amenities: values.amenities,
                        wubook:''
                    },
                    category: values.category,
                    resource: 0,
                    uom: null,
                    unit: 0.0
                };



                let a = await post(`/api/items`,item)
                if (response.ok)
                {
                    let propertyid = values.property;
                    successN('Item Created successfully');
                    getItemList(propertyid);
                    form.resetFields();
                    setImage('');

                    form.setFieldsValue({property: propertyid});
                }
                else
                {
                    errorN(`Create Item - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                let itemObj = await get(`/api/items/${values.id}`)
                if (response.ok)
                {
                    itemObj.rate = values.rate;

                    itemObj.data.type = values.type;
                    itemObj.data.name = values.name;
                    itemObj.data.desc = values.desc;
                    itemObj.data.accCode = values.accCode;
                    itemObj.data.taxes = values.taxes;
                    itemObj.data.fees = values.fees;
                    itemObj.data.amenities = values.amenities;


                    itemObj.category = values.category;


                    let a = await put(`/api/items/${values.id}`,itemObj)
                    if (response.ok)
                    {
                        let propertyid = values.property;
                        successN('Item Updated successfully');
                        getItemList(propertyid);
                        form.resetFields();
                        setImage('');

                        form.setFieldsValue({property: propertyid});
                    }
                    else
                    {
                        errorN(`Update User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Update User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }


        }
        catch (err)
        {
            errorN(`Error Get User: ${err}. Please contact the administrator.`);
        }
    }

    const handleChange = () => {

        // if(props.isBackDate !== true)
        // {
           /* const values = form.getFieldsValue(true);
            console.log('why me', values.roomNox)
            let x = '';

            for(let i of values.roomNox)
            {
                console.log('why me x', i)
                if(x === '')
                {
                    x = i;
                }
                else
                {
                    x = x + ',' + i;
                }
            }

            values.roomNo = x;

            props.reloadMe(values);

            let obj = props.obj;
            setAvailCount(props.getAllo(obj.itemID));*/
        // }

    }

    async function getItem(id) {
        try
        {
            let ratePlanList = await get(`/api/items/${id}/rates`)
            if (response.ok)
            {
                let ls = [];
                for(let i of ratePlanList)
                {
                    ls.push({id: i.id , name: i.data.plan + ': ' + i.data.name});
                }

                setRateList(ls);

                let itemObj = await get(`/api/items/${id}`)
                if (response.ok)
                {
                    itemObj.type = itemObj.data.type;
                    itemObj.name = itemObj.data.name;
                    itemObj.desc = itemObj.data.desc;
                    itemObj.fees = itemObj.data.fees;
                    itemObj.taxes = itemObj.data.taxes;
                    itemObj.accCode = itemObj.data.accCode;
                    itemObj.amenities = itemObj.data.amenities;


                    form.setFieldsValue(itemObj);
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Get User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }

                const values = form.getFieldsValue(true);
                let picList = await get(`/api/files/?hotel=${values.property}`)
                if (response.ok)
                {
                    let picid = 0;

                    for(let i of picList)
                    {
                        if(i.data !== undefined)
                        {
                            let n = i.data.name;
                            let s = n.split(".");

                            if(s[0] === 'pic_' + id)
                            {
                                picid  = i.id;
                            }
                        }
                    }

                    if(picid !== 0)
                    {
                        let a = window.location.origin;
                        setImage(a + `/api/files/${picid}?action=download`);
                    }
                    else {
                        setImage('');
                    }

                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Get Image List - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }

            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get User - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get User: ${err}. Please contact the administrator.`);
        }
    }

    async function removeItem(id) {
        try
        {
            const values = form.getFieldsValue(true);
            let obj = JSON.parse(localStorage.getItem('channelsetting'));

            if(obj !== undefined && obj !== null)
            {
                if(obj.data.channelName !== '')
                {
                    errorN(`NOT ALLOWED: This property has been synced to a Channel Manager`);
                    return;
                }
            }

            if (id === undefined || id === null || id === '')
            {
                errorN("ID not found. Please contact the administrator.");
                return;
            }


            let a = await del(`/api/items/${id}`)
            if (response.ok)
            {

                let propertyid = values.property;
                successN('Item Removed successfully');
                await getItemList(propertyid);
                form.resetFields();

                form.setFieldsValue({property: propertyid});
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Item Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Remove Item: ${err}. Please contact the administrator.`);
        }
    }

    const columns = [

        {
            width: '20%',
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <a  onClick={() => getItem(record.id)}>
                        <EditOutlined twoToneColor="blue"/>
                        &nbsp;
                        Edit
                    </a>
                </>
            )
        },
        {
            width: '70%',
            title:"Name",
            dataIndex:"name",
            key:"name",
            ...getColumnSearchProps('name','Name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            width: '10%',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Do you want to remove this?`}
                        onConfirm={() => removeItem(record.id)}
                        okText="Yes"
                        cancelText="No">
                        <a>
                            <CloseCircleOutlined style={{color: 'red'}}/>
                        </a>
                    </Popconfirm>

                </>
            )
        },
    ]

    async function getItemList(e){
        let id = e;

        try
        {
            let itemList = await get(`/api/hotels/${id}/items`)
            if (response.ok)
            {
                for (let i of itemList)
                {
                    i.name = i.data.name
                }

                setData(itemList);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get User List - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
        }


    }

    async function loadType(id) {

        let ls = [];
        if(id === 'service')
        {
            ls.push({id: 'reservation', name: 'reservation'});
            ls.push({id: 'meal', name:'meal'});

            ls.push({id: 'roomservice', name:'roomservice'});
            ls.push({id: 'addon', name: 'addon'});
        }
        else if (id === 'product')
        {
            ls.push({id: 'fnb', name:'fnb'});
            ls.push({id: 'roomservice', name:'roomservice'});
            ls.push({id: 'addon', name:'addon'});
        }

        setTypeList(ls);
    }

    return (
        <>
            <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                &nbsp;&nbsp;<ShoppingCartOutlined />
                &nbsp;&nbsp;Item
            </div>
            <Layout>

                <Spin spinning={loading}>
                    <Content style={{backgroundColor: 'white'}}>
                        <Form
                            layout={formLayout}
                            form={form}
                            // style={{ width: '100%'}}
                            onFinish={handleFinish}
                        >
                            <Form.Item
                                style={{ display: 'none' }}
                                label="id"
                                name="id"
                                rules={[]}
                            >
                                <InputNumber />
                            </Form.Item>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7} style={{padding: '20px'}}>
                                    <Form.Item
                                        label="Property"
                                        name="property"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Property',
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange ={getItemList}
                                            placeholder="Property"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {propertyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Category"
                                        name="category"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Category',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            onChange = {loadType}
                                            placeholder="Category"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='service'>Service</Option>
                                            <Option value='product'>Product</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Type"
                                        name="type"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Type',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            mode="multiple"
                                            allowClear
                                            placeholder="Type"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {typeList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input name',
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Name" />
                                    </Form.Item>

                                    <Form.Item
                                        name="desc"
                                        label="Description"
                                        // hasFeedback
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please enter Customer Background',
                                        //     },
                                        // ]}
                                    >
                                        <TextArea placeholder="Description" rows={5}/>
                                    </Form.Item>




                                </Col>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7} style={{padding: '20px'}}>

                                    <Form.Item
                                        name="amenities"
                                        label="Amenities"
                                        // hasFeedback
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please enter Customer Background',
                                        //     },
                                        // ]}
                                    >
                                        <TextArea placeholder="Amenities" rows={5}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Account Code"
                                        name="accCode"
                                        rules={[
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Code" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Default Rate Plan"
                                        name="rate"
                                        rules={[
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            allowClear
                                            placeholder="Rate"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {rateList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Taxation"
                                        name="taxes"
                                        rules={[
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            mode="multiple"
                                            allowClear
                                            placeholder="Tax"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {taxList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Fee"
                                        name="fees"
                                        rules={[
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '50%' }}
                                            mode="multiple"
                                            allowClear
                                            placeholder="Fee"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {feeList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>



                                    {/*<br /><br />
                                    <Space>
                                        <Button type="primary" onClick={() => {form.resetFields(); setData([]);setImage('');}}  loading={loading} >
                                            <StepForwardOutlined /> Clear
                                        </Button>
                                        <Button type="danger" onClick={() => form.submit()}  loading={loading} >
                                            <StepForwardOutlined /> Confirm
                                        </Button>
                                    </Space>*/}
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10} xl={10} style={{padding: '20px'}}>
                                    <Table
                                        defaultExpandAllRows={true}
                                        columns={columns}
                                        dataSource={data}
                                        /* exportable
                                         exportableProps={{ showColumnPicker: true, fileName: "In-House Guest-List",btnProps: {
                                                 type: "primary",
                                                 icon: <FileExcelOutlined />,
                                                 /!*children: <span>Export</span>,*!/
                                             } }}*/
                                        searchable
                                        searchableProps={{
                                            // dataSource,
                                            // setDataSource: setSearchDataSource,
                                            inputProps: {
                                                placeholder: "Search this table...",
                                                prefix: <SearchOutlined />,
                                            },
                                        }}
                                        bordered
                                        size="small"
                                        scroll={{ x: 'max-content',y: 320 }}
                                        //scroll={{ y: 370 }}
                                        pagination={false}
                                    />
                                    <br />
                                    <Image
                                        width={200}
                                        src={image}
                                    />

                                    <br /><br />
                                    <Space>
                                        <Button type="primary" onClick={() => {form.resetFields(); setData([]);setImage('');}}  loading={loading} >
                                            <StepForwardOutlined /> Clear
                                        </Button>
                                        <Button type="danger" onClick={() => form.submit()}  loading={loading} >
                                            <StepForwardOutlined /> Confirm
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Content>
                </Spin>
            </Layout>


        </>
    );
}

export default Item
