import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {A, navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined,
    RightCircleOutlined,
    DollarCircleOutlined,
    LeftCircleOutlined,
    CreditCardOutlined,
    FileOutlined,
    UsergroupDeleteOutlined,
    FileDoneOutlined, FileSyncOutlined, FileProtectOutlined, EyeOutlined, WarningTwoTone


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import FModal from "./modal";
import {ShowBooking, ShowFolio, ShowInvoice, ShowPayment, ShowReceipt} from "./function";
const {SubMenu} = Menu;
const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function CheckInOutRoom(props) {
    const { get, post, response, loading, error, del, put } = useFetch();
    const { get:getLocal, post:postLocal, response:responseLocal, loading:loadingLocal} = useFetch(`http://localhost:8023`);

    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [calculated, setCalculated] = useState({price: 0, tax: 0, fee:0, discount:0,rounding:0,amount:0,paid:0,balance:0});
    const [isPaying, setIsPaying] = useState(false);
    const [isCash, setIsCash] = useState(false);
    const [isCard, setIsCard] = useState(false);
    const [visibleSubmit, setVisibleSubmit] = useState(false);

    const [dataNoRoom, setDataNoRoom] = useState([]);
    const [dataWithRoom, setDataWithRoom] = useState([]);

    const [inhouse, setInhouse] = useState(false);
    const [toinvoice, setToinvoice] = useState('');
    const [status, setStatus] = useState('');

    const labelCol = 22;
    const wrapperCol = 22;

    let statusx = true;
    function toggleStatus() {
        statusx = !statusx;

        if(statusx === false)
        {
            setToinvoice('');
        }
        else
        {
            setToinvoice('All the units are now closed. Please proceed to CREATE INVOICE');
        }

    }

    useEffect(() => {

        if(props.inhouse === true)
        {
            setInhouse(true);
        }


        loadFolio();
        loadAllocation();

        setInterval(toggleStatus, 500);

        return () => {

        }
    }, [])

    async function getCardType(number){

        if(number === '' || number === null || number === undefined)
        {
            return '';
        }

        try
        {
            // visa
            let re = new RegExp("^4");
            if (number.match(re) != null)
                return "Visa";

            // Mastercard
            // Updated for Mastercard 2017 BINs expansion
            if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
                return "Mastercard";

            // AMEX
            re = new RegExp("^3[47]");
            if (number.match(re) != null)
                return "AMEX";

            // Discover
            re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
            if (number.match(re) != null)
                return "Discover";

            // Diners
            re = new RegExp("^36");
            if (number.match(re) != null)
                return "Diners";

            // Diners - Carte Blanche
            re = new RegExp("^30[0-5]");
            if (number.match(re) != null)
                return "Diners - Carte Blanche";

            // JCB
            re = new RegExp("^35(2[89]|[3-8][0-9])");
            if (number.match(re) != null)
                return "JCB";

            // Visa Electron
            re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
            if (number.match(re) != null)
                return "Visa Electron";

            return ''

        }
        catch (err)
        {
            errorN(`Error Card Type: ${err}. Please contact the administrator.`);
            return '';
        }
    }

    async function onSubmit(){
        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));
            const values = form.getFieldsValue(true);

            if(values.method !== undefined)
            {
                form.validateFields().then(async values => {

                    let foliox = await get(`/api/folios/${values.folioid}`)
                    if (response.ok)
                    {
                        let paytype = '';

                        if (values.type == 'Payment')
                        {
                            paytype = 'receipt';
                        }
                        else
                        {
                            paytype = 'remittance';
                        }

                        let cardtype = await getCardType(values.cardNo !== undefined && values.cardNo !== null && values.cardNo !== '' ? values.cardNo : '');

                        let payment = {
                            hotel : obj.id,
                            amount : values.toPay,
                            type : paytype,
                            folio : values.folioid,
                            collector : localStorage.getItem('id'),
                            data : {
                                cardNo : values.cardNo !== undefined && values.cardNo !== null && values.cardNo !== '' ? values.cardNo : '',
                                expiry : values.expiry !== undefined && values.expiry !== null && values.expiry !== '' ? values.expiry : '',
                                paymentFor : values.paymentFor,
                                slipNo : values.slipNo !== undefined && values.slipNo !== null && values.slipNo !== '' ? values.slipNo : '',
                                cardType : cardtype,
                                by : {
                                    id : localStorage.getItem('id'),
                                    name : localStorage.getItem('name')
                                },
                                method : values.method !== undefined && values.method !== null && values.method !== '' ? values.method : '',
                                payor : foliox.data.payor
                            }
                        };

                        let a = await post(`/api/payments`,payment)
                        if (response.ok)
                        {
                            successN('Payment Successful');
                            loadFolio();
                        }
                        else
                        {
                            errorN(`Assign Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        }
                    }
                    else
                    {
                        if(response.status === 401)
                        {
                            localStorage.removeItem('session');
                            window.location.reload();
                        }
                        else
                        {
                            errorN(`Get Folio- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        }

                    }



                });
            }
        }
        catch (err)
        {
            errorN(`Error Make Payment: ${err}. Please contact the administrator.`);
        }


    }

    async function loadFolio() {
        try
        {
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));


            let foliox = await get(`/api/folios/${propsObj.folioid}`)
            if (response.ok)
            {
                setStatus(foliox.status);

                let a  ={};

                a.price = foliox.breakdown.price;

                let tx = 0;
                let fe = 0;
                let dc = 0;

                for(let x of foliox.breakdown.tax)
                {
                    tx = tx + x.rate;
                }
                for(let x of foliox.breakdown.fee)
                {
                    fe = fe + x.rate;
                }

                for (let x of foliox.breakdown.discount)
                {
                    dc = dc + x.rate;
                }

                a.tax = tx;
                a.fee = fe;
                a.discount = dc;

                a.rounding = foliox.rounding;
                a.amount = foliox.amount;
                a.paid = foliox.paid;
                a.balance = foliox.amount - foliox.paid;

                setCalculated(a);

                //let obj = props.obj;
                form.setFieldsValue({
                    ...propsObj,
                    ...foliox,
                    type: undefined,
                    method: undefined,
                    toPay: undefined,
                    cardNo: undefined,
                    expiry: undefined,
                    slipNo: undefined
                });
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Load Folio: ${err}. Please contact the administrator.`);
        }
    }

    async function loadAllocation() {
        try
        {
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let globalRoomList = JSON.parse(localStorage.getItem('roomlist'));

            let parameter = `?folio=${propsObj.folioid}`;

            let allocationList = await get(`/api/hotels/${globalObj.id}/allocations${parameter}`)
            if (response.ok)
            {
                //console.log('allocationList',allocationList)
                let table = [];
                let table2 = [];

                let flg = false;

                for(let i of allocationList)
                {
                    if(i.status !== 'Closed')
                    {
                        flg = true;
                    }

                    if(i.room === null)
                    {
                        table.push({
                            id: i.id,
                            status: i.status,
                            unitno: 'Unit No.',
                            unittype: i.data.item.name,
                            name: i.data.guest.name,
                            folioid: i.folio,
                            itemid: i.item,
                            start: i.start,
                            end: i.end,
                            arrival: moment(i.start).local().format('DD-MM-YYYY'),
                            departure: moment(i.end).local().format('DD-MM-YYYY'),
                            room: i.room,
                            sdate: i.sdate,
                            edate: i.edate
                        });
                    }
                    else
                    {
                        let roomNo = "";

                        for(let x of globalRoomList)
                        {
                            if(x.id == i.room)
                            {
                                roomNo = x.data.no;
                            }
                        }

                        table2.push({
                            id: i.id,
                            status: i.status,
                            unitno: roomNo,
                            unittype: i.data.item.name,
                            name: i.data.guest.name,
                            folioid: i.folio,
                            itemid: i.item,
                            start: i.start,
                            end: i.end,
                            arrival: moment(i.start).local().format('DD-MM-YYYY'),
                            departure: moment(i.end).local().format('DD-MM-YYYY'),
                            room: i.room,
                            sdate: i.sdate,
                            edate: i.edate
                        });
                    }
                }

                setDataNoRoom(table);
                setDataWithRoom(table2);

                setStatus(flg === false && allocationList.length > 0 ? 'Closed' : '');

                //props.loadData();
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Load Allocation: ${err}. Please contact the administrator.`);
        }
    }

    const onPayment = (e) => {
        try{

            if(e === null || e === undefined || e === '')
            {
                setIsPaying(false);
                setIsCash(false);
                setIsCard(false);

                /*form.setFieldsValue({
                    amount: undefined,
                    cardNo: undefined,
                    expiry: undefined,
                    slipNo: undefined

                });*/
            }
            else
            {
                setIsPaying(true);

                /*form.setFieldsValue({
                    cardNo: undefined,
                    expiry: undefined,
                    slipNo: undefined

                });*/
            }

            if(e === 'Cash')
            {
                setIsCash(true);
                setIsCard(false);
            }
            else if(e === "Credit Card" || e === "Debit Card" || e === "Master Card" || e === "Visa" || e === "Amex")
            {
                setIsCash(false);
                setIsCard(true);
            }
            else
            {
                setIsCash(false);
                setIsCard(false);
            }

        }
        catch (err)
        {
            errorN(`Error onPayment: ${err}. Please contact the administrator.`);
        }
    }

    const columnsWithRoom = [
        {
            title: 'Arrival',
            dataIndex: 'arrival',
            width: '20%',
        },
        {
            title: 'Departure',
            dataIndex: 'departure',
            width: '20%',
        },
        {
            title: 'Unit No.',
            dataIndex: 'unitno',
            width: '15%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '15%',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '15%',
            render: (text, record) => (
                <>
                    {record.status === 'Blocked' ?
                        <Popconfirm
                            title={`Are you sure want to proceed this action?`}
                            onConfirm={() => checkin(record.id, record.room)}
                            okText="Yes"
                            cancelText="No">
                            <StepForwardOutlined   twoToneColor="green"/>&nbsp;
                            <a>Check In</a>
                        </Popconfirm>
                        :
                        record.status === 'Occupied' ?
                            <Popconfirm
                                title={`Are you sure want to proceed this action?`}
                                onConfirm={() => checkout(record.id, record.room)}
                                okText="Yes"
                                cancelText="No">
                                <StepForwardOutlined   twoToneColor="green"/>&nbsp;
                                <a>Check Out</a>
                            </Popconfirm>
                            :
                            null
                    }

                </>
            )
        },
        {
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            width: '15%',
            render: (text, record) => (
                <>
                    {record.status === 'Blocked' ?
                        <>
                        </>
                        :
                        record.status === 'Occupied' ?
                            <Popconfirm
                                title={`Are you sure want to proceed this action?`}
                                onConfirm={() => writecard(record.id, record.room, record.sdate, record.edate)}
                                okText="Yes"
                                cancelText="No">
                                <CreditCardOutlined    twoToneColor="green"/>&nbsp;
                                <a>Write Card</a>
                            </Popconfirm>
                            :
                            null
                    }

                </>
            )
        },

    ];

    const columnsNoRoom = [
        {
            title: 'Arrival',
            dataIndex: 'arrival',
            width: '20%',
        },
        {
            title: 'Departure',
            dataIndex: 'departure',
            width: '20%',
        },
        {
            title: 'Unit No.',
            dataIndex: 'unitno',
            width: '15%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '15%',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '15%',
            render: (text, record) => (
                <>
                    <StepForwardOutlined   twoToneColor="green"/>&nbsp;
                    <FModal formname={'setroom'} obj={record} loadData={loadAllocation} />
                </>
            )
        },

    ];

    async function checkin(allocationid, roomid) {
        try
        {
            const values = form.getFieldsValue(true);
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let userid = localStorage.getItem('id');
            let username = localStorage.getItem('name');
            let timesettingObj = JSON.parse(localStorage.getItem('timesetting'));

            let date = new Date();
            let start = '';
            let end = '';
            let now = '';

            let h1 = timesettingObj.data.end;
            let h =12;
            if(h1 !== null && h1 !== undefined)
            {
                let i = h1.split(':');
                h = parseInt(i[0]);
            }

            start = moment(propsObj.arrival !== null && propsObj.arrival !== undefined ? propsObj.arrival : propsObj.start).local().format('YYYY-MM-DD');
            end = moment(propsObj.departure !== null && propsObj.departure !== undefined ? propsObj.departure : propsObj.end).local().add(h, 'h').format('YYYY-MM-DD HH:mm:ss');
            now = moment(date).format('YYYY-MM-DD HH:mm:ss');

            //console.log('end', end)
            //console.log('now',now)
            //console.log('propsObj', propsObj)


            if (end <= now)
            {
                errorN(`Invalid: Check-In NOT ALLOWED. This allocation is already due Check Out.`);
                return;
            }

            let room = {room: roomid};

            let a = await post(`/api/allocations/${allocationid}/checkin`,room)
            if (response.ok)
            {
                let ob = await get(`/api/allocations/${allocationid}`)
                if (response.ok)
                {
                    ob.data.checkedInBy = {id:userid , name:username };

                    let b = await put(`/api/allocations/data/${allocationid}`,ob)
                    if (response.ok)
                    {
                        successN('Check In Successful');
                        //props.loadData();
                        loadAllocation();
                    }
                    else
                    {
                        errorN(`Update Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    errorN(`Get Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                if(response.status === 503)
                {
                    errorN(`Check In - Unit not available for check-in because the unit is currently not in Available-Clean.`);
                }
                else
                {
                    errorN(`Check In - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }

        }
        catch (err)
        {
            errorN(`Error Check In: ${err}. Please contact the administrator.`);
        }
    }

    async function checkout(allocationid, roomid) {
        try
        {
            const values = form.getFieldsValue(true);
            let propsObj = props.obj !== undefined ? props.obj : JSON.parse(localStorage.getItem('temproomdetails'));
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let userid = localStorage.getItem('id');
            let username = localStorage.getItem('name');

            let room = {room: roomid};

            let a = await post(`/api/allocations/${allocationid}/checkout`,room)
            if (response.ok)
            {
                let ob = await get(`/api/allocations/${allocationid}`)
                if (response.ok)
                {
                    ob.data.checkedOutBy = {id:userid , name:username };

                    let b = await put(`/api/allocations/data/${allocationid}`,ob)
                    if (response.ok)
                    {
                        successN('Check Out Successful');
                        //props.loadData();
                        loadAllocation();
                    }
                    else
                    {
                        errorN(`Update Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    errorN(`Get Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                errorN(`Check Out - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Check In: ${err}. Please contact the administrator.`);
        }
    }

    async function writecard(allocationid, roomid, sdate, edate) {
        try
        {
            let roomList = JSON.parse(localStorage.getItem('roomlist'));
            let gvTimeSetting = JSON.parse(localStorage.getItem('timesetting'));
            let gvCustomSetting = JSON.parse(localStorage.getItem('customsetting'));
            let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));
            let userid = localStorage.getItem('id');
            let username = localStorage.getItem('name');

            let roomID = roomid;

            let room = 0;
            let floor = 0;
            let building = 0;
            let roomNo = '';
            let doorCode = '';

            for(let x of roomList)
            {
                if (x.id === roomID)
                {
                    roomNo = x.data.no;
                    room = x.data.room;
                    floor = x.data.floor;
                    building = x.data.building;
                    doorCode = x.data.doorCode;
                }
            }

            let tmBuffer = gvTimeSetting.data.buffer !== null && gvTimeSetting.data.buffer !== undefined ? gvTimeSetting.data.buffer : '00:00';

            let splitme = tmBuffer.split(':');

            let H = parseInt(splitme[0]);
            let M = parseInt(splitme[1]);

            let dStart = moment(sdate).format('YYYY-MM-DDTHH:mm:SS');
            let dEnd = moment(edate).add(H, 'hours').add(M, 'minutes').format('YYYY-MM-DDTHH:mm:SS')

            //console.log('start',dStart)
            //console.log('end',dEnd)

            let proceed = true;
            let useGlobal = false;

            /*if (gvCustomSetting !== null && gvCustomSetting !== undefined)
            {
                if (gvCustomSetting.data !== null && gvCustomSetting.data !== undefined)
                {
                    if (gvCustomSetting.data.allowEditAccessCardDateTime === true)
                    {
                        let frm = {
                            dStart: dStart,
                            dEnd: dEnd,
                            roomNo: roomNo
                        }

                        proceed = MyGlobal.gvOKIssueCard;
                        useGlobal = true;
                    }
                }
            }*/

            if (proceed === true)
            {

                let dtStart = useGlobal === true ? '' : dStart;
                let dtEnd = useGlobal === true ? '' : dEnd;

                //let tmStart = useGlobal === true ? MyGlobal.gvTStart.ToString("hh:mm:ss tt") : dStart.ToString("hh:mm:ss tt");
                //let tmEnd = useGlobal === true ? MyGlobal.gvTEnd.ToString("hh:mm:ss tt") : dEnd.ToString("hh:mm:ss tt");

                let card = {
                    checkin: dtStart,
                    checkout: dtEnd,
                    room: {
                        no: roomNo,
                        room: parseInt(room),
                        floor: parseInt(floor),
                        building: parseInt(building),
                        doorId: doorCode
                    },
                    count: 0,
                    serial: 0
                };

                //console.log('card', JSON.stringify(card))

                if(devicesetting !== null)
                {
                    if(devicesetting.data !== undefined)
                    {
                        if(devicesetting.data.doorLock !== undefined)
                        {
                            let a = await postLocal(`/card_issue?code=${devicesetting.data.doorLock.code}&type=${devicesetting.data.doorLock.type}&allocation=${allocationid}`, card)
                            if (responseLocal.status === 200)
                            {
                                successN("Write Card Successful");
                            }
                            else
                            {
                                errorN(`Write Card - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }
                        }
                    }
                }

            }


        }
        catch (err)
        {
            errorN(`Error Write Card: ${err}. Please contact the administrator.`);
        }
    }

    const openFolio = (e) => {
        //props.setVisible(false);
    }

    function senddata(e){
        const values = form.getFieldsValue(true);

        let obj = {};
        if(e === 'showinvoice')
        {
            obj = {
                invoiceid: values.invoiceid,
                folioid: values.folioid,
                foliono: values.no,

            }
        }
        else if(e === 'showslip' || e === 'showfolio')
        {
            obj = {
                folioid: values.folioid,
                foliono: values.no,

            }
        }
        else if(e === 'showbooking')
        {
            obj = {
                bookingid: values.bookingid

            }
        }
        else
        {
            obj = {
                folioid: values.folioid,
                foliono: values.no
            }
        }

        return obj;
    }

    return (
        <>
            {inhouse === false ?
                <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                    &nbsp;&nbsp;<RightCircleOutlined  />
                    &nbsp;&nbsp;Manage Booking
                </div>
                :
                null
            }

            <Layout>
                <Spin spinning={loading}>
                    <Content style={{backgroundColor: 'white'}}>
                        <Form
                            layout={formLayout}
                            form={form}
                            // style={{ width: '100%'}}
                            onFinish={handleFinish}
                        >

                            <div style={{backgroundColor: 'white', padding: '20px', margin: '0px 0',height:'73vh'}}>
                                <InfiniteScroll
                                    pageStart={0}
                                    hasMore={true || false}
                                    useWindow={false}
                                >
                                    <Row>
                                        <Col xs={12} sm={12} md={24} lg={14} xl={14}>
                                            <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px', height: '69vh'}}>
                                                {inhouse === false ? (status !== 'Closed' ? <legend>Manage Booking</legend> :  <legend>Manage Booking : <span style={{ fontWeight: 'bold', color: 'red' }}>{toinvoice.toUpperCase()}</span></legend>) : (status !== 'Closed' ?  <Button type="danger" loading={loading} onClick={() => {props.loadBack();}}>BACK</Button> : <Space><Button type="danger" loading={loading} onClick={() => {props.loadBack();}}>BACK</Button><span style={{ fontWeight: 'bold', color: 'red', fontSize: '14px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<WarningTwoTone twoToneColor="orange" />&nbsp;&nbsp;{toinvoice.toUpperCase()}</span></Space>)}
                                                <Divider style={{ paddingTop: 10 }} plain orientation="left"><RightCircleOutlined />Assign Unit</Divider>
                                                <Table columns={columnsNoRoom} pagination={false} dataSource={dataNoRoom} scroll={{ y: 150 }} size ={'small'} loading={loading} style={{ height: 177}} />
                                                <Divider style={{ paddingTop: 10 }} plain orientation="left"><LeftCircleOutlined />Check In / Check Out</Divider>
                                                <Table columns={columnsWithRoom} pagination={false} dataSource={dataWithRoom} scroll={{ y: 150 }} size ={'small'} loading={loading} style={{ height: 177 }} />

                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={24} lg={10} xl={10}>
                                            <Row>
                                                <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{paddingTop: '0px', paddingRight: '20px'}}>
                                                    {/* <Divider plain>Amount</Divider> */}
                                                    <Form.Item
                                                        style={{ display: 'none' }}
                                                        label="id"
                                                        name="folioid"
                                                        labelCol={{ span: labelCol }}
                                                        wrapperCol={{ span: wrapperCol }}
                                                        rules={[]}
                                                    >
                                                        <InputNumber />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Customer Name"
                                                        name="name"
                                                        rules={[]}
                                                    >
                                                        <Input disabled placeholder="Name" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Folio No."
                                                        name="no"
                                                        rules={[]}
                                                    >
                                                        <Input disabled placeholder="No." />
                                                    </Form.Item>
                                                    <br />
                                                    <Descriptions column={1} layout={'horizontal'} bordered size='small'>
                                                        <Descriptions.Item label="Subtotal"><b>{formatter.format(calculated.price)}</b></Descriptions.Item>
                                                        <Descriptions.Item label="Tax"><b>{formatter.format(calculated.tax)}</b></Descriptions.Item>
                                                        <Descriptions.Item label="Fee"><b>{formatter.format(calculated.fee)}</b></Descriptions.Item>
                                                        <Descriptions.Item label="Discount"><b>{formatter.format(calculated.discount)}</b></Descriptions.Item>
                                                        <Descriptions.Item label="Rounding"><b>{formatter.format(calculated.rounding)}</b></Descriptions.Item>
                                                        <Descriptions.Item label="TOTAL"><b>{formatter.format(calculated.amount)}</b></Descriptions.Item>
                                                    </Descriptions>
                                                    <br />
                                                    <Descriptions column={1} layout={'horizontal'} bordered size='small'>
                                                        <Descriptions.Item label="Paid"><b>{formatter.format(calculated.paid)}</b></Descriptions.Item>
                                                        <Descriptions.Item label="Balance"><b>{formatter.format(calculated.balance)}</b></Descriptions.Item>
                                                    </Descriptions>
                                                    <br /><br />
                                                    {/*<Button type="primary" onClick={openFolio} loading={loading}>
                                                        <FModal formname={'update'} obj={props.obj} loadData={props.loadData} loadAllocation={loadAllocation} />
                                                    </Button>*/}

                                                    <Button type="primary" loading={loading}>
                                                        {/*<ShowPayment obj={props.obj}  />*/}
                                                        <ShowPayment obj={senddata}  />
                                                    </Button>
                                                    <>&nbsp;&nbsp;</>
                                                    <Button type="danger" loading={loading}>
                                                        <FModal formname={'update'} label={'View Folio Details'} obj={senddata('showfolio')} loadData={loadFolio} />
                                                    </Button>

                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                    {/* <Divider plain>Pay</Divider> */}
                                                    <Form.Item
                                                        name="type"
                                                        labelCol={{ span: labelCol }}
                                                        wrapperCol={{ span: wrapperCol }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please choose a Type',
                                                            },
                                                        ]}
                                                    >
                                                        <Radio.Group>
                                                            <Radio value='Payment'>Payment</Radio>
                                                            <Radio value='Refund'>Refund</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="method"
                                                        label="Payment Method"
                                                        labelCol={{ span: labelCol }}
                                                        wrapperCol={{ span: wrapperCol }}
                                                        rules={[
                                                            {
                                                                required: isPaying,
                                                                message: 'Please choose Payment Method',
                                                            },
                                                        ]}
                                                    >
                                                        <Select allowClear placeholder="Payment Method" onChange={onPayment}>
                                                            <Option value="Cash">Cash</Option>
                                                            <Option value="Agoda">Agoda</Option>
                                                            <Option value="Alipay">Alipay</Option>
                                                            <Option value="Amex">Amex</Option>
                                                            <Option value="Bank Transfer">Bank Transfer</Option>
                                                            <Option value="Booking.com">Booking.com</Option>
                                                            <Option value="Cheque">Cheque</Option>
                                                            <Option value="Credit Card">Credit Card</Option>
                                                            <Option value="Debit Card">Debit Card</Option>
                                                            <Option value="EPass">EPass</Option>
                                                            <Option value="Expedia">Expedia</Option>
                                                            <Option value="Master Card">Master Card</Option>
                                                            <Option value="Online Banking">Online Banking</Option>
                                                            <Option value="Paypal">Paypal</Option>
                                                            <Option value="Ticket.com">Ticket.com</Option>
                                                            <Option value="TouchnGo eWallet">TouchnGo eWallet</Option>
                                                            <Option value="Traveloka">Traveloka</Option>
                                                            <Option value="Visa">Visa</Option>
                                                            <Option value="WeChat Pay">WeChat Pay</Option>
                                                            <Option value="Maybank QRPay">Maybank QRPay</Option>
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="paymentFor"
                                                        label="Payment For"
                                                        //labelCol={{ span: labelCol }}
                                                        //wrapperCol={{ span: wrapperCol }}
                                                        rules={[
                                                            {
                                                                required: isPaying,
                                                                message: 'Please enter For',
                                                            },
                                                        ]}
                                                    >
                                                        <Select style={{width: '90%'}} allowClear placeholder="Payment For">
                                                            <Option value="Deposit">Deposit</Option>
                                                            <Option value="Payment">Payment</Option>
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Amount"
                                                        name="toPay"
                                                        rules={[
                                                            {
                                                                required: isPaying,
                                                                message: 'Please enter Amount',
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber disabled={!isPaying} style={{width: '50%'}}  min={0.05} max={10000000000}
                                                                     formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                     parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Card / Doc No."
                                                        name="cardNo"
                                                        labelCol={{ span: labelCol }}
                                                        wrapperCol={{ span: wrapperCol }}
                                                        rules={[
                                                            {
                                                                required: isCard,
                                                                message: 'Please enter Card / Doc No.',
                                                            },
                                                        ]}
                                                    >
                                                        <Input disabled={!isCard} placeholder="Card / Doc No." />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Expiry Date (mm/yy)"
                                                        name="expiry"
                                                        labelCol={{ span: labelCol }}
                                                        wrapperCol={{ span: wrapperCol }}
                                                        rules={[
                                                            {
                                                                required: isCard,
                                                                message: 'Please enter Expiry Date',
                                                            },
                                                        ]}
                                                    >
                                                        <Input  disabled={!isCard} placeholder="Expiry Date" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Slip No."
                                                        name="slipNo"
                                                        labelCol={{ span: labelCol }}
                                                        wrapperCol={{ span: wrapperCol }}
                                                        rules={[
                                                            {
                                                                required: (isCash === false && isPaying === true),
                                                                message: 'Please enter Slip No.',
                                                            },
                                                        ]}
                                                    >
                                                        <Input disabled={!(isCash === false && isPaying === true)} placeholder="Slip No." />
                                                    </Form.Item>

                                                    <div className="form-footer" style={{paddingRight: '40px', textAlign: 'right'}}>
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="Are you sure to make this payment?"
                                                            onConfirm={onSubmit}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button type="danger" loading={loading}>
                                                                <DollarCircleOutlined  /> Make Payment
                                                            </Button>
                                                        </Popconfirm>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </InfiniteScroll>
                            </div>
                        </Form>
                    </Content>
                </Spin>
            </Layout>
        </>
    );
}

export default CheckInOutRoom
