import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

function SetHousekeeper(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])

    const [disabled, setDisabled] = useState(false);

    const [housekeeperList, setHousekeeperList] = useState([]);

    useEffect(() => {

        loadHousekeeper();

        return () => {

        }
    }, [])

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {
            const values = form.getFieldsValue(true);
            let propsObj = JSON.parse(localStorage.getItem('temproomdetails'));
            let obj = JSON.parse(localStorage.getItem('currentproperty'));


            let h = {
                hotel: parseInt(obj.id),
                room: propsObj.id,
                staff: values.housekeeper
            };


            let a = await post(`/api/housekeepings`,h)
            if (response.ok)
            {
                successN('Cleaning started successfully');
                props.loadData();
                props.setVisible(false);
                localStorage.setItem('openmodal', 'false');
            }
            else
            {
                errorN(`Set Housekeeper - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Set Housekeeper: ${err}. Please contact the administrator.`);
        }

    }

    async function loadHousekeeper() {
        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let alist = await get(`/api/parties?type=staff`)
            if (response.ok)
            {
                let a = [];

                //console.log('hs',alist);


                for(let j of alist)
                {
                    if(j.data !== undefined)
                    {
                        if(j.data.staff !== undefined)
                        {
                            if(j.data.staff.role === 'housekeeper' && j.data.staff.property !== undefined)
                            {
                                if(j.data.staff.property.id === obj.id)
                                {
                                    a.push(j);
                                }
                            }
                        }
                    }
                }


                setHousekeeperList(a);
            }
            else
            {
                errorN(`Housekeeper List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Housekeeper List: ${err}. Please contact the administrator.`);
        }
    }

    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{padding: '20px'}}>

                        <Form.Item
                            label='Housekeeper Name'
                            name="housekeeper"
                            rules={[
                                {
                                    required: true,
                                    message: `Please choose Housekeeper`,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Housekeeper"
                                /*style={{width: '300px'}}*/
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                optionFilterProp="children">
                                {housekeeperList.map(value => <Option key={value.id} value={value.id}>{value.data.name}</Option>)}
                            </Select>
                        </Form.Item>

                        <br />
                        <Button type="danger" onClick={() => form.submit()}  loading={loading} disabled={disabled}>
                            <StepForwardOutlined /> Confirm
                        </Button>
                    </Col>
                </Row>

            </Form>

        </>
    );
}

export default SetHousekeeper
