import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined, DollarCircleOutlined,IdcardOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import FModal from "./modal";

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function ChooseRoomModal(props) {
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);


    useEffect(() => {

        return () => {

        }
    }, [])

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const setData = () => {
        let obj = props.obj;

        if(obj.roomNo !== null && obj.roomNo !== undefined && obj.roomNo !== '')
        {
            let d = obj.roomNo.split(',');

            obj.roomNox = d;
        }
        else
        {
            obj.roomNox = [];
        }

        form.setFieldsValue(obj);

        setAvailCount(props.getAllo(obj.itemID));

        setIsBackDate(props.isBackDate);
    }

    const onSubmit = () => {
        const values = form.getFieldsValue(true);

        let x = '';


        for(let i of values.roomNox)
        {
            if(x === '')
            {
                x = i;
            }
            else
            {
                x = x + ',' + i;
            }
        }

        values.roomNo = x;

        props.reloadMe(values);
        setVisible(false);
    }

    const handleChange = () => {

        // if(props.isBackDate !== true)
        // {
            const values = form.getFieldsValue(true);
            let x = '';

            for(let i of values.roomNox)
            {
                if(x === '')
                {
                    x = i;
                }
                else
                {
                    x = x + ',' + i;
                }
            }

            values.roomNo = x;

            props.reloadMe(values);

            let obj = props.obj;
            setAvailCount(props.getAllo(obj.itemID));
        // }

    }

    return (
        <>
            {props.type === 'Danger' ?
                <Button type="danger" size="small" onClick={() => {setVisible(true); setData();}}  loading={loading}>
                    <PlayCircleOutlined  /> Select
                </Button>
            :
                <Button type="primary" size="small" onClick={() => {setVisible(true); setData();}}  loading={loading}>
                    <PlayCircleOutlined  /> Select
                </Button>
            }


            <Modal
                centered ={true}
                width="30%"
                destroyOnClose={true}
                visible={visible}
                title="Choose Service"
                onOk={handleOk}
                closable = {false}
                onCancel={handleCancel}
                maskClosable={false}
                footer =""
            >
               <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                            <Form.Item
                                label="Rate Plan"
                                name="rate"
                                rules={[]}
                            >
                                <Input disabled placeholder="Rate Plan" />
                            </Form.Item>

                            <Form.Item
                                label="Price"
                                name="price"
                                rules={[]}
                            >
                                <InputNumber disabled style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                            {isBackDate === true ?
                                <Form.Item
                                name="roomNox"
                                label= 'Select Unit'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please choose a Quantity',
                                //     },
                                // ]}
                                >
                                    <Select
                                        mode= 'multiple'
                                        onChange={handleChange}
                                        allowClear
                                        placeholder="Qty"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch
                                        optionFilterProp="children">
                                        {availCount.map(value => <Option key={value.count} value={value.count}>{value.count}</Option>)}
                                    </Select>
                                </Form.Item>
                            :
                            <Form.Item
                                name="availability"
                                label= 'Select Qty'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please choose a Quantity',
                                //     },
                                // ]}
                                >
                                    <Select
                                        onChange={handleChange}
                                        allowClear
                                        placeholder="Qty"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch
                                        optionFilterProp="children">
                                        {availCount.map(value => <Option key={value.count} value={value.count}>{value.count}</Option>)}
                                    </Select>
                                </Form.Item>
                            }


                            <Form.Item
                                label="Open Price"
                                name="open"
                                rules={[]}
                            >
                                <InputNumber style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                            <Form.Item
                                label="Discount"
                                name="discount"
                                rules={[]}
                            >
                                <InputNumber style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                            <br />
                            <Button type="primary" onClick={() => form.submit()}  loading={loading}>
                                <StepForwardOutlined /> Confirm
                            </Button>
                        </Col>
                    </Row>

                </Form>

            </Modal>
        </>
    );
}

function Booking(props) {
    const [state,dispatch] = useContext(Context)
    const { get, post, response, loading, error, del, put } = useFetch();
    const { get:getLocal, post:postLocal, response:responseLocal, loading:loadingLocal} = useFetch(`http://localhost:8023`)

    const [form] = Form.useForm()
    const [formLayout, setFormLayout] = useState('vertical');
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);

        booknow();

    }, [form])

    const [bookingType, setBookingType] = useState('walkin');
    const [nationalityList, setNationalityList] = useState([]);
    const [roomtypeList, setRoomtypeList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);

    const labelCol = 22;
    const wrapperCol = 22;

    const [current, setCurrent] = useState(0);
    const [visibleSubmit, setVisibleSubmit] = useState(false);
    const [isPaying, setIsPaying] = useState(false);
    const [isCash, setIsCash] = useState(false);
    const [isCard, setIsCard] = useState(false);
    const [calculated, setCalculated] = useState({price: 0, tax: 0, fee:0, discount:0,rounding:0,amount:0});

    const [customerExistTel, setCustomerExistTel] = useState(false);
    const [customerExistName, setCustomerExistName] = useState(false);
    const [customerExistEmail, setCustomerExistEmail] = useState(false);

    const [allowBackDate, setAllowBackDate] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [isBackDate, setIsBackDate] = useState(false);
    const [foliocreated, setFoliocreated] = useState(false);

    const [mykadAvailable, setMykadAvailable] = useState(false);

    const [allowCheckin, setAllowCheckin] = useState(false);


    const clearForm = () => {
        form.resetFields();

        setBookingType('walkin');
        setRoomtypeList([]);
        setClientList([]);
        setSupplierList([]);
        setStateList([]);
        setCurrent(0);
        setVisibleSubmit(false);
        setIsPaying(false);
        setIsCash(false);
        setIsCard(false);
        setCalculated({price: 0, tax: 0, fee:0, discount:0,rounding:0,amount:0});
        setCustomerExistTel(false);
        setCustomerExistName(false);
        setCustomerExistEmail(false);
    }

    const next = () => {
        form.validateFields().then(values => {
            setCurrent(current + 1);
        })

    };

    const prev = () => {
        setCurrent(current - 1);
    };

    async function loadData() {

        let customsettingObj = JSON.parse(localStorage.getItem('customsetting'));

        if(customsettingObj !== null)
        {
            if(customsettingObj.data.backDate === true)
            {
                setAllowBackDate(true);
            }
        }

        let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));

        if(devicesetting !== null)
        {
            if(devicesetting.data !== undefined)
            {
                if(devicesetting.data.mykad !== undefined)
                {
                    setMykadAvailable(devicesetting.data.mykad);
                }
            }
        }

        let timesettingObj = JSON.parse(localStorage.getItem('timesetting'));

        if(timesettingObj !== null)
        {
            if(timesettingObj.data !== undefined)
            {
                if(timesettingObj.data.cutOut !== undefined)
                {
                    let h1 = timesettingObj.data.cutOut;
                    let h = 0;
                    let m = 0;
                    if(h1 !== null && h1 !== undefined)
                    {
                        let i = h1.split(':');
                        h = parseInt(i[0]);
                        m = parseInt(i[1]);
                    }
                    let date = new Date();
                    let n = moment(date).format('YYYY-MM-DD');
                    let nw = moment(n).add(h, 'h').add(m, 'm').format('YYYY-MM-DD HH:mm:ss');
                    let todaynow = moment(date).format('YYYY-MM-DD HH:mm:ss');


                    if(todaynow <= nw)
                    {
                        let arr = [];
                        arr.push(moment(todaynow).add(-1, 'd'))
                        arr.push(undefined)
                        form.setFieldsValue({
                            dt: arr
                        });
                    }
                    else
                    {
                        let arr = [];
                        arr.push(moment(todaynow))
                        //arr.push(moment(todaynow).add(1, 'd'))
                        arr.push(undefined)
                        form.setFieldsValue({
                           dt: arr
                        });
                    }
                }
            }
        }
    }

    async function loadCbo() {
        let obj = JSON.parse(localStorage.getItem('countrylist'));

        setNationalityList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setCountryList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    }

    const getState = (e) => {

        try
        {
            setStateList([]);
            form.setFieldsValue({
                state: undefined
            });

            let obj = JSON.parse(localStorage.getItem('countrylist'));

            let ls = obj.data.filter(x => x.name === e);

            if(ls.length > 0)
            {
                setStateList(ls[0].states.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)));
            }

        }
        catch(err)
        {
            errorN(`Error Get States: ${err}. Please contact the administrator.`);
        }

    }

    useEffect(() => {


        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {
            loadCbo();
            loadData();
        }

        //loadData().then(r => {});

        return () => {

        }
    },[])

    async function getClientSupplier(e) {
        try
        {
            let alist = await get(`/api/parties?type=${e}`)
            if (response.ok)
            {
               if(e === 'client')
                {
                    setClientList(alist);

                    form.setFieldsValue({
                        clientlist: alist
                    });
                }
                else
                {
                    setSupplierList(alist);

                    form.setFieldsValue({
                        supplierlist: alist
                    });
                }
            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error GCS: ${err}. Please contact the administrator.`);
        }
    }

    const onChange = (e) => {
        setBookingType(e.target.value);

        if(e.target.value === 'client')
        {
            getClientSupplier('client');
        }
        else if(e.target.value === 'agency')
        {
            getClientSupplier('supplier');
        }
    }

    const expandedRowRender = (e) => {

        const values = form.getFieldsValue(true);

        let ls = values.availabilitylist.filter(x => x.itemID === e.itemID);

        ls = ls.sort((a,b) => (a.rateID > b.rateID) ? 1 : ((b.rateID > a.rateID) ? -1 : 0))

        return <Table columns={columnsA} size="small" dataSource={ls} pagination={false} />;
    };

    const columnsA =[

        {
            width: '50%',
            title:"Rate Plan",
            dataIndex:"rate",
            key:"rate",
        },
        {
            width: '10%',
            title:"Price",
            dataIndex:"price",
            key:"price",
        },
        {
            width: '10%',
            title: isBackDate === false ? 'Qty' : 'Unit(s)',
            dataIndex:isBackDate === false ? 'availability' : 'roomNo',
            key:isBackDate === false ? 'availability' : 'roomNo',
        },
        {
            width: '10%',
            title:"Open Price",
            dataIndex:"open",
            key:"open",
        },
        {
            width: '10%',
            title:"Discount",
            dataIndex:"discount",
            key:"discount",
        },
        {
            width: '10%',
            title:"",
            dataIndex:"action",
            key:"action",
            render:(text, record) => (
               <ChooseRoomModal
               obj={record}
               reloadMe={reloadMe}
               getAllo={getAllo}
               type={isBackDate === false ? (record.availability !== '' && record.availability !== null && record.availability !== undefined ? 'Danger' : 'Primary') : (record.roomNo !== '' && record.roomNo !== null && record.roomNo !== undefined ? 'Danger' : 'Primary') }
               isBackDate={isBackDate}
               />
            )
        },
    ]

    const columns = [
        { title: 'Type', dataIndex: 'item', key: 'item' },
    ]

    async function loadAvailability(e){

        setRoomtypeList([]);
        form.setFieldsValue({
            roomtypelist: [],
            availabilitylist: []
        });
        setCalculated({price: 0, tax: 0, fee:0, discount:0,rounding:0,amount:0});

        const values = form.getFieldsValue(true);

        if(values.dt === undefined || values.dt === null)
        {
            return;
        }
        else
        {
            if(values.dt[0] === undefined || values.dt[1] === undefined || values.dt[0] === null || values.dt[1] === null)
            {
                return;
            }
        }

        let timesettingObj = JSON.parse(localStorage.getItem('timesetting'));
        let date = new Date();
        let todaynow = moment(date).format('YYYY-MM-DD HH:mm:ss');
        if(timesettingObj !== null)
        {
            if(timesettingObj.data !== undefined)
            {
                if(timesettingObj.data.cutOut !== undefined)
                {
                    let h1 = timesettingObj.data.cutOut;
                    let h = 0;
                    let m = 0;
                    if(h1 !== null && h1 !== undefined)
                    {
                        let i = h1.split(':');
                        h = parseInt(i[0]);
                        m = parseInt(i[1]);
                    }

                    let n = moment(date).format('YYYY-MM-DD');
                    let nw = moment(n).add(h, 'h').add(m, 'm').format('YYYY-MM-DD HH:mm:ss');


                    if(todaynow <= nw)
                    {
                        todaynow = (moment(todaynow).add(-1, 'd'))
                    }
                }
            }
        }



        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let e = moment(values.dt[1]).format('YYYY-MM-DD');
            let s = moment(values.dt[0]).format('YYYY-MM-DD');
            let t = moment(todaynow).format('YYYY-MM-DD');

            if(e === s)
            {
                errorN('Departure date should greater than Arrival date.');
                /*form.setFieldsValue({
                    dt: undefined
                });*/
                loadData();
                return;
            }

            if(t > s && (values.allowbackdate === false || values.allowbackdate === undefined) )
            {
                errorN('Back Date booking is NOT ALLOWED');
                /*form.setFieldsValue({
                    dt: undefined
                });*/
                loadData();
                return;
            }

            //let dtNow = moment(Date.now());

            if(values.allowbackdate === true)
            {
                //if(s >= dtNow || e >= dtNow)
                if(s >= t || e >= t)
                {
                    errorN('Back Date entry Arrival & Departure should not greater than current date.');
                    /*form.setFieldsValue({
                        dt: undefined
                    });*/
                    loadData();
                    return;
                }
            }

            //let s = dtStart.toISOString();
            //let e = dtEnd.toISOString();

            let availabilityList = await get(`/api/hotels/${obj.id}/availability_rates?start=${s}&end=${e}`)
            if (response.ok)
            {
                let table1 =[];
                let table2 =[];

                for(let i of availabilityList)
                {
                    for(let j of i.rates)
                    {
                        if (j.data.wubook === "" || j.data.wubook === null || j.data.wubook === undefined)
                        {
                            table1.push({
                                key: j.id,
                                item: i.data.name + ": " + i.total + " unit(s)",
                                rate:  j.data.plan + ": " + j.data.name,
                                availability: '',
                                roomNo: '',
                                price: j.type === 'base' ? j.data.price : 'Daily',
                                rateType: j.type,
                                itemID: i.item,
                                rateID: j.id,
                                discount: '',
                                open: ''
                            })
                        }

                    }

                    table2.push({key: i.item,itemID: i.item,item: i.data.name + ": " + i.total + " unit(s)", total:i.total})
                }


               setRoomtypeList(table2);
                //setAvailabilityList(table1);

                form.setFieldsValue({
                    roomtypelist: table2,
                    availabilitylist: table1
                });
            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error LAV: ${err}. Please contact the administrator.`);
        }
    }

    async function reloadMe (obj) {

        setRoomtypeList([]);

        const values = form.getFieldsValue(true);

        let ls = values.availabilitylist.filter(x => x.rateID !== obj.rateID);

        // let arr = (!!obj.availability) && (obj.availability.constructor === Array) ; //await isArray(obj.availability);

        // let x = '';
        // if(arr === true)
        // {
        //     for(let i of obj.availability)
        //     {
        //         if(x === '')
        //         {
        //             x = i;
        //         }
        //         else
        //         {
        //             x = x + ',' + i;
        //         }
        //     }

        //     //obj.roomNo = x;
        //     obj.availability = x;

        // }

        ls.push(obj);
        form.setFieldsValue({
            availabilitylist: ls
        });

        setRoomtypeList(values.roomtypelist);

        calculate();

        // for(let i of values.roomtypelist)
        // {
        //     if(i.itemID === obj.itemID)
        //     {
        //         console.log('hereq1', i);
        //         expandedRowRender(i);
        //     }
        // }


    }

    const getAllo = (itemid) => {

        const values = form.getFieldsValue(true);

        let roomls = JSON.parse(localStorage.getItem('roomlist'));
        let marks = [];

        if(values.allowbackdate === true)
        {
            try{

                let used = [];

                for(let i of values.availabilitylist)
                {
                    if(i.itemID === itemid)
                    {
                        if(i.roomNo !== null && i.roomNo !== undefined && i.roomNo !== '')
                        {
                            let d = i.roomNo.split(',');

                           for(let i of d)
                           {
                               used.push(i);
                           }
                        }
                    }
                }

                let total = [];

                for(let i of roomls)
                {
                    if(i.item === itemid)
                    {
                        total.push(i.data.no);
                    }
                }

                for(let i of used)
                {
                    let index = total.indexOf(i);
                    if (index > -1) {
                        total.splice(index, 1);
                    }
                }

                for(let j of total)
                {
                    marks.push({count:j});
                }


            }
            catch (err) {
                errorN(`Error GAT: ${err}. Please contact the administrator.`);
            }
        }
        else
        {
            let used = 0;
            let total = 0;

            try{

                for(let i of values.availabilitylist)
                {
                    if(i.itemID === itemid)
                    {
                        if(i.availability !== null && i.availability !== undefined && i.availability !== '')
                        {
                            used = used + i.availability;
                        }
                    }
                }


                for(let i of values.roomtypelist)
                {
                    if(i.itemID === itemid)
                    {
                        total = i.total;
                    }
                }

                for(let j=0; j<= total-used; j++)
                {
                    if(j !== 0)
                    {
                        marks.push({count:j})
                    }
                }
            }
            catch (err) {
                errorN(`Error GAF: ${err}. Please contact the administrator.`);
            }
        }

        return marks;
    }

    const onPayment = (e) => {
        try{

            if(e === null || e === undefined || e === '')
            {
                setIsPaying(false);
                setIsCash(false);
                setIsCard(false);

                form.setFieldsValue({
                    amount: undefined,
                    cardNo: undefined,
                    expiry: undefined,
                    slipNo: undefined

                });
            }
            else
            {
                setIsPaying(true);

                form.setFieldsValue({
                    cardNo: undefined,
                    expiry: undefined,
                    slipNo: undefined

                });
            }

            if(e === 'Cash')
            {
                setIsCash(true);
                setIsCard(false);
            }
            else if(e === "Credit Card" || e === "Debit Card" || e === "Master Card" || e === "Visa" || e === "Amex")
            {
                setIsCash(false);
                setIsCard(true);
            }
            else
            {
                setIsCash(false);
                setIsCard(false);
            }

        }
        catch (err)
        {
            errorN(`Error onPayment: ${err}. Please contact the administrator.`);
        }
    }

    async function calculate() {
        const values = form.getFieldsValue(true);

        if(values.dt === undefined || values.dt === null )
        {
            return;
        }

        if(values.dt[0] === undefined || values.dt[0] === null )
        {
            return;
        }

        if(values.dt[1] === undefined || values.dt[1] === null)
        {
            return;
        }

        let source = '';
        if(values.type === 'Client')
        {
            source = 'client';
        }
        else if(values.type === 'Travel Agency')
        {
            source = 'agency';
        }
        else
        {
            source = 'walkin';
        }

        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let e = moment(values.dt[1]).format('YYYY-MM-DD');
            let s = moment(values.dt[0]).format('YYYY-MM-DD');

            //let s = dtStart.toISOString();
            //let e = dtEnd.toISOString();

            let n = null;

            if(values.nationality !== undefined)
            {
                let objx = JSON.parse(localStorage.getItem('countrylist'));

                for(let i of objx.data)
                {
                    if(i.name === values.nationality)
                    {
                        n = {id: i.code3, name: i.name}
                    }
                }
            }



            let bookingObj = {
                booking: {
                    hotel: obj.id,
                    source: source,
                    start: s,
                    data: {
                        stay: {
                            arrival: s,
                            departure: e
                        },
                        payor: {
                            nationality:  n,
                            type: values.guesttype
                        }
                    }
                },
                lines: await getBookingLine()
            }

            let a = await post(`/api/bookings/calculate`, bookingObj)
            if (response.ok)
            {
                a.price = a.breakdown.price;

                let tx = 0;
                let fe = 0;
                let dc = 0;

                for(let x of a.breakdown.tax)
                {
                    tx = tx + x.rate;
                }
                for(let x of a.breakdown.fee)
                {
                    fe = fe + x.rate;
                }

                for (let x of a.breakdown.discount)
                {
                    dc = dc + x.rate;
                }

                a.tax = tx;
                a.fee = fe;
                a.discount = dc;

                setCalculated(a);
            }
            else
            {
                errorN(`CALCULATE- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error CALCULATE: ${err}. Please contact the administrator.`);
        }
    }

    async function getBookingLine() {
        try
        {
            const values = form.getFieldsValue(true);
            let roomls = JSON.parse(localStorage.getItem('roomlist'));

            let ls =[];

            let e = moment(values.dt[1]).format('DD/MM/YYYY');
            let s = moment(values.dt[0]).format('DD/MM/YYYY');

            //let s = dtStart.toISOString('dd/MM/yyyy');
            //let e = dtEnd.toISOString('dd/MM/yyyy');

            const date1 = new Date(values.dt[0]);
            const date2 = new Date(values.dt[1]);
            const diffTime = Math.abs(date2 - date1);
            let duration = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


            for(let i of values.availabilitylist)
            {
                if((i.availability !== null && i.availability !== undefined && i.availability !== '') || (i.roomNo !== null && i.roomNo !== undefined && i.roomNo !== ''))
                {
                    let avail = i.availability;

                    if(values.allowbackdate === true)
                    {
                        let c = i.roomNo.split(',');
                        avail = c.length;
                    }

                    let rate = i.rateID;

                    let requestedDiscount = 0;
                    let open = 0;

                    if (i.discount !== null && i.discount !== undefined && i.discount !== '' && i.discount !== 0)
                    {
                        requestedDiscount = i.discount;
                    }

                    if (i.open !== null && i.open !== undefined && i.open !== '' && i.open !== 0)
                    {
                        open = i.open;
                    }


                    let op = {
                        price: open
                    };

                    let d = i.roomNo.split(',');

                    for (let j = 0; j < avail; j++)
                    {
                        let n = '';

                        if(values.allowbackdate === true)
                        {
                            for(let x of roomls)
                            {
                                if(x.data.no === d[j])
                                {
                                    n = x.id;
                                }
                            }
                        }

                        ls.push({unit: duration, rate: rate,
                            data: {
                                arrival: s,
                                departure: e,
                                roomID: n
                            },
                            requestedDiscount: requestedDiscount,
                            open: op
                        });
                    }
                }
            }

            return ls;

        }
        catch (err)
        {
            errorN(`Error GetLines: ${err}. Please contact the administrator.`);
        }
    }

    async function checkCustomer(e, typ){

        let str = '';
        if(typeof e === 'string')
        {
            str = e;
        }
        else
        {
            str = e.target.value;
        }

        if(str === '' ||  str === null ||  str === undefined || str.length <= 3)
        {
            if(typ === 'tel')
            {
                setCustomerExistTel(false);
            }
            else if(typ === 'name')
            {
                setCustomerExistName(false);
            }
            else if(typ === 'email')
            {
                setCustomerExistEmail(false);
            }

            return;
        }

        try
        {
            let s = '';
            if(typ === 'tel')
            {
                s='tel=' + str;
            }
            else if(typ === 'name')
            {
                s='name=' + str;
            }
            else if(typ === 'email')
            {
                s='email=' + str;
            }


            let countx = await get(`/api/parties?${s}`)
            if (response.ok)
            {
               if(countx.length > 0)
               {
                    if(typ === 'tel')
                    {
                        setCustomerExistTel(true);
                    }
                    else if(typ === 'name')
                    {
                        setCustomerExistName(true);
                    }
                    else if(typ === 'email')
                    {
                        setCustomerExistEmail(true);
                    }

               }
               else
               {
                    if(typ === 'tel')
                    {
                        setCustomerExistTel(false);
                    }
                    else if(typ === 'name')
                    {
                        setCustomerExistName(false);
                    }
                    else if(typ === 'email')
                    {
                        setCustomerExistEmail(false);
                    }
               }
            }
            else
            {
                errorN(`Customer List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Check Customer: ${err}. Please contact the administrator.`);
        }
    }

    const setCustomer = (type) => {
        const values = form.getFieldsValue(true);

        return {tel: values.tel , name: values.name, email: values.email, code: type}

    }

    async function loadCustData(customerID){

        if(customerID === '' || customerID === null || customerID === undefined)
        {
            return;
        }

        setCustomerExistEmail(false);
        setCustomerExistName(false);
        setCustomerExistTel(false);

        try
        {
            let profile = await get(`/api/parties/${customerID}`)
            if (response.ok)
            {
                form.setFieldsValue({
                    id : profile.id,
                    tel : profile.data.tel,
                    name : profile.data.name,
                    identificationtype : profile.data.identificationType,
                    identificationno : profile.data.identificationNo,
                    email : profile.data.email,
                    nationality : profile.data.nationality,
                    line : profile.data.address.line,
                    city : profile.data.address.city,
                    postcode : profile.data.address.postcode,
                    country : profile.data.address.country,
                    state : profile.data.address.state,
                    category : profile.category,
                    company : profile.data.guest.guestCompany,
                    guesttype: profile.data.guest.type,
                });

                calculate();
            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Load Customer: ${err}. Please contact the administrator.`);
        }
    }

    async function getCardType(number){

        if(number === '' || number === null || number === undefined)
        {
            return '';
        }

        try
        {
            // visa
            let re = new RegExp("^4");
            if (number.match(re) != null)
                return "Visa";

            // Mastercard
            // Updated for Mastercard 2017 BINs expansion
            if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
                return "Mastercard";

            // AMEX
            re = new RegExp("^3[47]");
            if (number.match(re) != null)
                return "AMEX";

            // Discover
            re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
            if (number.match(re) != null)
                return "Discover";

            // Diners
            re = new RegExp("^36");
            if (number.match(re) != null)
                return "Diners";

            // Diners - Carte Blanche
            re = new RegExp("^30[0-5]");
            if (number.match(re) != null)
                return "Diners - Carte Blanche";

            // JCB
            re = new RegExp("^35(2[89]|[3-8][0-9])");
            if (number.match(re) != null)
                return "JCB";

            // Visa Electron
            re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
            if (number.match(re) != null)
                return "Visa Electron";

            return ''

        }
        catch (err)
        {
            errorN(`Error Card Type: ${err}. Please contact the administrator.`);
            return '';
        }
    }

    async function booknow()  {
        const values = form.getFieldsValue(true);

        if(values.allowbackdate === true)
        {
            if(values.availabilitylist === undefined)
            {
                errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                return;
            }
            else
            {
                if(values.availabilitylist.length === 0)
                {
                    errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                    return;
                }
                else
                {
                    let count  = 0;
                    for(let i of values.availabilitylist)
                    {
                        if(i.roomNo !== null && i.roomNo !== undefined && i.roomNo !== '')
                        {
                            let a = i.roomNo.split(',');
                            count = count + a.length;
                        }
                    }

                    if(count === 0)
                    {
                        errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                        return;
                    }
                }
            }
        }
        else
        {
            if(values.availabilitylist === undefined)
            {
                errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                return;
            }
            else
            {
                if(values.availabilitylist.length === 0)
                {
                    errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                    return;
                }
                else
                {
                    let count  = 0;
                    for(let i of values.availabilitylist)
                    {
                        if(i.availability !== null && i.availability !== undefined && i.availability !== '')
                        {
                            count = count + i.availability;
                        }
                    }

                    if(count === 0)
                    {
                        errorN(`Invalid: Choose at least 1 unit to proceed with this booking.`);
                        return;
                    }
                }
            }
        }





        let source = values.type;
        /*if(values.type === 'Client')
        {
            source = 'client';
        }
        else if(values.type === 'Travel Agency')
        {
            source = 'agency';
        }
        else
        {
            source = 'walkin';
        }*/

        try
        {
            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let e = moment(values.dt[1]).format('YYYY-MM-DD');
            let s = moment(values.dt[0]).format('YYYY-MM-DD');

            //let s = dtStart.toISOString();
            //let e = dtEnd.toISOString();

            let pay = null;
            let guest = null;
            let nationality = null;

            if(values.id !== undefined && values.id !== null && values.id !== '')
            {
                guest = {id:values.id, name:values.name};
            }

            if(values.nationality !== undefined)
            {
                let objx = JSON.parse(localStorage.getItem('countrylist'));

                for(let i of objx.data)
                {
                    if(i.name === values.nationality)
                    {
                        nationality = {id: i.code3, name: i.name}
                    }
                }
            }


            let otaagencyName = '';
            let otaagencyID = '';

            if(source === 'client')
            {
                if(values.clientlist !== undefined)
                {
                    if(values.clientlist !== null)
                    {
                        if(values.clientlist.length > 0)
                        {
                            for(let i of values.clientlist)
                            {
                                if(i.id === values.client)
                                {
                                    otaagencyName = i.data.name;
                                    otaagencyID = i.id;
                                }
                            }
                        }
                    }
                }

            }

            if(source === 'agency')
            {
                if(values.supplierlist !== undefined)
                {
                    if(values.supplierlist !== null)
                    {
                        if(values.supplierlist.length > 0)
                        {
                            for(let i of values.supplierlist)
                            {
                                if(i.id === values.supplier)
                                {
                                    otaagencyName = i.data.name;
                                    otaagencyID = i.id;
                                }
                            }
                        }
                    }
                }
            }

            let payor = {
                name : values.name,
                otaagency : {
                    name : otaagencyName,
                    id : otaagencyID
                },
                refno : values.refno,
                contactPerson : values.contactPerson,
                gender : values.gender,
                designation : values.designation,
                tel : values.tel,
                email :  values.email,
                nationality : nationality,
                identification : {
                    name :  values.identificationtype,
                    id :  values.identificationno
                },
                type : values.guesttype,
                address : {
                    line :  values.line,
                    city :  values.city,
                    postcode :  values.postcode,
                    country :  values.country,
                    state :  values.state
                },
                guest : guest,
                category :  values.category,
                guestCompany :  values.company

            }

            if(values.method !== undefined && values.method !== null && values.method !== '' && values.amount !== undefined && values.amount !== null && values.amount !== '' && values.amount !== '0' && values.amount !== '0.00' && values.amount !== 0)
            {
                let cardtype = await getCardType(values.cardNo !== undefined && values.cardNo !== null && values.cardNo !== '' ? values.cardNo : '');

                pay = {
                    hotel : obj.id,
                    amount : values.amount,
                    type : "receipt",
                    collector : localStorage.getItem('id'),
                    data :  {
                        cardNo : values.cardNo !== undefined && values.cardNo !== null && values.cardNo !== '' ? values.cardNo : '',
                        expiry : values.expiry !== undefined && values.expiry !== null && values.expiry !== '' ? values.expiry : '',
                        paymentFor : 'Payment',
                        slipNo : values.slipNo !== undefined && values.slipNo !== null && values.slipNo !== '' ? values.slipNo : '',
                        cardType : cardtype,
                        by : {
                            id : localStorage.getItem('id'),
                            name : localStorage.getItem('name')
                        },
                        method : values.method !== undefined && values.method !== null && values.method !== '' ? values.method : ''
                    }
                }
            }


            let bookingObj = {
                booking: {
                    hotel: obj.id,
                    source: source,
                    start: s,
                    data: {
                        requests: values.requests,
                        by : {
                            id : localStorage.getItem('id'),
                            name : localStorage.getItem('name')
                        },
                        stay: {
                            arrival: s,
                            departure: e
                        },
                        payor: payor,
                        rcode: values.refno

                    }
                },
                lines: await getBookingLine(),
                payment: pay,
                backDate: values.allowbackdate === true ? {checkin:s,checkout:e} : null
            }

            if(values.allowbackdate === true)
            {
                //console.log("ape la",bookingObj);
                let a = await post(`/api/bookings/lines/predate`, bookingObj)
                if (response.ok)
                {
                    setIsDone(true);
                    setFoliocreated(true);
                    clearForm();
                }
                else
                {
                    errorN(`Book Now BD - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                localStorage.removeItem('tempbookingid');
                localStorage.removeItem('tempcustomerid');
                localStorage.removeItem('tempcustomertype');


                let a = await post(`/api/bookings/lines`, bookingObj)
                if (response.ok)
                {
                    let x = response.headers.get('location')

                    localStorage.setItem('tempbookingid', x);
                    localStorage.setItem('tempcustomerid', values.id !== undefined && values.id !== null && values.id !== '' ? values.id : '');
                    localStorage.setItem('tempcustomertype', values.guesttype);
                    //props.loadData();
                    setIsDone(true);
                    await createfolio();
                    //clearForm();
                }
                else
                {
                    errorN(`Book Now - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }



        }
        catch (err)
        {
            errorN(`Error BOOK: ${err}. Please contact the administrator.`);
        }

    }

    const onAllowBackDate = (e) => {

        setIsBackDate(e.target.checked);

        setRoomtypeList([]);

        form.setFieldsValue({
            dt: undefined,
            roomtypelist: [],
            availabilitylist: [],
            allowbackdate: e.target.checked
        });

        if(e.target.checked === false)
        {
            loadData();
        }
    }

    async function createfolio() {
        let bookingID = localStorage.getItem('tempbookingid');
        let customerID = localStorage.getItem('tempcustomerid');
        let customerType = localStorage.getItem('tempcustomertype');

        const values = form.getFieldsValue(true);
        let date = new Date();

        try
        {
            let a = await get(`/api/bookings/${bookingID}`)
            if (response.ok)
            {
                if(customerID === undefined || customerID === null || customerID === '' || customerID === 0 || customerID === 'undefined')
                {
                    let guest = {
                        designation: '',
                        gender: '',
                        type: customerType,
                        guestCompany: a.data.payor.guestCompany
                    };



                    let party = {
                        category : a.data.payor.category,
                        data : {
                            name : a.data.payor.name,
                            email : a.data.payor.email,
                            tel : a.data.payor.tel,
                            address : a.data.payor.address && Object.keys(a.data.payor.address).length !== 0 && a.data.payor.address.constructor === Object ? a.data.payor.address : {
                                line : '',
                                city : '',
                                postcode : '',
                                state : '',
                                country : ''
                            },
                            client : null,
                            company : null,
                            guest : guest,
                            staff : null,
                            supplier : null,
                            identificationType : a.data.payor.identification && Object.keys(a.data.payor.identification).length !== 0 && a.data.payor.identification.constructor === Object ? a.data.payor.identification.name : '',
                            identificationNo : a.data.payor.identification && Object.keys(a.data.payor.identification).length !== 0 && a.data.payor.identification.constructor === Object ? a.data.payor.identification.id : '',
                            nationality :a.data.payor.nationality.name,
                            belongsTo: null
                        }
                    };


                    let c = await post(`/api/parties`, party)
                    if (response.ok)
                    {
                        let x = response.headers.get('location');
                        customerID = x;
                    }
                    else
                    {
                        errorN(`Create Party - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        return;
                    }
                }

                let g = {id: customerID, name: a.data.payor.name};
                a.data.payor.guest = g;

                let json = {
                    party: customerID,
                    booking: a
                }

                localStorage.removeItem('tempfolioid');

                let b = await post(`/api/bookings/${bookingID}/folio`, json)
                if (response.ok)
                {
                    try {
                        let s = moment(values.dt[0]).format('YYYY-MM-DD');
                        let e = moment(values.dt[1]).format('YYYY-MM-DD');

                        let now = moment(date).format('YYYY-MM-DD');

                        if(s <= now && e > now)
                        {
                            let folio = await get(`/api/bookings/${bookingID}/folios`)
                            if (response.ok)
                            {
                                localStorage.setItem('tempfolioid', folio[0].id);
                                localStorage.setItem('temparrival', s);
                                localStorage.setItem('tempdeparture', e);
                                setAllowCheckin(true);
                            }
                            else
                            {
                                errorN(`Get Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                            }

                        }
                    }
                    catch (err)
                    {
                        errorN(`Error Get Folio: ${err}. Please contact the administrator.`);
                    }



                    //props.loadData();
                    setFoliocreated(true);
                    clearForm();
                }
                else
                {
                    errorN(`Create Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
            else
            {
                errorN(`Get Booking - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error BOOK: ${err}. Please contact the administrator.`);
        }

    }

    async function getIC(){
        try {
            let a = await getLocal(`/read_ic`)
            if (responseLocal.status === 200)
            {
                if(a.status === 'The smart card has been removed, so further communication is not possible.')
                {
                    errorN(a.status);
                }
                else if(a.status === 'Sequence contains no elements')
                {
                    errorN(a.status);
                }
                else
                {
                    /*let re = /_\\n/gi;
                    let b = JSON.stringify(a);
                    console.log(b)
                    b = b.replace(re,', ');

                    a = JSON.parse(b);*/

                    form.setFieldsValue({name : a.name,
                        identificationtype : 'Identification Card',
                        identificationno : a.ic,
                        line : a.address.replace(/(\r\n|\n|\r)/gm, ", "),
                        city : a.city,
                        postcode : a.postcode,
                        country : 'Malaysia',
                        state : a.state,
                        gender: a.gender,
                        nationality: 'Malaysia'
                    });

                    checkCustomer( a.name,'name')
                }
            }
            else
            {
                errorN(`Read IC is not working properly. Please contact the administrator.`);
            }
        }
        catch (e) {
            errorN(e.message);
        }

    }

    function senddata(){
        let folioid = localStorage.getItem('tempfolioid');
        let arrival = localStorage.getItem('temparrival');
        let departure = localStorage.getItem('tempdeparture');

        return {folioid:folioid,
            arrival:arrival,
            departure:departure
        }
    }

    return (
        <>
        {/*<div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        &nbsp;&nbsp;<PhoneOutlined />
        &nbsp;&nbsp;Book Now!
        </div>    */}
        <Layout>

            <Spin spinning={loading}>
            <Content style={{backgroundColor: 'white'}}>
                <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                    <div style={{backgroundColor: 'white', padding: '20px', margin: '0px 0',height:'73vh'}}>
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={true || false}
                        useWindow={false}
                    >
                        {isDone === true ?
                        <>
                            {foliocreated === false ?
                            <>
                                <Result
                                    status="success"
                                    title="Please wait..."
                                    subTitle="Booking in progress"
                                    extra={[
                                    /*<Button onClick={() => {createfolio();}} type="primary" key="yes">
                                        <CheckCircleOutlined />Yes
                                    </Button>,
                                    <Button onClick={() => {setIsDone(false);}} key="no">
                                        <CloseCircleOutlined />No
                                    </Button>,*/
                                    ]}
                                />
                            </>
                            :
                            <>
                            <Result
                                    icon={<SmileOutlined />}
                                    title="Great, we have done all the operations!"
                                    extra={
                                        <>
                                            <Button onClick={() => {setIsDone(false);setFoliocreated(false);loadData();}} key=""type="primary">New Booking</Button>
                                            {allowCheckin === true ?
                                                <>
                                                    <br /><br /><br />
                                                    DO YOU WANT TO PROCEED WITH CHECK-IN? CLICK <b><u><FModal formname={'checkinout'} obj={senddata()} loadData={loadData} /></u></b> TO CONTINUE.
                                                </>
                                            :
                                            null}
                                        </>
                                    }
                                />
                            </>
                            }
                        </>
                        :
                        <>
                            <Row>
                                <Col xs={12} sm={12} md={24} lg={14} xl={14} >
                                    <div style={{backgroundColor: 'white', padding: '20px', marginRight: '20px', height: '69vh'}}>
                                        <legend>Stay Information</legend>
                                    <Row>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Form.Item
                                                label="Arrival - Departure"
                                                name="dt"
                                                //rules={[{ required: true, message: 'Dates missing!' }]}
                                            >
                                                <RangePicker onChange={loadAvailability} />
                                            </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{paddingTop: '35px'}}>
                                        {/* <Button size="big" type="primary" onClick={()=>{loadAvailability()}}loading={loading}>
                                            <SearchOutlined />Load
                                            </Button>   */}
                                            {allowBackDate === true ?
                                                <Checkbox onChange={onAllowBackDate}>Create Back Dated Booking</Checkbox>
                                            : null
                                            }

                                    </Col>
                                    </Row>

                                        <Table
                                            defaultExpandAllRows={true}
                                            columns={columns}
                                            dataSource={roomtypeList}
                                            expandable={{expandedRowRender}}
                                            bordered
                                            size="small"
                                            //expandRowByClick={false}
                                            scroll={{ y: 370 }}
                                            pagination={false}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={24} lg={10} xl={10}>
                                    <div style={{backgroundColor: 'white', padding: '20px', marginRight: '5px', height: '69vh'}}>
                                        {/* <legend>Guest Information</legend> */}
                                        <Steps current={current}>
                                            {steps.map(item => (
                                            <Step key={item.title} title={item.title} />
                                            ))}
                                        </Steps>
                                        <div style={{padding: '20px'}}>
                                            {(() => {
                                                switch (current) {
                                                    case 0:
                                                        return (
                                                            <>
                                                            <Form.Item
                                                                style={{ display: 'none' }}
                                                                label="id"
                                                                name="id"
                                                                labelCol={{ span: labelCol }}
                                                                wrapperCol={{ span: wrapperCol }}
                                                                rules={[]}
                                                            >
                                                                <InputNumber />
                                                            </Form.Item>

                                                            <Form.Item
                                                            name="type"
                                                            labelCol={{ span: labelCol }}
                                                            wrapperCol={{ span: wrapperCol }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please choose a Booking Type',
                                                                },
                                                            ]}
                                                            >
                                                                <Radio.Group onChange={onChange}>
                                                                    <Radio value='walkin'>Walk-In</Radio>
                                                                    <Radio value='client'>Client</Radio>
                                                                    <Radio value='agency'>Travel Agency</Radio>

                                                                </Radio.Group>
                                                            </Form.Item>

                                                            <Row>
                                                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                                    {mykadAvailable === true ?
                                                                    <>
                                                                        <Button type="danger" onClick={() => getIC()}  loading={loadingLocal}>
                                                                            <IdcardOutlined  /> Read IC
                                                                        </Button>
                                                                        <br /><br />
                                                                    </>
                                                                    :
                                                                    null
                                                                    }

                                                                    <Form.Item
                                                                        onBlur={(e) => checkCustomer(e,'tel')}
                                                                        label="Contact No."
                                                                        name="tel"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please enter Contact No.',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Contact Number" />
                                                                    </Form.Item>
                                                                    {customerExistTel === true ?
                                                                        <>
                                                                        <ModalCustomerExist setCustomer={() => setCustomer('tel')} loadme={loadCustData} />
                                                                        <br /><br />
                                                                        </>
                                                                    : null
                                                                    }
                                                                    <Form.Item
                                                                        onBlur={(e) => checkCustomer(e,'name')}
                                                                        label="Name"
                                                                        name="name"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please enter Name',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Name" />
                                                                    </Form.Item>
                                                                    {customerExistName === true ?
                                                                        <>
                                                                        <ModalCustomerExist setCustomer={() => setCustomer('name')} loadme={loadCustData} />
                                                                        <br /><br />
                                                                        </>
                                                                    : null
                                                                    }

                                                                    {bookingType === 'walkin' ?
                                                                    <>
                                                                        <Form.Item
                                                                            label="Company Name"
                                                                            name="company"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                    message: 'Please enter Company Name',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Company Name" />
                                                                        </Form.Item>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {bookingType === 'client' ?
                                                                            <Form.Item
                                                                                label='Client Name'
                                                                                name="client"
                                                                                labelCol={{ span: labelCol }}
                                                                                wrapperCol={{ span: wrapperCol }}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: `Please choose Client Name`,
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Select
                                                                                    placeholder="Client"
                                                                                    /*style={{width: '300px'}}*/
                                                                                    filterOption={(input, option) =>
                                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    showSearch
                                                                                    optionFilterProp="children">
                                                                                    {clientList.map(value => <Option key={value.id} value={value.id}>{value.data.name}</Option>)}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        :
                                                                            <Form.Item
                                                                                label='Travel Agency Name'
                                                                                name="supplier"
                                                                                labelCol={{ span: labelCol }}
                                                                                wrapperCol={{ span: wrapperCol }}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: `Please choose Travel Agency Name`,
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Select
                                                                                    placeholder="Travel Agency"
                                                                                    /*style={{width: '300px'}}*/
                                                                                    filterOption={(input, option) =>
                                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    showSearch
                                                                                    optionFilterProp="children">
                                                                                    {supplierList.map(value => <Option key={value.id} value={value.id}>{value.data.name}</Option>)}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        }


                                                                    </>
                                                                    }

                                                                    {bookingType !== 'walkin' ?
                                                                    <>
                                                                        <Form.Item
                                                                            label="Booking / Ref. No."
                                                                            name="refno"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter Ref. No.',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Ref. No." />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                                    }

                                                                </Col>
                                                                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                                                    <Form.Item
                                                                        onBlur={(e) => checkCustomer(e,'email')}
                                                                        label="Email"
                                                                        name="email"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please enter Email',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Email" />
                                                                    </Form.Item>
                                                                    {customerExistEmail === true ?
                                                                        <>
                                                                        <ModalCustomerExist setCustomer={() => setCustomer('email')} loadme={loadCustData} />
                                                                        <br /><br />
                                                                        </>
                                                                    : null
                                                                    }
                                                                    <Form.Item
                                                                        label="Nationality"
                                                                        name="nationality"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please choose Nationality',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            onChange={calculate}
                                                                            placeholder="Nationality"
                                                                            /*style={{width: '300px'}}*/
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            showSearch
                                                                            optionFilterProp="children">
                                                                            {nationalityList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Category"
                                                                        name="category"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please choose Category',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            placeholder="Category"
                                                                            /*style={{width: '300px'}}*/
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            showSearch
                                                                            optionFilterProp="children">
                                                                            <Option value='individual'>individual</Option>
                                                                            <Option value='organisation'>organisation</Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Guest Type"
                                                                        name="guesttype"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please choose Guest Type',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            onChange={calculate}
                                                                            placeholder="Guest Type"
                                                                            /*style={{width: '300px'}}*/
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            showSearch
                                                                            optionFilterProp="children">
                                                                            <Option value='Tourist'>Tourist</Option>
                                                                            <Option value='Tourist-OTA'>Tourist-OTA</Option>
                                                                            <Option value='Business'>Business</Option>
                                                                            <Option value='Government'>Government</Option>
                                                                            <Option value='Private Sector'>Private Sector</Option>
                                                                            <Option value='Contractor'>Contractor</Option>
                                                                            <Option value='Salesman'>Salesman</Option>
                                                                            <Option value='Driver'>Driver</Option>
                                                                            <Option value='Others'>Others</Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            </>
                                                        )
                                                    case 1:
                                                        return (
                                                            <>
                                                            <Row>
                                                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                                    <Form.Item
                                                                        label="Designation"
                                                                        name="designation"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: 'Please choose Identification Type',
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Select
                                                                            placeholder="Designation"
                                                                            /*style={{width: '300px'}}*/
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            showSearch
                                                                            optionFilterProp="children">
                                                                            <Option value='Mr.'>Mr.</Option>
                                                                            <Option value='Ms.'>Ms.</Option>
                                                                            <Option value='Mrs.'>Mrs.</Option>
                                                                            <Option value='En.'>En.</Option>
                                                                            <Option value='Cik.'>Cik.</Option>
                                                                            <Option value='Puan'>Puan</Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Gender"
                                                                        name="gender"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: 'Please choose Identification Type',
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Select
                                                                            placeholder="Gender"
                                                                            /*style={{width: '300px'}}*/
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            showSearch
                                                                            optionFilterProp="children">
                                                                            <Option value='Male'>Male</Option>
                                                                            <Option value='Female'>Female</Option>
                                                                            <Option value='Other'>Other</Option>
                                                                        </Select>
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        label="Identification Type"
                                                                        name="identificationtype"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: 'Please choose Identification Type',
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Select
                                                                            placeholder="Identification Type"
                                                                            /*style={{width: '300px'}}*/
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            showSearch
                                                                            optionFilterProp="children">
                                                                            <Option value='Identification Card'>Identification Card</Option>
                                                                            <Option value='Passport'>Passport</Option>
                                                                            <Option value='Permanent Resident'>Permanent Resident</Option>
                                                                            <Option value='Working Permit'>Working Permit</Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Identification No."
                                                                        name="identificationno"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: 'Please enter Address',
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Input placeholder="Identification No." />
                                                                    </Form.Item>

                                                                    {bookingType === 'client' ?
                                                                    <>
                                                                        <Form.Item
                                                                            label={`${bookingType} Contact Person`}
                                                                            name="contactPerson"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            // rules={[
                                                                            //     {
                                                                            //         required: true,
                                                                            //         message: 'Please enter Contact Person Name',
                                                                            //     },
                                                                            // ]}
                                                                        >
                                                                            <Input placeholder="Contact Person" />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                                    }



                                                                </Col>
                                                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                                                                    <Form.Item
                                                                        label="Address"
                                                                        name="line"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: 'Please enter Address',
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Input placeholder="Address" />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="City"
                                                                        name="city"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: 'Please enter City',
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Input placeholder="City" />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="Postcode"
                                                                        name="postcode"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: 'Please enterPostcode',
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Input placeholder="Postcode" />
                                                                    </Form.Item>

                                                                    <Form.Item

                                                                        label='Country'
                                                                        name="country"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: `Please choose Country`,
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Select
                                                                            onChange={getState}
                                                                            placeholder="Country"
                                                                            /*style={{width: '300px'}}*/
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            showSearch
                                                                            optionFilterProp="children">
                                                                            {countryList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label='State'
                                                                        name="state"
                                                                        labelCol={{ span: labelCol }}
                                                                        wrapperCol={{ span: wrapperCol }}
                                                                        // rules={[
                                                                        //     {
                                                                        //         required: true,
                                                                        //         message: `Please choose State`,
                                                                        //     },
                                                                        // ]}
                                                                    >
                                                                        <Select
                                                                            placeholder="State"
                                                                            /*style={{width: '300px'}}*/
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            showSearch
                                                                            optionFilterProp="children">
                                                                            {stateList.map(value => <Option key={value} value={value}>{value}</Option>)}
                                                                        </Select>
                                                                    </Form.Item>

                                                                </Col>

                                                            </Row>
                                                            </>
                                                        )
                                                    case 2:
                                                        return (
                                                            <>
                                                                <Row>
                                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{paddingTop: '0px', paddingRight: '20px'}}>
                                                                    {/* <Divider plain>Amount</Divider> */}
                                                                        <Descriptions column={1} layout={'horizontal'} bordered size='small'>
                                                                            <Descriptions.Item label="Subtotal"><b>{formatter.format(calculated.price)}</b></Descriptions.Item>
                                                                            <Descriptions.Item label="Tax"><b>{formatter.format(calculated.tax)}</b></Descriptions.Item>
                                                                            <Descriptions.Item label="Fee"><b>{formatter.format(calculated.fee)}</b></Descriptions.Item>
                                                                            <Descriptions.Item label="Discount"><b>{formatter.format(calculated.discount)}</b></Descriptions.Item>
                                                                            <Descriptions.Item label="Rounding"><b>{formatter.format(calculated.rounding)}</b></Descriptions.Item>
                                                                            <Descriptions.Item label="TOTAL"><b>{formatter.format(calculated.amount)}</b></Descriptions.Item>
                                                                        </Descriptions>
                                                                        <br />
                                                                        <Form.Item
                                                                            name="requests"
                                                                            label="Request"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            // hasFeedback
                                                                            // rules={[
                                                                            //     {
                                                                            //         required: true,
                                                                            //         message: 'Please enter Customer Background',
                                                                            //     },
                                                                            // ]}
                                                                        >
                                                                            <TextArea placeholder="Request (if any)" rows={5}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                                    {/* <Divider plain>Pay</Divider> */}
                                                                        <Form.Item
                                                                            name="method"
                                                                            label="Payment Method"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                        >
                                                                            <Select allowClear placeholder="Payment Method" onChange={onPayment}>
                                                                                <Option value="Cash">Cash</Option>
                                                                                <Option value="Agoda">Agoda</Option>
                                                                                <Option value="Alipay">Alipay</Option>
                                                                                <Option value="Amex">Amex</Option>
                                                                                <Option value="Bank Transfer">Bank Transfer</Option>
                                                                                <Option value="Booking.com">Booking.com</Option>
                                                                                <Option value="Cheque">Cheque</Option>
                                                                                <Option value="Credit Card">Credit Card</Option>
                                                                                <Option value="Debit Card">Debit Card</Option>
                                                                                <Option value="EPass">EPass</Option>
                                                                                <Option value="Expedia">Expedia</Option>
                                                                                <Option value="Master Card">Master Card</Option>
                                                                                <Option value="Online Banking">Online Banking</Option>
                                                                                <Option value="Paypal">Paypal</Option>
                                                                                <Option value="Ticket.com">Ticket.com</Option>
                                                                                <Option value="TouchnGo eWallet">TouchnGo eWallet</Option>
                                                                                <Option value="Traveloka">Traveloka</Option>
                                                                                <Option value="Visa">Visa</Option>
                                                                                <Option value="WeChat Pay">WeChat Pay</Option>
                                                                                <Option value="Maybank QRPay">Maybank QRPay</Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Amount"
                                                                            name="amount"
                                                                            rules={[
                                                                                {
                                                                                    required: isPaying,
                                                                                    message: 'Please enter Amount',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumber disabled={!isPaying} style={{width: '50%'}}  min={0.05} max={10000000000}
                                                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Card / Doc No."
                                                                            name="cardNo"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: isCard,
                                                                                    message: 'Please enter Card / Doc No.',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input disabled={!isCard} placeholder="Card / Doc No." />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Expiry Date (mm/yy)"
                                                                            name="expiry"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: isCard,
                                                                                    message: 'Please enter Expiry Date',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input  disabled={!isCard} placeholder="Expiry Date" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Slip No."
                                                                            name="slipNo"
                                                                            labelCol={{ span: labelCol }}
                                                                            wrapperCol={{ span: wrapperCol }}
                                                                            rules={[
                                                                                {
                                                                                    required: (isCash === false && isPaying === true),
                                                                                    message: 'Please enter Slip No.',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input disabled={!(isCash === false && isPaying === true)} placeholder="Slip No." />
                                                                        </Form.Item>
                                                                    </Col>

                                                                </Row>
                                                            </>
                                                        )
                                                    default:
                                                        return (
                                                            <div></div>
                                                        )
                                                }
                                            })()}
                                        </div>

                                        <div className="form-footer" style={{paddingRight: '40px', textAlign: 'right'}}>
                                            {current > 0 && (
                                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}  loading={loading}>
                                                <StepBackwardOutlined /> Previous
                                            </Button>
                                            )}
                                            {current < steps.length - 1 && (
                                            <Button type="primary" onClick={() => next()}  loading={loading}>
                                                <StepForwardOutlined /> Next
                                            </Button>
                                            )}
                                            {current === steps.length - 1 && (
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="Are you sure to Create this booking?"
                                                    onConfirm={booknow}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button type="danger" loading={loading}>
                                                        <DollarCircleOutlined  /> Book Now!
                                                    </Button>
                                                </Popconfirm>
                                            )}

                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </>
                        }

                    </InfiniteScroll>

                    </div>
                </Form>
            </Content>
            </Spin>
        </Layout>


        </>
    );
}

export default Booking
