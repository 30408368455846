import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;


function BlockRoom(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [disabled, setDisabled] = useState(false);


    useEffect(() => {

        //loadData();

        return () => {

        }
    }, [])

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const setData = () => {


        /*let obj = props.obj;

        console.log('pund', props.obj)
        if(obj.roomNo !== null && obj.roomNo !== undefined && obj.roomNo !== '')
        {
            let d = obj.roomNo.split(',');

            obj.roomNox = d;
        }
        else
        {
            obj.roomNox = [];
        }

        form.setFieldsValue(obj);

        setAvailCount(props.getAllo(obj.itemID));

        setIsBackDate(props.isBackDate);*/
    }

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {
            const values = form.getFieldsValue(true);
            let propsObj = JSON.parse(localStorage.getItem('temproomdetails'));
            let obj = JSON.parse(localStorage.getItem('currentproperty'));
            let userid = localStorage.getItem('id');
            let username = localStorage.getItem('name');

            let e = moment(values.dt[1]).format('YYYY-MM-DD');
            let s = moment(values.dt[0]).format('YYYY-MM-DD');

            let room = {

                hotel: parseInt(obj.id),
                room: propsObj.id,
                start: s,
                end: e,
                data: {
                category: values.category.toLowerCase(),
                    reason: values.remarks,
                    unitNo: propsObj.roomNo,
                    checkedInBy: {
                    id: userid,
                    name: username
                    }
                }
            };


            let a = await post(`/api/allocations`,room)
            //console.log(a,'/api/allocations',room,response)
            if (response.ok)
            {
                successN('Unit Blocked successfully');
                //props.loadData();
                props.setVisible(false);
            }
            else
            {
                errorN(`Unit Blocked - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Unit Blocked: ${err}. Please contact the administrator.`);
        }

    }

    const handleChange = () => {

        // if(props.isBackDate !== true)
        // {
           /* const values = form.getFieldsValue(true);
            console.log('why me', values.roomNox)
            let x = '';

            for(let i of values.roomNox)
            {
                console.log('why me x', i)
                if(x === '')
                {
                    x = i;
                }
                else
                {
                    x = x + ',' + i;
                }
            }

            values.roomNo = x;

            props.reloadMe(values);

            let obj = props.obj;
            setAvailCount(props.getAllo(obj.itemID));*/
        // }

    }

    async function loadData() {
        try
        {
            let propsObj = props.obj;
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));

            let globalRoomList = JSON.parse(localStorage.getItem('roomlist'));

            let s = '';
            let e = '';
            let parameter = '';

            //s = moment.utc(propsObj.start).format('YYYY-MM-DDTHH:mm:ss[Z]');
            //e = moment.utc(propsObj.end).format('YYYY-MM-DDTHH:mm:ss[Z]');

            s = propsObj.start;
            e = propsObj.end;

            parameter = `?start=${s}&end=${e}`


            let roomList = await get(`/api/hotels/${globalObj.id}/availability_rooms${parameter}`)
            if (response.ok)
            {
                let table1 =[];
                let str = '';
                for(let i of roomList)
                {
                    if(propsObj.itemid === i.item)
                    {
                        for(let j of i.rooms)
                        {
                            for(let k of globalRoomList)
                            {
                                if(j.id === k.id)
                                {
                                    if (k.occupancy == "vacant" && k.cleanliness == "clean")
                                    {
                                        str = "Available";
                                    }
                                    else if (k.occupancy == "vacant" && k.cleanliness == "dirty")
                                    {
                                        str = "Available-Dirty";
                                    }
                                    else if (k.occupancy == "vacant" && k.cleanliness == "cleaning")
                                    {
                                        str = "Available-Cleaning";
                                    }
                                    else if (k.occupancy == "occupied" && k.cleanliness == "clean")
                                    {
                                        str = "Occupied";
                                    }
                                    else if (k.occupancy == "occupied" && k.cleanliness == "dirty")
                                    {
                                        str = "Occupied-Dirty";
                                    }
                                    else if (k.occupancy == "occupied" && k.cleanliness == "cleaning")
                                    {
                                        str = "Occupied-Cleaning";
                                    }
                                    else if (k.occupancy == "reserved" && k.cleanliness == "clean")
                                    {
                                        str = "Reserved";
                                    }
                                    else if (k.occupancy == "reserved" && k.cleanliness == "dirty")
                                    {
                                        str = "Reserved-Dirty";
                                    }
                                    else if (k.occupancy == "reserved" && k.cleanliness == "cleaning")
                                    {
                                        str = "Reserved-Cleaning";
                                    }
                                    else if (k.occupancy == "blocked")
                                    {
                                        str = "Blocked";
                                    }

                                    table1.push({id: j.id, no: j.data.no + ' : ' + str})
                                }
                            }
                        }
                    }
                }
                setAvailCount(table1);

                let obj = props.obj;
                form.setFieldsValue(obj);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error LA: ${err}. Please contact the administrator.`);
        }
    }

    async function loadAvailability(){

    }
    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                        <Form.Item
                            label="Start - End"
                            name="dt"
                            rules={[{ required: true, message: 'Please choose Dates' }]}
                        >
                            <RangePicker onChange={loadAvailability} />
                        </Form.Item>

                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose Category',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Category"
                                /*style={{width: '300px'}}*/
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                optionFilterProp="children">
                                <Option value='Maintenance'>Maintenance</Option>
                                <Option value='Others'>Others</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>

                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            // hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Remarks',
                                },
                            ]}
                        >
                            <TextArea placeholder="Remarks" rows={5}/>
                        </Form.Item>

                        <br />
                        <Button type="danger" onClick={() => form.submit()}  loading={loading} disabled={disabled}>
                            <StepForwardOutlined /> Confirm
                        </Button>
                    </Col>
                </Row>

            </Form>

        </>
    );
}

export default BlockRoom
