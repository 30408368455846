import React, {useState,useEffect,useContext,useCallback} from 'react'

import {
    Typography,
    DatePicker,
    Select,
    Radio,
    Divider,
    Spin,
    Avatar,
    Menu,
    Dropdown,
    Form,
    Card,
    Drawer,
    Layout,
    Tabs,
    Button,
    Input,
    Space,
    Row,
    Col,
    Popconfirm
} from "antd";
import InfiniteScroll from 'react-infinite-scroller';
import Highlighter from 'react-highlight-words';
import {errorN, successN} from "../../general";
import { A,navigate} from "hookrouter";
import useFetch from "use-http";
import {
    BellOutlined,
    ProfileOutlined,
    SolutionOutlined,
    PhoneOutlined,
    HomeOutlined,
    ControlOutlined,
    DollarOutlined,
    ContainerOutlined,
    MinusSquareOutlined,
    BorderOutlined,
    RightCircleOutlined,
    SearchOutlined,
    FileExcelOutlined, GlobalOutlined, CloseCircleOutlined,CaretRightOutlined,EditOutlined


} from '@ant-design/icons'

import routes from "../../router"
import Store, {Context} from "../../store"
import moment from 'moment'
import FModal from "../Bookings/modal";

import { Table } from "ant-table-extensions";

const {Sider, Content,Footer } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const formatter = new Intl.NumberFormat('en-MY', {
    //style: 'currency',
    //currency: 'MYR',
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

function OTAModified(props) {
    const [state,dispatch] = useContext(Context)
    const { get, post, response, loading, error, del, put } = useFetch()
    const [drawOpen, setDrawOpen] = useState(false);

    const [form] = Form.useForm()
    const [formLayout, setFormLayout] = useState('vertical');
    const handleFinish = useCallback(() => {
        console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);

    }, [form])

    const [dateRange, setDateRange] = useState(false);
    const [keyword, setKeyword] = useState(false);
    const [arrivalDate, setArrivalDate] = useState(false);
    const [arrivalDateRange, setArivalDateRange] = useState(false);

    const [data, setData] = useState([]);

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');

    const [sourceFilters,setSourceFilters] = useState([]);
    const [statusFilters,setStatusFilters] = useState([]);
    const [agencyFilters,setAgencyFilters] = useState([]);
    const [nationalityFilters,setNationalityFilters] = useState([]);

    const labelCol = 22;
    const wrapperCol = 22;

    async function removeDuplicates(data) {
        // let unique = data.reduce(function(a,b){
        //   if(a.indexOf(b) < 0) a.push(b);
        //   return a;
        // },[]);

        const unique = data.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === data.findIndex(obj => {
              return JSON.stringify(obj) === _thing;
            });
          });

        console.log('unique',unique)
        return unique;
      };

    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={node => {
                    searchInput=node;
                }}
                placeholder={`Search ${caption}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.select(), 100);
        }
    },
    render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
});

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
            setsearchText(selectedKeys[0]);
            setsearchedColumn(dataIndex);
       /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };

    async function loadData() {
        form.validateFields().then(async values => {

            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            try
            {
                let folioList = await get(`/api/hotels/${obj.id}/folio/channel`)
                if (response.ok)
                {
                    console.log("folioList", folioList);

                    let table1 =[];
                    //let table2 =[];

                    let source =[];
                    let status =[];
                    let agency =[];
                    let nationality =[];

                    for(let i of folioList)
                    {
                        /*if(i.payments !== null && i.payments !== undefined)
                        for(let x of i.payments)
                        {
                            table2.push({
                                id:x.id,
                                billNo:"",
                                name:"",
                                no:x.no,
                                date:moment(x.date).format('DD-MM-YYYY'),
                                status: "",
                                amount:x.amount,
                                method:x.data.method,
                                type:x.type,
                                by:x.data.by != null ? x.data.by.name : "",
                                bookingid:i.id
                            })
                        }*/

                        table1.push({
                            id:i.id,
                            date: moment(i.created).format('DD-MM-YYYY'),
                            no:i.no,
                            name:i.data.payor.name,
                            status:i.status,
                            source:i.source,
                            otaagency:i.data.payor.otaagency.name,
                            refno:i.data.rcode, //payor.refno,
                            start:i.data.stay.arrival,
                            end:i.data.stay.departure,
                            by:i.data.by != null ? i.data.by.name : "",
                            amount:i.amount != null ? i.amount : "0.00",
                            paid:i.paid != null ? i.paid : "0.00",
                            balance: i.amount != null ? (i.amount - i.paid) : "0.00",
                            tel:i.data.payor.tel,
                            email:i.data.payor.email,
                            nationality: i.data.payor.nationality.name,
                            cancelNote:i.data.cancelledBy != null ? (i.data.cancelledBy.reason != null ? i.data.cancelledBy.reason : "") : "",
                            cancelBy:i.data.cancelledBy != null ? (i.data.cancelledBy.name != null ? i.data.cancelledBy.name : "") : "",
                            folinvno:  i.invoiceno,
                            folio:i.folio,
                            modifiedRequest: i.data.modifiedRequest !== null ? i.data.modifiedRequest === 'm' ? 'MODIFIED' : 'CANCELLED' : 'NEED ATTENTION'

                        })

                        source.push({text:i.source,value:i.source});
                        status.push({text:i.status,value:i.status});
                        agency.push({text:i.data.payor.otaagency.name,value:i.data.payor.otaagency.name});
                        nationality.push({text:i.data.payor.nationality.name,value:i.data.payor.nationality.name});


                    }

                    let so = await removeDuplicates(source);
                    let st = await removeDuplicates(status);
                    let ag = await removeDuplicates(agency);
                    let nt= await removeDuplicates(nationality);

                    //console.log("so ans so", so)

                    setData(table1);
                    setSourceFilters(so);
                    setStatusFilters(st);
                    setAgencyFilters(ag);
                    setNationalityFilters(nt);
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                    errorN(`Load Data - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            catch(err)
            {
                errorN(`Error Load Data: ${err}. Please contact the administrator.`);
            }

        })
    }

    useEffect(() => {


        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {
            loadData();
        }

        //loadData().then(r => {});


        return () => {

        }
    },[])

    const onChange = (e) => {
        console.log('type', e.target.value)

        form.setFieldsValue({typex: e.target.value});

        setDateRange(false);
        setKeyword(false);
        setArrivalDate(false);
        setArivalDateRange(false);

        if(e.target.value === 'Date Range')
        {
            setDateRange(true);
        }

        if(e.target.value === 'Keyword')
        {
            setKeyword(true);
        }

        if(e.target.value === 'Arrival Date')
        {
            setArrivalDate(true);
        }

        if(e.target.value === 'Arrival Date Range')
        {
            setArivalDateRange(true);
        }

    }

    const columns = [

        {
            width: '100px',
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Are you sure want to proceed this action?`}
                        onConfirm={() => acknowledge(record.id)}
                        okText="Yes"
                        cancelText="No">
                         <a>Acknowledge Modification</a>
                    </Popconfirm>

                </>
            )
        },
        {
            width: '60px',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <FModal formname={'update'} obj={{...record, folioid: record.id}} loadData={loadData} />

                </>
            )
        },
        {
            width: '100px',
            title:"OTA Request ",
            dataIndex:"modifiedRequest",
            key:"modifiedRequest",
            sorter: (a, b) => a.modifiedRequest.localeCompare(b.modifiedRequest),
        },
        {
            width: '100px',
            title:"Date",
            dataIndex:"date",
            key:"date",
            sorter: (a, b) => a.date.localeCompare(b.date),
        },
        {
            width: '100px',
            title:"No.",
            dataIndex:"no",
            key:"no",
            sorter: (a, b) => a.no.localeCompare(b.no),
        },
        {
            width: '200px',
            title:"Name",
            dataIndex:"name",
            key:"name",
            ...getColumnSearchProps('name','Name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            width: '100px',
            title:"Status",
            dataIndex:"status",
            key:"status",
            filters: statusFilters,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            width: '100px',
            title:"Invoice No.",
            dataIndex:"folinvno",
            key:"folinvno",
            ...getColumnSearchProps('folinvno','Invoice No.'),
            sorter: (a, b) => a.folinvno.localeCompare(b.folinvno),
        },
        {
            width: '100px',
            title:"Source",
            dataIndex:"source",
            key:"source",
            filters: sourceFilters,
            onFilter: (value, record) => record.source.indexOf(value) === 0,
            sorter: (a, b) => a.source.localeCompare(b.source),
        },
        {
            width: '100px',
            title:"Agency",
            dataIndex:"otaagency",
            key:"otaagency",
            filters: agencyFilters,
            onFilter: (value, record) => record.otaagency.indexOf(value) === 0,
            sorter: (a, b) => a.otaagency.localeCompare(b.otaagency),
        },
        {
            width: '100px',
            title:"Ref. No.",
            dataIndex:"refno",
            key:"refno",
            sorter: (a, b) => a.refno.localeCompare(b.refno),
        },
        {
            width: '100px',
            title:"Arrival",
            dataIndex:"start",
            key:"start",
            sorter: (a, b) => a.start.localeCompare(b.start),
        },
        {
            width: '100px',
            title:"Departure",
            dataIndex:"end",
            key:"end",
            sorter: (a, b) => a.end.localeCompare(b.end),
        },
        {
            width: '200px',
            title:"By",
            dataIndex:"by",
            key:"by",
            sorter: (a, b) => a.by.localeCompare(b.by),
        },
        {
            width: '100px',
            title:"Amount",
            dataIndex:"amount",
            key:"amount",
            sorter: (a, b) => a.amount.localeCompare(b.amount),
        },
        {
            width: '100px',
            title:"Received",
            dataIndex:"paid",
            key:"paid",
            sorter: (a, b) => a.paid.localeCompare(b.paid),
        },
        {
            width: '100px',
            title:"Balance",
            dataIndex:"balance",
            key:"balance",
            sorter: (a, b) => a.balance.localeCompare(b.balance),
        },
        {
            width: '150px',
            title:"Contact No.",
            dataIndex:"tel",
            key:"tel",
            sorter: (a, b) => a.tel.localeCompare(b.tel),
        },
        {
            width: '300px',
            title:"Email",
            dataIndex:"email",
            key:"email",
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            width: '100px',
            title:"Nationality",
            dataIndex:"nationality",
            key:"nationality",
            filters: nationalityFilters,
            onFilter: (value, record) => record.nationality.indexOf(value) === 0,
            sorter: (a, b) => a.nationality.localeCompare(b.nationality),
        },
        {
            width: '300px',
            title:"Cancel Reason",
            dataIndex:"cancelNote",
            key:"cancelNote",
            sorter: (a, b) => a.cancelNote.localeCompare(b.cancelNote),
        },
        {
            width: '200px',
            title:"Cancel By",
            dataIndex:"cancelBy",
            key:"cancelBy",
            sorter: (a, b) => a.cancelBy.localeCompare(b.cancelBy),
        }
    ]

    async function acknowledge(folioID){
        try
        {
            let b = await post(`/api/folios/${folioID}/channel`)
            if (response.ok)
            {
                successN('Acknowledge Modification Successful');
                loadData();
            }
            else
            {
                errorN(`Create Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Acknowledge: ${err}. Please contact the administrator.`);
        }
    }



    return (
        <>
        {/*<div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        &nbsp;&nbsp;<GlobalOutlined />
        &nbsp;&nbsp;OTA Modified Entry
        </div>  */}
        <Layout>

            <Spin spinning={loading}>
            <Content style={{backgroundColor: 'white'}}>
                <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                    <div style={{backgroundColor: 'white', padding: '20px', margin: '0px 0',height:'73vh',overflow:'auto'}}>
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={true || false}
                        useWindow={false}
                    >


                    <div rowKey="id" style={{backgroundColor: 'white', paddingRight: '20px',paddingLeft: '20px',paddingBottom: '20px', marginRight: '20px'}}>
                        <Table
                            defaultExpandAllRows={true}
                            columns={columns}
                            dataSource={data}
                            exportable
                            exportableProps={{ showColumnPicker: true, fileName: "OTA Modified Entry",btnProps: {
                                    type: "primary",
                                    icon: <FileExcelOutlined />,
                                    /*children: <span>Export</span>,*/
                                } }}
                            searchable
                            searchableProps={{
                                // dataSource,
                                // setDataSource: setSearchDataSource,
                                inputProps: {
                                    placeholder: "Search this table...",
                                    prefix: <SearchOutlined />,
                                },
                            }}
                            //expandable={{expandedRowRender}}
                            bordered
                            size="small"
                            //expandRowByClick={false}
                            scroll={{ x: 1500,y: 360 }}
                            pagination={false}

                            /*footer={pageData => {
                                let totalAmount = 0;
                                let totalReceived = 0;
                                let totalBalance = 0;

                                pageData.forEach(({ amount, paid, balance }) => {
                                    totalAmount += parseFloat(amount);
                                    totalReceived += parseFloat(paid);
                                    totalBalance += parseFloat(balance);
                                });

                                return (
                                  <>
                                    <Text><b>Total Amount: {formatter.format(totalAmount)}</b></Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text><b>Total Received: {formatter.format(totalReceived)}</b></Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text type="danger"><b>Total Balance: {formatter.format(totalBalance)}</b></Text>

                                  </>
                                );
                              }}*/

                        />
                    </div>

                    </InfiniteScroll>
                    </div>
                </Form>
            </Content>
            </Spin>
        </Layout>
        {/* <div style={{ textAlign: 'center', fontSize: '14px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        {`©${state.year} Managed by Ratri Cloud`}
        </div>   */}

        </>
    );
}

export default OTAModified
