import React, { useEffect, useMemo, useRef, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {errorN} from "../../general";
import useFetch from "use-http";
import {FileOutlined} from "@ant-design/icons";
import {Button, Menu} from "antd";
import {A} from "hookrouter";
import moment from 'moment'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    Courier: {
        normal: 'Courier',
        bold: 'Courier-Bold',
        italics: 'Courier-Oblique',
        bolditalics: 'Courier-BoldOblique'
    },
    Helvetica: {
        normal: 'Helvetica',
        bold: 'Helvetica-Bold',
        italics: 'Helvetica-Oblique',
        bolditalics: 'Helvetica-BoldOblique'
    },
    Times: {
        normal: 'Times-Roman',
        bold: 'Times-Bold',
        italics: 'Times-Italic',
        bolditalics: 'Times-BoldItalic'
    },
    Symbol: {
        normal: 'Symbol'
    },
    ZapfDingbats: {
        normal: 'ZapfDingbats'
    }
};

export function ShowBooking(props) {
    const { get, post, response, loading, error, del, put } = useFetch();

    let formatter = new Intl.NumberFormat('en-MY', {
        style: 'decimal'/*,
        currency: 'MYR',*/

    });

    let formatterNum = new Intl.NumberFormat('en-MY', {

    });

    useEffect(() => {
        return () => {

        }
    }, [])


    async function showBooking(){
        try {

            let propsObj = props.obj !== undefined ? props.obj('showbooking') : {bookingid: props.id};
            let currCode = localStorage.getItem('currencycode');
            let notice = localStorage.getItem('folionotice');
            let footer = localStorage.getItem('foliofooter');
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = JSON.parse(localStorage.getItem('roomlist'));

            notice = notice.replaceAll('\"', '');
            notice = notice.replaceAll('\\r', '');
            notice = notice.replaceAll('\\n', '\n');

            if(propsObj.bookingid === '' || propsObj.bookingid === null || propsObj.bookingid === undefined)
            {
                errorN('ID Missing. Please contact the system administrator.');
                return;
            }

            let report = {};
            let cust = {};
            let bodyData = [];
            let bodyDataFee = [];
            let bodyDataTax = [];
            let bodyDataAllo = [];
            let subtotal = 0;
            let discount = 0;
            let dep = 0;
            let pay = 0;
            let bal = 0;
            let tax = 0;
            let fee = 0;

            let isCancelled = '';

            let objfee ={};
            let objtax = {};
            let objfeeline = {};
            let objtaxline = {};

            let custadds = '';

            let hotelObj = await get(`/api/hotels/${globalObj.id}`)
            if (response.ok)
            {
                if(hotelObj.data === null || hotelObj.data === undefined)
                {
                    errorN('Important Data Missing. Please fill in Property Information to proceed.');
                    return;
                }

                report.lblCompany = hotelObj.data.name;
                report.lblAddress = hotelObj.data.address != null ? hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country : undefined;
                report.lblWebsite = hotelObj.data.website;
                report.lblTel = hotelObj.data.tel;
                report.lblEmail = hotelObj.data.email;
            }
            else
            {
                errorN(`Get Hotel Info - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let billObj = await get(`/api/bookings/${propsObj.bookingid}`)
            if (response.ok)
            {
                if(billObj === null || billObj === undefined || billObj === {})
                {
                    errorN('Requested document does not exist');
                    return;
                }

                if(billObj.status === 'Cancelled')
                    isCancelled = '- (CANCELLED)'

                report.lblTTX = billObj.data.company != null ? billObj.data.company.company.ttxNo : undefined;
                report.lblSST = billObj.data.company != null ? billObj.data.company.company.sstNo : undefined;

                cust = billObj.data.payor;

                if(cust.address !== undefined && cust.address !== null)
                {
                    //hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country
                    if(cust.address.line !== undefined && cust.address.line !== null && cust.address.line !== '')
                    {
                        custadds = custadds + cust.address.line;
                    }

                    if(cust.address.postcode !== undefined && cust.address.postcode !== null && cust.address.postcode !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.postcode;
                    }

                    if(cust.address.city !== undefined && cust.address.city !== null && cust.address.city !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.city;
                    }

                    if(cust.address.state !== undefined && cust.address.state !== null && cust.address.state !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.state;
                    }

                    if(cust.address.country !== undefined && cust.address.country !== null && cust.address.country !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.country;
                    }

                }

                if (billObj.breakdown.discount.length > 0)
                {
                    for (let d of billObj.breakdown.discount)
                    {
                        discount = discount + d.rate;
                    }
                }

                let sourceData = billObj.breakdown.fee;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataFee.push(dataRow);

                    fee = fee + sourceRow.rate;
                });


                sourceData = billObj.breakdown.tax;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataTax.push(dataRow);

                    tax = tax + sourceRow.rate;
                });

                if(tax === 0 && report.lblSST !== undefined)
                {
                    let dataRow = ['','',{font: 'Courier', text: 'SST (0%)'},'',{font: 'Courier', text: '0.00',alignment: 'right'}];
                    bodyDataTax.push(dataRow);
                }

                bal =  ((billObj.amount ?? 0) - (billObj.paid ?? 0)).toFixed(2);

                if (billObj.data.company !== null && billObj.data.company !== undefined)
                    report.lblManagedBy = 'Managed By: ' + billObj.data.company.name + ' (' + billObj.data.company.identificationNo + ')';

                if(fee > 0)
                {
                    objfee = {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    };
                    objfeeline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }

                if(tax > 0 || (tax === 0 && report.lblSST !== undefined))
                {
                    objtax = {
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    }
                    objtaxline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }
            }
            else
            {
                errorN(`Get Booking - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let OrderItems = await get(`/api/bookings/${propsObj.bookingid}/lines`)
            if (response.ok)
            {

                let sourceData = OrderItems.sort((a,b) => a.data.item.name - b.data.item.name);

                let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Description`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Qty`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'right'},
                ];
                bodyData.push(dataRowx)

                let i = 1;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = [{font: 'Courier', text: i,alignment: 'center'},{font: 'Courier', text: moment(sourceRow.date).format('DD-MM-YYYY'),alignment: 'center'},{font: 'Courier', text: sourceRow.data.item.name},{font: 'Courier', text: sourceRow.unit,alignment: 'center'},{font: 'Courier', text: sourceRow.breakdown.price.toFixed(2),alignment: 'right'}];
                    bodyData.push(dataRow);

                    subtotal = subtotal + sourceRow.breakdown.price;
                    i++;
                });
            }
            else
            {
                errorN(`Get BookingLines - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

/*            let allocationListObj = await get(`/api/hotels/${globalObj.id}/allocations?folio=${propsObj.folioid}`)
            if (response.ok)
            {
                if (allocationListObj.length > 0)
                {
                    let roomNo = 'Not Assigned';
                    let lst = [];
                    let i = 1;

                    let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Unit No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `In`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Out`,alignment: 'center'},
                    ];
                    bodyDataAllo.push(dataRowx);


                    for (let valued of allocationListObj)
                    {
                        for (let x of roomList)
                        {
                            if (x.id === valued.room)
                            {
                                roomNo = x.data.no;
                            }
                        }

                        lst.push(roomNo);

                        if (valued.status === "Blocked")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.start).format('DD-MM-YYYY'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);
                        }
                        else if (valued.status == "Occupied")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);

                        }
                        else if (valued.status == "Closed")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkout).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'}]);
                        }

                        roomNo = "Not Assigned";
                        i++;
                    }
                }
            }
            else
            {
                errorN(`Get Allocations - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }*/

            let a = window.location.origin;
            let logostr = a + '/images/square-xxl.png';
            let logoList = await get(`/api/files/?hotel=${globalObj.id}`)
            if (response.ok)
            {
                let lid = '';
                if(logoList.length > 0)
                {
                    for(let i of logoList)
                    {
                        if(i.data !== null && i.data !== undefined)
                        {
                            if(i.data.name.substring(0, 4) === 'logo')
                            {
                                lid = i.id;
                            }
                        }
                    }
                }

                if(lid !== '')
                {
                    let a = window.location.origin;
                    logostr = a + `/api/files/${lid}?action=download`;
                }
            }
            else
            {
                errorN(`Get Logo - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let receiptListObj = await get(`/api/bookings/${propsObj.bookingid}/payments`)
            if (response.ok)
            {
                if (receiptListObj.length > 0)
                {
                    for (let ix of receiptListObj)
                    {
                        if (ix.data.paymentFor === "Deposit")
                        {
                            if (ix.type === "receipt")
                            {
                                dep = dep +  parseFloat(ix.amount);
                            }
                            else
                            {
                                dep = dep - parseFloat(ix.amount);
                            }

                        }
                        else
                        {
                            if (ix.type === "receipt")
                            {
                                pay = pay + parseFloat(ix.amount);
                            }
                            else
                            {
                                pay = pay - parseFloat(ix.amount);
                            }
                        }
                    }
                }

                pay = pay.toFixed(2);
                dep = dep.toFixed(2);
            }
            else
            {
                errorN(`Get Payment - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let d = new Date();
            let definition = {
                pageSize: 'A4',
                pageOrientation: 'portrait',
                pageMargins: [50, 100, 50, 50],
                header: function (page, pages) {
                    return {
                        table: {
                            widths: ['60%','40%'],
                            body: [
                                [
                                    [
                                        {text: `BOOKING ${isCancelled}`,style: 'superheader',alignment: 'left', color: `${billObj.status !== 'Cancelled' ? 'black' : 'red'}`},
                                        {text: `\n${report.lblCompany}`,style: 'header',alignment: 'left'},
                                        {text: `\n${report.lblAddress}`,style: 'normal',alignment: 'left'},


                                    ],
                                    {alignment: 'right', image: 'mypic', width: 65, height: 65,},
                                ]

                            ],
                        },
                        layout: 'noBorders',
                        margin: [50, 30, 50, 5],
                    }
                },
                footer: function (page, pages) {
                    return [
                        /*{ text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'center' },*/

                        [
                            {
                                text: {fontSize: 7,bold: true,font: 'Helvetica', text: report.lblManagedBy, alignment: 'center'},
                                margin: [0, 0, 0, 0]
                            },
                            {
                                table: {
                                    widths: ['17%','17%','17%','17%','17%','15%'],
                                    body: [
                                        [
                                            {fontSize: 7, alignment: 'center', text: `Tel: ${report.lblTel}`},
                                            {fontSize: 7, alignment: 'center', text: `Email: ${report.lblEmail}`},
                                            {fontSize: 7, alignment: 'center', text: `Website: ${report.lblWebsite}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblSST !== undefined ? 'SST No.: ' + report.lblSST : ''}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblTTX !== undefined ? 'TTX No.: ' + report.lblTTX : ''}`},
                                            {text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'right' },
                                        ]
                                    ]
                                },
                                layout: 'noBorders',
                                margin: [50, 10, 50, 20],
                            }
                        ]


                    ]


                },
                info: {
                    title: `Booking - ${billObj.no}`,
                    author: 'K.Nivagaran',
                    subject: 'create booking view',
                    keywords: '',
                },
                content: [
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        style: 'tableExample2',
                        table: {
                            widths: ['70%','13%','17%'],
                            body: [
                                [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Billed To`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Booking No.`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${billObj.no}`}
                                ],
                                [
                                    '',
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date of Issue`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${moment(billObj.created).format('DD-MM-YYYY')}`}
                                ],
                                [
                                    {bold: true,style: 'normal', text: `${cust.name.toUpperCase()}`},
                                    '',
                                    ''
                                ],
                                [
                                    /*{style: 'normal', text: `${cust.address.line + ', ' + cust.address.postcode + ', ' + cust.address.city + ', ' + cust.address.state + ', ' + cust.address.country}`},*/
                                    {style: 'normal', text: `${custadds}`},
                                    '',
                                    ''
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['15%','55%','13%','17%'],
                            body: [
                                [
                                    {bold: true,style: 'normal', text: `Identification No.`},
                                    {style: 'normal', text: `:  ${cust.identification.id !== null && cust.identification.id !== undefined ? cust.identification.id : ''}`},
                                    {bold: true,style: 'normal', text: `Source`},
                                    {style: 'normal', text: `:  ${billObj.source === 'walkin' ? 'Walk In' : billObj.data.payor.otaagency.name}`},
                                ],
                                [
                                    {bold: true,style: 'normal', text: `Tel No.`},
                                    {style: 'normal', text: `:  ${cust.tel !== null && cust.tel !== undefined ? cust.tel : ''}`},
                                    '',
                                    {style: 'normal', text: `${billObj.source === 'client' || billObj.source === 'agency' ? ':  ' + billObj.data.bcode !== null ? (billObj.data.bcode + "/" + billObj.data.rcode) : billObj.data.rcode : ''}`},

                                ],
                                [
                                    {bold: true,style: 'normal', text: `Nationality`},
                                    {style: 'normal', text: `:  ${cust.nationality !== null && cust.nationality !== undefined  ? cust.nationality.name : ''}`},
                                    {bold: true,style: 'normal', text: `Issued By`},
                                    {style: 'normal', text: `:  ${billObj.data.by.name.toUpperCase()}`},
                                ],
                                [
                                    {bold: true,style: 'normal', text: `Company`},
                                    {style: 'normal', text: `:  ${cust.guestCompany !== null && cust.guestCompany !== undefined  ? cust.guestCompany : ''}`},
                                    {bold: true,style: 'normal', text: ``},
                                    {style: 'normal', text: `  `},
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Services`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyData
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 5, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Discount`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${discount.toFixed(2)}`,alignment: 'right'}
                                            ],
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Subtotal`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${(subtotal-discount).toFixed(2)}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    objfee,
                    objfeeline,
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    objtax,
                    objtaxline,
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Rounding Adjustment`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${billObj.rounding !== undefined && billObj.rounding !== null ? billObj.rounding : 0.00}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {fontSize: 9,bold: true,font: 'Helvetica', text: `TOTAL`},
                                                '',
                                                {fontSize: 9,bold: true,font: 'Helvetica', text: `${currCode} ${billObj.amount !== undefined && billObj.amount !== null ? billObj.amount : 0.00}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                   /* {
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['10%','30%','30%','30%'],
                                        body: bodyDataAllo
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 5, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },*/
                    {
                        columns:[
                            {
                                width: '38%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Notice to Guest:-\n\n`},
                                    {font: 'Courier',style: 'normal', text: notice},
                                ],
                                margin: [0, 15, 0, 0]
                            },
                            [
                                {
                                    text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Payment Details:-\n\n`},
                                    margin: [0, 15, 0, 0]
                                },
                                {
                                    table: {
                                        widths: ['40%','60%'],
                                        body: [
                                            [
                                                {font: 'Courier',style: 'normal', text: `Payment`},
                                                {font: 'Courier', style: 'normal', text: `${pay}`,alignment: 'right'}
                                            ],
                                            [
                                                {font: 'Courier',style: 'normal', text: `Deposit`},
                                                {font: 'Courier', style: 'normal', text: `${dep}`,alignment: 'right'}
                                            ],
                                            [
                                                {font: 'Courier',style: 'normal', text: `Balance`},
                                                {font: 'Courier', style: 'normal', text: `${bal}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                    margin: [0, 0, 0, 0],
                                    lineHeight: 1.2
                                },
                                /*{
                                    text: [
                                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Acknowledged By\n\n`},
                                        `\n\n`,
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Signature\t : __________________________\n\n`},
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Name\t\t\t: ${cust.name.toUpperCase()}`},
                                    ],
                                    margin: [123, 30, 0, 0]
                                }*/
                            ]


                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        text: {fontSize: 9,bold: true,font: 'Helvetica', text: footer !== 'undefined' && footer !== undefined && footer !== '' && footer !== null  ? footer : 'Thank you for choosing us and please come again!', alignment: 'center'},
                        margin: [0, 5, 0, 0]
                    },

                ],
                styles: {
                    superheader: {
                        font: 'Helvetica',
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',

                    },
                    header: {
                        font: 'Helvetica',
                        fontSize: 12,
                        bold: true,
                        alignment: 'justify'
                    },
                    normal: {
                        fontSize: 8,
                        bold: false,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    normalbold: {
                        fontSize: 8,
                        bold: true,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    bolding: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    tableExample: {
                        fontSize: 8,
                        margin: [0, 5, 0, 0]
                    },
                    tableExample2: {
                        fontSize: 9,
                        margin: [0, 10, 0, 0]
                    },
                    tableExample3: {
                        fontSize: 10,
                        margin: [0, 5, 0, 15]
                    },
                },
                defaultStyle: {
                    font: 'Times',
                    columnGap: 23
                },
                images:{
                    mypic: logostr
                }
            }
            let win = window.open('', '_blank');
            pdfMake.createPdf(definition).open({}, win);
        }
        catch (err)
        {
            errorN(`Error Show Folio: ${err}. Please contact the administrator.`);
        }


    };

    return (
        <>
            <A href={''} onClick={() => {showBooking();}}>View</A>
        </>
    );
}

export function ShowFolio(props) {
    const { get, post, response, loading, error, del, put } = useFetch();

    let formatter = new Intl.NumberFormat('en-MY', {
        style: 'decimal'/*,
        currency: 'MYR',*/

    });

    let formatterNum = new Intl.NumberFormat('en-MY', {

    });

    useEffect(() => {
        return () => {

        }
    }, [])


    async function showFolio(){
        try {

            let propsObj = props.obj('showfolio');
            let currCode = localStorage.getItem('currencycode');
            let notice = localStorage.getItem('folionotice');
            let footer = localStorage.getItem('foliofooter');
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = JSON.parse(localStorage.getItem('roomlist'));

            notice = notice.replaceAll('\"', '');
            notice = notice.replaceAll('\\r', '');
            notice = notice.replaceAll('\\n', '\n');

            if(propsObj.folioid === '' || propsObj.folioid === null || propsObj.folioid === undefined)
            {
                errorN('ID Missing. Please contact the system administrator.');
                return;
            }

            let report = {};
            let cust = {};
            let bodyData = [];
            let bodyDataFee = [];
            let bodyDataTax = [];
            let bodyDataAllo = [];
            let subtotal = 0;
            let discount = 0;
            let dep = 0;
            let pay = 0;
            let bal = 0;
            let tax = 0;
            let fee = 0;

            let isCancelled = '';

            let objfee ={};
            let objtax = {};
            let objfeeline = {};
            let objtaxline = {};

            let custadds = '';

            let hotelObj = await get(`/api/hotels/${globalObj.id}`)
            if (response.ok)
            {
                if(hotelObj.data === null || hotelObj.data === undefined)
                {
                    errorN('Important Data Missing. Please fill in Property Information to proceed.');
                    return;
                }

                report.lblCompany = hotelObj.data.name;
                report.lblAddress = hotelObj.data.address != null ? hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country : undefined;
                report.lblWebsite = hotelObj.data.website;
                report.lblTel = hotelObj.data.tel;
                report.lblEmail = hotelObj.data.email;
            }
            else
            {
                errorN(`Get Hotel Info - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let billObj = await get(`/api/folios/${propsObj.folioid}`)
            if (response.ok)
            {
                if(billObj === null || billObj === undefined || billObj === {})
                {
                    errorN('Requested document does not exist');
                    return;
                }

                if(billObj.status === 'Cancelled')
                    isCancelled = '- (CANCELLED)'

                report.lblTTX = billObj.data.company != null ? billObj.data.company.company.ttxNo : undefined;
                report.lblSST = billObj.data.company != null ? billObj.data.company.company.sstNo : undefined;

                cust = billObj.data.payor;
                //console.log("dd",cust)

                if(cust.address !== undefined && cust.address !== null)
                {
                    //hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country
                    if(cust.address.line !== undefined && cust.address.line !== null && cust.address.line !== '')
                    {
                        custadds = custadds + cust.address.line;
                    }

                    if(cust.address.postcode !== undefined && cust.address.postcode !== null && cust.address.postcode !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.postcode;
                    }

                    if(cust.address.city !== undefined && cust.address.city !== null && cust.address.city !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.city;
                    }

                    if(cust.address.state !== undefined && cust.address.state !== null && cust.address.state !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.state;
                    }

                    if(cust.address.country !== undefined && cust.address.country !== null && cust.address.country !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.country;
                    }

                }

                if (billObj.breakdown.discount.length > 0)
                {
                    for (let d of billObj.breakdown.discount)
                    {
                        discount = discount + d.rate;
                    }
                }

                let sourceData = billObj.breakdown.fee;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataFee.push(dataRow);

                    fee = fee + sourceRow.rate;
                });


                sourceData = billObj.breakdown.tax;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataTax.push(dataRow);

                    tax = tax + sourceRow.rate;
                });

                if(tax === 0 && report.lblSST !== undefined)
                {
                    let dataRow = ['','',{font: 'Courier', text: 'SST (0%)'},'',{font: 'Courier', text: '0.00',alignment: 'right'}];
                    bodyDataTax.push(dataRow);
                }

                bal =  ((billObj.amount ?? 0) - (billObj.paid ?? 0)).toFixed(2);

                if (billObj.data.company !== null && billObj.data.company !== undefined)
                    report.lblManagedBy = 'Managed By: ' + billObj.data.company.name + ' (' + billObj.data.company.identificationNo + ')';

                if(fee > 0)
                {
                    objfee = {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    };
                    objfeeline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }

                if(tax > 0 || (tax === 0 && report.lblSST !== undefined))
                {
                    objtax = {
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    }
                    objtaxline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }
            }
            else
            {
                errorN(`Get Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let OrderItems = await get(`/api/folios/${propsObj.folioid}/lines`)
            if (response.ok)
            {

                let sourceData = OrderItems.sort((a,b) => a.data.item.name - b.data.item.name);

                let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Description`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Qty`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'right'},
                ];
                bodyData.push(dataRowx)

                let i = 1;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = [{font: 'Courier', text: i,alignment: 'center'},{font: 'Courier', text: moment(sourceRow.date).format('DD-MM-YYYY'),alignment: 'center'},{font: 'Courier', text: sourceRow.data.item.name},{font: 'Courier', text: sourceRow.unit,alignment: 'center'},{font: 'Courier', text: sourceRow.breakdown.price.toFixed(2),alignment: 'right'}];
                    bodyData.push(dataRow);

                    subtotal = subtotal + sourceRow.breakdown.price;
                    i++;
                });
            }
            else
            {
                errorN(`Get FolioLines - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let allocationListObj = await get(`/api/hotels/${globalObj.id}/allocations?folio=${propsObj.folioid}`)
            if (response.ok)
            {
                if (allocationListObj.length > 0)
                {
                    let roomNo = 'Not Assigned';
                    let lst = [];
                    let i = 1;

                    let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Unit No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `In`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Out`,alignment: 'center'},
                    ];
                    bodyDataAllo.push(dataRowx);


                    for (let valued of allocationListObj)
                    {
                        for (let x of roomList)
                        {
                            if (x.id === valued.room)
                            {
                                roomNo = x.data.no;
                            }
                        }

                        lst.push(roomNo);

                        if (valued.status === "Blocked")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.start).format('DD-MM-YYYY'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);
                        }
                        else if (valued.status == "Occupied")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);

                        }
                        else if (valued.status == "Closed")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkout).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'}]);
                        }

                        roomNo = "Not Assigned";
                        i++;
                    }
                }
            }
            else
            {
                errorN(`Get Allocations - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let a = window.location.origin;
            let logostr = a + '/images/square-xxl.png';
            let logoList = await get(`/api/files/?hotel=${globalObj.id}`)
            if (response.ok)
            {
                let lid = '';
                if(logoList.length > 0)
                {
                    for(let i of logoList)
                    {
                        if(i.data !== null && i.data !== undefined)
                        {
                            if(i.data.name.substring(0, 4) === 'logo')
                            {
                                lid = i.id;
                            }
                        }
                    }
                }

                if(lid !== '')
                {
                    let a = window.location.origin;
                    logostr = a + `/api/files/${lid}?action=download`;
                }
            }
            else
            {
                errorN(`Get Logo - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let receiptListObj = await get(`/api/folios/${propsObj.folioid}/payments`)
            if (response.ok)
            {
                if (receiptListObj.length > 0)
                {
                    for (let ix of receiptListObj)
                    {
                        if (ix.data.paymentFor === "Deposit")
                        {
                            if (ix.type === "receipt")
                            {
                                dep = dep +  parseFloat(ix.amount);
                            }
                            else
                            {
                                dep = dep - parseFloat(ix.amount);
                            }

                        }
                        else
                        {
                            if (ix.type === "receipt")
                            {
                                pay = pay + parseFloat(ix.amount);
                            }
                            else
                            {
                                pay = pay - parseFloat(ix.amount);
                            }
                        }
                    }
                }

                pay = pay.toFixed(2);
                dep = dep.toFixed(2);
            }
            else
            {
                errorN(`Get Payment - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let d = new Date();
            let definition = {
                pageSize: 'A4',
                pageOrientation: 'portrait',
                pageMargins: [50, 100, 50, 50],
                header: function (page, pages) {
                    return {
                        table: {
                            widths: ['60%','40%'],
                            body: [
                                [
                                    [
                                        {text: `GUEST REGISTRATION ${isCancelled}`,style: 'superheader',alignment: 'left', color: `${billObj.status !== 'Cancelled' ? 'black' : 'red'}`},
                                        {text: `\n${report.lblCompany}`,style: 'header',alignment: 'left'},
                                        {text: `\n${report.lblAddress}`, style: 'normal',alignment: 'left'},
                                    ],
                                    {alignment: 'right', image: 'mypic', width: 65, height: 65,},
                                ]

                            ],
                        },
                        layout: 'noBorders',
                        margin: [50, 30, 50, 5],
                    }
                },
                footer: function (page, pages) {
                    return [
                        /*{ text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'center' },*/

                            [
                                {
                                    text: {fontSize: 7,bold: true,font: 'Helvetica', text: report.lblManagedBy, alignment: 'center'},
                                    margin: [0, 0, 0, 0]
                                },
                                {
                                    table: {
                                        widths: ['17%','17%','17%','17%','17%','15%'],
                                        body: [
                                            [
                                                {fontSize: 7, alignment: 'center', text: `Tel: ${report.lblTel}`},
                                                {fontSize: 7, alignment: 'center', text: `Email: ${report.lblEmail}`},
                                                {fontSize: 7, alignment: 'center', text: `Website: ${report.lblWebsite}`},
                                                {fontSize: 7, alignment: 'center', text: `${report.lblSST !== undefined ? 'SST No.: ' + report.lblSST : ''}`},
                                                {fontSize: 7, alignment: 'center', text: `${report.lblTTX !== undefined ? 'TTX No.: ' + report.lblTTX : ''}`},
                                                {text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'right' },
                                            ]
                                        ]
                                    },
                                    layout: 'noBorders',
                                    margin: [50, 10, 50, 20],
                                }
                            ]


                    ]


                },
                info: {
                    title: `Guest Registration - ${propsObj.foliono}`,
                    author: 'K.Nivagaran',
                    subject: 'create folio view',
                    keywords: '',
                },
                content: [
                    {
                        table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {fontSize: 7, alignment: 'left', text: `${report.lblSST !== undefined ? 'SST No.: ' + report.lblSST : ''}\t${report.lblTTX !== undefined ? 'TTX No.: ' + report.lblTTX : ''}`},
                                ],
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 0],
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        style: 'tableExample2',
                        table: {
                            widths: ['70%','13%','17%'],
                            body: [
                                [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Billed To`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Folio No.`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${billObj.no}`}
                                ],
                                [
                                    '',
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date of Issue`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${moment(billObj.created).format('DD-MM-YYYY')}`}
                                ],
                                [
                                    {bold: true,style: 'normal', text: `${cust.name.toUpperCase()}`},
                                    '',
                                    ''
                                ],
                                [
                                    /*{style: 'normal', text: `${cust.address.line + ', ' + cust.address.postcode + ', ' + cust.address.city + ', ' + cust.address.state + ', ' + cust.address.country}`},*/
                                    {style: 'normal', text: `${custadds}`},
                                    '',
                                    ''
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['15%','55%','13%','17%'],
                            body: [
                                [
                                    {bold: true,style: 'normal', text: `Identification No.`},
                                    {style: 'normal', text: `:  ${cust.identification.id !== null && cust.identification.id !== undefined ? cust.identification.id : ''}`},
                                    {bold: true,style: 'normal', text: `Source`},
                                    {style: 'normal', text: `:  ${billObj.source === 'walkin' ? 'Walk In' : billObj.data.payor.otaagency.name}`},
                                ],
                                [
                                    {bold: true,style: 'normal', text: `Tel No.`},
                                    {style: 'normal', text: `:  ${cust.tel !== null && cust.tel !== undefined ? cust.tel : ''}`},
                                    '',
                                    {style: 'normal', text: `${billObj.source === 'client' || billObj.source === 'agency' ? ':  ' + billObj.data.rcode != undefined && billObj.data.rcode != null && billObj.data.rcode != '' ? (billObj.data.bcode != null && billObj.data.bcode != '' ? (billObj.data.rcode.toString().indexOf('/') == -1 ? (billObj.data.bcode + '/' + billObj.data.rcode): billObj.data.rcode) : billObj.data.rcode) : (billObj.data.payor.refno != null && billObj.data.payor.refno != '' ? billObj.data.payor.refno:''):''}`},
                                ],
                                [
                                    {bold: true,style: 'normal', text: `Nationality`},
                                    {style: 'normal', text: `:  ${cust.nationality !== null && cust.nationality !== undefined  ? cust.nationality.name : ''}`},
                                    {bold: true,style: 'normal', text: `Issued By`},
                                    {style: 'normal', text: `:  ${billObj.data.by.name.toUpperCase()}`},
                                ],
                                [
                                    {bold: true,style: 'normal', text: `Company`},
                                    {style: 'normal', text: `:  ${cust.guestCompany !== null && cust.guestCompany !== undefined  ? cust.guestCompany : ''}`},
                                    {bold: true,style: 'normal', text: ``},
                                    {style: 'normal', text: `  `},
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Services`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyData
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 5, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Discount`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${discount.toFixed(2)}`,alignment: 'right'}
                                            ],
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Subtotal`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${(subtotal-discount).toFixed(2)}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    objfee,
                    objfeeline,
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    objtax,
                    objtaxline,
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Rounding Adjustment`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${billObj.rounding !== undefined && billObj.rounding !== null ? billObj.rounding : 0.00}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {fontSize: 9,bold: true,font: 'Helvetica', text: `TOTAL`},
                                                '',
                                                {fontSize: 9,bold: true,font: 'Helvetica', text: `${currCode} ${billObj.amount !== undefined && billObj.amount !== null ? billObj.amount : 0.00}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['10%','30%','30%','30%'],
                                        body: bodyDataAllo
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 5, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Notice to Guest:-\n\n`},
                                    {font: 'Courier',style: 'normal', text: notice},
                                ],
                                margin: [0, 15, 0, 0]
                            },
                            [
                                {
                                    text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Payment Details:-\n\n`},
                                    margin: [0, 15, 0, 0]
                                },
                                {
                                    table: {
                                        widths: ['40%','60%'],
                                        body: [
                                            [
                                                {font: 'Courier',style: 'normal', text: `Payment`},
                                                {font: 'Courier', style: 'normal', text: `${pay}`,alignment: 'right'}
                                            ],
                                            [
                                                {font: 'Courier',style: 'normal', text: `Deposit`},
                                                {font: 'Courier', style: 'normal', text: `${dep}`,alignment: 'right'}
                                            ],
                                            [
                                                {font: 'Courier',style: 'normal', text: `Balance`},
                                                {font: 'Courier', style: 'normal', text: `${bal}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                    margin: [0, 0, 0, 0],
                                    lineHeight: 1.2
                                },
                                {
                                    text: [
                                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Acknowledged By\n\n`},
                                        `\n\n`,
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Signature\t : __________________________\n\n`},
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Name\t\t\t: ${cust.name.toUpperCase()}`},
                                    ],
                                    margin: [123, 30, 0, 0]
                                }
                            ]


                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        text: {fontSize: 9,bold: true,font: 'Helvetica', text: footer !== 'undefined' && footer !== undefined && footer !== '' && footer !== null  ? footer : 'Thank you for choosing us and please come again!', alignment: 'center'},
                        margin: [0, 5, 0, 0]
                    },

                ],
                styles: {
                    superheader: {
                        font: 'Helvetica',
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',

                    },
                    header: {
                        font: 'Helvetica',
                        fontSize: 12,
                        bold: true,
                        alignment: 'justify'
                    },
                    normal: {
                        fontSize: 8,
                        bold: false,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    normalbold: {
                        fontSize: 8,
                        bold: true,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    bolding: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    tableExample: {
                        fontSize: 8,
                        margin: [0, 5, 0, 0]
                    },
                    tableExample2: {
                        fontSize: 9,
                        margin: [0, 10, 0, 0]
                    },
                    tableExample3: {
                        fontSize: 10,
                        margin: [0, 5, 0, 15]
                    },
                },
                defaultStyle: {
                    font: 'Times',
                    columnGap: 23
                },
                images:{
                    mypic: logostr
                }
            }

            //console.log("here 1")
            let win = window.open('', '_blank');
            pdfMake.createPdf(definition).open({}, win);
           // console.log("here 2")
        }
        catch (err)
        {
            errorN(`Error Show Folio: ${err}. Please contact the administrator.`);
        }


    };

    return (
        <>
            <A href={''} onClick={() => {showFolio();}}>Folio</A>
        </>
    );
}

export function ShowInvoice(props) {
    const { get, post, response, loading, error, del, put } = useFetch();


    let formatter = new Intl.NumberFormat('en-MY', {
        style: 'decimal'/*,
        currency: 'MYR',*/

    });

    let formatterNum = new Intl.NumberFormat('en-MY', {

    });

    useEffect(() => {
        return () => {

        }
    }, [])


    async function showInvoice(){
        try {

            let propsObj = props.obj('showinvoice');
            let currCode = localStorage.getItem('currencycode');
            let notice = localStorage.getItem('folionotice');
            let footer = localStorage.getItem('foliofooter');
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = JSON.parse(localStorage.getItem('roomlist'));

            notice = notice.replaceAll('\"', '');
            notice = notice.replaceAll('\\r', '');
            notice = notice.replaceAll('\\n', '\n');



            if(propsObj.invoiceid === '' || propsObj.invoiceid === null || propsObj.invoiceid === undefined)
            {
                errorN('ID Missing. Please contact the system administrator.');
                return;
            }

            let report = {};
            let cust = {};
            let bodyData = [];
            let bodyDataFee = [];
            let bodyDataTax = [];
            let bodyDataAllo = [];
            let subtotal = 0;
            let discount = 0;
            let tax = 0;
            let fee = 0;

            let objfee = {};
            let objtax = {};
            let objfeeline = {};
            let objtaxline = {};

            let custadds = '';

            let hotelObj = await get(`/api/hotels/${globalObj.id}`)
            if (response.ok)
            {
                if(hotelObj.data === null || hotelObj.data === undefined)
                {
                    errorN('Important Data Missing. Please fill in Property Information to proceed.');
                    return;
                }

                report.lblCompany = hotelObj.data.name;
                report.lblAddress = hotelObj.data.address != null ? hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country : undefined;
                report.lblWebsite = hotelObj.data.website;
                report.lblTel = hotelObj.data.tel;
                report.lblEmail = hotelObj.data.email;
            }
            else
            {
                errorN(`Get Hotel Info - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let billObj = await get(`/api/invoices/${propsObj.invoiceid}`)
            if (response.ok)
            {
                if(billObj === null || billObj === undefined || billObj === {})
                {
                    errorN('Requested document does not exist');
                    return;
                }



                report.lblTTX = billObj.data.company != null ? billObj.data.company.company.ttxNo : undefined;
                report.lblSST = billObj.data.company != null ? billObj.data.company.company.sstNo : undefined;

                cust = billObj.data.payor;

                if(cust.address !== undefined && cust.address !== null)
                {
                    //hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country
                    if(cust.address.line !== undefined && cust.address.line !== null && cust.address.line !== '')
                    {
                        custadds = custadds + cust.address.line;
                    }

                    if(cust.address.postcode !== undefined && cust.address.postcode !== null && cust.address.postcode !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.postcode;
                    }

                    if(cust.address.city !== undefined && cust.address.city !== null && cust.address.city !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.city;
                    }

                    if(cust.address.state !== undefined && cust.address.state !== null && cust.address.state !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.state;
                    }

                    if(cust.address.country !== undefined && cust.address.country !== null && cust.address.country !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.country;
                    }

                }

                if (billObj.breakdown.discount.length > 0)
                {
                    for (let d of billObj.breakdown.discount)
                    {
                        discount = discount + d.rate;
                    }
                }

                let sourceData = billObj.breakdown.fee;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataFee.push(dataRow);

                    fee = fee + sourceRow.rate;
                });


                sourceData = billObj.breakdown.tax;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataTax.push(dataRow);

                    tax = tax + sourceRow.rate;
                });


                if(tax === 0 && report.lblSST !== undefined)
                {
                    let dataRow = ['','',{font: 'Courier', text: 'SST (0%)'},'',{font: 'Courier', text: '0.00',alignment: 'right'}];
                    bodyDataTax.push(dataRow);
                }

                if (billObj.data.company !== null && billObj.data.company !== undefined)
                    report.lblManagedBy = 'Managed By: ' + billObj.data.company.name + ' (' + billObj.data.company.identificationNo + ')';


                if(fee > 0)
                {
                    objfee = {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    };
                    objfeeline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }

                if(tax > 0 || (tax === 0 && report.lblSST !== undefined))
                {
                    objtax = {
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    }
                    objtaxline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }

            }
            else
            {
                errorN(`Get Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }


            let OrderItems = await get(`/api/invoices/${propsObj.invoiceid}/lines`)
            if (response.ok)
            {

                let sourceData = OrderItems.sort((a,b) => a.data.item.name - b.data.item.name);

                let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Description`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Qty`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'right'},
                ];
                bodyData.push(dataRowx)

                let i = 1;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = [{font: 'Courier', text: i,alignment: 'center'},{font: 'Courier', text: moment(sourceRow.date).format('DD-MM-YYYY'),alignment: 'center'},{font: 'Courier', text: sourceRow.data.item.name},{font: 'Courier', text: sourceRow.unit,alignment: 'center'},{font: 'Courier', text: sourceRow.breakdown.price.toFixed(2),alignment: 'right'}];
                    bodyData.push(dataRow);

                    subtotal = subtotal + sourceRow.breakdown.price;
                    i++;
                });
            }
            else
            {
                errorN(`Get FolioLines - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let allocationListObj = await get(`/api/hotels/${globalObj.id}/allocations?folio=${propsObj.folioid}`)
            if (response.ok)
            {
                if (allocationListObj.length > 0)
                {
                    let roomNo = 'Not Assigned';
                    let lst = [];
                    let i = 1;

                    let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Unit No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `In`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Out`,alignment: 'center'},
                    ];
                    bodyDataAllo.push(dataRowx);


                    for (let valued of allocationListObj)
                    {
                        for (let x of roomList)
                        {
                            if (x.id === valued.room)
                            {
                                roomNo = x.data.no;
                            }
                        }

                        lst.push(roomNo);

                        if (valued.status === "Blocked")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.start).format('DD-MM-YYYY'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);
                        }
                        else if (valued.status == "Occupied")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);

                        }
                        else if (valued.status == "Closed")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkout).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'}]);
                        }

                        roomNo = "Not Assigned";
                        i++;
                    }
                }
            }
            else
            {
                errorN(`Get Allocations - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let a = window.location.origin;
            let logostr = a + '/images/square-xxl.png';
            let logoList = await get(`/api/files/?hotel=${globalObj.id}`)
            if (response.ok)
            {
                let lid = '';
                if(logoList.length > 0)
                {
                    for(let i of logoList)
                    {
                        if(i.data !== null && i.data !== undefined)
                        {
                            if(i.data.name.substring(0, 4) === 'logo')
                            {
                                lid = i.id;
                            }
                        }
                    }
                }

                if(lid !== '')
                {
                    let a = window.location.origin;
                    logostr = a + `/api/files/${lid}?action=download`;
                }
            }
            else
            {
                errorN(`Get Logo - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }



            let d = new Date();
            let definition = {
                pageSize: 'A4',
                pageOrientation: 'portrait',
                pageMargins: [50, 100, 50, 50],
                header: function (page, pages) {
                    return {
                        table: {
                            widths: ['60%','40%'],
                            body: [
                                [
                                    [
                                        {text: `INVOICE`,style: 'superheader',alignment: 'left'},
                                        {text: `\n${report.lblCompany}`,style: 'header',alignment: 'left'},
                                        {text: `\n${report.lblAddress}`,style: 'normal',alignment: 'left'},


                                    ],
                                    {alignment: 'right', image: 'mypic', width: 65, height: 65,},
                                ]

                            ],
                        },
                        layout: 'noBorders',
                        margin: [50, 30, 50, 5],
                    }
                },
                footer: function (page, pages) {
                    return [
                        /*{ text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'center' },*/

                        [
                            {
                                text: {fontSize: 7,bold: true,font: 'Helvetica', text: report.lblManagedBy, alignment: 'center'},
                                margin: [0, 0, 0, 0]
                            },
                            {
                                table: {
                                    widths: ['17%','17%','17%','17%','17%','15%'],
                                    body: [
                                        [
                                            {fontSize: 7, alignment: 'center', text: `Tel: ${report.lblTel}`},
                                            {fontSize: 7, alignment: 'center', text: `Email: ${report.lblEmail}`},
                                            {fontSize: 7, alignment: 'center', text: `Website: ${report.lblWebsite}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblSST !== undefined ? 'SST No.: ' + report.lblSST : ''}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblTTX !== undefined ? 'TTX No.: ' + report.lblTTX : ''}`},
                                            {text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'right' },
                                        ]
                                    ]
                                },
                                layout: 'noBorders',
                                margin: [50, 10, 50, 20],
                            }
                        ]


                    ]


                },
                info: {
                    title: `Invoice - ${billObj.no}`,
                    author: 'K.Nivagaran',
                    subject: 'create invoice view',
                    keywords: '',
                },
                content: [
                    {
                        table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {fontSize: 7, alignment: 'left', text: `${report.lblSST !== undefined ? 'SST No.: ' + report.lblSST : ''}\t${report.lblTTX !== undefined ? 'TTX No.: ' + report.lblTTX : ''}`},
                                ],
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 0],
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        style: 'tableExample2',
                        table: {
                            widths: ['70%','13%','17%'],
                            body: [
                                [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Billed To`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Invoice No.`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${billObj.no}`}
                                ],
                                [
                                    '',
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date of Issue`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${moment(billObj.created).format('DD-MM-YYYY')}`}
                                ],
                                [
                                    {bold: true,style: 'normal', text: `${cust.name.toUpperCase()}`},
                                    '',
                                    ''
                                ],
                                [
                                    /*{style: 'normal', text: `${cust.address.line + ', ' + cust.address.postcode + ', ' + cust.address.city + ', ' + cust.address.state + ', ' + cust.address.country}`},*/
                                    {style: 'normal', text: `${custadds}`},
                                    '',
                                    ''
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['15%','55%','13%','17%'],
                            body: [
                                [
                                    {bold: true,style: 'normal', text: `Identification No.`},
                                    {style: 'normal', text: `:  ${cust.identification.id !== null && cust.identification.id !== undefined ? cust.identification.id : ''}`},
                                    {bold: true,style: 'normal', text: `Folio No.`},
                                    {style: 'normal', text: `:  ${propsObj.foliono}`},
                                ],
                                [
                                    {bold: true,style: 'normal', text: `Tel No.`},
                                    {style: 'normal', text: `:  ${cust.tel !== null && cust.tel !== undefined ? cust.tel : ''}`},
                                    '',
                                    '',
                                ],
                                [
                                    {bold: true,style: 'normal', text: `Nationality`},
                                    {style: 'normal', text: `:  ${cust.nationality !== null && cust.nationality !== undefined  ? cust.nationality.name : ''}`},
                                    {bold: true,style: 'normal', text: `Issued By`},
                                    {style: 'normal', text: `:  ${billObj.data.by.name.toUpperCase()}`},
                                ],
                                [
                                    {bold: true,style: 'normal', text: `Company`},
                                    {style: 'normal', text: `:  ${cust.guestCompany !== null && cust.guestCompany !== undefined  ? cust.guestCompany : ''}`},
                                    {bold: true,style: 'normal', text: ``},
                                    {style: 'normal', text: `  `},
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Services`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyData
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 5, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Discount`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${discount.toFixed(2)}`,alignment: 'right'}
                                            ],
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Subtotal`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${(subtotal-discount).toFixed(2)}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    objfee,
                    objfeeline,
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    objtax,
                    objtaxline,
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Rounding Adjustment`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${billObj.rounding !== undefined && billObj.rounding !== null ? billObj.rounding : 0.00}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {fontSize: 9,bold: true,font: 'Helvetica', text: `TOTAL`},
                                                '',
                                                {fontSize: 9,bold: true,font: 'Helvetica', text: `${currCode} ${billObj.amount !== undefined && billObj.amount !== null ? billObj.amount : 0.00}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['10%','30%','30%','30%'],
                                        body: bodyDataAllo
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 5, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        text: {fontSize: 9,bold: true,font: 'Helvetica', text: footer !== 'undefined' && footer !== undefined && footer !== '' && footer !== null  ? footer : 'Thank you for choosing us and please come again!', alignment: 'center'},
                        margin: [0, 20, 0, 0]
                    },

                ],
                styles: {
                    superheader: {
                        font: 'Helvetica',
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',

                    },
                    header: {
                        font: 'Helvetica',
                        fontSize: 12,
                        bold: true,
                        alignment: 'justify'
                    },
                    normal: {
                        fontSize: 8,
                        bold: false,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    normalbold: {
                        fontSize: 8,
                        bold: true,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    bolding: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    tableExample: {
                        fontSize: 8,
                        margin: [0, 5, 0, 0]
                    },
                    tableExample2: {
                        fontSize: 9,
                        margin: [0, 10, 0, 0]
                    },
                    tableExample3: {
                        fontSize: 10,
                        margin: [0, 5, 0, 15]
                    },
                },
                defaultStyle: {
                    font: 'Times',
                    columnGap: 23
                },
                images:{
                    mypic: logostr
                }
            }
            let win = window.open('', '_blank');
            pdfMake.createPdf(definition).open({}, win);
        }
        catch (err)
        {
            errorN(`Error Show Folio: ${err}. Please contact the administrator.`);
        }


    };

    return (
        <>
            <A href={''} onClick={() => {showInvoice();}}>Invoice</A>
        </>
    );
}

export function ShowReceipt(props) {
    const { get, post, response, loading, error, del, put } = useFetch();

    let formatter = new Intl.NumberFormat('en-MY', {
        style: 'decimal'/*,
        currency: 'MYR',*/

    });

    let formatterNum = new Intl.NumberFormat('en-MY', {

    });

    useEffect(() => {
        return () => {

        }
    }, [])


    async function showReceipt(){
        try {

            let propsObj = props.obj;
            let currCode = localStorage.getItem('currencycode');
            let notice = localStorage.getItem('receiptnotice');
            let footer = localStorage.getItem('foliofooter');
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = JSON.parse(localStorage.getItem('roomlist'));

            notice = notice.replaceAll('\"', '');
            notice = notice.replaceAll('\\r', '');
            notice = notice.replaceAll('\\n', '\n');




            if(propsObj.folioid === '' || propsObj.folioid === null || propsObj.folioid === undefined)
            {
                errorN('ID Missing. Please contact the system administrator.');
                return;
            }

            let report = {};
            let cust = {};
            let bodyData = [];
            let bodyDataFee = [];
            let bodyDataTax = [];
            let bodyDataAllo = [];
            let subtotal = 0;
            let discount = 0;
            let dep = 0;
            let pay = 0;
            let bal = 0;
            let tax = 0;
            let fee = 0;
            let unitNo = '';

            let isCancelled = '';

            let objfee ={};
            let objtax = {};
            let objfeeline = {};
            let objtaxline = {};
            let objnotice = {};

            let custadds = '';

            let hotelObj = await get(`/api/hotels/${globalObj.id}`)
            if (response.ok)
            {
                if(hotelObj.data === null || hotelObj.data === undefined)
                {
                    errorN('Important Data Missing. Please fill in Property Information to proceed.');
                    return;
                }

                report.lblCompany = hotelObj.data.name;
                report.lblAddress = hotelObj.data.address != null ? hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country : undefined;
                report.lblWebsite = hotelObj.data.website;
                report.lblTel = hotelObj.data.tel;
                report.lblEmail = hotelObj.data.email;
            }
            else
            {
                errorN(`Get Hotel Info - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let billObj = await get(`/api/folios/${propsObj.folioid}`)
            if (response.ok)
            {
                if(billObj === null || billObj === undefined || billObj === {})
                {
                    errorN('Requested document does not exist');
                    return;
                }

                if(billObj.status === 'Cancelled')
                    isCancelled = '- (CANCELLED)'

                report.lblTTX = billObj.data.company != null ? billObj.data.company.company.ttxNo : undefined;
                report.lblSST = billObj.data.company != null ? billObj.data.company.company.sstNo : undefined;

                cust = billObj.data.payor;

                if(cust.address !== undefined && cust.address !== null)
                {
                    //hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country
                    if(cust.address.line !== undefined && cust.address.line !== null && cust.address.line !== '')
                    {
                        custadds = custadds + cust.address.line;
                    }

                    if(cust.address.postcode !== undefined && cust.address.postcode !== null && cust.address.postcode !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.postcode;
                    }

                    if(cust.address.city !== undefined && cust.address.city !== null && cust.address.city !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.city;
                    }

                    if(cust.address.state !== undefined && cust.address.state !== null && cust.address.state !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.state;
                    }

                    if(cust.address.country !== undefined && cust.address.country !== null && cust.address.country !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.country;
                    }

                }

                if (billObj.breakdown.discount.length > 0)
                {
                    for (let d of billObj.breakdown.discount)
                    {
                        discount = discount + d.rate;
                    }
                }

                let sourceData = billObj.breakdown.fee;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataFee.push(dataRow);

                    fee = fee + sourceRow.rate;
                });


                sourceData = billObj.breakdown.tax;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataTax.push(dataRow);

                    tax = tax + sourceRow.rate;
                });

                if(tax === 0 && report.lblSST !== undefined)
                {
                    let dataRow = ['','',{font: 'Courier', text: 'SST (0%)'},'',{font: 'Courier', text: '0.00',alignment: 'right'}];
                    bodyDataTax.push(dataRow);
                }

                bal =  ((billObj.amount ?? 0) - (billObj.paid ?? 0)).toFixed(2);

                if (billObj.data.company !== null && billObj.data.company !== undefined)
                    report.lblManagedBy = 'Managed By: ' + billObj.data.company.name + ' (' + billObj.data.company.identificationNo + ')';

                /*if(fee > 0)
                {
                    objfee = {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    };
                    objfeeline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }*/

                /*if(tax > 0 || (tax === 0 && report.lblSST !== undefined))
                {
                    objtax = {
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    }
                    objtaxline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }*/
            }
            else
            {
                errorN(`Get Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            /*let OrderItems = await get(`/api/folios/${propsObj.folioid}/lines`)
            if (response.ok)
            {

                let sourceData = OrderItems.sort((a,b) => a.data.item.name - b.data.item.name);

                let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Description`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Qty`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'right'},
                ];
                bodyData.push(dataRowx)

                let i = 1;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = [{font: 'Courier', text: i,alignment: 'center'},{font: 'Courier', text: moment(sourceRow.date).format('DD-MM-YYYY'),alignment: 'center'},{font: 'Courier', text: sourceRow.data.item.name},{font: 'Courier', text: sourceRow.unit,alignment: 'center'},{font: 'Courier', text: sourceRow.breakdown.price.toFixed(2),alignment: 'right'}];
                    bodyData.push(dataRow);

                    subtotal = subtotal + sourceRow.breakdown.price;
                    i++;
                });
            }
            else
            {
                errorN(`Get FolioLines - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }*/

            let allocationListObj = await get(`/api/hotels/${globalObj.id}/allocations?folio=${propsObj.folioid}`)
            if (response.ok)
            {
                if (allocationListObj.length > 0)
                {
                    let roomNo = 'Not Assigned';
                    let lst = [];
                    let i = 1;

                    let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Unit No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `In`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Out`,alignment: 'center'},
                    ];
                    bodyDataAllo.push(dataRowx);


                    for (let valued of allocationListObj)
                    {
                        for (let x of roomList)
                        {
                            if (x.id === valued.room)
                            {
                                roomNo = x.data.no;
                            }
                        }

                        lst.push(roomNo);

                        for(let n of lst)
                        {
                            if(unitNo === '')
                            {
                                unitNo = n;
                            }
                            else
                            {
                                unitNo = unitNo + ',' + n;
                            }
                        }

                        if (valued.status === "Blocked")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.start).format('DD-MM-YYYY'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);
                        }
                        else if (valued.status == "Occupied")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);

                        }
                        else if (valued.status == "Closed")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkout).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'}]);
                        }

                        roomNo = "Not Assigned";
                        i++;
                    }
                }
            }
            else
            {
                errorN(`Get Allocations - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let a = window.location.origin;
            let logostr = a + '/images/square-xxl.png';
            let logoList = await get(`/api/files/?hotel=${globalObj.id}`)
            if (response.ok)
            {
                let lid = '';
                if(logoList.length > 0)
                {
                    for(let i of logoList)
                    {
                        if(i.data !== null && i.data !== undefined)
                        {
                            if(i.data.name.substring(0, 4) === 'logo')
                            {
                                lid = i.id;
                            }
                        }
                    }
                }

                if(lid !== '')
                {
                    let a = window.location.origin;
                    logostr = a + `/api/files/${lid}?action=download`;
                }
            }
            else
            {
                errorN(`Get Logo - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let receiptListObj = await get(`/api/payments/${propsObj.id}`)
            if (response.ok)
            {

                pay = parseFloat(receiptListObj.amount);
                pay = pay.toFixed(2);


                objfee = {
                    columns:[
                        {
                            width: '15%',
                            text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                        },
                        [
                            {
                                style: 'tableExample',
                                table: {
                                    widths: ['5%','20%','50%','5%','20%'],
                                    body: [

                                        [
                                            '','',
                                            {fontSize: 9,bold: true,font: 'Helvetica', text: `For`,alignment: 'left'},
                                            '',
                                            {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'right'}
                                        ],
                                        [
                                            '','',
                                            {font: 'Courier',style: 'normal', text: `${receiptListObj.data.paymentFor}`,alignment: 'left'},
                                            '',
                                            {font: 'Courier',style: 'normal', text: pay, alignment: 'right'},
                                        ],
                                    ]
                                },
                                layout: 'noBorders',
                            },

                        ],

                    ],
                    margin: [0, 0, 0, 0]
                };
                objfeeline = {
                    columns:[
                        {
                            width: '38%',
                            text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                        },
                        {
                            width: '62%',
                            color: 'gray',
                            text: ['.................................................................................................']
                        }

                    ],
                    margin: [0, 0, 0, 0]
                };

                let on = null;

                if(receiptListObj.type === 'receipt')
                {
                    on = {
                        width: '38%',
                        text: [
                            {fontSize: 9,bold: true,font: 'Helvetica', text: `Notice to Guest:-\n\n`},
                            {font: 'Courier',style: 'normal', text: notice},
                        ],
                        margin: [0, 15, 0, 0]
                    };
                }
                else
                {
                    on = {
                        width: '38%',
                        text: [
                            ''
                        ],
                        margin: [0, 15, 0, 0]
                    };
                }

                objnotice = {
                    columns:[
                        on,
                        [

                            {
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `${receiptListObj.type === 'receipt' ? 'Received By' : 'Refunded By'}\n\n`},
                                    ``,
                                    {fontSize: 8,bold: false,style: 'normal', text: `Name\t  :\t${receiptListObj.data.by.name}\n`},
                                    {fontSize: 8,bold: false,style: 'normal', text: `Date\t\t:\t${moment(receiptListObj.date).format('DD-MM-YYYY hh:mm:ss A')}`},
                                ],
                                margin: [123, 10, 0, 0]
                            }
                        ]


                    ],
                    margin: [0, 0, 0, 0]
                };


            }
            else
            {
                errorN(`Get Payment - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let d = new Date();
            let definition = {
                pageSize: 'A4',
                pageOrientation: 'portrait',
                pageMargins: [50, 100, 50, 50],
                header: function (page, pages) {
                    return {
                        table: {
                            widths: ['60%','40%'],
                            body: [
                                [
                                    [
                                        {text: `${receiptListObj.type === 'receipt' ? 'RECEIPT' : 'REFUND SLIP'}`,style: 'superheader',alignment: 'left'},
                                        {text: `\n${report.lblCompany}`,style: 'header',alignment: 'left'},
                                        {text: `\n${report.lblAddress}`,style: 'normal',alignment: 'left'},


                                    ],
                                    {alignment: 'right', image: 'mypic', width: 65, height: 65,},
                                ]

                            ],
                        },
                        layout: 'noBorders',
                        margin: [50, 30, 50, 5],
                    }
                },
                /*footer: function (page, pages) {
                    return [
                        /!*{ text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'center' },*!/

                        [
                            {
                                text: {fontSize: 7,bold: true,font: 'Helvetica', text: report.lblManagedBy, alignment: 'center'},
                                margin: [0, 0, 0, 0]
                            },
                            {
                                table: {
                                    widths: ['17%','17%','17%','17%','17%','15%'],
                                    body: [
                                        [
                                            {fontSize: 7, alignment: 'center', text: `Tel: ${report.lblTel}`},
                                            {fontSize: 7, alignment: 'center', text: `Email: ${report.lblEmail}`},
                                            {fontSize: 7, alignment: 'center', text: `Website: ${report.lblWebsite}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblSST !== undefined ? 'SST No.: ' + report.lblSST : ''}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblTTX !== undefined ? 'TTX No.: ' + report.lblSST : ''}`},
                                            {text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'right' },
                                        ]
                                    ]
                                },
                                layout: 'noBorders',
                                margin: [50, 10, 50, 20],
                            }
                        ]


                    ]


                },*/
                info: {
                    title: `Receipt - ${receiptListObj.no}`,
                    author: 'K.Nivagaran',
                    subject: 'create receipt view',
                    keywords: '',
                },
                content: [
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        style: 'tableExample2',
                        table: {
                            widths: ['70%','13%','17%'],
                            body: [
                                [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `${receiptListObj.type === 'receipt' ? 'Billed To' : 'Received By' }`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Receipt No.`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${receiptListObj.no}`}
                                ],
                                [
                                    '',
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date of Issue`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${moment(receiptListObj.date).format('DD-MM-YYYY')}`}
                                ],
                                [
                                    {bold: true,style: 'normal', text: `${cust.name.toUpperCase()}`},
                                    '',
                                    ''
                                ],
                                [
                                    /*{style: 'normal', text: `${cust.address.line + ', ' + cust.address.postcode + ', ' + cust.address.city + ', ' + cust.address.state + ', ' + cust.address.country}`},*/
                                    {style: 'normal', text: `${custadds}`},
                                    '',
                                    ''
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['15%','55%','13%','17%'],
                            body: [
                                [
                                    {bold: true,style: 'normal', text: `Tel No.`},
                                    {style: 'normal', text: `:  ${cust.tel}`},

                                    {bold: true,style: 'normal', text: `Payment Method`},
                                    {style: 'normal', text: `:  ${receiptListObj.data.method}`},
                                ],
                                [
                                    '',
                                    '',
                                    {bold: true,style: 'normal', text: `Unit No.`},
                                    {style: 'normal', text: `:  ${unitNo}`},
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    objfee,
                    objfeeline,
                    objnotice,
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Notice to Guest:-\n\n`},
                                    {font: 'Courier',style: 'normal', text: notice},
                                ],
                                margin: [0, 15, 0, 0]
                            },
                            [

                                {
                                    text: [
                                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Acknowledged By\n\n`},
                                        `\n\n`,
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Signature\t : __________________________\n\n`},
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Name\t\t\t: __________________________\n\n`},
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Date\t\t\t  : __________________________`},
                                    ],
                                    margin: [123, 10, 0, 0]
                                }
                            ]


                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        text: {fontSize: 9,bold: true,font: 'Helvetica', text: footer, alignment: 'center'},
                        margin: [0, 5, 0, 0]
                    },

                ],
                styles: {
                    superheader: {
                        font: 'Helvetica',
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',

                    },
                    header: {
                        font: 'Helvetica',
                        fontSize: 12,
                        bold: true,
                        alignment: 'justify'
                    },
                    normal: {
                        fontSize: 8,
                        bold: false,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    normalbold: {
                        fontSize: 8,
                        bold: true,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    bolding: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    tableExample: {
                        fontSize: 8,
                        margin: [0, 5, 0, 0]
                    },
                    tableExample2: {
                        fontSize: 9,
                        margin: [0, 10, 0, 0]
                    },
                    tableExample3: {
                        fontSize: 10,
                        margin: [0, 5, 0, 15]
                    },
                },
                defaultStyle: {
                    font: 'Times',
                    columnGap: 23
                },
                images:{
                    mypic: logostr
                }
            }
            let win = window.open('', '_blank');
            pdfMake.createPdf(definition).open({}, win);
        }
        catch (err)
        {
            errorN(`Error Show Folio: ${err}. Please contact the administrator.`);
        }


    };

    return (
        <>
            <A href={''} onClick={() => {showReceipt();}}>View</A>
        </>
    );
}

export function ShowPayment(props) {
    const { get, post, response, loading, error, del, put } = useFetch();

    let formatter = new Intl.NumberFormat('en-MY', {
        style: 'decimal'/*,
        currency: 'MYR',*/

    });

    let formatterNum = new Intl.NumberFormat('en-MY', {

    });

    useEffect(() => {
        return () => {

        }
    }, [])


    async function showSlip(){
        try {

            let propsObj = props.obj('showslip');
            let currCode = localStorage.getItem('currencycode');
            let notice = localStorage.getItem('receiptnotice');
            let footer = localStorage.getItem('foliofooter');
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = JSON.parse(localStorage.getItem('roomlist'));

            notice = notice.replaceAll('\"', '');
            notice = notice.replaceAll('\\r', '');
            notice = notice.replaceAll('\\n', '\n');


            if(propsObj.folioid === '' || propsObj.folioid === null || propsObj.folioid === undefined)
            {
                errorN('ID Missing. Please contact the system administrator.');
                return;
            }

            let report = {};
            let cust = {};
            let bodyData = [];
            let bodyDataFee = [];
            let bodyDataTax = [];
            let bodyDataAllo = [];
            let subtotal = 0;
            let discount = 0;
            let dep = 0;
            let pay = 0;
            let bal = 0;
            let tax = 0;
            let fee = 0;
            let unitNo = '';

            let isCancelled = '';

            let objfee ={};
            let objtax = {};
            let objfeeline = {};
            let objtaxline = {};

            let custadds = '';

            let hotelObj = await get(`/api/hotels/${globalObj.id}`)
            if (response.ok)
            {
                if(hotelObj.data === null || hotelObj.data === undefined)
                {
                    errorN('Important Data Missing. Please fill in Property Information to proceed.');
                    return;
                }
                let adds = '';
                if(hotelObj.data.address !== undefined && hotelObj.data.address !== null)
                {
                    //hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country
                    if(hotelObj.data.address.line !== undefined && hotelObj.data.address.line !== null && hotelObj.data.address.line !== '')
                    {
                        adds = adds + hotelObj.data.address.line;
                    }

                    if(hotelObj.data.address.postcode !== undefined && hotelObj.data.address.postcode !== null && hotelObj.data.address.postcode !== '')
                    {
                        adds =  adds + ', ' + hotelObj.data.address.postcode;
                    }

                    if(hotelObj.data.address.city !== undefined && hotelObj.data.address.city !== null && hotelObj.data.address.city !== '')
                    {
                        adds =  adds + ', ' + hotelObj.data.address.city;
                    }

                    if(hotelObj.data.address.state !== undefined && hotelObj.data.address.state !== null && hotelObj.data.address.state !== '')
                    {
                        adds =  adds + ', ' + hotelObj.data.address.state;
                    }

                    if(hotelObj.data.address.country !== undefined && hotelObj.data.address.country !== null && hotelObj.data.address.country !== '')
                    {
                        adds =  adds + ', ' + hotelObj.data.address.country;
                    }

                }

                report.lblCompany = hotelObj.data.name;
                report.lblAddress = adds; //hotelObj.data.address != undefined && hotelObj.data.address != null ? hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country : '';
                report.lblWebsite = hotelObj.data.website;
                report.lblTel = hotelObj.data.tel;
                report.lblEmail = hotelObj.data.email;
            }
            else
            {
                errorN(`Get Hotel Info - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let billObj = await get(`/api/folios/${propsObj.folioid}`)
            if (response.ok)
            {
                if(billObj === null || billObj === undefined || billObj === {})
                {
                    errorN('Requested document does not exist');
                    return;
                }

                if(billObj.status === 'Cancelled')
                    isCancelled = '- (CANCELLED)'

                report.lblTTX = billObj.data.company != null ? billObj.data.company.company.ttxNo : undefined;
                report.lblSST = billObj.data.company != null ? billObj.data.company.company.sstNo : undefined;

                cust = billObj.data.payor;


                if(cust.address !== undefined && cust.address !== null)
                {
                    //hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country
                    if(cust.address.line !== undefined && cust.address.line !== null && cust.address.line !== '')
                    {
                        custadds = custadds + cust.address.line;
                    }

                    if(cust.address.postcode !== undefined && cust.address.postcode !== null && cust.address.postcode !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.postcode;
                    }

                    if(cust.address.city !== undefined && cust.address.city !== null && cust.address.city !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.city;
                    }

                    if(cust.address.state !== undefined && cust.address.state !== null && cust.address.state !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.state;
                    }

                    if(cust.address.country !== undefined && cust.address.country !== null && cust.address.country !== '')
                    {
                        custadds =  custadds + ', ' + cust.address.country;
                    }

                }

                if (billObj.breakdown.discount.length > 0)
                {
                    for (let d of billObj.breakdown.discount)
                    {
                        discount = discount + d.rate;
                    }
                }

                let sourceData = billObj.breakdown.fee;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataFee.push(dataRow);

                    fee = fee + sourceRow.rate;
                });


                sourceData = billObj.breakdown.tax;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = ['','',{font: 'Courier', text: sourceRow.name},'',{font: 'Courier', text: sourceRow.rate.toFixed(2),alignment: 'right'}];
                    bodyDataTax.push(dataRow);

                    tax = tax + sourceRow.rate;
                });

                if(tax === 0 && report.lblSST !== undefined)
                {
                    let dataRow = ['','',{font: 'Courier', text: 'SST (0%)'},'',{font: 'Courier', text: '0.00',alignment: 'right'}];
                    bodyDataTax.push(dataRow);
                }

                bal =  ((billObj.amount ?? 0) - (billObj.paid ?? 0)).toFixed(2);

                if (billObj.data.company !== null && billObj.data.company !== undefined)
                    report.lblManagedBy = 'Managed By: ' + billObj.data.company.name + ' (' + billObj.data.company.identificationNo + ')';

                /*if(fee > 0)
                {
                    objfee = {
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    };
                    objfeeline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }*/

                /*if(tax > 0 || (tax === 0 && report.lblSST !== undefined))
                {
                    objtax = {
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    }
                    objtaxline = {
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    };
                }*/
            }
            else
            {
                errorN(`Get Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            /*let OrderItems = await get(`/api/folios/${propsObj.folioid}/lines`)
            if (response.ok)
            {

                let sourceData = OrderItems.sort((a,b) => a.data.item.name - b.data.item.name);

                let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Description`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Qty`,alignment: 'center'},
                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'right'},
                ];
                bodyData.push(dataRowx)

                let i = 1;
                sourceData.forEach(function(sourceRow) {
                    let dataRow = [{font: 'Courier', text: i,alignment: 'center'},{font: 'Courier', text: moment(sourceRow.date).format('DD-MM-YYYY'),alignment: 'center'},{font: 'Courier', text: sourceRow.data.item.name},{font: 'Courier', text: sourceRow.unit,alignment: 'center'},{font: 'Courier', text: sourceRow.breakdown.price.toFixed(2),alignment: 'right'}];
                    bodyData.push(dataRow);

                    subtotal = subtotal + sourceRow.breakdown.price;
                    i++;
                });
            }
            else
            {
                errorN(`Get FolioLines - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }*/

            let allocationListObj = await get(`/api/hotels/${globalObj.id}/allocations?folio=${propsObj.folioid}`)
            if (response.ok)
            {
                if (allocationListObj.length > 0)
                {
                    let roomNo = 'Not Assigned';
                    let lst = [];
                    let i = 1;

                    let dataRowx = [{fontSize: 9,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Unit No.`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `In`,alignment: 'center'},
                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Out`,alignment: 'center'},
                    ];
                    bodyDataAllo.push(dataRowx);


                    for (let valued of allocationListObj)
                    {
                        for (let x of roomList)
                        {
                            if (x.id === valued.room)
                            {
                                roomNo = x.data.no;
                            }
                        }

                        lst.push(roomNo);

                        for(let n of lst)
                        {
                            if(unitNo === '')
                            {
                                unitNo = n;
                            }
                            else
                            {
                                unitNo = unitNo + ',' + n;
                            }
                        }

                        if (valued.status === "Blocked")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.start).format('DD-MM-YYYY'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);
                        }
                        else if (valued.status == "Occupied")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.end).format('DD-MM-YYYY'),alignment: 'center'}]);

                        }
                        else if (valued.status == "Closed")
                        {
                            bodyDataAllo.push([{font: 'Courier', text: i,alignment: 'center'},
                                {font: 'Courier', text: roomNo + ' (' + valued.data.guest.name + ')' ,alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkin).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'},
                                {font: 'Courier', text: moment(valued.checkout).format('DD-MM-YYYY hh:mm:ss A'),alignment: 'center'}]);
                        }

                        roomNo = "Not Assigned";
                        i++;
                    }
                }
            }
            else
            {
                errorN(`Get Allocations - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let a = window.location.origin;
            let logostr = a + '/images/square-xxl.png';
            let logoList = await get(`/api/files/?hotel=${globalObj.id}`)
            if (response.ok)
            {
                let lid = '';
                if(logoList.length > 0)
                {
                    for(let i of logoList)
                    {
                        if(i.data !== null && i.data !== undefined)
                        {
                            if(i.data.name.substring(0, 4) === 'logo')
                            {
                                lid = i.id;
                            }
                        }
                    }
                }

                if(lid !== '')
                {
                    let a = window.location.origin;
                    logostr = a + `/api/files/${lid}?action=download`;
                }
            }
            else
            {
                errorN(`Get Logo - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let receiptListObj = await get(`/api/folios/${propsObj.folioid}/payments`)
            if (response.ok)
            {
                if (receiptListObj.length > 0)
                {
                    for (let ix of receiptListObj)
                    {
                        if (ix.data.paymentFor === "Deposit")
                        {
                            if (ix.type === "receipt")
                            {
                                dep = dep +  parseFloat(ix.amount);
                            }
                            else
                            {
                                dep = dep - parseFloat(ix.amount);
                            }

                        }
                        else
                        {
                            if (ix.type === "receipt")
                            {
                                pay = pay + parseFloat(ix.amount);
                            }
                            else
                            {
                                pay = pay - parseFloat(ix.amount);
                            }
                        }
                    }
                }

                pay = pay.toFixed(2);
                dep = dep.toFixed(2);

                objfee = {
                    columns:[
                        {
                            width: '15%',
                            text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                        },
                        [
                            {
                                style: 'tableExample',
                                table: {
                                    widths: ['5%','20%','50%','5%','20%'],
                                    body: [

                                        [
                                            '','',
                                            {fontSize: 9,bold: true,font: 'Helvetica', text: `For`,alignment: 'left'},
                                            '',
                                            {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'right'}
                                        ],
                                        [
                                            '','',
                                            {font: 'Courier',style: 'normal', text: `Payment`,alignment: 'left'},
                                            '',
                                            {font: 'Courier',style: 'normal', text: pay, alignment: 'right'},
                                        ],
                                        [
                                            '','',
                                            {font: 'Courier',style: 'normal', text: `Deposit`,alignment: 'left'},
                                            '',
                                            {font: 'Courier',style: 'normal', text: dep, alignment: 'right'},
                                        ],
                                    ]
                                },
                                layout: 'noBorders',
                            },

                        ],

                    ],
                    margin: [0, 0, 0, 0]
                };
                objfeeline = {
                    columns:[
                        {
                            width: '38%',
                            text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                        },
                        {
                            width: '62%',
                            color: 'gray',
                            text: ['.................................................................................................']
                        }

                    ],
                    margin: [0, 0, 0, 0]
                };
            }
            else
            {
                errorN(`Get Payment - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let d = new Date();
            let definition = {
                pageSize: 'A4',
                pageOrientation: 'portrait',
                pageMargins: [50, 100, 50, 50],
                header: function (page, pages) {
                    return {
                        table: {
                            widths: ['60%','40%'],
                            body: [
                                [
                                    [
                                        {text: `PAYMENT`,style: 'superheader',alignment: 'left'},
                                        {text: `\n${report.lblCompany}`,style: 'header',alignment: 'left'},
                                        {text: `\n${report.lblAddress}`,style: 'normal',alignment: 'left'},


                                    ],
                                    {alignment: 'right', image: 'mypic', width: 65, height: 65,},
                                ]

                            ],
                        },
                        layout: 'noBorders',
                        margin: [50, 30, 50, 5],
                    }
                },
                /*footer: function (page, pages) {
                    return [
                        /!*{ text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'center' },*!/

                        [
                            {
                                text: {fontSize: 7,bold: true,font: 'Helvetica', text: report.lblManagedBy, alignment: 'center'},
                                margin: [0, 0, 0, 0]
                            },
                            {
                                table: {
                                    widths: ['17%','17%','17%','17%','17%','15%'],
                                    body: [
                                        [
                                            {fontSize: 7, alignment: 'center', text: `Tel: ${report.lblTel}`},
                                            {fontSize: 7, alignment: 'center', text: `Email: ${report.lblEmail}`},
                                            {fontSize: 7, alignment: 'center', text: `Website: ${report.lblWebsite}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblSST !== undefined ? 'SST No.: ' + report.lblSST : ''}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblTTX !== undefined ? 'TTX No.: ' + report.lblSST : ''}`},
                                            {text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'right' },
                                        ]
                                    ]
                                },
                                layout: 'noBorders',
                                margin: [50, 10, 50, 20],
                            }
                        ]


                    ]


                },*/
                info: {
                    title: `Payment Slip`,
                    author: 'K.Nivagaran',
                    subject: 'create payment view',
                    keywords: '',
                },
                content: [
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        style: 'tableExample2',
                        table: {
                            widths: ['70%','13%','17%'],
                            body: [
                                [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Billed To`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Folio No.`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${billObj.no}`}
                                ],
                                [
                                    '',
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Date of Issue`},
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `:  ${moment(new Date).format('DD-MM-YYYY')}`}
                                ],
                                [
                                    {bold: true,style: 'normal', text: `${cust.name.toUpperCase()}`},
                                    '',
                                    ''
                                ],
                                [
                                    /*{style: 'normal', text: `${cust.address.line + ', ' + cust.address.postcode + ', ' + cust.address.city + ', ' + cust.address.state + ', ' + cust.address.country}`},*/
                                    {style: 'normal', text: `${custadds}`},
                                    '',
                                    ''
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['15%','55%','13%','17%'],
                            body: [
                                [
                                    {bold: true,style: 'normal', text: `Tel No.`},
                                    {style: 'normal', text: `:  ${cust.tel}`},
                                    {bold: true,style: 'normal', text: `Unit No.`},
                                    {style: 'normal', text: `:  ${unitNo}`},
                                ],

                            ]
                        },
                        layout: 'noBorders',
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Services`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyData
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 5, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Discount`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${discount.toFixed(2)}`,alignment: 'right'}
                                            ],
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Subtotal`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${(subtotal-discount).toFixed(2)}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    objfee,
                    objfeeline,
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Fee`},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataFee
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*objtax,*/
                    /*objtaxline,*/
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax`},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: bodyDataTax
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {font: 'Courier',style: 'normal', text: `Rounding Adjustment`},
                                                '',
                                                {font: 'Courier', style: 'normal', text: `${billObj.rounding !== undefined && billObj.rounding !== null ? billObj.rounding : 0.00}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '38%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            {
                                width: '62%',
                                color: 'gray',
                                text: ['.................................................................................................']
                            }

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['5%','20%','50%','5%','20%'],
                                        body: [
                                            [
                                                '',
                                                '',
                                                {fontSize: 9,bold: true,font: 'Helvetica', text: `TOTAL`},
                                                '',
                                                {fontSize: 9,bold: true,font: 'Helvetica', text: `${currCode} ${billObj.amount !== undefined && billObj.amount !== null ? billObj.amount : 0.00}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 0, 0, 0]
                    },*/
                    /*{
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },*/
                    /*{
                        columns:[
                            {
                                width: '15%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: ``},
                                ]
                            },
                            [
                                {
                                    style: 'tableExample',
                                    table: {
                                        widths: ['10%','30%','30%','30%'],
                                        body: bodyDataAllo
                                    },
                                    layout: 'noBorders',
                                },

                            ],

                        ],
                        margin: [0, 5, 0, 0]
                    },*/
                    /*{
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },*/
                    {
                        columns:[
                            {
                                width: '38%',
                                text: [
                                    {fontSize: 9,bold: true,font: 'Helvetica', text: `Notice to Guest:-\n\n`},
                                    {font: 'Courier',style: 'normal', text: notice},
                                ],
                                margin: [0, 15, 0, 0]
                            },
                            [
                                /*{
                                    text: {fontSize: 9,bold: true,font: 'Helvetica', text: `Payment Details:-\n\n`},
                                    margin: [0, 15, 0, 0]
                                },
                                {
                                    table: {
                                        widths: ['40%','60%'],
                                        body: [
                                            [
                                                {font: 'Courier',style: 'normal', text: `Payment`},
                                                {font: 'Courier', style: 'normal', text: `${pay}`,alignment: 'right'}
                                            ],
                                            [
                                                {font: 'Courier',style: 'normal', text: `Deposit`},
                                                {font: 'Courier', style: 'normal', text: `${dep}`,alignment: 'right'}
                                            ],
                                            [
                                                {font: 'Courier',style: 'normal', text: `Balance`},
                                                {font: 'Courier', style: 'normal', text: `${bal}`,alignment: 'right'}
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                    margin: [0, 0, 0, 0],
                                    lineHeight: 1.2
                                },*/
                                {
                                    text: [
                                        {fontSize: 9,bold: true,font: 'Helvetica', text: `Acknowledged By\n\n`},
                                        `\n\n`,
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Signature\t : __________________________\n\n`},
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Name\t\t\t: __________________________\n\n`},
                                        {fontSize: 8,bold: true,font: 'Helvetica', text: `Date\t\t\t  : __________________________`},
                                    ],
                                    margin: [123, 10, 0, 0]
                                }
                            ]


                        ],
                        margin: [0, 0, 0, 0]
                    },
                    {
                        color: 'gray',
                        text: ['.....................................................................................................................................................................']
                    },
                    {
                        text: {fontSize: 9,bold: true,font: 'Helvetica', text: footer, alignment: 'center'},
                        margin: [0, 5, 0, 0]
                    },

                ],
                styles: {
                    superheader: {
                        font: 'Helvetica',
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',

                    },
                    header: {
                        font: 'Helvetica',
                        fontSize: 12,
                        bold: true,
                        alignment: 'justify'
                    },
                    normal: {
                        fontSize: 8,
                        bold: false,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    normalbold: {
                        fontSize: 8,
                        bold: true,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    bolding: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    tableExample: {
                        fontSize: 8,
                        margin: [0, 5, 0, 0]
                    },
                    tableExample2: {
                        fontSize: 9,
                        margin: [0, 10, 0, 0]
                    },
                    tableExample3: {
                        fontSize: 10,
                        margin: [0, 5, 0, 15]
                    },
                },
                defaultStyle: {
                    font: 'Times',
                    columnGap: 23
                },
                images:{
                    mypic: logostr
                }
            }
            let win = window.open('', '_blank');
            pdfMake.createPdf(definition).open({}, win);
        }
        catch (err)
        {
            errorN(`Error Show Slip: ${err}. Please contact the administrator.`);
        }


    };

    return (
        <>
            <A href={''} onClick={() => {showSlip();}}>Payment Slip</A>
        </>
    );
}

export function ShowReport(props) {
    const { get, post, response, loading, error, del, put } = useFetch();

    let formatter = new Intl.NumberFormat('en-MY', {
        style: 'decimal'/*,
        currency: 'MYR',*/

    });
    let formatterNum = new Intl.NumberFormat('en-MY', {

    });

    useEffect(() => {
        return () => {

        }
    }, [])

    async function setReport(currCode){
        let reportno = props.no;
        let obj ={};

        if(reportno === '1')
        {
            obj.pageOrientation = 'landscape';
            let bodyData = [];
            let amount = 0;
            let received = 0;
            let balance = 0 ;

            let firstRow = [{fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Name`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Status`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Source`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Agency`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Ref. No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Arrival`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Departure`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `By`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Received (${currCode})`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Balance (${currCode})`,alignment: 'center'},
/*                {fontSize: 9,bold: true,font: 'Helvetica', text: `Contact No.`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Email`,alignment: 'center'},*/
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Nationality`,alignment: 'center'},
                /*{fontSize: 9,bold: true,font: 'Helvetica', text: `Cancel Reason`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Cancel By`,alignment: 'center'},*/
            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {fontSize: 6,font: 'Courier', text: sourceRow.date,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.no,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.name,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.status,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.source,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.otaagency,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.refno,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.start,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.end,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.by,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: parseFloat(sourceRow.amount).toFixed(2),alignment: 'right'},
                    {fontSize: 6,font: 'Courier', text: parseFloat(sourceRow.paid).toFixed(2),alignment: 'right'},
                    {fontSize: 6,font: 'Courier', text:  parseFloat(sourceRow.balance).toFixed(2),alignment: 'right'},
/*                  {fontSize: 6,font: 'Courier', text: sourceRow.tel,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.email,alignment: 'left'},*/
                    {fontSize: 6,font: 'Courier', text: sourceRow.nationality,alignment: 'left'},
                    /*{fontSize: 6,font: 'Courier', text: sourceRow.cancelNote,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.cancelBy,alignment: 'left'}*/
                ];
                bodyData.push(dataRow);

                amount = amount + parseFloat(sourceRow.amount);
                received = received + parseFloat(sourceRow.paid);
                balance = balance + parseFloat(sourceRow.balance);
            });

            let lastRow = [
                {font: 'Helvetica', text:'TOTAL',alignment: 'center',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text: amount.toFixed(2),alignment: 'right',bold: true},
                {font: 'Helvetica', text: received.toFixed(2),alignment: 'right',bold: true},
                {font: 'Helvetica', text: balance.toFixed(2),alignment: 'right',bold: true},
/*                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},*/
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                /*{font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true}*/

            ];
            bodyData.push(lastRow);

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['7%','7%','8%','7%','7%','8%','7%','7%','7%','7%','7%','7%','7%','7%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        if(reportno === '2')
        {
            obj.pageOrientation = 'landscape';
            let bodyData = [];
            let amount = 0;
            let received = 0;
            let balance = 0 ;

            let firstRow = [{fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Name`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Status`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Invoice No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Source`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Agency`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Ref. No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Arrival`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Departure`,alignment: 'center'},

                {fontSize: 8,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Received (${currCode})`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Balance (${currCode})`,alignment: 'center'},
                /*                {fontSize: 9,bold: true,font: 'Helvetica', text: `Contact No.`,alignment: 'center'},
                                {fontSize: 9,bold: true,font: 'Helvetica', text: `Email`,alignment: 'center'},*/
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Nationality`,alignment: 'center'},
                /*{fontSize: 9,bold: true,font: 'Helvetica', text: `Cancel Reason`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Cancel By`,alignment: 'center'},*/
            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {fontSize: 6,font: 'Courier', text: sourceRow.date,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.no,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.name,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.status,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.folinvno,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.source,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.otaagency,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.refno,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.start,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.end,alignment: 'center'},

                    {fontSize: 6,font: 'Courier', text: parseFloat(sourceRow.amount).toFixed(2),alignment: 'right'},
                    {fontSize: 6,font: 'Courier', text: parseFloat(sourceRow.paid).toFixed(2),alignment: 'right'},
                    {fontSize: 6,font: 'Courier', text:  parseFloat(sourceRow.balance).toFixed(2),alignment: 'right'},
                    /*                  {fontSize: 6,font: 'Courier', text: sourceRow.tel,alignment: 'left'},
                                        {fontSize: 6,font: 'Courier', text: sourceRow.email,alignment: 'left'},*/
                    {fontSize: 6,font: 'Courier', text: sourceRow.nationality,alignment: 'left'},
                    /*{fontSize: 6,font: 'Courier', text: sourceRow.cancelNote,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.cancelBy,alignment: 'left'}*/
                ];
                bodyData.push(dataRow);

                amount = amount + parseFloat(sourceRow.amount);
                received = received + parseFloat(sourceRow.paid);
                balance = balance + parseFloat(sourceRow.balance);
            });

            let lastRow = [
                {font: 'Helvetica', text:'TOTAL',alignment: 'center',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text: amount.toFixed(2),alignment: 'right',bold: true},
                {font: 'Helvetica', text: received.toFixed(2),alignment: 'right',bold: true},
                {font: 'Helvetica', text: balance.toFixed(2),alignment: 'right',bold: true},
                /*                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                                {font: 'Helvetica', text: '',alignment: 'right',bold: true},*/
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                /*{font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true}*/

            ];
            bodyData.push(lastRow);

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['7%','7%','8%','7%','7%','8%','7%','7%','7%','7%','7%','7%','7%','7%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        if(reportno === '3')
        {
            obj.pageOrientation = 'landscape';
            let bodyData = [];
            let amount = 0;
            let received = 0;
            let balance = 0 ;

            let firstRow = [{fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Name`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Status`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Folio No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Source`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Agency`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Ref. No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Arrival`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Departure`,alignment: 'center'},

                {fontSize: 8,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Received (${currCode})`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Balance (${currCode})`,alignment: 'center'},
                /*                {fontSize: 9,bold: true,font: 'Helvetica', text: `Contact No.`,alignment: 'center'},
                                {fontSize: 9,bold: true,font: 'Helvetica', text: `Email`,alignment: 'center'},*/
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Nationality`,alignment: 'center'},
                /*{fontSize: 9,bold: true,font: 'Helvetica', text: `Cancel Reason`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Cancel By`,alignment: 'center'},*/
            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {fontSize: 6,font: 'Courier', text: sourceRow.date,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.no,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.name,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.status,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.folinvno,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.source,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.otaagency,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.refno,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.start,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.end,alignment: 'center'},

                    {fontSize: 6,font: 'Courier', text: parseFloat(sourceRow.amount).toFixed(2),alignment: 'right'},
                    {fontSize: 6,font: 'Courier', text: parseFloat(sourceRow.paid).toFixed(2),alignment: 'right'},
                    {fontSize: 6,font: 'Courier', text:  parseFloat(sourceRow.balance).toFixed(2),alignment: 'right'},
                    /*                  {fontSize: 6,font: 'Courier', text: sourceRow.tel,alignment: 'left'},
                                        {fontSize: 6,font: 'Courier', text: sourceRow.email,alignment: 'left'},*/
                    {fontSize: 6,font: 'Courier', text: sourceRow.nationality,alignment: 'left'},
                    /*{fontSize: 6,font: 'Courier', text: sourceRow.cancelNote,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.cancelBy,alignment: 'left'}*/
                ];
                bodyData.push(dataRow);

                amount = amount + parseFloat(sourceRow.amount);
                received = received + parseFloat(sourceRow.paid);
                balance = balance + parseFloat(sourceRow.balance);
            });

            let lastRow = [
                {font: 'Helvetica', text:'TOTAL',alignment: 'center',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text: amount.toFixed(2),alignment: 'right',bold: true},
                {font: 'Helvetica', text: received.toFixed(2),alignment: 'right',bold: true},
                {font: 'Helvetica', text: balance.toFixed(2),alignment: 'right',bold: true},
                /*                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                                {font: 'Helvetica', text: '',alignment: 'right',bold: true},*/
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                /*{font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true}*/

            ];
            bodyData.push(lastRow);

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['7%','7%','8%','7%','7%','8%','7%','7%','7%','7%','7%','7%','7%','7%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        if(reportno === '4')
        {
            obj.pageOrientation = 'landscape';
            let bodyData = [];
            let amount = 0;

            let firstRow = [{fontSize: 8,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Payment No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Received For`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Name`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Method`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Type`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `By`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Category`,alignment: 'center'},
            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {fontSize: 6,font: 'Courier', text:sourceRow.date,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.no,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.billNo,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.name,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: parseFloat(sourceRow.amount).toFixed(2),alignment: 'right'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.method,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.type,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.by,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.payingFor,alignment: 'left'},
                ];
                bodyData.push(dataRow);

                amount = amount + parseFloat(sourceRow.amount);
            });

            let lastRow = [
                {font: 'Helvetica', text:'TOTAL',alignment: 'center',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: amount.toFixed(2),alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
            ];
            bodyData.push(lastRow);

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            //widths: ['10%','10%','10%','20%','10%','10%','10%','10%','10%'],
                            widths: ['14%','9%','9%','20%','9%','9%','10%','10%','10%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        if(reportno === '5')
        {
            obj.pageOrientation = 'landscape';
            let bodyData = [];
            let amount = 0;

            let firstRow = [{fontSize: 8,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Payment No.`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Received For`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Name`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Method`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Type`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `By`,alignment: 'center'},
                {fontSize: 8,bold: true,font: 'Helvetica', text: `Category`,alignment: 'center'},
            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {fontSize: 6,font: 'Courier', text: sourceRow.date,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.no,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.billNo,alignment: 'center'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.name,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: parseFloat(sourceRow.amount).toFixed(2),alignment: 'right'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.method,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.type,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.by,alignment: 'left'},
                    {fontSize: 6,font: 'Courier', text: sourceRow.payingFor,alignment: 'left'},
                ];
                bodyData.push(dataRow);

                amount = amount + parseFloat(sourceRow.amount);
            });

            let lastRow = [
                {font: 'Helvetica', text:'TOTAL',alignment: 'center',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: amount.toFixed(2),alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
            ];
            bodyData.push(lastRow);

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['14%','9%','9%','20%','9%','9%','10%','10%','10%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        if(reportno === '8')
        {
            obj.pageOrientation = 'landscape';
            let bodyData = [];
            let subtotal = 0;

            let firstRow = [{fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Invoice No.`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Name`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Subtotal (${currCode})`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Type`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'center'},
            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {font: 'Courier', text: moment(sourceRow.date).format('DD-MM-YYYY'),alignment: 'center'},
                    {font: 'Courier', text: sourceRow.no,alignment: 'center'},
                    {font: 'Courier', text: sourceRow.name,alignment: 'left'},
                    {font: 'Courier', text: sourceRow.subtotal,alignment: 'right'},
                    {font: 'Courier', text: sourceRow.type,alignment: 'left'},
                    {font: 'Courier', text: sourceRow.tax,alignment: 'right'}
                ];
                bodyData.push(dataRow);

                subtotal = subtotal + parseFloat(sourceRow.tax);
            });

            let lastRow = [
                {font: 'Helvetica', text:'TOTAL',alignment: 'center',bold: true},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true},
                {font: 'Helvetica', text:'',alignment: 'left',bold: true},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true},
                {font: 'Helvetica', text: '',alignment: 'left',bold: true},
                {font: 'Helvetica', text: subtotal.toFixed(2),alignment: 'right',bold: true}
            ];
            bodyData.push(lastRow);

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['12%','12%','26%','12%','26%','12%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        if(reportno === '9')
        {
            obj.pageOrientation = 'landscape';
            let bodyData = [];
            let subtotal = 0;
            let tax = 0;
            let rounding = 0;
            let amount = 0;

            let firstRow = [{fontSize: 9,bold: true,font: 'Helvetica', text: `Date`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Invoice No.`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Subtotal (${currCode})`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Tax (${currCode})`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Rounding (${currCode})`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Amount (${currCode})`,alignment: 'center'},
            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {font: 'Courier', text: moment(sourceRow.date).format('DD-MM-YYYY'),alignment: 'center'},
                    {font: 'Courier', text: sourceRow.no,alignment: 'center'},
                    {font: 'Courier', text: sourceRow.subtotal,alignment: 'right'},
                    {font: 'Courier', text: sourceRow.tax,alignment: 'right'},
                    {font: 'Courier', text: sourceRow.rounding,alignment: 'right'},
                    {font: 'Courier', text: sourceRow.amount,alignment: 'right'}
                ];
                bodyData.push(dataRow);

                subtotal = subtotal + parseFloat(sourceRow.subtotal);
                tax = tax + parseFloat(sourceRow.tax);
                rounding = rounding + parseFloat(sourceRow.rounding);
                amount = amount + parseFloat(sourceRow.amount);

            });

            let lastRow = [
                {font: 'Helvetica', text:'TOTAL',alignment: 'center',bold: true,},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true,},
                {font: 'Helvetica', text: subtotal.toFixed(2),alignment: 'right',bold: true,},
                {font: 'Helvetica', text: tax.toFixed(2),alignment: 'right',bold: true,},
                {font: 'Helvetica', text: rounding.toFixed(2),alignment: 'right',bold: true,},
                {font: 'Helvetica', text: amount.toFixed(2),alignment: 'right',bold: true,}
            ];
            bodyData.push(lastRow);

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['20%','20%','15%','15%','15%','15%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        if(reportno === '11')
        {
            obj.pageOrientation = 'portrait';
            let bodyData = [];

            let firstRow = [{fontSize: 9,bold: true,font: 'Helvetica', text: `Unit No.`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Housekeeper`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Status`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Start`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `End`,alignment: 'center'},
            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {font: 'Courier', text: sourceRow.roomNo,alignment: 'center'},
                    {font: 'Courier', text: sourceRow.name,alignment: 'left'},
                    {font: 'Courier', text: sourceRow.status,alignment: 'left'},
                    {font: 'Courier', text: sourceRow.start,alignment: 'center'},
                    {font: 'Courier', text: sourceRow.end,alignment: 'center'}
                ];
                bodyData.push(dataRow);

            });

           /* let lastRow = [
                {font: 'Helvetica', text:'',alignment: 'center',bold: true,},
                {font: 'Helvetica', text: '',alignment: 'center',bold: true,},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true,},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true,},
                {font: 'Helvetica', text: '',alignment: 'right',bold: true,}

            ];
            bodyData.push(lastRow);*/

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['10%','25%','15%','25%','25%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        if(reportno === '12')
        {
            obj.pageOrientation = 'portrait';
            let bodyData = [];

            let firstRow = [{fontSize: 9,bold: true,font: 'Helvetica', text: `Unit No.`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Status`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Date Range`,alignment: 'center'},
                {fontSize: 9,bold: true,font: 'Helvetica', text: `Notes`,alignment: 'center'},

            ];
            bodyData.push(firstRow)

            props.data.forEach(function(sourceRow) {
                let dataRow = [
                    {font: 'Courier', text: sourceRow.roomNo,alignment: 'center'},
                    {font: 'Courier', text: sourceRow.status,alignment: 'center'},
                    {font: 'Courier', text: sourceRow.date,alignment: 'center'},
                    {font: 'Courier', text: sourceRow.reason,alignment: 'left'},

                ];
                bodyData.push(dataRow);

            });

            /* let lastRow = [
                 {font: 'Helvetica', text:'',alignment: 'center',bold: true,},
                 {font: 'Helvetica', text: '',alignment: 'center',bold: true,},
                 {font: 'Helvetica', text: '',alignment: 'right',bold: true,},
                 {font: 'Helvetica', text: '',alignment: 'right',bold: true,},
                 {font: 'Helvetica', text: '',alignment: 'right',bold: true,}

             ];
             bodyData.push(lastRow);*/

            obj.columnArr = [
                [
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['10%','10%','25%','55%'],
                            body: bodyData
                        },
                        //layout: 'noBorders',
                    },

                ],

            ];
        }

        return obj;
    }

    async function showReport(){
        try {

            let propsObj = props;
            let currCode = localStorage.getItem('currencycode');
            let footer = localStorage.getItem('foliofooter');
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));



            if(propsObj.data.length <= 0)
            {
                errorN('No data available');
                return;
            }

            let report = {};
            let bodyData = [];
            let columnArr = [];

            let subtotal = 0;

            let hotelObj = await get(`/api/hotels/${globalObj.id}`)
            if (response.ok)
            {
                if(hotelObj.data === null || hotelObj.data === undefined)
                {
                    errorN('Important Data Missing. Please fill in Property Information to proceed.');
                    return;
                }

                report.lblCompany = hotelObj.data.name;
                report.lblAddress = hotelObj.data.address != null ? hotelObj.data.address.line + ', ' + hotelObj.data.address.postcode + ', ' + hotelObj.data.address.city + ', ' + hotelObj.data.address.state + ', ' + hotelObj.data.address.country : undefined;
                report.lblWebsite = hotelObj.data.website;
                report.lblTel = hotelObj.data.tel;
                report.lblEmail = hotelObj.data.email;
            }
            else
            {
                errorN(`Get Hotel Info - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }


            let a = window.location.origin;
            let logostr = a + '/images/square-xxl.png';
            let logoList = await get(`/api/files/?hotel=${globalObj.id}`)
            if (response.ok)
            {
                let lid = '';
                if(logoList.length > 0)
                {
                    for(let i of logoList)
                    {
                        if(i.data !== null && i.data !== undefined)
                        {
                            if(i.data.name.substring(0, 4) === 'logo')
                            {
                                lid = i.id;
                            }
                        }
                    }
                }

                if(lid !== '')
                {
                    let a = window.location.origin;
                    logostr = a + `/api/files/${lid}?action=download`;
                }
            }
            else
            {
                errorN(`Get Logo - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                return;
            }

            let obj = await setReport(currCode);

            let d = new Date();
            let definition = {
                pageSize: 'A4',
                pageOrientation: obj.pageOrientation,
                pageMargins: [50, 100, 50, 50],
                header: function (page, pages) {
                    return {
                        table: {
                            widths: ['60%','40%'],
                            body: [
                                [
                                    [
                                        {text: `${props.name}`,style: 'superheader',alignment: 'left'},
                                        {text: `\n${props.range}`,style: 'normal10',alignment: 'left'},
                                    ],
                                    {alignment: 'right', image: 'mypic', width: 65, height: 65,},
                                ]

                            ],
                        },
                        layout: 'noBorders',
                        margin: [50, 30, 50, 5],
                    }
                },
                footer: function (page, pages) {
                    return [
                        /*{ text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'center' },*/

                        [
                            {
                                text: {fontSize: 7,bold: true,font: 'Helvetica', text: report.lblManagedBy, alignment: 'center'},
                                margin: [0, 0, 0, 0]
                            },
                            {
                                table: {
                                    widths: ['17%','17%','17%','17%','17%','15%'],
                                    body: [
                                        [
                                            {fontSize: 7, alignment: 'center', text: `Tel: ${report.lblTel}`},
                                            {fontSize: 7, alignment: 'center', text: `Email: ${report.lblEmail}`},
                                            {fontSize: 7, alignment: 'center', text: `Website: ${report.lblWebsite}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblSST !== undefined ? 'SST No.: ' + report.lblSST : ''}`},
                                            {fontSize: 7, alignment: 'center', text: `${report.lblTTX !== undefined ? 'TTX No.: ' + report.lblTTX : ''}`},
                                            {text: page.toString() + ' of ' + pages, fontSize: 7, alignment: 'right' },
                                        ]
                                    ]
                                },
                                layout: 'noBorders',
                                margin: [50, 10, 50, 20],
                            }
                        ]


                    ]


                },
                info: {
                    title: `${props.name}`,
                    author: 'K.Nivagaran',
                    subject: 'create report view',
                    keywords: '',
                },
                content: [
                    {
                        columns:obj.columnArr,
                        margin: [0, 5, 0, 0]
                    },

                ],
                styles: {
                    superheader: {
                        font: 'Helvetica',
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',

                    },
                    header: {
                        font: 'Helvetica',
                        fontSize: 12,
                        bold: true,
                        alignment: 'justify'
                    },
                    normal: {
                        fontSize: 8,
                        bold: false,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    normal10: {
                        fontSize: 10,
                        bold: false,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    normalbold: {
                        fontSize: 8,
                        bold: true,
                        alignment: 'justify',
                        lineHeight: 1.2
                    },
                    bolding: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'justify'
                    },
                    tableExample: {
                        fontSize: 8,
                        margin: [0, 5, 0, 0]
                    },
                    tableExample2: {
                        fontSize: 9,
                        margin: [0, 10, 0, 0]
                    },
                    tableExample3: {
                        fontSize: 10,
                        margin: [0, 5, 0, 15]
                    },
                },
                defaultStyle: {
                    font: 'Times',
                    columnGap: 23
                },
                images:{
                    mypic: logostr
                }
            }
            let win = window.open('', '_blank');
            pdfMake.createPdf(definition).open({}, win);
        }
        catch (err)
        {
            errorN(`Error Show Folio: ${err}. Please contact the administrator.`);
        }


    };

    return (
        <>
            <Button style={{marginTop:'7px'}} onClick={showReport} key=""type="danger">Save as PDF</Button>
        </>
    );
}
