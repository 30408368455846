import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,    
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function EditLines(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [disabled, setDisabled] = useState(false);

    const [bookedRoomList, setBookedRoomList] = useState([]);
    const [roomList, setRoomList] = useState([]);

    const labelCol = 22;
    const wrapperCol = 22;

    useEffect(() => {

        loadRoomNo();

        return () => {

        }
    }, [])

    async function changeNow() {
        try {
            //const values = form.getFieldsValue(true);

            form.validateFields().then(async values => {
                let propsObj = props.obj;

                let linesObj = await get(`/api/folio_lines/${propsObj.id}`)
                if (response.ok)
                {
                    let d = [];
                    d.push({
                    name: "Open",
                        rate: values.discount,
                        calc: "fixed"
                    });
                    linesObj.data.item.discounts = d;
                    linesObj.open = linesObj.data.open;

                    let a = await put(`/api/folio_lines/${propsObj.id}`,linesObj)
                    if (response.ok)
                    {
                        form.setFieldsValue({
                            discount: undefined
                        });
                        successN('Updated successful');
                        props.loadData();
                    }
                    else
                    {
                        errorN(`Discount - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    errorN(`Get Lines - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }




            });

        }
        catch (err)
        {
            errorN(`Error Discount: ${err}. Please contact the administrator.`);
        }

    }

    async function loadRoomNo() {
        try
        {
            let propsObj = props.obj;

            form.setFieldsValue({
                item: propsObj.item
            });
        }
        catch (err)
        {
            errorN(`Error Load Allocation: ${err}. Please contact the administrator.`);
        }
    }

    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{padding: '20px'}}>
                        <div>

                            <Form.Item
                                label="Item Name"
                                name="item"
                                labelCol={{ span: labelCol }}
                                wrapperCol={{ span: wrapperCol }}
                                rules={[]}
                            >
                                <Input disabled placeholder="Unit No." />
                            </Form.Item>

                            <Form.Item
                                label="Discount Amount"
                                name="discount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Amount',
                                    },
                                ]}
                            >
                                <InputNumber style={{width: '90%'}}  min={0.05} max={10000000000}
                                             formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>

                            <Popconfirm
                                placement="topRight"
                                title="Are you sure to update this item?"
                                onConfirm={changeNow}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="danger" loading={loading}>
                                    <CloudUploadOutlined  /> Update
                                </Button>
                            </Popconfirm>
                        </div>
                    </Col>
                </Row>
            </Form>

        </>
    );
}

export default EditLines
