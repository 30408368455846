import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,    
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

function RewriteCard(props) {
    const { get, post, response, loading, error, del, put } = useFetch(`http://localhost:8023`)
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [disabled, setDisabled] = useState(false);

    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

// eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        // Can not select days before today and today
        //console.log('ini',current.add(-1, 'days'))
        console.log('ini dia',dayjs().endOf('day'))
        return current && current <= dayjs().endOf('day');
    };
    const disabledDateTime = () => ({
        disabledHours: () => range(0, 24).splice(0, 12),
        //disabledMinutes: () => range(30, 60),
        //disabledSeconds: () => [55, 56],
        disabledSeconds: () => range(0, 60).splice(0, 60),
    });
    const disabledRangeTime = (_, type) => {
        if (type === 'start') {
            return {
                //disabledHours: () => range(0, 60).splice(4, 20),
                //disabledMinutes: () => range(30, 60),
                //disabledSeconds: () => [55, 56],
            };
        }
        return {
            //disabledHours: () => range(0, 60).splice(20, 4),
            //disabledMinutes: () => range(0, 31),
            //disabledSeconds: () => [55, 56],
        };
    };


    useEffect(() => {

        readCard();

        return () => {

        }
    }, [])

    const onSubmit = () => {

        writecard();
    }

    async function readCard(){
        try {
            let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));

            if(devicesetting !== null)
            {
                if(devicesetting.data !== undefined)
                {
                    if(devicesetting.data.doorLock !== undefined)
                    {
                        let cardi = await get(`/card_read?code=${devicesetting.data.doorLock.code}&type=${devicesetting.data.doorLock.type}`)
                        if (response.status === 200)
                        {
                            if(cardi === '' || cardi === null || cardi === undefined)
                            {
                                setDisabled(true);
                                errorN('No data');
                            }
                            else
                            {
                                if ((cardi.room.room === '0' && cardi.room.floor === '0' && cardi.room.building === '0') || (cardi.room.room === 0 && cardi.room.floor === 0 && cardi.room.building === 0) )
                                {
                                    form.setFieldsValue({unit: cardi.room.doorId !== undefined && cardi.room.doorId !== null ? cardi.room.doorId : 'No data', dt: cardi.checkout !== undefined && cardi.checkout !== null ? moment(cardi.checkout) : undefined});
                                }
                                else
                                {
                                    form.setFieldsValue({unit: cardi.room.room !== undefined && cardi.room.room !== null ? cardi.room.room : 'No data', dt: cardi.checkout !== undefined && cardi.checkout !== null ? moment(cardi.checkout) : undefined});
                                }
                            }
                        }
                        else
                        {
                            setDisabled(true);
                           errorN('Failed: No data. Please contact the administrator');
                        }
                    }
                }
            }
        }
        catch (e) {
            setDisabled(true);
            errorN(e.message);
        }
    }

    async function writecard() {
        try
        {
            const values = form.getFieldsValue(true);

            let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));

            if(devicesetting !== null)
            {
                if(devicesetting.data !== undefined)
                {
                    if(devicesetting.data.doorLock !== undefined)
                    {
                        let cardi = await get(`/card_read?code=${devicesetting.data.doorLock.code}&type=${devicesetting.data.doorLock.type}`)
                        if (response.status === 200)
                        {
                            if(cardi === '' || cardi === null || cardi === undefined)
                            {
                                errorN('No data');
                            }
                            else
                            {
                                cardi.checkout = moment(values.dt).format('YYYY-MM-DDTHH:mm:SS');

                                let a = await post(`/card_issue?code=${devicesetting.data.doorLock.code}&type=${devicesetting.data.doorLock.type}&allocation=1`, cardi)
                                if (response.ok)
                                {
                                    successN("Write Card Successful");
                                }
                                else
                                {
                                    errorN(`Write Card - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                                }
                            }
                        }
                        else
                        {
                            errorN('Failed: No data. Please contact the administrator');
                        }
                    }
                }
            }
        }
        catch (err)
        {
            errorN(`Error Write Card: ${err}. Please contact the administrator.`);
        }
    }

    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{padding: '20px'}}>
                        <Form.Item
                            label="Unit No."
                            name="unit"
                            rules={[]}
                        >
                            <Input disabled placeholder="Unit No." />
                        </Form.Item>

                        <Form.Item
                            label="Check-Out"
                            name="dt"
                            rules={[]}
                        >
                            <DatePicker
                                allowClear ={false}
                                format="YYYY-MM-DD HH:mm:ss"
                                //disabledDate={disabledDate}
                                //disabledTime={disabledDateTime}
                                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                            />
                        </Form.Item>

                        <br />
                        <Button type="danger" onClick={() => form.submit()}  loading={loading} disabled={disabled}>
                            <StepForwardOutlined /> Confirm
                        </Button>
                    </Col>
                </Row>

            </Form>

        </>
    );
}

export default RewriteCard
