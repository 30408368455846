import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Image,Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input,  Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    ShoppingCartOutlined,
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined, FileExcelOutlined, SearchOutlined, TeamOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN, errorN, toSentenceCase} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import { Table } from "ant-table-extensions";
import Highlighter from "react-highlight-words";

const { TextArea } = Input;
const { Option } = Select;
const {Sider, Content,Footer } = Layout;

function Party(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [data, setData] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [rateList, setRateList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [feeList, setFeeList] = useState([]);
    const [image, setImage] = useState('');

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');

    const [nationalityList, setNationalityList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [tabkey, setTabkey]= useState( '');

    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput=node;
                    }}
                    placeholder={`Search ${caption}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
        /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };

    async function loadCbo() {
        let obj = JSON.parse(localStorage.getItem('countrylist'));

        setNationalityList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setCountryList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    }

    const getState = (e) => {

        try
        {
            setStateList([]);
            form.setFieldsValue({
                state: undefined
            });

            let obj = JSON.parse(localStorage.getItem('countrylist'));

            let ls = obj.data.filter(x => x.name === e);

            if(ls.length > 0)
            {
                setStateList(ls[0].states.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)));
            }

        }
        catch(err)
        {
            errorN(`Error Get States: ${err}. Please contact the administrator.`);
        }

    }

    useEffect(() => {

        loadCbo();
        loadProperty();

        return () => {

        }
    }, [])

    async function loadProperty() {
        try
        {
            let list = JSON.parse(localStorage.getItem('allproperties'));

            let ls = [];
            for(let i of list)
            {
                ls.push({id: i.id.toString(), name: i.data.name})
            }

            setPropertyList(ls);
        }
        catch (err)
        {
            errorN(`Error Load Property: ${err}. Please contact the administrator.`);
        }
    }

    const onSubmit = () => {
        form.validateFields().then(values => {
            AssignNow();
        })
    }

    async function AssignNow() {
        try {
            const values = form.getFieldsValue(true);

            let propertiesobj = JSON.parse(localStorage.getItem('allproperties'));

            if(values.id === undefined || values.id === null || values.id === '')
            {
                let company = {};
                let staff = {};
                let supplier = {};
                let client = {};
                let arr = [];


                if (values.type === "company")
                {
                    company = {
                        sstNo : values.sstno,
                        ttxNo : values.ttxno,
                        //companyRegNo : txtCompRegNoCompany.Text.Trim(),
                        contactPerson : values.contactcompany,
                        contactNo : values.telcompany,
                        fax : values.faxcompany

                    };
                }
                else if (values.type === "staff")
                {

                   if(values.property === undefined || values.property === null || values.property === ''|| values.property === [])
                   {
                       errorN(`Choose a property to proceed.`);
                       return;
                   }

                    const selectedValue = values.property;
                    let selectedObj = propertiesobj.find(x => x.id.toString() === selectedValue.toString());


                    staff = {

                        designation : values.designation,
                            gender : values.gender,
                        //nationality : leditNationality.Text.Trim(),
                        role : values.role,
                        property : { id : selectedObj.id , name : selectedObj.data.name}
                    };
                }
                else if (values.type === "client")
                {
                    client = {
                        //companyRegNo : txtCompRegNoClient.Text.Trim(),
                        contactPerson : values.contactclient,
                        contactNo : values.telclient,
                        fax : values.faxclient
                    };
                }
                else if (values.type === "supplier")
                {
                    if(values.property === undefined || values.property === null || values.property === ''|| values.property === [])
                    {
                        errorN(`Choose a property to proceed.`);
                        return;
                    }

                    supplier = {
                        //companyRegNo : txtCompRegNoSupplier.Text.Trim(),
                        contactPerson : values.contactsupplier,
                        contactNo : values.telsupplier,
                        fax : values.faxsupplier,
                        //channelName : txtChannel.Text.Trim(),
                        channelID : values.channelid,
                        chargeSC : values.servicecharge === 'true',
                        chargeSST : values.sst === 'true',
                        chargeTTX :values.tourismtax === 'true'
                    };

                    for(let i of values.property)
                    {
                        let selectedObj = propertiesobj.find(x => x.id.toString() === i.toString());

                        arr.push({ id : selectedObj.id , name : selectedObj.data.name});
                    }
                }

                let party = {
                    category : values.category,
                    data : {
                        name : values.name,
                        email : values.email,
                        tel : values.tel,
                        address : {
                            line :  values.line,
                            city :  values.city,
                            postcode :  values.postcode,
                            country :  values.country,
                            state :  values.state
                        },
                        client : values.type === "client" ? client : null,
                        company :  values.type === "company" ? company : null,
                        guest : null,
                        staff :  values.type === "staff" ? staff : null,
                        supplier :  values.type === "supplier" ? supplier : null,
                        identificationType : values.identificationtype,
                        identificationNo : values.identificationno,
                        nationality : null,
                        belongsTo : arr
                    }
                };


                let a = await post(`/api/parties`,party)
                if (response.ok)
                {
                    let type = values.type;
                    successN('Party Created successfully');
                    await getPartyList(type);
                    form.resetFields();
                    setImage('');

                    form.setFieldsValue({type: type});
                }
                else
                {
                    errorN(`Create Party - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                let itemObj = await get(`/api/parties/${values.id}`)
                if (response.ok)
                {
                    let company = {};
                    let staff = {};
                    let supplier = {};
                    let client = {};
                    let arr = [];

                    if (values.type === "company")
                    {
                        company = {
                            sstNo : values.sstno,
                            ttxNo : values.ttxno,
                            //companyRegNo : txtCompRegNoCompany.Text.Trim(),
                            contactPerson : values.contactcompany,
                            contactNo : values.telcompany,
                            fax : values.faxcompany

                        };
                    }
                    else if (values.type === "staff")
                    {

                        if(values.property === undefined || values.property === null || values.property === ''|| values.property === [])
                        {
                            errorN(`Choose a property to proceed.`);
                            return;
                        }

                        const selectedValue = values.property;
                        let selectedObj = propertiesobj.find(x => x.id.toString() === selectedValue.toString());


                        staff = {

                            designation : values.designation,
                            gender : values.gender,
                            //nationality : leditNationality.Text.Trim(),
                            role : values.role,
                            property : { id : selectedObj.id , name : selectedObj.data.name}
                        };
                    }
                    else if (values.type === "client")
                    {
                        client = {
                            //companyRegNo : txtCompRegNoClient.Text.Trim(),
                            contactPerson : values.contactclient,
                            contactNo : values.telclient,
                            fax : values.faxclient
                        };
                    }
                    else if (values.type === "supplier")
                    {
                        if(values.property === undefined || values.property === null || values.property === ''|| values.property === [])
                        {
                            errorN(`Choose a property to proceed.`);
                            return;
                        }

                        supplier = {
                            //companyRegNo : txtCompRegNoSupplier.Text.Trim(),
                            contactPerson : values.contactsupplier,
                            contactNo : values.telsupplier,
                            fax : values.faxsupplier,
                            //channelName : txtChannel.Text.Trim(),
                            channelID : values.channelid,
                            chargeSC : values.servicecharge === 'true',
                            chargeSST : values.sst === 'true',
                            chargeTTX :values.tourismtax === 'true'
                        };

                        for(let i of values.property)
                        {
                            let selectedObj = propertiesobj.find(x => x.id.toString() === i.toString());

                            arr.push({ id : selectedObj.id , name : selectedObj.data.name});
                        }
                    }


                    itemObj.category = values.category;

                    itemObj.data.name = values.name;
                    itemObj.data.email = values.email;
                    itemObj.data.tel = values.tel;
                    itemObj.data.address.line =  values.line;
                    itemObj.data.address.city =  values.city;
                    itemObj.data.address.postcode =  values.postcode;
                    itemObj.data.address.country =  values.country;
                    itemObj.data.address.state =  values.state;
                    itemObj.data.client = values.type === "client" ? client : null;
                    itemObj.data.company =  values.type === "company" ? company : null;
                    itemObj.data.guest = null;
                    itemObj.data.staff =  values.type === "staff" ? staff : null;
                    itemObj.data.supplier =  values.type === "supplier" ? supplier : null;
                    itemObj.data.identificationType = values.identificationtype;
                    itemObj.data.identificationNo = values.identificationno;
                    itemObj.data.nationality = null;
                    itemObj.data.belongsTo = arr;


                    let a = await put(`/api/parties/${values.id}`,itemObj)
                    if (response.ok)
                    {
                        let type = values.type;
                        successN('Party Updated successfully');
                        await getPartyList(type);
                        form.resetFields();
                        setImage('');

                        form.setFieldsValue({type: type});
                    }
                    else
                    {
                        errorN(`Update Party - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Update Party - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }


        }
        catch (err)
        {
            errorN(`Error Save Party: ${err}. Please contact the administrator.`);
        }
    }

    async function getItem(id) {
        try
        {
            const values = form.getFieldsValue(true);

            let itemObj = await get(`/api/parties/${id}`)
            if (response.ok)
            {
                itemObj.type = values.type;

                itemObj.tel= itemObj.data.tel;
                itemObj.name= itemObj.data.name;
                itemObj.email= itemObj.data.email;

                itemObj.city= itemObj.data.address.city;
                itemObj.line= itemObj.data.address.line;
                itemObj.state= itemObj.data.address.state;
                itemObj.country= itemObj.data.address.country;
                itemObj.postcode= itemObj.data.address.postcode;


                if(itemObj.data.belongsTo !== [] && itemObj.data.belongsTo !== null && itemObj.data.belongsTo !== undefined)
                {
                    let arr = [];
                    for(let i of itemObj.data.belongsTo)
                    {
                        arr.push(i.id.toString())
                    }

                    itemObj.property= arr;
                }

                itemObj.identificationno= itemObj.data.identificationNo;
                itemObj.identificationtype= itemObj.data.identificationType;

                if(values.type === 'supplier')
                {
                    itemObj.channelid= itemObj.data.supplier.channelID;
                    itemObj.servicecharge = itemObj.data.supplier.chargeSC.toString();
                    itemObj.sst= itemObj.data.supplier.chargeSST.toString();
                    itemObj.tourismtax= itemObj.data.supplier.chargeTTX.toString();


                    itemObj.contactsupplier= itemObj.data.supplier.contactPerson;
                    itemObj.telsupplier= itemObj.data.supplier.contactNo;
                    itemObj.faxsupplier= itemObj.data.supplier.fax;
                }

                if(values.type === 'client')
                {
                    itemObj.contactclient= itemObj.data.client.contactPerson;
                    itemObj.telclient= itemObj.data.client.contactNo;
                    itemObj.faxclient= itemObj.data.client.fax;
                }

                if(values.type === 'staff')
                {
                    itemObj.designation= itemObj.data.staff.designation;
                    itemObj.gender= itemObj.data.staff.gender;
                    itemObj.role= itemObj.data.staff.role;
                    itemObj.property= itemObj.data.staff.property.id.toString();
                }

                if(values.type === 'company')
                {
                    itemObj.ttxno= itemObj.data.company.ttxNo;
                    itemObj.sstno = itemObj.data.company.sstNo;

                    itemObj.contactcompany= itemObj.data.company.contactPerson;
                    itemObj.telcompany= itemObj.data.company.contactNo;
                    itemObj.faxcompany= itemObj.data.company.fax;
                }


                form.setFieldsValue(itemObj);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get Party - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Party: ${err}. Please contact the administrator.`);
        }
    }

    /*async function removeItem(id) {
        try
        {
            const values = form.getFieldsValue(true);
            let obj = JSON.parse(localStorage.getItem('channelsetting'));

            if(obj !== undefined && obj !== null)
            {
                if(obj.data.channelName !== '')
                {
                    errorN(`NOT ALLOWED: This property has been synced to a Channel Manager`);
                    return;
                }
            }

            if (id === undefined || id === null || id === '')
            {
                errorN("ID not found. Please contact the administrator.");
                return;
            }


            let a = await del(`/api/items/${id}`)
            if (response.ok)
            {

                let propertyid = values.property;
                successN('Item Removed successfully');
                await getItemList(propertyid);
                form.resetFields();

                form.setFieldsValue({property: propertyid});
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Item Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Remove Item: ${err}. Please contact the administrator.`);
        }
    }*/

    const columns = [

        {
            width: '20%',
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <a  onClick={() => getItem(record.id)}>
                        <EditOutlined twoToneColor="blue"/>
                        &nbsp;
                        Edit
                    </a>
                </>
            )
        },
        {
            width: '70%',
            title:"Name",
            dataIndex:"name",
            key:"name",
            ...getColumnSearchProps('name','Name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        /*{
            width: '10%',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Do you want to remove this?`}
                        onConfirm={() => removeItem(record.id)}
                        okText="Yes"
                        cancelText="No">
                        <a>
                            <CloseCircleOutlined style={{color: 'red'}}/>
                        </a>
                    </Popconfirm>

                </>
            )
        },*/
    ]

    async function getPartyList(e){
        try
        {
            let itemList = await get(`/api/parties?type=${e}`)
            if (response.ok)
            {
                //console.log(itemList)

                for (let i of itemList)
                {
                    i.name = i.data.name
                }
                setData(itemList);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Get User List - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get Item List: ${err}. Please contact the administrator.`);
        }


    }

    const onChange = (e) => {
        setData([]);
        form.resetFields();

        setTabkey(e.target.value);
        form.setFieldsValue({type: e.target.value});

        getPartyList(e.target.value).then(r => {});
    }

    return (
        <>
            <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
                &nbsp;&nbsp;<TeamOutlined />
                &nbsp;&nbsp;Party {`${tabkey ? ' - ' + toSentenceCase(tabkey) : ''}`}
            </div>
            <Layout>

                <Spin spinning={loading}>
                    <Content style={{backgroundColor: 'white'}}>
                        <Form
                            layout={formLayout}
                            form={form}
                            // style={{ width: '100%'}}
                            onFinish={handleFinish}
                        >
                            <Form.Item
                                style={{ display: 'none' }}
                                label="id"
                                name="id"
                                rules={[]}
                            >
                                <InputNumber />
                            </Form.Item>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{padding: '20px'}}>

                                    <Form.Item
                                        name="type"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose a Type',
                                            },
                                        ]}
                                    >
                                        <Radio.Group onChange={onChange}>
                                            <Radio value='client'>Client</Radio>
                                            <Radio value='staff'>Staff</Radio>
                                            <Radio value='supplier'>Supplier</Radio>
                                            <Radio value='company'>Company</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Name',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Contact No."
                                        name="tel"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Contact No.',
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Contact Number" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Email',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Email" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Identification Type"
                                        name="identificationtype"
                                    >
                                        <Select style={{ width: '50%' }}
                                            placeholder="Identification Type"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='Company Registration'>Company Registration</Option>
                                            <Option value='Identification Card'>Identification Card</Option>
                                            <Option value='Passport'>Passport</Option>
                                            <Option value='Permanent Resident'>Permanent Resident</Option>
                                            <Option value='Working Permit'>Working Permit</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Identification No."
                                        name="identificationno"
                                    >
                                        <Input style={{ width: '50%' }} placeholder="Identification No." />
                                    </Form.Item>

                                    <Form.Item
                                        label="Category"
                                        name="category"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose Category',
                                            },
                                        ]}
                                    >
                                        <Select style={{ width: '50%' }}
                                                placeholder="Category"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            <Option value='individual'>individual</Option>
                                            <Option value='organisation'>organisation</Option>
                                        </Select>
                                    </Form.Item>


                                </Col>
                                <Col xs={24} sm={24} md={24} lg={4} xl={4} style={{padding: '20px'}}>

                                    <Form.Item
                                        label="Address"
                                        name="line"
                                    >
                                        <Input placeholder="Address" />
                                    </Form.Item>
                                    <Form.Item
                                        label="City"
                                        name="city"

                                    >
                                        <Input placeholder="City" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Postcode"
                                        name="postcode"

                                    >
                                        <Input placeholder="Postcode" />
                                    </Form.Item>

                                    <Form.Item

                                        label='Country'
                                        name="country"

                                    >
                                        <Select
                                            onChange={getState}
                                            placeholder="Country"
                                            /*style={{width: '300px'}}*/
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {countryList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label='State'
                                        name="state"

                                    >
                                        <Select
                                            placeholder="State"
                                            /*style={{width: '300px'}}*/
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showSearch
                                            optionFilterProp="children">
                                            {stateList.map(value => <Option key={value} value={value}>{value}</Option>)}
                                        </Select>
                                    </Form.Item>



                                </Col>
                                <Col xs={24} sm={24} md={24} lg={5} xl={5} style={{padding: '20px'}}>

                                    <Tabs activeKey={tabkey}>
                                        <Tabs.TabPane tab="Client" key="client">
                                            <Form.Item
                                                label="Contact Person"
                                                name="contactclient"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input  placeholder="Name" />
                                            </Form.Item>

                                            <Form.Item
                                                label="Contact No."
                                                name="telclient"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }} placeholder="Contact No." />
                                            </Form.Item>

                                            <Form.Item
                                                label="Fax No."
                                                name="faxclient"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }}  placeholder="Fax No." />
                                            </Form.Item>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="Staff" key="staff">
                                            <Form.Item
                                                label="Designation"
                                                name="designation"
                                            >
                                                <Select
                                                    placeholder="Designation"
                                                    style={{ width: '50%' }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    <Option value='Mr.'>Mr.</Option>
                                                    <Option value='Ms.'>Ms.</Option>
                                                    <Option value='Mrs.'>Mrs.</Option>
                                                    <Option value='En.'>En.</Option>
                                                    <Option value='Cik.'>Cik.</Option>
                                                    <Option value='Puan'>Puan</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Gender"
                                                name="gender"
                                            >
                                                <Select
                                                    placeholder="Gender"
                                                    style={{ width: '50%' }}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    <Option value='Male'>Male</Option>
                                                    <Option value='Female'>Female</Option>
                                                    <Option value='Other'>Other</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Role"
                                                name="role"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please choose Role',
                                                    },*/
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: '50%' }}
                                                    placeholder="Role"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    <Option value='administrator'>Administrator</Option>
                                                    <Option value='manager'>Manager</Option>
                                                    <Option value='supervisor'>Supervisor</Option>
                                                    <Option value='cashier'>Cashier</Option>
                                                    <Option value='housekeeper'>Housekeeper</Option>
                                                    <Option value='accountant'>Accountant</Option>
                                                </Select>
                                            </Form.Item>

                                            {/*<Form.Item
                                                label="Nationality"
                                                name="nationality"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please choose Nationality',
                                                    },
                                                ]}
                                            >
                                                <Select

                                                    placeholder="Nationality"

                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    {nationalityList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                                </Select>
                                            </Form.Item>*/}

                                            <Form.Item
                                                label="Property"
                                                name="property"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please choose Property',
                                                    },*/
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Property"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    {propertyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                                </Select>
                                            </Form.Item>

                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="Supplier" key="supplier">

                                            {/*<Form.Item
                                                label="Channel"
                                                name="channel"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }}   placeholder="Channel" />
                                            </Form.Item>*/}

                                            <Form.Item
                                                label="Channel ID"
                                                name="channelid"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }}   placeholder="Channel ID" />
                                            </Form.Item>

                                            <Form.Item
                                                label="Property"
                                                name="property"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please choose Property',
                                                    },*/
                                                ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Property"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    showSearch
                                                    optionFilterProp="children">
                                                    {propertyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Contact Person"
                                                name="contactsupplier"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input  placeholder="Name" />
                                            </Form.Item>

                                            <Form.Item
                                                label="Contact No."
                                                name="telsupplier"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }} placeholder="Contact No." />
                                            </Form.Item>

                                            <Form.Item
                                                label="Fax No."
                                                name="faxsupplier"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }}  placeholder="Fax No." />
                                            </Form.Item>

                                            <legend>Impose on Incoming Price</legend>

                                            <Space style={{ width: '100%' }}>

                                                <Form.Item
                                                    label="Service Charge"
                                                    name="servicecharge"
                                                >
                                                    <Select
                                                        placeholder="Choose"
                                                        style={{ width: '100%' }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        showSearch
                                                        optionFilterProp="children">
                                                        <Option value='true'>Yes</Option>
                                                        <Option value='false'>No</Option>
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    label="Tourism Tax"
                                                    name="tourismtax"
                                                >
                                                    <Select
                                                        placeholder="Choose"
                                                        style={{ width: '100%' }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        showSearch
                                                        optionFilterProp="children">
                                                        <Option value='true'>Yes</Option>
                                                        <Option value='false'>No</Option>
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    label="SST Charge"
                                                    name="sst"
                                                >
                                                    <Select
                                                        placeholder="Choose"
                                                        style={{ width: '100%' }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        showSearch
                                                        optionFilterProp="children">
                                                        <Option value='true'>Yes</Option>
                                                        <Option value='false'>No</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Space>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="Company" key="company">
                                            <Form.Item
                                                label="Tourism Tax No."
                                                name="ttxno"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }}   placeholder="TTX No." />
                                            </Form.Item>

                                            <Form.Item
                                                label="SST Reg.No."
                                                name="sstno"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }}   placeholder="SST No." />
                                            </Form.Item>

                                            <Form.Item
                                                label="Contact Person"
                                                name="contactcompany"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input  placeholder="Name" />
                                            </Form.Item>

                                            <Form.Item
                                                label="Contact No."
                                                name="telcompany"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }} placeholder="Contact No." />
                                            </Form.Item>

                                            <Form.Item
                                                label="Fax No."
                                                name="faxcompany"
                                                rules={[
                                                    /*{
                                                        required: true,
                                                        message: 'Please enter Name',
                                                    },*/
                                                ]}
                                            >
                                                <Input style={{ width: '50%' }}  placeholder="Fax No." />
                                            </Form.Item>
                                        </Tabs.TabPane>
                                    </Tabs>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={9} xl={9} style={{padding: '20px'}}>
                                    <Table
                                        defaultExpandAllRows={true}
                                        columns={columns}
                                        dataSource={data}
                                        /* exportable
                                         exportableProps={{ showColumnPicker: true, fileName: "In-House Guest-List",btnProps: {
                                                 type: "primary",
                                                 icon: <FileExcelOutlined />,
                                                 /!*children: <span>Export</span>,*!/
                                             } }}*/
                                        searchable
                                        searchableProps={{
                                            // dataSource,
                                            // setDataSource: setSearchDataSource,
                                            inputProps: {
                                                placeholder: "Search this table...",
                                                prefix: <SearchOutlined />,
                                            },
                                        }}
                                        bordered
                                        size="small"
                                        scroll={{ x: 'max-content',y: 320 }}
                                        //scroll={{ y: 370 }}
                                        pagination={false}
                                    />
                                    <br />
                                    <Image
                                        width={200}
                                        src={image}
                                    />

                                    <br /><br />
                                    <Space>
                                        <Button type="primary" onClick={() => {form.resetFields(); setData([]);}}  loading={loading} >
                                            <StepForwardOutlined /> Clear
                                        </Button>
                                        <Button type="danger" onClick={() => form.submit()}  loading={loading} >
                                            <StepForwardOutlined /> Confirm
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Content>
                </Spin>
            </Layout>


        </>
    );
}

export default Party
