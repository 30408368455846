import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Form,
    Input,    
    Modal,
    Row,
    Select,
    Steps,
    Table
    
} from "antd";

import {
    layout,
    errorN,    
} from "./general";

import { FileSearchOutlined,UserOutlined, } from '@ant-design/icons';
import useFetch from "use-http";

export function ModalCustomerExist(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [visible, setVisible] = useState(false);
    const [dat] = useState(props.dat);
   
    const [form] = Form.useForm();

    const [dataSearch, setDataSearch]= useState([]);

    useEffect(() => {

        
        return () => {

        }
    }, [])

    const showModal = () => {
        setVisible(true);
        getData();
    };

    const handleCancel = e => {
        setVisible(false);
    };


    async function fetchData(s) {       

        try{
            let data = await get(`/api/parties?${s}`)
            if (response.ok)
            {
                if(data.length > 0)
                {
                    let a = [];
    
                    for(let i of data)
                    {
                        a.push({id:i.id, name: i.data.name, tel: i.data.tel, email: i.data.email})
                    }
    
    
                    setDataSearch(a);  
                }                         
            }
            else
            {
                errorN(`Customer List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error fetchData: ${err}. Please contact the administrator.`);
        }
        
    }

    const getData = () => {

        let values = props.setCustomer();

        let s = '';

        //console.log('butiro', values)

        if(values.code === 'tel')
        {
            s ='tel=' + values.tel;
        }
        else if(values.code === 'name')
        {
            s ='name=' + values.name;
        }
        else if(values.code === 'email')
        {
            s ='email=' + values.email;
        }


        if(s !== '')
        {
            fetchData(s);
        }


    }

    const getForms = (e) => {

        props.loadme(e);
        setVisible(false);
    }

    const columns =[
        {
            width: '12%',
            title:"",
            dataIndex:"action",
            key:"action",
            render:(text, record) => (

                <a onClick={() => getForms(record.id)}>
                    <FileSearchOutlined />
                    &nbsp;
                    Choose
                </a>

            )
        },
        {
            width:'35%',
            title:"Name",
            dataIndex:"name",
            key:"name",
        },
        {
            width:'20%',
            title:"Contact No.",
            dataIndex:"tel",
            key:"tel",
        },
        {
            width:'33%',
            title:"Email",
            dataIndex:"email",
            key:"email",
        },

    ]

    return (
        <>

            <Button size='small' type="danger" onClick={showModal}><UserOutlined/>Guest Exist!</Button>
            <Modal
            width={'50%'}
                destroyOnClose={true}
                visible={visible}
                title="Customer List"
                /*onOk={this.handleOk}*/
                closable = {false}
                //onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Close
                    </Button>,
                ]}
            >


                <Form
                    {...layout}
                    id="modalForm"
                    name="basic"
                    initialValues={{
                        remember: true
                    }}
                    /*onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}*/
                    form={form}
                >

                    <div
                        style={{
                            backgroundColor: 'white'
                        }}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                <Table 
                                scroll={{ y: 370 }} 
                                dataSource={dataSearch} 
                                columns={columns} 
                                rowKey="id" 
                                loading={loading} 
                                size="small" 
                                pagination={false} >
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Modal>
        </>
    );
}