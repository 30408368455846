import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,    
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function ExtendStay(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [disabled, setDisabled] = useState(false);

    const [bookedRoomList, setBookedRoomList] = useState([]);
    const [ratePlanList, setRatePlanList] = useState([]);
    const [allowOpenRate, setaAllowOpenRate] = useState(true);
    const [allowRatePlan, setaAllowRatePlan] = useState(true);

    const labelCol = 22;
    const wrapperCol = 22;

    useEffect(() => {

        loadRoomNo();

        return () => {

        }
    }, [])

    async function extendNow() {
        try {
            //const values = form.getFieldsValue(true);

            form.validateFields().then(async values => {
                let propsObj = props.obj;
                if(values.departuretype === 'early')
                {
                    let obj = {
                        unit: values.qty,
                        uom: values.departureunit === 'day' ? "Day" : "Hour"
                    };

                    let a = await post(`/api/allocations/${propsObj.id}/shorten`,obj)
                    if (response.ok)
                    {
                        successN('Shorten Stay successful');
                        props.loadData();
                    }
                    else
                    {
                        errorN(`Shorten Stay - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }
                }
                else if(values.departuretype === 'late')
                {
                    let obj = {};
                    if(values.rateplan === undefined)
                    {
                        let op = {};

                        if (values.openRate !== undefined)
                        {
                            if (parseFloat(values.openRate) !== 0)
                            {
                                op.price = parseFloat(values.openRate);
                            }
                        }

                        obj = {
                            unit: values.qty,
                            uom: values.departureunit === 'day' ? "Day" : "Hour",
                            open: op
                        };
                    }
                    else
                    {
                        let id = values.rateplan;

                        obj = {
                            rate: id, //here
                            unit: values.qty,
                            uom: values.departureunit === 'day' ? "Day" : "Hour"

                        };
                    }

                    let a = await post(`/api/allocations/${propsObj.id}/extend`,obj)
                    if (response.ok)
                    {
                        successN('Extend Stay successful');
                        props.loadData();
                    }
                    else
                    {
                        errorN(`Extend Stay - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }


                }

            });

        }
        catch (err)
        {
            errorN(`Error Extend Unit: ${err}. Please contact the administrator.`);
        }

    }

    const onChange = (e) => {

        if(e.target.value === 'early')
        {
            setaAllowOpenRate(false);
            setaAllowRatePlan(false);
        }
        else
        {
            setaAllowOpenRate(true);
            setaAllowRatePlan(true);
        }
    }

    const onChangeRatePlan = (e) => {
        if(e !== undefined)
        {
            setaAllowOpenRate(false);
        }
        else
        {
            setaAllowOpenRate(true);
        }
    }

    async function loadRoomNo() {
        try
        {
            let propsObj = props.obj;
            let globalRoomList = JSON.parse(localStorage.getItem('roomlist'));

            let rateplanlist = await get(`/api/items/${propsObj.itemid}/rates`)
            if (response.ok)
            {
                let roomNo = "";

                for(let x of globalRoomList)
                {
                    if(x.id == propsObj.room)
                    {
                        roomNo = x.data.no;
                    }
                }

                let ls =[];
                for(let i of rateplanlist)
                {
                        ls.push({id: i.id, name: i.data.plan + ": " + i.data.name});
                }
                setRatePlanList(ls);

                form.setFieldsValue({
                    unitno: roomNo
                });
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Load Allocation: ${err}. Please contact the administrator.`);
        }
    }

    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={15} xl={15} style={{padding: '20px'}}>
                        <div>

                            <Form.Item
                                name="departuretype"
                                labelCol={{ span: labelCol }}
                                wrapperCol={{ span: wrapperCol }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please choose a Type',
                                    },
                                ]}
                            >
                                <Radio.Group onChange={onChange}>
                                    <Radio value='early'>Early Departure</Radio>
                                    <Radio value='late'>Late Departure</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="Unit No."
                                name="unitno"
                                labelCol={{ span: labelCol }}
                                wrapperCol={{ span: wrapperCol }}
                                rules={[]}
                            >
                                <Input disabled placeholder="Unit No." />
                            </Form.Item>

                            <Form.Item
                                label='Rate Plan'
                                name="rateplan"
                                labelCol={{ span: labelCol }}
                                wrapperCol={{ span: wrapperCol }}
                                rules={[

                                ]}
                            >
                                <Select
                                    disabled={!allowRatePlan}
                                    onChange={onChangeRatePlan}
                                    allowClear
                                    placeholder="Rate Plan"
                                    /*style={{width: '300px'}}*/
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    optionFilterProp="children">
                                    {ratePlanList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                </Select>
                            </Form.Item>


                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} style={{padding: '20px'}}>
                        <div>
                            <Form.Item
                                name="departureunit"
                                labelCol={{ span: labelCol }}
                                wrapperCol={{ span: wrapperCol }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please choose a Booking Type',
                                    },
                                ]}
                            >
                                <Radio.Group >
                                    <Radio value='day'>Day</Radio>
                                    <Radio value='hour'>Hour</Radio>

                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="Qty"
                                name="qty"
                                labelCol={{ span: labelCol }}
                                wrapperCol={{ span: wrapperCol }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please choose Category',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Qty"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    optionFilterProp="children">
                                    <Option value='1'>1</Option>
                                    <Option value='2'>2</Option>
                                    <Option value='3'>3</Option>
                                    <Option value='4'>4</Option>
                                    <Option value='5'>5</Option>
                                    <Option value='6'>6</Option>
                                    <Option value='7'>7</Option>
                                    <Option value='8'>8</Option>
                                    <Option value='9'>9</Option>
                                    <Option value='10'>10</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Custom Rate"
                                name="openRate"
                                rules={[
                                ]}
                            >
                                <InputNumber disabled={!allowOpenRate} style={{width: '90%'}}  min={0.05} max={10000000000}
                                             formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>

                            <Popconfirm
                                placement="topRight"
                                title="Are you sure to update this allocation?"
                                onConfirm={extendNow}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="danger" loading={loading}>
                                    <CloudUploadOutlined  /> Update
                                </Button>
                            </Popconfirm>
                        </div>
                    </Col>
                </Row>
            </Form>

        </>
    );
}

export default ExtendStay
