import React, {useState, useEffect, useContext, useCallback} from 'react'
import ReactDOM from 'react-dom'
import {useRoutes, A, usePath, navigate,setBasepath} from 'hookrouter'
import useFetch, {Provider} from "use-http"

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import {Image,Select, Button, Form, Input, Layout, Menu, Modal, Popconfirm, Space} from 'antd'
import {
    HomeOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    LogoutOutlined,
    LoginOutlined,
    UserAddOutlined,
    UnorderedListOutlined,
    SettingOutlined,
    ContactsOutlined,
    LockOutlined,
    MailOutlined,
    OrderedListOutlined,
    MenuUnfoldOutlined,
    CloseCircleOutlined ,
    FileOutlined,
    QuestionCircleOutlined,
    BulbOutlined,
    SmileOutlined,
    ReloadOutlined,
    EyeInvisibleOutlined, EyeTwoTone,SolutionOutlined,ProfileOutlined,
    KeyOutlined,ShoppingCartOutlined,VideoCameraOutlined,ReconciliationOutlined,DollarOutlined

} from '@ant-design/icons'
//import './style.css'
import 'antd/dist/antd.less';

import routes from "./router"
import Store, {Context} from "./store"
import {errorN, successN} from "./general";

import SModal from "./containers/Settings/modal"
import FModal from "./containers/Bookings/modal";

//setBasepath('/fems');



const {Header, Content, Footer} = Layout;
const {SubMenu} = Menu;
const { Option, OptGroup  } = Select;

function Login(props) {

    const { get, post, response, loading, error, del, put } = useFetch()
    const [state,dispatch] = useContext(Context)
    const [visible,setVisible] = useState(false);
    const [failed,setFailed] = useState(false);
    const [notice, setNotice]= useState({});
    const [propertyList, setPropertyList] = useState([]);

    const [realm, setRealm] = useState(false);

    const [form] = Form.useForm()

    async function loadSettings() {

        localStorage.removeItem('emailsetting');
        localStorage.removeItem('noticesetting');
        localStorage.removeItem('folionotice');
        localStorage.removeItem('receiptnotice');
        localStorage.removeItem('foliofooter');
        localStorage.removeItem('timesetting');
        localStorage.removeItem('systemparametersetting');
        localStorage.removeItem('currencycode');
        localStorage.removeItem('channelsetting');
        localStorage.removeItem('feesetting');
        localStorage.removeItem('devicesetting');
        localStorage.removeItem('guestparametersetting');
        localStorage.removeItem('customsetting');
        localStorage.removeItem('unitcustomname');
        localStorage.removeItem('fullpaymentbeforeinvoice');

        dispatch({type:'SYS_AVAILABILITYMANUALOVERRIDE',payload: false});
        dispatch({type:'SYS_STOPSALE',payload:  false});
        dispatch({type:'SYS_RATECONTROL',payload:  false});
        dispatch({type:'SYS_DOORLOCK',payload: false});


        let obj = JSON.parse(localStorage.getItem('currentproperty'));

        if(obj !== null)
        {
            let settingList = await get(`/api/hotels/${obj.id}/settings`)
            if (response.ok)
            {

                for (let x of settingList)
                {
                    if (x.type === "email")
                    {
                        localStorage.setItem('emailsetting', JSON.stringify(x));
                    }

                    if (x.type === "notice")
                    {
                        localStorage.setItem('noticesetting', JSON.stringify(x));
                        localStorage.setItem('folionotice', JSON.stringify(x.data !== null ? x.data.folio !== null ? x.data.folio : '' : ''));
                        localStorage.setItem('receiptnotice', JSON.stringify(x.data !== null ? x.data.receipt !== null ? x.data.receipt : '' : ''));
                        localStorage.setItem('foliofooter', JSON.stringify(x.data !== null ? x.data.footer !== null ? x.data.footer : 'Thank you for your business and please come again!' : 'Thank you for your business and please come again!'));
                    }

                    if (x.type === "time")
                    {
                        localStorage.setItem('timesetting', JSON.stringify(x));
                    }

                    if (x.type === "systemparameter")
                    {
                        localStorage.setItem('systemparametersetting', JSON.stringify(x));

                        if(x.data != null)
                        {
                            if(x.data.currency != null)
                            {
                                if(x.data.currency.code === 'MYR')
                                {
                                    localStorage.setItem('currencycode', 'RM');
                                }
                                else
                                {
                                    localStorage.setItem('currencycode', x.data.currency.code);
                                }
                            }
                        }
                    }

                    if (x.type === "channel")
                    {
                        localStorage.setItem('channelsetting', JSON.stringify(x));
                    }

                    if (x.type === "fee")
                    {
                        localStorage.setItem('feesetting', JSON.stringify(x));
                    }

                    if (x.type === "device")
                    {
                        localStorage.setItem('devicesetting', JSON.stringify(x));

                        if(x.data !== null)
                        {
                            if(x.data.doorLock !== null)
                            {
                                if(x.data.doorLock.doorLock !== null)
                                {
                                    dispatch({type:'SYS_DOORLOCK',payload: x.data.doorLock.doorLock})
                                }
                                else
                                {
                                    dispatch({type:'SYS_DOORLOCK',payload: false})
                                }
                            }
                            else
                            {
                                dispatch({type:'SYS_DOORLOCK',payload: false})
                            }
                        }
                        else
                        {
                            dispatch({type:'SYS_DOORLOCK',payload: false})
                        }

                    }

                    if (x.type === "guestParameter")
                    {
                        localStorage.setItem('guestparametersetting', JSON.stringify(x));
                    }

                    if (x.type === "custom")
                    {
                        localStorage.setItem('customsetting', JSON.stringify(x));
                        localStorage.setItem('unitcustomname', 'Unit');

                        if (x != null)
                        {
                            if (x.data != null)
                            {
                                if (x.data.unitName != null)
                                {
                                    if (x.data.unitName !== '')
                                    {
                                        localStorage.setItem('unitcustomname', x.data.unitName);
                                    }
                                }

                                localStorage.setItem('fullpaymentbeforeinvoice', x.data.fullPaymentBeforeInvoice);
                            }
                        }

                        dispatch({type:'SYS_AVAILABILITYMANUALOVERRIDE',payload: x.data.availabilityManualOverride})
                        dispatch({type:'SYS_STOPSALE',payload:  x.data.stopSale})
                        dispatch({type:'SYS_RATECONTROL',payload:  x.data.rateControl})


                    }
                }

                return response.status;
            }
            else
            {
                if(response.status === 401)
                {
                    logoutMe();
                }
                else
                {
                    errorN(`Load Settings: ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

                //errorN(`Load Settings: ${response.status}: ${response.statusText}. Please contact the administrator.`);

                return response.status;
            }
        }


    }

    async function loadProperties() {

        localStorage.removeItem('allproperties');


        let propertiesList = await get(`/api/hotels`)
        if (response.ok)
        {
            localStorage.setItem('allproperties', JSON.stringify(propertiesList));

            return response.status;
        }
        else
        {
            if(response.status === 401)
            {
                logoutMe();
            }
            else
            {
                errorN(`Load Settings: ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

            //errorN(`Load Settings: ${response.status}: ${response.statusText}. Please contact the administrator.`);

            return response.status;
        }


    }

    async function loadCommonList() {

        localStorage.removeItem('countrylist');
        localStorage.removeItem('currencylist');
        localStorage.removeItem('taxlist');


        let obj = JSON.parse(localStorage.getItem('currentproperty'));

        if(obj !== null)
        {
            let commonList = await get(`/api/lists`)
            if (response.ok)
            {


                for (let x of commonList)
                {
                    if (x.type === "country")
                    {
                        localStorage.setItem('countrylist', JSON.stringify(x));
                    }

                    if (x.type === "currency")
                    {
                        localStorage.setItem('currencylist', JSON.stringify(x));
                    }

                    if (x.type === "taxes")
                    {
                        localStorage.setItem('taxlist', JSON.stringify(x));
                    }

                }
            }
            else
            {
                if(response.status === 401)
                {
                    logoutMe();
                }
                else
                {
                    errorN(`Load Common List: ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }


            }


        }

        return response.status;
    }

    async function logoutMe() {
        let n = await post(`/api/logout`)
        if (response.ok)
        {


            localStorage.setItem('page', '');
            navigate('/');

            localStorage.removeItem('session');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            localStorage.removeItem('cookieexpiry');
            localStorage.removeItem('role');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('properties');
            localStorage.removeItem('currentproperty');
            localStorage.removeItem('dashboardstyle');

            localStorage.removeItem('emailsetting');
            localStorage.removeItem('noticesetting');
            localStorage.removeItem('folionotice');
            localStorage.removeItem('receiptnotice');
            localStorage.removeItem('foliofooter');
            localStorage.removeItem('timesetting');
            localStorage.removeItem('systemparametersetting');
            localStorage.removeItem('currencycode');
            localStorage.removeItem('channelsetting');
            localStorage.removeItem('feesetting');
            localStorage.removeItem('devicesetting');
            localStorage.removeItem('guestparametersetting');
            localStorage.removeItem('customsetting');
            localStorage.removeItem('unitcustomname');
            localStorage.removeItem('fullpaymentbeforeinvoice');

            localStorage.removeItem('countrylist');
            localStorage.removeItem('currencylist');
            localStorage.removeItem('taxlist');

            localStorage.removeItem('roomlist');
            localStorage.removeItem('allproperties');

            localStorage.removeItem('websocket');


            dispatch({type:'USER_SESSION',payload: false});
            dispatch({type:'USER_USERNAME',payload: ''});
            dispatch({type:'USER_NAME',payload: ''});
            dispatch({type:'USER_ROLE',payload:''});
            dispatch({type:'USER_ID',payload:0});
            dispatch({type:'USER_EMAIL',payload:''});
            dispatch({type:'USER_PROPERTIES',payload:[]});
            dispatch({type:'USER_CURRENTPROPERTY',payload:{}});
            dispatch({type:'USER_DASHBOARDSTYLE',payload:'floor'});


        }
        else
        {
            errorN(`Logout: ${response.status}: ${response.statusText}. Please contact the administrator.`);

        }

    }

    useEffect(() => {

        if(localStorage.getItem('realm') !== null && localStorage.getItem('realm') !== undefined)
        {
           setRealm(true);
           form.setFieldsValue({
            realm: localStorage.getItem('realm')
        });
        }


        let d = new Date();
        let n = d.getFullYear();
        dispatch({type:'SYS_YEAR',payload: n});


        if(localStorage.getItem('session') === null || localStorage.getItem('session') === undefined)
        {

            setVisible(true);
            navigate('/')
        }
        else
        {
            if(props.mode === 'login')
            {

                dispatch({type:'USER_SESSION',payload: true})
                dispatch({type:'USER_USERNAME',payload: localStorage.getItem('username')});
                dispatch({type:'USER_NAME',payload: localStorage.getItem('name')});
                dispatch({type:'USER_ROLE',payload:localStorage.getItem('role')});
                dispatch({type:'USER_ID',payload:localStorage.getItem('id')});
                dispatch({type:'USER_EMAIL',payload:localStorage.getItem('email')});
                dispatch({type:'USER_PROPERTIES',payload:JSON.parse(localStorage.getItem('properties'))});
                dispatch({type:'USER_CURRENTPROPERTY',payload:JSON.parse(localStorage.getItem('currentproperty'))});
                dispatch({type:'USER_DASHBOARDSTYLE',payload:localStorage.getItem('dashboardstyle')});
            }

        }


        return () => {
            if(props.mode === 'login')
            {

                loadSettings().then(r => {

                    if(r === 200)
                    {
                        checkRestrictDevice().then(x => {
                            if(x === 0)
                            {
                                loadCommonList();
                                loadProperties();

                                navigate(`/${localStorage.getItem('page')}`);
                            }
                            else
                            {
                                logoutMe();
                            }
                        });


                    }

                });
            }

        }
    }, [state.username,visible,dispatch])

    async function loginMe(data) {

        let sendDetails = data;

        let login = await post(`/api/login`, data)
        if (response.ok)
        {
            let userProfile = await get('/api/me')
            if (response.ok)
            {
                if (userProfile != null)
                    {
                        if (userProfile.active === false)
                        {
                            setNotice(`INVALID USER: Your access has been disabled. Please contact the administrator.`);
                            return;
                        }


                        setFailed(false);
                        let x = userProfile.data !== null ? (userProfile.data.propertyAccess !== null ? userProfile.data.propertyAccess : []) : [];

                        setPropertyList(x);

                        if(x.length === 1 || x.length > 0)
                        {


                            if(x.length === 1)
                            {
                                localStorage.setItem('session', 'true');
                                localStorage.setItem('username', userProfile.usr);
                                localStorage.setItem('id', userProfile.id);
                                localStorage.setItem('cookieexpiry', login.expire);
                                localStorage.setItem('role',  userProfile.auth);
                                localStorage.setItem('name', userProfile.data !== null ? (userProfile.data.name !== null ? userProfile.data.name : 'Administrator') : 'Administrator');
                                localStorage.setItem('email', userProfile.data !== null ? (userProfile.data.email !== null ? userProfile.data.email : '') : '');
                                localStorage.setItem('properties', userProfile.data !== null ? (userProfile.data.propertyAccess !== null ? JSON.stringify(userProfile.data.propertyAccess) : '') : '');
                                localStorage.setItem('dashboardstyle', userProfile.data !== null ? (userProfile.data.dashboardStyle !== null ? userProfile.data.dashboardStyle : 'floor') : 'floor');
                                localStorage.setItem('realm', sendDetails.realm);

                                dispatch({type:'USER_SESSION',payload: true});
                                dispatch({type:'USER_USERNAME',payload: userProfile.usr});
                                dispatch({type:'USER_NAME',payload: userProfile.data !== null ? (userProfile.data.name !== null ? userProfile.data.name : 'Administrator') : 'Administrator'});
                                dispatch({type:'USER_ROLE',payload:userProfile.auth});
                                dispatch({type:'USER_ID',payload:userProfile.id});
                                dispatch({type:'USER_EMAIL',payload:userProfile.data !== null ? (userProfile.data.email !== null ? userProfile.data.email : '') : ''});
                                dispatch({type:'USER_PROPERTIES',payload:userProfile.data !== null ? (userProfile.data.propertyAccess !== null ? userProfile.data.propertyAccess : []) : []});
                                dispatch({type:'USER_DASHBOARDSTYLE',payload:userProfile.data !== null ? (userProfile.data.dashboardStyle !== null ? userProfile.data.dashboardStyle : 'floor') : 'floor'});


                                localStorage.setItem('currentproperty', JSON.stringify(x[0]));

                                loadSettings().then(r => {

                                    if(r === 200)
                                    {
                                        checkRestrictDevice().then(i => {
                                            if(i === 0)
                                            {
                                                dispatch({type:'USER_CURRENTPROPERTY',payload: x[0]});

                                                loadCommonList();
                                                loadProperties();
                                                localStorage.setItem('page', 'dashboard');
                                                navigate(`/${localStorage.getItem('page')}`);
                                            }
                                            else
                                            {
                                                logoutMe();
                                            }
                                        });

                                    }
                                    else
                                    {
                                        logoutMe();
                                    }

                                });

                            }
                            else
                            {
                                localStorage.setItem('userprofile', JSON.stringify(userProfile));
                                localStorage.setItem('login', JSON.stringify(login));
                                localStorage.setItem('realm', sendDetails.realm);
                            }

                        }
                        else
                        {
                            setFailed(true);
                            setNotice(`Login NP: ${response.status}: ${response.statusText}. No Property assigned to this user. Please contact the administrator.`);
                        }

                    }
            }
            else
            {
                setFailed(true);
                setNotice(`Login PL: ${response.status}: ${response.statusText}. Property list failed to load. Please contact the administrator.`);
            }




        }
        else
        {
            if(response.status === 401)
            {
                setNotice(`Incorrect email/password.`);
            }
            else
            {
                setNotice(`Login: ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

            setFailed(true);
            form.resetFields();
        }
    }

    const onSubmit = (e) => {
        //e.preventDefault();

        form.validateFields().then(values => {

            const {username, password, realm} = values;
            const sendDetails = {
                username,
                password,
                realm
            }
            loginMe(sendDetails).then(r => {});
        })
    };

    const onKeyPress = (e) => {
        if(e.which === 13) {
            onSubmit();
        }
    }

    const onChooseProperty = () => {

        form.validateFields().then(values => {


            if(props.mode === 'login')
            {
                let userProfile = JSON.parse(localStorage.getItem('userprofile'));
                let login = JSON.parse(localStorage.getItem('login'));

                let x = userProfile.data !== null ? (userProfile.data.propertyAccess !== null ? userProfile.data.propertyAccess : []) : [];

                if(values.currentproperty !== undefined && values.currentproperty !== null)
                {
                    for(let a of x)
                    {
                        if(a.id.toString() === values.currentproperty.toString())
                        {
                            localStorage.setItem('session', 'true');
                            localStorage.setItem('username', userProfile.usr);
                            localStorage.setItem('id', userProfile.id);
                            localStorage.setItem('cookieexpiry', login.expire);
                            localStorage.setItem('role',  userProfile.auth);
                            localStorage.setItem('name', userProfile.data !== null ? (userProfile.data.name !== null ? userProfile.data.name : 'Administrator') : 'Administrator');
                            localStorage.setItem('email', userProfile.data !== null ? (userProfile.data.email !== null ? userProfile.data.email : '') : '');
                            localStorage.setItem('properties', userProfile.data !== null ? (userProfile.data.propertyAccess !== null ? JSON.stringify(userProfile.data.propertyAccess) : '') : '');
                            localStorage.setItem('dashboardstyle', userProfile.data !== null ? (userProfile.data.dashboardStyle !== null ? userProfile.data.dashboardStyle : 'floor') : 'floor');

                            dispatch({type:'USER_SESSION',payload: true});
                            dispatch({type:'USER_USERNAME',payload: userProfile.usr});
                            dispatch({type:'USER_NAME',payload: userProfile.data !== null ? (userProfile.data.name !== null ? userProfile.data.name : 'Administrator') : 'Administrator'});
                            dispatch({type:'USER_ROLE',payload:userProfile.auth});
                            dispatch({type:'USER_ID',payload:userProfile.id});
                            dispatch({type:'USER_EMAIL',payload:userProfile.data !== null ? (userProfile.data.email !== null ? userProfile.data.email : '') : ''});
                            dispatch({type:'USER_PROPERTIES',payload:userProfile.data !== null ? (userProfile.data.propertyAccess !== null ? userProfile.data.propertyAccess : []) : []});
                            dispatch({type:'USER_DASHBOARDSTYLE',payload:userProfile.data !== null ? (userProfile.data.dashboardStyle !== null ? userProfile.data.dashboardStyle : 'floor') : 'floor'});

                            localStorage.setItem('currentproperty',JSON.stringify(a));
                            //console.log("obj a", a)

                            loadSettings().then(r => {
                                if(r === 200)
                                {


                                    checkRestrictDevice().then(x => {
                                        if(x === 0)
                                        {
                                            dispatch({type:'USER_CURRENTPROPERTY',payload: a});

                                            loadCommonList();
                                            loadProperties();

                                            localStorage.setItem('page', 'dashboard');
                                            navigate(`/${localStorage.getItem('page')}`);

                                            localStorage.removeItem('userprofile');
                                            localStorage.removeItem('login');
                                        }
                                        else
                                        {
                                            logoutMe();
                                        }
                                    });

                                }
                                else
                                {
                                    logoutMe();
                                }

                            });

                        }
                    }
                }
            }
            else
            {
                let x = JSON.parse(localStorage.getItem('properties'));

                if(values.currentproperty !== undefined && values.currentproperty !== null)
                {
                    for(let a of x)
                    {
                        if(a.id.toString() === values.currentproperty.toString())
                        {
                            localStorage.setItem('currentproperty',JSON.stringify(a));

                            loadSettings().then(r => {

                                if(r === 200)
                                {


                                    checkRestrictDevice().then(x => {
                                        if(x === 0)
                                        {

                                            dispatch({type:'USER_CURRENTPROPERTY',payload: a});

                                            loadCommonList();
                                            loadProperties();

                                            localStorage.setItem('page', 'dashboard');
                                            navigate(`/${localStorage.getItem('page')}`);
                                        }
                                        else
                                        {
                                            logoutMe();
                                        }
                                    });

                                }
                                else
                                {
                                    logoutMe();
                                }

                            });

                        }
                    }
                }
            }
        });
    }

    async function checkRestrictDevice(){
        let customSettings = JSON.parse(localStorage.getItem('customsetting'));

        if( customSettings !== undefined && customSettings !== null && customSettings !== '')
        {
            if( customSettings.data !== undefined && customSettings.data !== null && customSettings.data !== '')
            {
                if( customSettings.data.restrictDevice !== undefined && customSettings.data.restrictDevice !== null && customSettings.data.restrictDevice !== '')
                {
                    if( customSettings.data.restrictDevice === true && (state.role !== 'administrator' && state.role !== 'manager' && state.role !== 'supervisor'))
                    {
                        let a = await get(`/auth`)
                        if (response.status === 200)
                        {
                            if(response.data !== undefined)
                            {
                                if(response.data.status === 'OK')
                                {
                                    //console.log('kenya1',JSON.stringify(response) )
                                    return 0
                                }
                                else
                                {
                                    errorN(`You are not allowed to view this property in this device.`);
                                    return 1
                                }
                            }
                            else
                            {
                                errorN(`You are not allowed to view this property in this device.`);
                                return 1
                            }


                        }
                        else
                        {
                            errorN(`You are not allowed to view this property in this device.`);
                            return 1
                        }
                    }
                    else
                    {
                        return 0
                    }
                }
                else
                {
                    return 0
                }
            }
            else
            {
                return 0
            }

        }
        else
        {
            return 0
        }

    }

    const onRealm = () => {
       setRealm(false);
    }

    return (
        <>
        {props.mode === 'change' ?
            <A href={''} onClick={() => {setVisible(true); setPropertyList(JSON.parse(localStorage.getItem('properties')));}}>Change Property</A>
        : null
        }


            <Modal
                visible={visible}
                title={props.mode === 'login' ? 'Login' : 'Change Property'}
                /*onOk={this.handleOk}*/
                closable = {false}
                //onCancel={this.handleCancel}
                footer =""
                /*footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
                        Submit
                    </Button>,
                ]}*/
                width={isMobile === true ? '100%':'25%'}
            >
                <Form

                    id="modalForm"
                    name="basic"
                    initialValues={{
                        remember: true
                    }}
                    /*onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}*/
                    form={form}
                >
                    {(propertyList.length === 0 || propertyList.length === 1) && props.mode === 'login' ?
                    <>
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input onKeyPress={onKeyPress} prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Username" />

                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password
                                onKeyPress={onKeyPress}
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Password"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>

                        <Space>
                            <Form.Item
                                name="realm"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your property code!',
                                    },
                                ]}
                            >
                                <Input
                                disabled={realm}
                                onKeyPress={onKeyPress}
                                prefix={<HomeOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Property Code"
                                />

                            </Form.Item>
                            {realm === true ?
                                <Form.Item
                                name="button"
                                >
                                    <Button type="primary" onClick={onRealm} loading={loading}>
                                        <ReloadOutlined />
                                    </Button>

                                </Form.Item>
                            : null
                            }

                        </Space>

                    </>
                    :
                    <>
                        <Form.Item
                            name="currentproperty"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose your Property',
                                },
                            ]}
                        >
                            <Select
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                optionFilterProp="children"
                                placeholder="Property">
                                {propertyList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </>
                    }


                    {failed === false ? '' : <><div style={{ color: 'red', paddingBottom: '15px' }}>{'Login Failed! ' + notice }</div></>}

                    <Form.Item>
                        <Space>
                            {propertyList.length === 0 ?
                                <>
                                    <div>
                                        <Button type="primary" onClick={onSubmit} loading={loading}>
                                            <LoginOutlined /> SIGN IN
                                        </Button>
                                        &nbsp;
                                        &nbsp;&nbsp;&nbsp;
                                    </div>
                                    <a className="login-form-forgot" href="">
                                        Forgot password
                                    </a>
                                    &nbsp;&nbsp;&nbsp;


                                </>
                                :
                                <>
                                    <div>
                                        <Button type="primary" onClick={onChooseProperty} loading={loading}>
                                            <HomeOutlined /> SET PROPERTY
                                        </Button>
                                        &nbsp;
                                        &nbsp;&nbsp;&nbsp;
                                        {props.mode === 'change' ?
                                            <Button onClick={()=>{setVisible(false)}} loading={loading}>
                                                <CloseCircleOutlined /> Close
                                            </Button>
                                        : null
                                        }

                                    </div>

                                </>
                            }

                            </Space>


                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

function SetOrder(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [state,dispatch] = useContext(Context)
    const [visible,setVisible] = useState(false);


    const [form] = Form.useForm()

    useEffect(() => {

        return () => {

        }
    }, [])

    async function onSubmit() {

        const values = form.getFieldsValue(true);



        try {

            let id = localStorage.getItem('id')

            let userObj = await get(`/api/users/${id}`)
            if (response.ok)
            {
                userObj.data.dashboardStyle = values.order;

                let a = await put(`/api/users/${id}`,userObj)
                if (response.ok)
                {
                    localStorage.setItem('dashboardstyle', values.order);
                    dispatch({type:'USER_DASHBOARDSTYLE',payload:values.order});
                }
                else
                {
                    errorN(`Set Dashboard Style - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Set Dashboard Style - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Get User: ${err}. Please contact the administrator.`);
        }

    }

    const onKeyPress = (e) => {
        if(e.which === 13) {
            onSubmit();
        }
    }


    return (
        <>

            <A href={''} onClick={() => {setVisible(true);}}>Set Unit Order By</A>


            <Modal
                visible={visible}
                title='Set Unit Order'
                /*onOk={this.handleOk}*/
                closable = {false}
                //onCancel={this.handleCancel}
                footer =""
                /*footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
                        Submit
                    </Button>,
                ]}*/
                width={isMobile === true ? '100%':'32%'}
            >
                <Form

                    id="modalForm"
                    name="basic"
                    initialValues={{
                        remember: true
                    }}
                    /*onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}*/
                    form={form}
                >

                    <Form.Item
                        name="order"
                        rules={[
                            {
                                required: true,
                                message: 'Please choose your Order',
                            },
                        ]}
                    >
                        <Select
                            onKeyPress={onKeyPress}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            optionFilterProp="children"
                            placeholder="Order">
                            <Option key='floor' value='floor'>Floor</Option>
                            <Option key='roomType' value='roomType'>Service Type</Option>
                            <Option key='status' value='status'>Status</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <div>
                                <Button type="primary" onClick={onSubmit} loading={loading}>
                                    <OrderedListOutlined /> SET ORDER
                                </Button>
                                &nbsp;
                                &nbsp;&nbsp;&nbsp;

                                <Button onClick={()=>{setVisible(false)}} loading={loading}>
                                    <CloseCircleOutlined /> Close
                                </Button>
                            </div>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

function Main(props) {
    const routeResult = useRoutes(routes)
    const {post, response, get} = useFetch()
    const path = usePath()
    const [state,dispatch] = useContext(Context);

    const [sessionOn, setSessionOn] = useState(false);

    const [channelOn, setChannelOn] = useState(false);

    //const [socket, setSocket] = useState(undefined);

    async function loadData() {
        let channelobj = JSON.parse(localStorage.getItem('channelsetting'));



        if(channelobj !== undefined && channelobj !== null)
        {
            if(channelobj.data !== undefined && channelobj.data !== null)
            {
                if(channelobj.data.channelName !== undefined && channelobj.data.channelName !== null)
                {
                    if(channelobj.data.channelName.trim() !== '')
                    {
                        setChannelOn(true);
                    }
                }
            }
        }
    }

    async function logoutMe() {
        let n = await post(`/api/logout`)
        if (response.ok)
        {
            localStorage.setItem('page', '');
            navigate('/');



            localStorage.removeItem('session');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            localStorage.removeItem('cookieexpiry');
            localStorage.removeItem('role');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('properties');
            localStorage.removeItem('currentproperty');
            localStorage.removeItem('dashboardstyle');

            localStorage.removeItem('emailsetting');
            localStorage.removeItem('noticesetting');
            localStorage.removeItem('folionotice');
            localStorage.removeItem('receiptnotice');
            localStorage.removeItem('foliofooter');
            localStorage.removeItem('timesetting');
            localStorage.removeItem('systemparametersetting');
            localStorage.removeItem('currencycode');
            localStorage.removeItem('channelsetting');
            localStorage.removeItem('feesetting');
            localStorage.removeItem('devicesetting');
            localStorage.removeItem('guestparametersetting');
            localStorage.removeItem('customsetting');
            localStorage.removeItem('unitcustomname');
            localStorage.removeItem('fullpaymentbeforeinvoice');
            localStorage.removeItem('countrylist');
            localStorage.removeItem('currencylist');
            localStorage.removeItem('taxlist');
            localStorage.removeItem('roomlist');


            dispatch({type:'USER_SESSION',payload: false});
            dispatch({type:'USER_USERNAME',payload: ''});
            dispatch({type:'USER_NAME',payload: ''});
            dispatch({type:'USER_ROLE',payload:''});
            dispatch({type:'USER_ID',payload:0});
            dispatch({type:'USER_EMAIL',payload:''});
            dispatch({type:'USER_PROPERTIES',payload:[]});
            dispatch({type:'USER_CURRENTPROPERTY',payload:{}});
            dispatch({type:'USER_DASHBOARDSTYLE',payload:'floor'});

        }
        else
        {
            errorN(`Logout: ${response.status}: ${response.statusText}. Please contact the administrator.`);

        }

    }

    let socket;

    window.addEventListener("online", function() {
        console.log("I am connected to the internet");
        localStorage.setItem('connected', 'true');
        //localStorage.setItem('websocket', 'alive');
        //websocket('e');

    })

    window.addEventListener("offline", function() {
        //console.log("Disconnected...so sad!!!")
        localStorage.setItem('connected', 'false');
        localStorage.setItem('websocket', 'dead');
    })

    useEffect(() => {
        //let socket = new WebSocket("ws://hms.ratricloud.com/socket");
        //console.log('hit')
        if(localStorage.getItem('session') === null)
        {
            setSessionOn(false);
        }
        else
        {
            if(localStorage.getItem('page') !== null && localStorage.getItem('page') !== '')
            {
                setSessionOn(true);
                loadData();

                setInterval(sendping,30000);
            }
            else
            {
                setSessionOn(false);
            }


        }


        return () => {

        }
    },)

    useEffect(() => {
        //console.log('what', state.session);

        if (state.session === true) {

            websocket('a');

        }
        else {
            if (socket) {
                socket.close();
            }
        }

        return () => {
            //socket close
            if (socket) {
                socket.close();
            }
        }
    },[state.session])

    useEffect(() => {
        //console.log('what 2',state.session,state.reconnect);

        if(state.session === true && state.reconnect === true)
        {
            websocket('b');
            dispatch({type:'RECONNECT',payload: false});
        }

        return () => {
        }
    },[state.reconnect])

    function websocket(e){
        //console.log("its e", e)

        if (socket) {
            socket.close();
        }

        //connect socket
        socket = new WebSocket("wss://hms.ratricloud.com/socket");

        socket.onopen = function() {
            localStorage.setItem('websocket', 'alive');
        };

        //onmessage
        socket.onmessage = function(event) {

            //console.log('event.data',event.data)
            if(event.data === 'Pong')
            {
                localStorage.setItem('websocket', 'alive');
            }
            else
            {
                let page = localStorage.getItem('page');
                let jsonObject = JSON.parse(event.data);
                if(jsonObject.msg === 'New Booking' && page === 'dashboard')
                {
                    setTimeout(() => {
                        dispatch({type:'USER_RELOAD',payload: true});
                    }, 1000);

                }
            }
        }

    }

    const sendping = () => {
        if(socket)
        {
            //console.log('socket.readyState',socket.readyState)

            if (socket.readyState === 1 && state.session === true) {
                //console.log('entering')
                localStorage.setItem('websocket', 'dead');
                socket.send('Ping');
            }
            else if (socket.readyState === 3 && state.session === true)
            {
                //localStorage.setItem('websocket', 'dead');
                //websocket('c');
            }
        }
    }

    return (
        <Layout style={{ height: "100vh"}}>

            {/*<Sider collapsible collapsed={collapsed} onCollapse={collap => {
                setCollapsed(collap)
            }}>*/}


                {/*<div className="logo"/>*/}


            {/*</Sider>*/}
            <Header  className="site-layout-background" style={{ backgroundImage:`url("/header-bg4.png")`, padding: 0, color:'black', fontSize: isMobile === false ? '20px' : '16px',  width: '100%'}} >
                {/*&nbsp;&nbsp;<img src={'/moonosys48.png'} alt="" style={{float:'left', paddingTop: '10px', paddingLeft: '15px'}} />&nbsp;&nbsp;{`Ratri Property Management System ${propertyname !== '' ? ' - ' + propertyname : ''}`}*/}
                &nbsp;&nbsp;<img src={'/moonosys48.png'} alt="" style={{float:'left', paddingTop: '10px', paddingLeft: '15px'}} />&nbsp;&nbsp;{`Ratri Property Management System ${state.currentproperty !== undefined && state.currentproperty !== null && (Object.keys(state.currentproperty).length !== 0 && state.currentproperty.constructor === Object) ? ' - ' + state.currentproperty.name : ''}`}
                {state.session !== undefined && state.session !== false ?
                null
                :
                <Login mode={'login'}/>
                }
            </Header>

            {sessionOn === true ?
                <Menu theme="white" selectedKeys={path.split('/')[1]} mode="horizontal">
                {state.session === true ?
                <>

                    <Menu.Item key="dashboard" icon={<HomeOutlined/>}>
                        <A href={'/dashboard'} onClick={() => {localStorage.setItem('page', 'dashboard')}}>Dashboard</A>
                    </Menu.Item>

                    <SubMenu key="sub2" icon={<FileOutlined/>} title="Reports">

                        {channelOn === true ?
                            <Menu.Item key="bookings" icon={<SolutionOutlined />}>
                                <A href={'/bookings'} onClick={() => {localStorage.setItem('page', 'bookings')}}>Bookings</A>
                            </Menu.Item>
                            :
                            null
                        }

                        {/*<Menu.Item key="bookings" icon={<SolutionOutlined />}>
                            <A href={'/bookings'} onClick={() => {localStorage.setItem('page', 'bookings')}}>Bookings</A>
                        </Menu.Item>*/}

                        <Menu.Item key="folios" icon={<UnorderedListOutlined />}>
                            <A href={'/folios'} onClick={() => {localStorage.setItem('page', 'folios')}}>Folios</A>
                        </Menu.Item>

                        <Menu.Item key="invoices" icon={<UnorderedListOutlined />}>
                            <A href={'/invoices'} onClick={() => {localStorage.setItem('page', 'invoices')}}>Invoices</A>
                        </Menu.Item>

                        <Menu.Item key="payments" icon={<UnorderedListOutlined />}>
                            <A href={'/payments'} onClick={() => {localStorage.setItem('page', 'payments')}}>Payments</A>
                        </Menu.Item>

                        <Menu.Item key="dailyshift" icon={<UnorderedListOutlined />}>
                            <A href={'/dailyshift'} onClick={() => {localStorage.setItem('page', 'dailyshift')}}>Daily Shift</A>
                        </Menu.Item>

                        {state.role === 'administrator' || state.role === 'manager' || state.role === 'supervisor' ?
                            <>
                                {channelOn === true ?
                                    <Menu.Item key="availability" icon={<UnorderedListOutlined/>}>
                                        <A href={'/availability'} onClick={() => {
                                            localStorage.setItem('page', 'availability')
                                        }}>Availability List</A>
                                    </Menu.Item>
                                    :
                                    null
                                }



                                <Menu.Item key="summary" icon={<UnorderedListOutlined />}>
                                    <A href={'/summary'} onClick={() => {localStorage.setItem('page', 'summary')}}>Summary Report</A>
                                </Menu.Item>

                               {/* <Menu.Item key="tax" icon={<UnorderedListOutlined />}>
                                    <A href={'/tax'} onClick={() => {localStorage.setItem('page', 'tax')}}>Tax Report</A>
                                </Menu.Item>*/}

                                <Menu.Item key="taxncharges" icon={<UnorderedListOutlined />}>
                                    <A href={'/taxncharges'} onClick={() => {localStorage.setItem('page', 'taxncharges')}}>Tax & Charges</A>
                                </Menu.Item>

                                <Menu.Item key="fax" icon={<UnorderedListOutlined />}>
                                    <A href={'/fax'} onClick={() => {localStorage.setItem('page', 'fax')}}>Tax Report</A>
                                </Menu.Item>

                                <Menu.Item key="guests" icon={<UnorderedListOutlined />}>
                                    <A href={'/guests'} onClick={() => {localStorage.setItem('page', 'guests')}}>In-house Guests</A>
                                </Menu.Item>

                                <Menu.Item key="housekeeping" icon={<UnorderedListOutlined />}>
                                    <A href={'/housekeeping'} onClick={() => {localStorage.setItem('page', 'housekeeping')}}>Housekeeping</A>
                                </Menu.Item>

                                <Menu.Item key="roomstatus" icon={<UnorderedListOutlined />}>
                                    <A href={'/roomstatus'} onClick={() => {localStorage.setItem('page', 'roomstatus')}}>Room Status</A>
                                </Menu.Item>

                            </>
                            :
                            null
                        }
                    </SubMenu>

                    {state.role === 'administrator' || state.role === 'manager' || state.role === 'supervisor' ?
                        <>
                            {/*<SubMenu key="sub3" icon={<UserOutlined/>} title="Administrator">

                            </SubMenu>*/}



                            <SubMenu key="sub4" icon={<SettingOutlined />} title="Settings">
                                <Menu.Item key="property" icon={<HomeOutlined  />}>
                                   {/* <SModal formname={'property'} />*/}
                                    <A href={'/property'} onClick={() => {localStorage.setItem('page', 'property')}}>Property</A>
                                </Menu.Item>
                                <Menu.Item key="item" icon={<ShoppingCartOutlined />}>
                                    {/*<SModal formname={'item'} />*/}
                                    <A href={'/item'} onClick={() => {localStorage.setItem('page', 'item')}}>Item</A>
                                </Menu.Item>
                                <Menu.Item key="resource" icon={<ReconciliationOutlined />}>
                                    {/*<SModal formname={'item'} />*/}
                                    <A href={'/resource'} onClick={() => {localStorage.setItem('page', 'resource')}}>Resource</A>
                                </Menu.Item>
                                <Menu.Item key="rateplan" icon={<DollarOutlined />}>
                                    {/*<SModal formname={'item'} />*/}
                                    <A href={'/rateplan'} onClick={() => {localStorage.setItem('page', 'rateplan')}}>Rate Plan</A>
                                </Menu.Item>
                                <Menu.Item key="systemuser" icon={<UserOutlined />}>
                                    {/*<SModal formname={'systemuser'} />*/}
                                    <A href={'/systemuser'} onClick={() => {localStorage.setItem('page', 'systemuser')}}>System User</A>
                                </Menu.Item>
                                <Menu.Item key="party" icon={<TeamOutlined />}>
                                    {/*<SModal formname={'systemuser'} />*/}
                                    <A href={'/party'} onClick={() => {localStorage.setItem('page', 'party')}}>Parties</A>
                                </Menu.Item>
                            </SubMenu>
                        </>
                        : null
                    }

                    <SubMenu key="sub5" icon={<UserOutlined />} title="User">
                        <Menu.Item key="changepassword" icon={<KeyOutlined  />}>
                            <SModal formname={'changepassword'} />
                        </Menu.Item>
                    </SubMenu>

                    {/*<SubMenu key="sub6" icon={<BulbOutlined />} title="Custom">
                    </SubMenu>*/}

                    {/*<SubMenu key="sub7" icon={<QuestionCircleOutlined  />} title="Help">
                    </SubMenu>  */}


                    <SubMenu key="subHelp" icon={<QuestionCircleOutlined  />} title="Help">
                        <Menu.Item key="videos" icon={<VideoCameraOutlined  />}>
                            <A href={'/videos'} onClick={() => {localStorage.setItem('page', 'videos')}}>User Guide Videos</A>
                        </Menu.Item>
                    </SubMenu>

                </>
                :
                    null
                }
                {state.session !== undefined && state.session !== false ?
                    <SubMenu key="subx" icon={<SmileOutlined />} title={`Hi ${state.name === undefined || state.name === '' ? 'Guest' : state.name}!`}>

                        <Menu.Item key="6c" icon={<OrderedListOutlined  />}>
                            <SetOrder />
                        </Menu.Item>

                        <Menu.Item key="6m" icon={<HomeOutlined />}>
                            <Login mode={'change'} />
                        </Menu.Item>

                        <Menu.Item key="6" icon={<LogoutOutlined/>}>
                            <Popconfirm
                                title="Are you sure want to Logout?"
                                onConfirm={() => {logoutMe().then(r => {})}}
                                /*onCancel={}*/
                                okText="Yes"
                                cancelText="No"
                            >
                                {/*<Button type="primary" style={{width: '100%'}}>LOGOUT</Button> */}
                                LOGOUT
                            </Popconfirm>
                        </Menu.Item>

                    </SubMenu>
                    :
                    <Menu.Item key="6s">
                        {/* Hi {state.name === undefined || state.name === '' ? 'Guest' : state.name}! */}
                        {/* <Login mode={'login'}/> */}
                    </Menu.Item>
                }

                </Menu>

            :
                <Image
                width={'100%'}
                src='/images/wallpage.jpg'
                preview={false}
                />
            }



            <Layout className="site-layout" >

                {/*<Content style={{margin: '0 16px'}}>*/}
                <Content style={{marginLeft:'5px'}}>
                    {routeResult}
                </Content>
                {/* <Footer style={{position: "sticky", textAlign: 'center', backgroundColor: 'white'}}> ©2021 Managed by Ratri Cloud</Footer> */}
            </Layout>
        </Layout>
    );

}

let options = {
    cachePolicy: 'no-cache', interceptors: {
        request: async ({ options, url, path, route }) => {
            //add code here
            return options
        },
        response: async ({response}) => {
            //window.location.href
           /* console.log('kenya1',response.status )
            if(response.status === 401) {


            }*/

            return response
        }
    }
}
ReactDOM.render(
    <Store>
        <Provider options={options}>
            <Main/>
        </Provider></Store>, document.querySelector('#root'))
