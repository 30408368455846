import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Typography,DatePicker,Select,Radio,Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';
import Highlighter from 'react-highlight-words';
import {errorN} from "../../general";
import { A,navigate} from "hookrouter";
import useFetch from "use-http";
import {
    BellOutlined ,
    ProfileOutlined ,
    SolutionOutlined ,
    PhoneOutlined ,
    HomeOutlined,
    ControlOutlined ,
    DollarOutlined ,
    ContainerOutlined ,
    MinusSquareOutlined ,
    BorderOutlined ,
    RightCircleOutlined,
    SearchOutlined,
    FileExcelOutlined


} from '@ant-design/icons'

import routes from "../../router"
import Store, {Context} from "../../store"
import moment from 'moment'
import FModal from "../Bookings/modal";

import { Table } from "ant-table-extensions";
import {ShowReceipt, ShowReport} from "../Bookings/function";

const {Sider, Content,Footer } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const formatter = new Intl.NumberFormat('en-MY', {
    //style: 'currency',
    //currency: 'MYR',
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

function DailyShift(props) {
    const [state,dispatch] = useContext(Context)
    const { get, post, response, loading, error, del, put } = useFetch()
    const [drawOpen, setDrawOpen] = useState(false);

    const [form] = Form.useForm()
    const [formLayout, setFormLayout] = useState('vertical');
    const handleFinish = useCallback(() => {
        console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);

    }, [form])

    const [dateRange, setDateRange] = useState(false);
    const [keyword, setKeyword] = useState(false);
    const [arrivalDate, setArrivalDate] = useState(false);
    const [arrivalDateRange, setArivalDateRange] = useState(false);

    const [data, setData] = useState([]);

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');

    const [sourceFilters,setSourceFilters] = useState([]);
    const [statusFilters,setStatusFilters] = useState([]);
    const [agencyFilters,setAgencyFilters] = useState([]);
    const [nationalityFilters,setNationalityFilters] = useState([]);

    const labelCol = 22;
    const wrapperCol = 22;

    const [by, setBy] = useState([]);
    const [dataW, setDataW] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [range, setRange] = useState('');

    async function removeDuplicates(data) {
        // let unique = data.reduce(function(a,b){
        //   if(a.indexOf(b) < 0) a.push(b);
        //   return a;
        // },[]);

        const unique = data.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === data.findIndex(obj => {
              return JSON.stringify(obj) === _thing;
            });
          });

        return unique;
      };

    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={node => {
                    searchInput=node;
                }}
                placeholder={`Search ${caption}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.select(), 100);
        }
    },
    render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
});

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
            setsearchText(selectedKeys[0]);
            setsearchedColumn(dataIndex);
       /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };

    async function loadData() {
        //form.validateFields().then(async values => {
            try
            {
                const values = form.getFieldsValue(true);

                form.setFieldsValue({
                    by: undefined
                });
                setData([]);
                setDataW([]);
                setFilteredData([]);

                if(values.dtDateRange === undefined || values.dtDateRange === null)
                {
                    return;
                }

                let obj = JSON.parse(localStorage.getItem('currentproperty'));
                let tim = JSON.parse(localStorage.getItem('timesetting'));

                let dStart = '';
                let dEnd = '';
                let parameter = '';

                dStart = moment(values.dtDateRange).format('YYYY-MM-DD');
                dEnd = moment(values.dtDateRange).format('YYYY-MM-DD');

                let s = tim.data.shift.shiftStart.time;
                let sP = tim.data.shift.shiftStart.period;
                let e = tim.data.shift.shiftEnd.time;
                let eP = tim.data.shift.shiftEnd.period;

                dStart = Date.parse(`${dStart}T${s}`);
                dEnd = Date.parse(`${dEnd}T${e}`);

                let dStart_new = moment(dStart).add(sP, 'days');
                let dEnd_new = moment(dEnd).add(eP, 'days');

                parameter = `?start=${moment.utc(dStart_new).format('YYYY-MM-DDTHH:mm:ss[Z]')}&end=${moment.utc(dEnd_new).format('YYYY-MM-DDTHH:mm:ss[Z]')}`

                let rList = await get(`/api/hotels/${obj.id}/payments${parameter}`)
                if (response.ok){
                    let table1 =[];
                    let table2 = [];

                    for(let x of rList)
                    {
                        let rNo = '';
                        rNo = x.folio !== null ? (x.data.folioNo !== null ? x.data.folioNo : '')  : (x.data.bookingNo !== null ? x.data.bookingNo : '');

                        table1.push({
                            id: x.id,
                            billNo: rNo,
                            name: x.data.payor != null ? x.data.payor.name : '',
                            no: x.no,
                            date: moment(x.date).format('YYYY-MM-DD hh:mm:ss A'),
                            status: '',
                            amount: x.type === "remittance" ? x.amount * -1 : x.amount,
                            method: x.data.method,
                            card: '',
                            expiry: '',
                            type: x.type === "remittance" ? "refund" : x.type,
                            payingFor: x.data.paymentFor,
                            by: x.data.by.name,
                            billId: '',
                            invoiceNo: '',
                            invoiceDate: '',
                            slipNo: '',
                            folioNo: x.data.folioNo,
                            bookingNo: x.data.bookingNo,
                            folioid: x.folio,
                            booking: x.booking
                        });

                        table2.push({
                            by: x.data.by.name
                        });

                    }

                    setDataW(table1);

                    let so = await removeDuplicates(table2);
                    /*setData(table1);*/

                    let arr = [];

                    let i = 1;
                    for(let x of so)
                    {
                        arr.push({
                            id: i.toString(),
                            name: x.by
                        });
                    }
                    setBy(arr);


                    form.setFieldsValue({
                        availabilitylist2: table1

                    });


                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                    errorN(`Load Data - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            catch(err)
            {
                errorN(`Error Load Data: ${err}. Please contact the administrator.`);
            }

        //})
    }

    async function showData() {
        form.validateFields().then(async values => {

            let ls = [];
            ls = dataW.filter(x => x.by === values.by);

            setData(ls);
            setFilteredData(ls);

            setRange(`Date: ${moment(values.dtDateRange).format('YYYY-MM-DD')} - FO Name: ${values.by}`);

        });
    }

    useEffect(() => {


        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {
            //loadData();
        }

        //loadData().then(r => {});


        return () => {

        }
    },[])

    /*const columns = [
        {
            title:"By",
            dataIndex:"by",
            key:"by",
            ...getColumnSearchProps('by','By'),
            sorter: (a, b) => a.by.localeCompare(b.by),
        },
    ]*/

    const columns = [

        {
            width: '100px',
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            render: (text, record) => (
                <>
                    <Space>
                        {record.folioid !== '' && record.folioid !== null && record.folioid !== undefined ?
                            <>
                                <FModal formname={'update'} obj={{...record}} loadData={loadData} />
                                <ShowReceipt obj={record}  />
                            </>
                            :
                            null
                        }
                    </Space>

                </>
            )
        },
        {
            width: '110px',
            title:"Date",
            dataIndex:"date",
            key:"date",
            sorter: (a, b) => a.date.localeCompare(b.date),
        },
        {
            width: '100px',
            title:"Payment No.",
            dataIndex:"no",
            key:"no",
            sorter: (a, b) => a.no.localeCompare(b.no),
        },
        {
            width: '100px',
            title:"Received For",
            dataIndex:"billNo",
            key:"billNo",
            ...getColumnSearchProps('billNo','Received For'),
            sorter: (a, b) => a.billNo.localeCompare(b.billNo),
        },
        /*{
            width: '100px',
            title:"Status",
            dataIndex:"status",
            key:"status",
            filters: statusFilters,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            sorter: (a, b) => a.status.localeCompare(b.status),
        }, */
        {
            width: '200px',
            title:"Name",
            dataIndex:"name",
            key:"name",
            ...getColumnSearchProps('name','Name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            width: '100px',
            title:"Amount",
            dataIndex:"amount",
            key:"amount",
            sorter: (a, b) => a.amount.localeCompare(b.amount),
        },
        {
            width: '100px',
            title:"Method",
            dataIndex:"method",
            key:"method",
            /*...getColumnSearchProps('method','Method'),*/
            sorter: (a, b) => a.method.localeCompare(b.method),
            filters: [
                {value:'Cash',text:'Cash'},
                {value:'Agoda',text:'Agoda'},
                {value:'Alipay',text:'Alipay'},
                {value:'Amex',text:'Amex'},
                {value:'Bank Transfer',text:'Bank Transfer'},
                {value:'Booking.com',text:'Booking.com'},
                {value:'Cheque',text:'Cheque'},
                {value:'Credit Card',text:'Credit Card'},
                {value:'Debit Card',text:'Debit Card'},
                {value:'EPass',text:'EPass'},
                {value:'Expedia',text:'Expedia'},
                {value:'Master Card',text:'Master Card'},
                {value:'Online Banking',text:'Online Banking'},
                {value:'Paypal',text:'Paypal'},
                {value:'Ticket.com',text:'Ticket.com'},
                {value:'TouchnGo eWallet',text:'TouchnGo eWallet'},
                {value:'Traveloka',text:'Traveloka'},
                {value:'Visa',text:'Visa'},
                {value:'WeChat Pay',text:'WeChat Pay'},
                {value:'Maybank QRPay',text:'Maybank QRPay'}
            ],
            onFilter: (value, record) => record.method.indexOf(value) === 0,
        },
        {
            width: '100px',
            title:"Type",
            dataIndex:"type",
            key:"type",
            /*...getColumnSearchProps('type','Type'),*/
            sorter: (a, b) => a.type.localeCompare(b.type),
            filters: [
                {
                    text: 'receipt',
                    value: 'receipt',
                },
                {
                    text: 'refund',
                    value: 'refund',
                },
            ],
            onFilter: (value, record) => record.type.indexOf(value) === 0,
        },
        {
            width: '200px',
            title:"By",
            dataIndex:"by",
            key:"by",
            ...getColumnSearchProps('by','By'),
            sorter: (a, b) => a.by.localeCompare(b.by),
        },

        {
            width: '100px',
            title:"Category",
            dataIndex:"payingFor",
            key:"payingFor",
            /*...getColumnSearchProps('payingFor','Category'),*/
            sorter: (a, b) => a.payingFor.localeCompare(b.payingFor),
            filters: [
                {
                    text: 'Deposit',
                    value: 'Deposit',
                },
                {
                    text: 'Payment',
                    value: 'Payment',
                },
            ],
            onFilter: (value, record) => record.payingFor.indexOf(value) === 0,
        },

    ]

    const expandedRowRender = (e) => {

        const values = form.getFieldsValue(true);
        let ls = [];

        ls = values.availabilitylist2.filter(x => x.by === e.by);

        ls = ls.sort((a,b) => (a.no > b.no) ? 1 : ((b.no > a.no) ? -1 : 0))

        //setFilteredData(ls);

        return <>
            <ShowReport data={filteredData} name={'Daily Shift'} range={range} no={'5'}   />
            <br /><br />
            <Table
            onChange={handleTableChange} // Handle filtering and sorting
            /*columns={columnsB}*/
            size="small"
            dataSource={ls}
            pagination={false}
            exportable
            exportableProps={{ showColumnPicker: true, fileName: "Daily Shift",btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    /*children: <span>Export</span>,*/
                } }}
            footer={pageData => {
                let totalAmount = 0;
                //let totalReceived = 0;
                //let totalBalance = 0;

                pageData.forEach(({ amount, paid, balance }) => {
                    totalAmount += parseFloat(amount);
                    //totalReceived += parseFloat(paid);
                    //totalBalance += parseFloat(balance);
                });

                return (
                    <>
                        <Text><b>Total Amount: {formatter.format(totalAmount)}</b></Text>
                        {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text><b>Total Received: {formatter.format(totalReceived)}</b></Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text type="danger"><b>Total Balance: {formatter.format(totalBalance)}</b></Text>*/}

                    </>
                );
            }}

        /></>;



    };

    const handleTableChange = (pagination, filters, sorter) => {

        let filteredData = data.filter(item => {
            return Object.entries(filters).every(([key, values]) => {
                const itemValue = item[key] ? item[key].toLowerCase() : null;
                values = values ? values.map(val => val.toLowerCase()) : null;

                // Include the item if the criterion for the key is null or undefined
                if (values === null || values === undefined) {
                    return true;
                }

                // Check if the item's property contains any of the specified values for that property
                return values.some(filterVal => itemValue.includes(filterVal));
            });
        });

        if (sorter.field) {
            filteredData.sort((a, b) => {
                if (sorter.order === 'ascend') {
                    return a[sorter.field].localeCompare(b[sorter.field]);
                } else if (sorter.order === 'descend') {
                    return b[sorter.field].localeCompare(a[sorter.field]);
                }
                return 0;
            });
        }
        // Update the filteredData state with the filtered and sorted data
        setFilteredData([...filteredData]);
    };

    return (
        <>
        <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        &nbsp;&nbsp;<ProfileOutlined />
        &nbsp;&nbsp;Daily Shift
        </div>
        <Layout>

            <Spin spinning={loading}>
            <Content style={{backgroundColor: 'white'}}>
                <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                    <div style={{backgroundColor: 'white', padding: '20px', margin: '0px 0',height:'73vh',overflow:'auto'}}>
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={true || false}
                        useWindow={false}
                    >
                    <div style={{backgroundColor: 'white', paddingRight: '20px',paddingLeft: '20px', paddingTop: '20px',marginRight: '20px'}}>
                    <Form.Item
                        style={{ display: 'none' }}
                        label="typex"
                        name="typex"
                        labelCol={{ span: labelCol }}
                        wrapperCol={{ span: wrapperCol }}
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>

                        <Space>
                            <Form.Item
                                label="Date"
                                name="dtDateRange"
                                rules={[{ required: true, message: 'Dates missing!' }]}
                            >
                                <DatePicker allowClear={true} onChange={() => {loadData();}} />
                            </Form.Item>

                            <Form.Item
                                label='FO Name'
                                name="by"
                                rules={[{ required: true, message: 'FO missing!' }]}
                            >
                                <Select style={{ width: 200 }}
                                    onChange={() => {showData()}}
                                    placeholder="FO name" /*style={{width: '300px'}}*/
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    optionFilterProp="children">
                                    {by.map(value => <Option key={value.id} value={value.name}>{value.name}</Option>)}
                                </Select>
                            </Form.Item>

                            {/*<Button style={{marginTop:'7px'}} onClick={() => {showData()}} key=""type="primary">Load</Button>*/}
                            <ShowReport data={filteredData} name={'Daily Shift'} range={range} no={'5'}   />
                        </Space>


                    </div>

                    <div rowKey="id" style={{backgroundColor: 'white', paddingRight: '20px',paddingLeft: '20px',paddingBottom: '20px', marginRight: '20px'}}>
                        <Table
                            onChange={handleTableChange} // Handle filtering and sorting
                            defaultExpandAllRows={true}
                            columns={columns}
                            dataSource={data}
                            exportable
                            exportableProps={{ showColumnPicker: true, fileName: "Payment-List",btnProps: {
                                    type: "primary",
                                    icon: <FileExcelOutlined />,
                                    /*children: <span>Export</span>,*/
                                } }}
                           /* searchable
                            searchableProps={{
                                // dataSource,
                                // setDataSource: setSearchDataSource,
                                inputProps: {
                                    placeholder: "Search this table...",
                                    prefix: <SearchOutlined />,
                                },
                            }}*/
                            //expandable={{expandedRowRender}}
                            bordered
                            size="small"
                            //expandRowByClick={false}
                            scroll={{ x: 1500,y: 360 }}
                            pagination={false}

                            footer={pageData => {
                                let totalAmount = 0;
                                //let totalReceived = 0;
                                //let totalBalance = 0;

                                pageData.forEach(({ amount, paid, balance }) => {
                                    totalAmount += parseFloat(amount);
                                    //totalReceived += parseFloat(paid);
                                    //totalBalance += parseFloat(balance);
                                });

                                return (
                                    <>
                                        <Text><b>Totall Amount: {formatter.format(totalAmount)}</b></Text>
                                        {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text><b>Total Received: {formatter.format(totalReceived)}</b></Text>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Text type="danger"><b>Total Balance: {formatter.format(totalBalance)}</b></Text>*/}

                                    </>
                                );
                            }}

                        />
                    </div>

                    </InfiniteScroll>
                    </div>
                </Form>
            </Content>
            </Spin>
        </Layout>
        {/* <div style={{ textAlign: 'center', fontSize: '14px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        {`©${state.year} Managed by Ratri Cloud`}
        </div>   */}

        </>
    );
}

export default DailyShift
