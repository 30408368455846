import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function ChangeRoom(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [disabled, setDisabled] = useState(false);

    const [bookedRoomList, setBookedRoomList] = useState([]);
    const [roomList, setRoomList] = useState([]);

    const labelCol = 22;
    const wrapperCol = 22;

    useEffect(() => {

        loadRoomNo(false).then(r => {});

        return () => {

        }
    }, [])

    async function changeNow() {
        try {
            //const values = form.getFieldsValue(true);

            form.validateFields().then(async values => {
                let propsObj = props.obj;

                let obj = {room:values.roomno};
                let a = await post(`/api/allocations/${propsObj.id}/change`,obj)
                if (response.ok)
                {
                    successN('Change Unit successful');
                    props.loadData();
                }
                else
                {
                    errorN(`Change Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            });

        }
        catch (err)
        {
            errorN(`Error Change Unit: ${err}. Please contact the administrator.`);
        }

    }

    async function loadRoomNo(flg) {
        try
        {
            let propsObj = props.obj;
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));
            let globalRoomList = JSON.parse(localStorage.getItem('roomlist'));

            let s = propsObj.start;
            let e = propsObj.end;

            let now = moment(new Date());
            let start = moment(propsObj.start).local();

            //console.log(propsObj,start,now,start <= now)

            let parameter = `?start=${s}&end=${e}`
            let rooms = await get(`/api/hotels/${globalObj.id}/availability_rooms${parameter}`)
            if (response.ok)
            {
                let roomNo = "";

                for(let x of globalRoomList)
                {
                    if(x.id == propsObj.room)
                    {
                        roomNo = x.data.no;
                    }
                }
                form.setFieldsValue({
                    unitno: roomNo
                });

                let roomsX =[];
                for (let i of rooms)
                {
                    for (let j of i.rooms)
                    {
                        let itm = "";
                        let roomid = "";
                        let status = "";
                        let occupancy = "";
                        for(let x of globalRoomList)
                        {
                            if(x.data.service.id == i.item)
                            {
                                itm = x.data.service.name;

                            }

                            if (x.data.no == j.data.no)
                            {
                                let str = '';

                                if (x.occupancy == "vacant" && x.cleanliness == "clean")
                                {
                                    str = "Available";
                                }
                                else if (x.occupancy == "vacant" && x.cleanliness == "dirty")
                                {
                                    str = "Available-Dirty";
                                }
                                else if (x.occupancy == "vacant" && x.cleanliness == "cleaning")
                                {
                                    str = "Available-Cleaning";
                                }
                                else if (x.occupancy == "occupied" && x.cleanliness == "clean")
                                {
                                    str = "Occupied";
                                }
                                else if (x.occupancy == "occupied" && x.cleanliness == "dirty")
                                {
                                    str = "Occupied-Dirty";
                                }
                                else if (x.occupancy == "occupied" && x.cleanliness == "cleaning")
                                {
                                    str = "Occupied-Cleaning";
                                }
                                else if (x.occupancy == "reserved" && x.cleanliness == "clean")
                                {
                                    str = "Reserved";
                                }
                                else if (x.occupancy == "reserved" && x.cleanliness == "dirty")
                                {
                                    str = "Reserved-Dirty";
                                }
                                else if (x.occupancy == "reserved" && x.cleanliness == "cleaning")
                                {
                                    str = "Reserved-Cleaning";
                                }
                                else if (x.occupancy == "blocked")
                                {
                                    str = "Blocked";
                                }

                                roomid = x.id;
                                status = str;
                                occupancy = x.occupancy;
                            }

                        }

                        //if(occupancy == "vacant")

                        if(flg === true)
                        {
                            if(start <= now)
                            {
                                if(occupancy == "vacant")
                                {
                                    roomsX.push({ id: roomid, name: j.data.no + ' : ' + itm + ' - ' + status });
                                }
                            }
                            else
                            {
                                roomsX.push({ id: roomid, name: j.data.no + ' : ' + itm + ' - ' + status });
                            }
                        }
                        else
                        {
                            if(propsObj.itemid === i.item)
                            {
                                if(start <= now)
                                {
                                    if(occupancy == "vacant")
                                    {
                                        roomsX.push({ id: roomid, name: j.data.no + ' : ' + itm + ' - ' + status });
                                    }
                                }
                                else
                                {
                                    roomsX.push({ id: roomid, name: j.data.no + ' : ' + itm + ' - ' + status });
                                }
                            }
                        }


                    }
                }

                roomsX.sort((a, b) => (a.color > b.color) ? 1 : -1);

                setRoomList(roomsX);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error Load Allocation: ${err}. Please contact the administrator.`);
        }
    }

    const onChange = (e) => {
        //console.log(`checked = ${e.target.checked}`);

        loadRoomNo(e.target.checked).then(r => {});
    };

    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{padding: '20px'}}>
                        <div>

                            <Form.Item
                                label="Current Unit"
                                name="unitno"
                                labelCol={{ span: labelCol }}
                                wrapperCol={{ span: wrapperCol }}
                                rules={[]}
                            >
                                <Input disabled placeholder="Unit No." />
                            </Form.Item>



                            <Form.Item
                                label='New Unit'
                                name="roomno"
                                labelCol={{ span: labelCol }}
                                wrapperCol={{ span: wrapperCol }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please choose New Unit',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    placeholder="New Room"
                                    /*style={{width: '300px'}}*/
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    optionFilterProp="children">
                                    {roomList.map(value => <Option key={value.id} value={value.id}>{value.name}</Option>)}
                                </Select>
                            </Form.Item>

                            <Checkbox onChange={onChange}>Show All Units</Checkbox>
                            <br /><br />

                            <Popconfirm
                                placement="topRight"
                                title="Are you sure to update this allocation?"
                                onConfirm={changeNow}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="danger" loading={loading}>
                                    <CloudUploadOutlined  /> Update
                                </Button>
                            </Popconfirm>
                        </div>
                    </Col>
                </Row>
            </Form>

        </>
    );
}

export default ChangeRoom
