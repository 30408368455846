import React, {useState,useEffect,useContext,useCallback} from 'react'

import {DatePicker,Descriptions,Image,Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {errorN} from "../../general";
import {A, navigate} from "hookrouter";
import useFetch from "use-http";
import {
    BarChartOutlined,  
    PieChartOutlined
} from '@ant-design/icons'
import { Pie} from '@ant-design/charts';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import routes from "../../router"
import Store, {Context} from "../../store"
import moment from 'moment'

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { RangePicker } = DatePicker;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function Availability(props) {
    const [state,dispatch] = useContext(Context)
    const { get, response, loading} = useFetch()
    const [data, setData] = useState([])
    const [columns, setColumn] = useState([])
    const [form] = Form.useForm()
    const [formLayout] = useState('vertical');
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);

    }, [form])

    const { height, width } = useWindowDimensions();

    async function loadData(){
        form.validateFields().then(async values => {

            console.log("values", values)

            let obj = JSON.parse(localStorage.getItem('currentproperty'));

            let s = '';
            let e = '';
            let parameter = '';

            s = (values.dtDateRange[0]).format('yyyy-MM-DD');
            //e = (values.dtDateRange[1].add('days', 1)).format('yyyy-MM-DD');
            e = (values.dtDateRange[1]).format('yyyy-MM-DD');

            parameter = `?start=${s}&end=${e}&hotel=${obj.id}`

            let dStartx = new Date(s);
            let dEndx = new Date(e);

            let ls1 =[];
            ls1.push({title: 'ID',dataIndex: 'id',key: 'id'})
            ls1.push({title: 'Item Name',dataIndex: 'name',key: 'name'})

            for (let d = dStartx; d <= dEndx; d.setDate(d.getDate() + 1)) {


                let re = /\//gi;
                let a = new Intl.DateTimeFormat(['ban', 'id']).format(d);
                a = "dt" + a.replace(re,'');

                ls1.push({title: new Intl.DateTimeFormat(['ban', 'id']).format(d),dataIndex: a ,key: a})
            }
            setColumn(ls1);

            dStartx = new Date(s);

            try
            {
                let availabilityList = await get(`/api/channel/available${parameter}`)
                if (response.ok)
                {
                    console.log("folioList", availabilityList);

                    let it = await get(`/api/hotels/${obj.id}/items`)
                    if (response.ok)
                    {
                        console.log("it", it);

                        for(let item of availabilityList)
                        {
                            for(let its of it)
                            {
                                if(its.id == item.id)
                                {
                                    item.name = its.data.name;
                                }
                            }

                            //let len =  item.avail.length;
                            let ls =[];
                            let x = 0;
                            for (let d = dStartx; d <= dEndx; d.setDate(d.getDate() + 1))
                            {
                                let re = /\//gi;
                                let ax = new Intl.DateTimeFormat(['ban', 'id']).format(d);
                                ax = "dt" + ax.replace(re,'');

                                let a = {
                                    name : ax,
                                    rate : item.avail[x],
                                    rate2 : item.availRatri[x],
                                };

                                ls.push(a);
                                x = x +1;
                            }
                            dStartx = new Date(s);

                            item.availDate = ls;
                        }

                        console.log("niva", availabilityList)

                        let dyObj = [];
                        for(let i of availabilityList)
                        {
                            let childObj = {
                                id : i.id,
                                name: i.name,
                            }

                            for(let y of i.availDate)
                            {
                                console.log('hit')
                                childObj[y.name] = y.rate;
                            }

                            dyObj.push(childObj);
                        }

                        setData(dyObj);
                        console.log('at lasty', dyObj)
                    }
                    else
                    {
                        if(response.status === 401)
                        {
                            localStorage.removeItem('session');
                            window.location.reload();
                        }
                        else
                        {
                            errorN(`Load Item Data - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        }

                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Load Data - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }

                //e = (values.dtDateRange[1].add('days', -1)).format('yyyy-MM-DD');
            }
            catch(err)
            {
                errorN(`Error Load Data: ${err}. Please contact the administrator.`);
            }

        })
    }

    useEffect(() => {

      
        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {
            /*let ls1 =[];
            ls1.push({title: 'Name',dataIndex: 'name',key: 'name',})
            ls1.push({title: 'Username',dataIndex: 'username',key: 'username'})
            ls1.push({title: 'Role',dataIndex: 'role',key: 'role'})
            setColumn(ls1);

            let ls2 =[];
            ls1.push({title: 'Namex',dataIndex: 'namex',key: 'namex',})
            ls1.push({title: 'Usernamex',dataIndex: 'usernamex',key: 'usernamex'})

            setColumn(columns => [...columns, ls2]);*/


        }

        return () => {
            
        }
    },[])

    return (
        <>
        <div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        &nbsp;&nbsp;<BarChartOutlined  />
        &nbsp;&nbsp;Availability List
        </div>    
        <Layout style={{height: isMobile === true ? '70vh':'74vh'}}>
           
            <Spin spinning={loading}>
            <Content> 
                 <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                <div style={{padding: '20px', margin: '0px 0',height:'72vh',overflow:'auto',width:`${width}px`}}>

                    <Space>
                        <Form.Item
                            label="Start - End Date"
                            name="dtDateRange"
                            rules={[{ required: true, message: 'Dates missing!' }]}
                        >
                            <RangePicker/>
                        </Form.Item>

                        <Button style={{marginTop:'7px'}} onClick={() => {loadData()}} key=""type="primary">Load</Button>
                    </Space>

                    <Table dataSource={data} columns={columns} rowKey="id" loading={loading}
                           scroll={{ x: 500}}
                           bordered
                           size="small"

                           pagination={false}
                    >

                    </Table>

                </div>          
                    
                 </Form>
            </Content> 
            </Spin>           
        </Layout>
        {/*<div style={{ textAlign: 'center', fontSize: '12px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        {`©${state.year} Managed by Ratri Cloud`}
        </div>   */}
        
        </>
    );
}

export default Availability
