import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,    
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function ChooseRoom(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [disabled, setDisabled] = useState(false);
    

    useEffect(() => {

        loadRoomNo();

        return () => {

        }
    }, [])

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const setData = () => {


        /*let obj = props.obj;

        console.log('pund', props.obj)
        if(obj.roomNo !== null && obj.roomNo !== undefined && obj.roomNo !== '')
        {
            let d = obj.roomNo.split(',');

            obj.roomNox = d;
        }
        else
        {
            obj.roomNox = [];
        }

        form.setFieldsValue(obj);

        setAvailCount(props.getAllo(obj.itemID));

        setIsBackDate(props.isBackDate);*/
    }

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {
            //console.log("propsObj", props.obj)
            let timesettingObj = JSON.parse(localStorage.getItem('timesetting'));
            const values = form.getFieldsValue(true);
            let propsObj = props.obj;
            let date = new Date();
            let start = '';
            let end = '';
            let now = '';

            let h1 = timesettingObj.data.end;
            let h =12;
            if(h1 !== null && h1 !== undefined)
            {
               let i = h1.split(':');
               h = parseInt(i[0]);
            }


            start = moment(propsObj.start).local().format('YYYY-MM-DD');
            end = (moment(propsObj.end).local().add(h, 'h')).format('YYYY-MM-DD HH:mm:ss');
            now = moment(date).format('YYYY-MM-DD HH:mm:ss');

            if(values.roomNox === undefined || values.roomNox === null || values.roomNox === '')
            {
                errorN(`Invalid: Choose a Unit No. to proceed.`);
                return;
            }

            if (end <= now)
            {
                errorN(`Invalid: Assign Unit NOT ALLOWED. This allocation is already due Check Out.`);
                return;
            }

            let room = {room:values.roomNox};
            let a = await post(`/api/allocations/${propsObj.id}/assign`,room)
            if (response.ok)
            {
                successN('Unit assigned successfully');
                props.loadData();
                setDisabled(true);
            }
            else
            {
                errorN(`Assign Unit - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error Assign Unit: ${err}. Please contact the administrator.`);
        }

    }

    const handleChange = () => {

        // if(props.isBackDate !== true)
        // {
           /* const values = form.getFieldsValue(true);
            console.log('why me', values.roomNox)
            let x = '';
                
            for(let i of values.roomNox)
            {
                console.log('why me x', i)
                if(x === '')
                {
                    x = i;
                }
                else
                {
                    x = x + ',' + i;
                }
            }      
            
            values.roomNo = x;

            props.reloadMe(values);
    
            let obj = props.obj;
            setAvailCount(props.getAllo(obj.itemID));*/
        // }
        
    }

    async function loadRoomNo() {
        try
        {
            let propsObj = props.obj;
            let globalObj = JSON.parse(localStorage.getItem('currentproperty'));

            let globalRoomList = JSON.parse(localStorage.getItem('roomlist'));

            let s = '';
            let e = '';
            let parameter = '';

            //s = moment.utc(propsObj.start).format('YYYY-MM-DDTHH:mm:ss[Z]');
            //e = moment.utc(propsObj.end).format('YYYY-MM-DDTHH:mm:ss[Z]');

            s = propsObj.start;
            e = propsObj.end;

            parameter = `?start=${s}&end=${e}`


            let roomList = await get(`/api/hotels/${globalObj.id}/availability_rooms${parameter}`)
            if (response.ok)
            {
                let table1 =[];
                let str = '';
                for(let i of roomList)
                {
                    if(propsObj.itemid === i.item)
                    {
                        for(let j of i.rooms)
                        {
                            for(let k of globalRoomList)
                            {
                                if(j.id === k.id)
                                {
                                    if (k.occupancy == "vacant" && k.cleanliness == "clean")
                                    {
                                        str = "Available";
                                    }
                                    else if (k.occupancy == "vacant" && k.cleanliness == "dirty")
                                    {
                                        str = "Available-Dirty";
                                    }
                                    else if (k.occupancy == "vacant" && k.cleanliness == "cleaning")
                                    {
                                        str = "Available-Cleaning";
                                    }
                                    else if (k.occupancy == "occupied" && k.cleanliness == "clean")
                                    {
                                        str = "Occupied";
                                    }
                                    else if (k.occupancy == "occupied" && k.cleanliness == "dirty")
                                    {
                                        str = "Occupied-Dirty";
                                    }
                                    else if (k.occupancy == "occupied" && k.cleanliness == "cleaning")
                                    {
                                        str = "Occupied-Cleaning";
                                    }
                                    else if (k.occupancy == "reserved" && k.cleanliness == "clean")
                                    {
                                        str = "Reserved";
                                    }
                                    else if (k.occupancy == "reserved" && k.cleanliness == "dirty")
                                    {
                                        str = "Reserved-Dirty";
                                    }
                                    else if (k.occupancy == "reserved" && k.cleanliness == "cleaning")
                                    {
                                        str = "Reserved-Cleaning";
                                    }
                                    else if (k.occupancy == "blocked")
                                    {
                                        str = "Blocked";
                                    }

                                    table1.push({id: j.id, no: j.data.no + ' : ' + str})
                                }
                            }
                        }
                    }
                }
                setAvailCount(table1);

                let obj = props.obj;
                form.setFieldsValue(obj);
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error LA: ${err}. Please contact the administrator.`);
        }
    }

    return (
        <>
            <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                        <Form.Item
                            label="Customer Name"
                            name="name"
                            rules={[]}
                        >
                            <Input disabled placeholder="Name" />
                        </Form.Item>

                        <Form.Item
                            label="Unit Type"
                            name="unittype"
                            rules={[]}
                        >
                            <Input disabled placeholder="Type" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>

                        <Form.Item
                            name="roomNox"
                            label= 'Select Unit'
                            rules={[]}
                        >
                            <Select
                                //mode= 'multiple'
                                onChange={handleChange}
                                allowClear
                                placeholder="Unit No."
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                optionFilterProp="children"
                                disabled={disabled}
                            >
                                {availCount.map(value => <Option key={value.id} value={value.id}>{value.no}</Option>)}
                            </Select>
                        </Form.Item>

                        <br />
                        <Button type="danger" onClick={() => form.submit()}  loading={loading} disabled={disabled}>
                            <StepForwardOutlined /> Confirm
                        </Button>
                    </Col>
                </Row>

            </Form>

        </>
    );
}

export default ChooseRoom
