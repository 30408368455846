import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined ,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined,
    EditOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function UpdateAllocation(props) {
    const { get, post, response, loading, error, del, put } = useFetch()
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    //const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);

    const [nationalityList, setNationalityList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [newspaperList, setNewspaperList] = useState([]);

    async function loadCbo() {
        let obj = JSON.parse(localStorage.getItem('countrylist'));
        let obj2 = JSON.parse(localStorage.getItem('guestparametersetting'));

        setNationalityList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setCountryList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));

        if(obj2 !== undefined && obj2 !== null)
        {
            if(obj2.data !== undefined && obj2.data !== null)
            {
                if(obj2.data.newspaper !== undefined && obj2.data.newspaper !== null)
                {
                    if(obj2.data.newspaper.length > 0)
                    {
                        setNewspaperList(obj2.data.newspaper);
                    }
                }
            }
        }
    }

    const getState = (e) => {

        try
        {
            setStateList([]);
            form.setFieldsValue({
                state: undefined
            });

            let obj = JSON.parse(localStorage.getItem('countrylist'));

            let ls = obj.data.filter(x => x.name === e);

            if(ls.length > 0)
            {
                setStateList(ls[0].states.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)));
            }

        }
        catch(err)
        {
            errorN(`Error Get States: ${err}. Please contact the administrator.`);
        }

    }

    useEffect(() => {

        loadCbo();
        loadData();

        return () => {

        }
    }, [])

    const onSubmit = () => {

        AssignNow();
    }

    async function AssignNow() {
        try {
            const values = form.getFieldsValue(true);

            let propsObj = props.obj;

            let alo = await get(`/api/allocations/${propsObj.id}`)
            if (response.ok)
            {
                alo.data.guest = {
                    id: values.identificationno,
                    name: values.name
                };
                alo.data.carNo = values.vehicle;

                alo.data.newspaper = values.newspaper;

                let nationality = null;
                if(values.nationality !== undefined)
                {
                    let objx = JSON.parse(localStorage.getItem('countrylist'));

                    for(let i of objx.data)
                    {
                        if(i.name === values.nationality)
                        {
                            nationality = {id: i.code3, name: i.name}
                        }
                    }
                }



                let address = {
                    line: values.line,
                    city: values.city,
                    postcode: values.postcode,
                    country: values.country,
                    state: values.state
                };

                alo.data.email = values.email;
                alo.data.tel = values.tel;

                alo.data.nationality = nationality;
                alo.data.address = address;

                alo.data.paxno = values.pax;

                let a = await put(`/api/allocations/data/${propsObj.id}`,alo)
                if (response.ok)
                {
                    successN('Guest Update successful');
                }
                else
                {
                    errorN(`Guest Update - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
            else
            {
                errorN(`Get Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Update Guest: ${err}. Please contact the administrator.`);
        }

    }

    async function loadData() {
        try
        {

            let propsObj = props.obj;

            let a = await get(`/api/allocations/${propsObj.id}`)
            if (response.ok)
            {

                if(a.data != null)
                {
                    let obj = {};

                    if(a.data.guest != null)
                    {
                        obj.name = a.data.guest.name;
                        obj.identificationno = a.data.guest.id;
                    }
                    obj.vehicle = a.data.carNo;

                    if(a.data.newspaper != undefined && a.data.newspaper != null)
                    {
                        if(a.data.newspaper.length > 0)
                        {
                            let str = [];
                            for(let i of a.data.newspaper)
                            {
                                if(i.trim() !== '')
                                    str.push(i);
                            }

                            obj.newspaper = str;
                        }
                        else
                        {
                            obj.newspaper = [];
                        }
                    }
                    else
                    {
                        obj.newspaper = [];
                    }


                    if(a.data.nationality != null)
                    {
                        obj.nationality = a.data.nationality.name;
                    }

                    if (a.data.address != null)
                    {
                        obj.line = a.data.address.line;
                        obj.city = a.data.address.city;
                        obj.postcode = a.data.address.postcode;
                        obj.country = a.data.address.country;
                        obj.state = a.data.address.state;
                    }

                    obj.email = a.data.email;
                    obj.tel = a.data.tel;

                    obj.pax = a.data.paxno;

                    form.setFieldsValue(obj);
                }
            }
            else
            {
                errorN(`Get Allocation - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error Get Allocation: ${err}. Please contact the administrator.`);
        }
    }

    return (
        <>
                <Form
                    layout={formLayout}
                    form={form}
                    // style={{ width: '100%'}}
                    onFinish={handleFinish}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding: '20px'}}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Name',
                                    },
                                ]}
                            >
                                <Input placeholder="Name" />
                            </Form.Item>

                            <Form.Item
                                label="Identification No."
                                name="identificationno"
                            >
                                <Input placeholder="Identification No." />
                            </Form.Item>

                            <Form.Item
                                label="Contact No."
                                name="tel"
                                rules={[
                                ]}
                            >
                                <Input placeholder="Contact Number" />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                label="Nationality"
                                name="nationality"
                                rules={[
                                ]}
                            >
                                <Select
                                    placeholder="Nationality"
                                    /*style={{width: '300px'}}*/
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    optionFilterProp="children">
                                    {nationalityList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding: '20px'}}>
                            <Form.Item
                                label="Address"
                                name="line"
                            >
                                <Input placeholder="Address" />
                            </Form.Item>
                            <Form.Item
                                label="City"
                                name="city"
                            >
                                <Input placeholder="City" />
                            </Form.Item>
                            <Form.Item
                                label="Postcode"
                                name="postcode"
                            >
                                <Input placeholder="Postcode" />
                            </Form.Item>

                            <Form.Item

                                label='Country'
                                name="country"
                            >
                                <Select
                                    onChange={getState}
                                    placeholder="Country"
                                    /*style={{width: '300px'}}*/
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    optionFilterProp="children">
                                    {countryList.map(value => <Option key={value.name} value={value.name}>{value.name}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label='State'
                                name="state"
                            >
                                <Select
                                    placeholder="State"
                                    /*style={{width: '300px'}}*/
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    optionFilterProp="children">
                                    {stateList.map(value => <Option key={value} value={value}>{value}</Option>)}
                                </Select>
                            </Form.Item>


                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding: '20px'}}>
                            <Form.Item
                                label="No. of Pax"
                                name="pax"
                                rules={[
                                ]}
                            >
                                <InputNumber style={{width: '90%'}}  min={0.05} max={10000000000}
                                             formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>

                            <Form.Item
                                label="Vehicle No."
                                name="vehicle"
                            >
                                <Input placeholder="Vehicle No." />
                            </Form.Item>

                            <Form.Item
                                label="Newspaper"
                                name="newspaper"
                                rules={[
                                ]}
                            >
                                <Select
                                    mode= 'multiple'
                                    placeholder="Newspaper"
                                    /*style={{width: '300px'}}*/
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    optionFilterProp="children">
                                    {newspaperList.map(value => <Option key={value} value={value}>{value}</Option>)}
                                </Select>
                            </Form.Item>

                            <br />
                            <Button type="danger" onClick={() => form.submit()}  loading={loading}>
                                <StepForwardOutlined /> Confirm
                            </Button>
                        </Col>
                    </Row>

                </Form>

        </>
    );
}

export default UpdateAllocation
