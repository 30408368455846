import React, {useState,useEffect,useContext,useCallback} from 'react'

import {Result,Checkbox,Descriptions,InputNumber,DatePicker,Modal,Popconfirm, Steps, Radio, Select, Divider, Spin, Avatar, Menu, Dropdown, Form, Card, Drawer, Layout, Tabs, Button, Input, Table, Space,Row, Col} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

import {navigate} from "hookrouter";
import useFetch from "use-http";
import {
    PhoneOutlined,
    CloudUploadOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    PlayCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SmileOutlined, DollarCircleOutlined, IdcardOutlined, RightCircleOutlined, LeftCircleOutlined, SearchOutlined


} from '@ant-design/icons'

import moment from 'moment'
import {successN,errorN} from "../../general";
import{ModalCustomerExist} from "../../modals";
import routes from "../../router"
import Store, {Context} from "../../store"
import FModal from "./modal";
import {ShowBooking} from "./function";
import Highlighter from "react-highlight-words";

const {Sider, Content,Footer } = Layout;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Step } = Steps;

const steps = [
  {
    title: 'Guest Info',
    //content: 'First-content',
  },
  {
    title: 'Additional Info',
    //content: 'Second-content',
  },
  {
    title: 'Payment & Confirm',
    //content: 'Last-content',
  },
];

const formatter = new Intl.NumberFormat('en-MY', {
    style: 'currency',
    currency: 'MYR',

});

function ChooseRoomModal(props) {
    const [formLayout] = useState('vertical');
    const [visible, setVisible] = useState(false);
    const { loading} = useFetch()
    const [form] = Form.useForm()
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));
        //const values = form.getFieldsValue(true);

        onSubmit();
    }, [form])
    const [availCount, setAvailCount] = useState([]);
    const [isBackDate, setIsBackDate] = useState([]);


    useEffect(() => {

        return () => {

        }
    }, [])

    const handleOk = e => {
        setVisible(false);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const setData = () => {
        let obj = props.obj;

        if(obj.roomNo !== null && obj.roomNo !== undefined && obj.roomNo !== '')
        {
            let d = obj.roomNo.split(',');

            obj.roomNox = d;
        }
        else
        {
            obj.roomNox = [];
        }

        form.setFieldsValue(obj);

        setAvailCount(props.getAllo(obj.itemID));

        setIsBackDate(props.isBackDate);
    }

    const onSubmit = () => {
        const values = form.getFieldsValue(true);

        let x = '';


        for(let i of values.roomNox)
        {
            if(x === '')
            {
                x = i;
            }
            else
            {
                x = x + ',' + i;
            }
        }

        values.roomNo = x;

        props.reloadMe(values);
        setVisible(false);
    }

    const handleChange = () => {

        // if(props.isBackDate !== true)
        // {
            const values = form.getFieldsValue(true);
            let x = '';

            for(let i of values.roomNox)
            {
                if(x === '')
                {
                    x = i;
                }
                else
                {
                    x = x + ',' + i;
                }
            }

            values.roomNo = x;

            props.reloadMe(values);

            let obj = props.obj;
            setAvailCount(props.getAllo(obj.itemID));
        // }

    }

    return (
        <>
            {props.type === 'Danger' ?
                <Button type="danger" size="small" onClick={() => {setVisible(true); setData();}}  loading={loading}>
                    <PlayCircleOutlined  /> Select
                </Button>
            :
                <Button type="primary" size="small" onClick={() => {setVisible(true); setData();}}  loading={loading}>
                    <PlayCircleOutlined  /> Select
                </Button>
            }


            <Modal
                centered ={true}
                width="30%"
                destroyOnClose={true}
                visible={visible}
                title="Choose Service"
                onOk={handleOk}
                closable = {false}
                onCancel={handleCancel}
                maskClosable={false}
                footer =""
            >
               <Form
                layout={formLayout}
                form={form}
                // style={{ width: '100%'}}
                onFinish={handleFinish}
                >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                            <Form.Item
                                label="Rate Plan"
                                name="rate"
                                rules={[]}
                            >
                                <Input disabled placeholder="Rate Plan" />
                            </Form.Item>

                            <Form.Item
                                label="Price"
                                name="price"
                                rules={[]}
                            >
                                <InputNumber disabled style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{padding: '20px'}}>
                            {isBackDate === true ?
                                <Form.Item
                                name="roomNox"
                                label= 'Select Unit'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please choose a Quantity',
                                //     },
                                // ]}
                                >
                                    <Select
                                        mode= 'multiple'
                                        onChange={handleChange}
                                        allowClear
                                        placeholder="Qty"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch
                                        optionFilterProp="children">
                                        {availCount.map(value => <Option key={value.count} value={value.count}>{value.count}</Option>)}
                                    </Select>
                                </Form.Item>
                            :
                            <Form.Item
                                name="availability"
                                label= 'Select Qty'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please choose a Quantity',
                                //     },
                                // ]}
                                >
                                    <Select
                                        onChange={handleChange}
                                        allowClear
                                        placeholder="Qty"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch
                                        optionFilterProp="children">
                                        {availCount.map(value => <Option key={value.count} value={value.count}>{value.count}</Option>)}
                                    </Select>
                                </Form.Item>
                            }


                            <Form.Item
                                label="Open Price"
                                name="open"
                                rules={[]}
                            >
                                <InputNumber style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                            <Form.Item
                                label="Discount"
                                name="discount"
                                rules={[]}
                            >
                                <InputNumber style={{width: '100%'}}  min={0} max={10000000000}
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                            </Form.Item>
                            <br />
                            <Button type="primary" onClick={() => form.submit()}  loading={loading}>
                                <StepForwardOutlined /> Confirm
                            </Button>
                        </Col>
                    </Row>

                </Form>

            </Modal>
        </>
    );
}

function WhatsToday(props) {
    const [state,dispatch] = useContext(Context)
    const { get, post, response, loading, error, del, put } = useFetch();
    const { get:getLocal, post:postLocal, response:responseLocal, loading:loadingLocal} = useFetch(`http://localhost:8023`)

    const [form] = Form.useForm()
    const [formLayout, setFormLayout] = useState('horizontal');
    const handleFinish = useCallback(() => {
        //console.log('Submit: ', form.getFieldsValue(true));

        const values = form.getFieldsValue(true);



    }, [form])

    const [bookingType, setBookingType] = useState('walkin');
    const [nationalityList, setNationalityList] = useState([]);
    const [roomtypeList, setRoomtypeList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);

    const labelCol = 22;
    const wrapperCol = 22;

    const [current, setCurrent] = useState(0);
    const [visibleSubmit, setVisibleSubmit] = useState(false);
    const [isPaying, setIsPaying] = useState(false);
    const [isCash, setIsCash] = useState(false);
    const [isCard, setIsCard] = useState(false);
    const [calculated, setCalculated] = useState({price: 0, tax: 0, fee:0, discount:0,rounding:0,amount:0});

    const [customerExistTel, setCustomerExistTel] = useState(false);
    const [customerExistName, setCustomerExistName] = useState(false);
    const [customerExistEmail, setCustomerExistEmail] = useState(false);

    const [allowBackDate, setAllowBackDate] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [isBackDate, setIsBackDate] = useState(false);
    const [foliocreated, setFoliocreated] = useState(false);

    const [mykadAvailable, setMykadAvailable] = useState(false);

    const [allowCheckin, setAllowCheckin] = useState(false);

    const [channelOn, setChannelOn] = useState(false);
    const [dataBooking, setDataBooking] = useState([]);
    const [dataArrival, setDataArrival] = useState([]);
    const [dataDeparture, setDataDeparture] = useState([]);

    const [searchText, setsearchText]= useState( '');
    const [searchedColumn, setsearchedColumn]= useState( '');

    let searchInput = null;
    const getColumnSearchProps = (dataIndex,caption) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput=node;
                    }}
                    placeholder={`Search ${caption}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        /*this.setState({*/
        setsearchText(selectedKeys[0]);
        setsearchedColumn(dataIndex);
        /* });*/
    };

    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('');
    };

    const columnsBooking = [
        {
            title: '',
            dataIndex: 'action1',
            key: 'action1',
            //width: '15%',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title={`Are you sure want to proceed this action?`}
                        onConfirm={() => createfolio(record.id)}
                        okText="Yes"
                        cancelText="No">
                        <a>Create Folio</a>
                    </Popconfirm>

                </>
            )
        },
        {
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            //width: '10%',
            render: (text, record) => (
                <>
                    {/*<ShowBooking obj={senddata('showbooking',record.id)}/>*/}
                    <ShowBooking id={record.id}/>
                </>
            )
        },
        {
            title: 'Booking No.',
            dataIndex: 'no',
            //width: '30%',
            ...getColumnSearchProps('no','Booking No.'),
            sorter: (a, b) => a.no.localeCompare(b.no),
        },
        {
            title: 'Guest Name',
            dataIndex: 'name',
            //width: '45%',
            ...getColumnSearchProps('name','Guest Name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            //width: '45%',
        },
        {
            title: 'Agency',
            dataIndex: 'otaagency',
            //width: '45%',
            ...getColumnSearchProps('otaagency','Agency'),
            sorter: (a, b) => a.otaagency.localeCompare(b.otaagency),
        },
        {
            title: 'Ref No.',
            dataIndex: 'rcode',
           // width: '45%',
            ...getColumnSearchProps('rcode','Ref No.'),
            sorter: (a, b) => a.rcode.localeCompare(b.rcode),
        },
    ];

    const columnsArrival = [
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            //width: '15%',
            render: (text, record) => (
                <>
                    {record.unitno === 'Unit No.' ?
                        <FModal formname={'setroom'} obj={record} loadData={reloadArrival} />
                        :
                        record.status === 'Blocked' || record.status === 'Occupied' ?
                            <FModal formname={'checkinout'} obj={record} loadData={reloadArrival} />
                            :
                            null
                    }
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            //width: '10%',
            render: (text, record) => (
                <FModal formname={'update'} obj={record} loadData={reloadArrival} />
            )
        },
        {
            title: 'Unit Type',
            dataIndex: 'unittype',
            //width: '25%',
            ...getColumnSearchProps('unittype','Unit Type'),
            sorter: (a, b) => a.unittype.localeCompare(b.unittype),
        },
        {
            title: 'Unit No.',
            dataIndex: 'unitno',
            //width: '15%',
            ...getColumnSearchProps('unitno','Unit No.'),
            sorter: (a, b) => a.unitno.localeCompare(b.unitno),
        },
        {
            title: 'Guest Name',
            dataIndex: 'name',
            //width: '35%',
            ...getColumnSearchProps('name','Guest Name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
    ];

    const columnsDeparture =[
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            //width: '15%',
            render: (text, record) => (
                <>
                    <FModal formname={'checkinout'} obj={record} loadData={reloadDeparture} />
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action2',
            key: 'action2',
            //width: '10%',
            render: (text, record) => (
                <FModal formname={'update'} obj={record} loadData={reloadDeparture} />
            )
        },
        {
            title: 'Unit Type',
            dataIndex: 'unittype',
            //width: '25%',
            ...getColumnSearchProps('unittype','Unit Type'),
            sorter: (a, b) => a.unittype.localeCompare(b.unittype),
        },
        {
            title: 'Unit No.',
            dataIndex: 'unitno',
            //width: '15%',
            ...getColumnSearchProps('unitno','Unit No.'),
            sorter: (a, b) => a.unitno.localeCompare(b.unitno),
        },
        {
            title: 'Guest Name',
            dataIndex: 'name',
            //width: '35%',
            ...getColumnSearchProps('name','Guest Name'),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },

    ];

    async function loadBooking() {
        try
        {
            const values = form.getFieldsValue(true);
            let obj = JSON.parse(localStorage.getItem('currentproperty'));
            let date = new Date();
            let a = date.toISOString();

            if(values.dtDateRange1 === undefined || values.dtDateRange1 === null)
            {
            }
            else
            {
                a = moment(values.dtDateRange1).toISOString();
            }


            let bookingList = await get(`/api/hotels/${obj.id}/bookings?start=${a}`)
            if (response.ok)
            {
                let bookingListPast = await get(`/api/hotels/${obj.id}/noshow`)
                if (response.ok)
                {
                    let ls = bookingList.filter(x => x.no !== null && x.status !== 'Cancelled' && x.status !== 'Closed');
                    let lsPast = bookingListPast.filter(x => x.no !== null && x.status !== 'Cancelled' && x.status !== 'Closed');


                    let table1 = [];

                    for(let i of ls)
                    {
                        if(i.no !== null)
                        {
                            table1.push({
                                id: i.id,
                                status: i.status,
                                chekedin: i.checkedin,
                                no: i.no,
                                name: i.data.payor.name,
                                source: i.source,
                                otaagency: i.data.payor.otaagency == null ? 'Walk In' : i.data.payor.otaagency.name,
                                refno: i.data.payor.refno,
                                start: i.data.stay.arrival,
                                end: i.data.stay.departure,
                                rcode: i.data.rcode,
                                amount: i.amount
                            })
                        }
                    }

                    for(let i of lsPast)
                    {
                        if(i.no !== null)
                        {
                            table1.push({
                                id: i.id,
                                status: i.status,
                                chekedin: i.checkedin,
                                no: i.no,
                                name: '(' + moment(i.data.stay.arrival).local().format('DD-MM-YYYY') + ') ' + i.data.payor.name,
                                source: i.source,
                                otaagency: i.data.payor.otaagency == null ? 'Walk In' : i.data.payor.otaagency.name,
                                refno: i.data.payor.refno,
                                start: i.data.stay.arrival,
                                end: i.data.stay.departure,
                                rcode: i.data.rcode,
                                amount: i.amount
                            })
                        }
                    }

                    setDataBooking(table1);

                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Past Booking List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Booking List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error LB: ${err}. Please contact the administrator.`);
        }
    }

    async function loadArrival() {
        try
        {
            const values = form.getFieldsValue(true);
            let obj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = await get(`/api/hotels/${obj.id}/rooms`)
            if (response.ok)
            {


                let date = new Date();
                let a = date.toISOString();

                if(values.dtDateRange2 === undefined || values.dtDateRange2 === null)
                {
                }
                else
                {
                    a = moment(values.dtDateRange2).toISOString();
                }

                let table1 =[];

                let allocationList = await get(`/api/hotels/${obj.id}/allocations?start=${a}`)
                if (response.ok)
                {

                    for(let i of allocationList)
                    {
                        if (i.room === null)
                        {
                            i.data.tempID = `Unit No.`;
                        }
                        else
                        {
                            for(let x of roomList)
                            {
                                if (x.id === i.room)
                                {
                                    i.data.tempID = x.data.no;
                                }
                            }
                        }
                    }

                    let ls = allocationList.filter(x => x.folio !== null && x.status !== 'Closed');

                    //let table1 =[];

                    for(let i of ls)
                    {
                        if(i.no !== null)
                        {
                            table1.push({
                                id: i.id,
                                status: i.status,
                                checkin: i.checkin,
                                checkout: i.checkout,
                                unitno: i.data.tempID,
                                unittype: i.data.item.name,
                                name: i.data.guest.name,
                                folioid: i.folio,
                                itemid: i.item,
                                start: i.start,
                                end: i.end
                            })
                        }
                    }

                    //setDataArrival(table1);

                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }

                let allocationStayOverList = await get(`/api/hotels/${obj.id}/stayover?date=${a}`)
                if (response.ok)
                {

                    for(let i of allocationStayOverList)
                    {
                        if (i.room === null)
                        {
                            i.data.tempID = `Unit No.`;
                        }
                        else
                        {
                            for(let x of roomList)
                            {
                                if (x.id === i.room)
                                {
                                    i.data.tempID = x.data.no;
                                }
                            }
                        }
                    }

                    let ls = allocationStayOverList.filter(x => x.folio !== null && x.status !== 'Closed');

                    for(let i of ls)
                    {
                        if(i.no !== null)
                        {
                            table1.push({
                                id: i.id,
                                status: i.status,
                                checkin: i.checkin,
                                checkout: i.checkout,
                                unitno: i.data.tempID,
                                unittype: i.data.item.name,
                                name: i.data.guest.name,
                                folioid: i.folio,
                                itemid: i.item,
                                start: i.start,
                                end: i.end
                            })
                        }
                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }

                setDataArrival(table1);

            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error LA: ${err}. Please contact the administrator.`);
        }
    }

    async function loadDeparture() {
        try
        {
            const values = form.getFieldsValue(true);
            let obj = JSON.parse(localStorage.getItem('currentproperty'));
            let roomList = await get(`/api/hotels/${obj.id}/rooms`)
            if (response.ok)
            {


                let date = new Date();
                let a = date.toISOString();

                if(values.dtDateRange3 === undefined || values.dtDateRange3 === null)
                {
                }
                else
                {
                    a = moment(values.dtDateRange3).toISOString();
                }

                let allocationList = await get(`/api/hotels/${obj.id}/allocations?end=${a}`)
                if (response.ok)
                {
                    let allocationListPast = await get(`/api/hotels/${obj.id}/late`)
                    if (response.ok)
                    {
                        for(let i of allocationList)
                        {
                            if (i.room === null)
                            {
                                i.data.tempID = `Unit No.`;
                            }
                            else
                            {
                                for(let x of roomList)
                                {
                                    if (x.id === i.room)
                                    {
                                        i.data.tempID = x.data.no;
                                    }
                                }
                            }
                        }

                        for(let i of allocationListPast)
                        {
                            if (i.room === null)
                            {
                                i.data.tempID = `Unit No.`;
                            }
                            else
                            {
                                for(let x of roomList)
                                {
                                    if (x.id === i.room)
                                    {
                                        i.data.tempID = x.data.no;
                                    }
                                }
                            }
                        }

                        let ls = allocationList.filter(x => x.folio !== null && x.data !== null);
                        let lsPast = allocationListPast.filter(x => x.folio !== null && x.data !== null);

                        console.log('ls',ls)

                        let table1 =[];

                        for(let i of ls)
                        {
                            if(i.no !== null)
                            {
                                table1.push({
                                    id: i.id,
                                    status: i.status,
                                    checkin: i.checkin,
                                    checkout: i.checkout,
                                    unitno: i.data.tempID,
                                    unittype: i.data.item.name,
                                    name: i.data.guest !== null ? i.data.guest.name : '',
                                    folioid: i.folio,
                                    start: i.start,
                                    end: i.end
                                })
                            }
                        }

                        for(let i of lsPast)
                        {
                            if(i.no !== null)
                            {
                                table1.push({
                                    id: i.id,
                                    status: i.status,
                                    checkin: i.checkin,
                                    checkout: i.checkout,
                                    unitno: i.data.tempID,
                                    unittype: i.data.item.name,
                                    name: i.data.guest !== null ? '(' + i.data.departure + ') ' + i.data.guest.name : '',
                                    folioid: i.folio,
                                    start: i.start,
                                    end: i.end
                                })
                            }
                        }

                        setDataDeparture(table1);

                    }
                    else
                    {
                        if(response.status === 401)
                        {
                            localStorage.removeItem('session');
                            window.location.reload();
                        }
                        else
                        {
                            errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        }

                    }
                }
                else
                {
                    if(response.status === 401)
                    {
                        localStorage.removeItem('session');
                        window.location.reload();
                    }
                    else
                    {
                        errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                    }

                }
            }
            else
            {
                if(response.status === 401)
                {
                    localStorage.removeItem('session');
                    window.location.reload();
                }
                else
                {
                    errorN(`Room List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
        }
        catch (err)
        {
            errorN(`Error LD: ${err}. Please contact the administrator.`);
        }

    }

    async function loadDataAll() {
        loadArrival();
        loadBooking();
        loadDeparture();
    }

    async function loadData() {

        let channelobj = JSON.parse(localStorage.getItem('channelsetting'));

        if(channelobj !== undefined && channelobj !== null)
        {
            if(channelobj.data !== undefined && channelobj.data !== null)
            {
                if(channelobj.data.channelName !== undefined && channelobj.data.channelName !== null)
                {
                    if(channelobj.data.channelName.trim() !== '')
                    {
                        setChannelOn(true);
                    }
                }
            }
        }

        let customsettingObj = JSON.parse(localStorage.getItem('customsetting'));

        if(customsettingObj !== null)
        {
            if(customsettingObj.data.backDate === true)
            {
                setAllowBackDate(true);
            }
        }

        let devicesetting = JSON.parse(localStorage.getItem('devicesetting'));

        if(devicesetting !== null)
        {
            if(devicesetting.data !== undefined)
            {
                if(devicesetting.data.mykad !== undefined)
                {
                    setMykadAvailable(devicesetting.data.mykad);
                }
            }
        }

        let timesettingObj = JSON.parse(localStorage.getItem('timesetting'));

        if(timesettingObj !== null)
        {
            if(timesettingObj.data !== undefined)
            {
                if(timesettingObj.data.cutOut !== undefined)
                {
                    let h1 = timesettingObj.data.cutOut;
                    let h = 0;
                    let m = 0;
                    if(h1 !== null && h1 !== undefined)
                    {
                        let i = h1.split(':');
                        h = parseInt(i[0]);
                        m = parseInt(i[1]);
                    }
                    let date = new Date();
                    let n = moment(date).format('YYYY-MM-DD');
                    let nw = moment(n).add(h, 'h').add(m, 'm').format('YYYY-MM-DD HH:mm:ss');
                    let todaynow = moment(date).format('YYYY-MM-DD HH:mm:ss');


                    if(todaynow <= nw)
                    {
                        let arr = [];
                        arr.push(moment(todaynow).add(-1, 'd'))
                        arr.push(undefined)
                        form.setFieldsValue({
                            dt: arr
                        });
                    }
                    else
                    {
                        let arr = [];
                        arr.push(moment(todaynow))
                        //arr.push(moment(todaynow).add(1, 'd'))
                        arr.push(undefined)
                        form.setFieldsValue({
                           dt: arr
                        });
                    }
                }
            }
        }
    }

    async function loadCbo() {
        let obj = JSON.parse(localStorage.getItem('countrylist'));

        setNationalityList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        setCountryList(obj.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    }

    const getState = (e) => {

        try
        {
            setStateList([]);
            form.setFieldsValue({
                state: undefined
            });

            let obj = JSON.parse(localStorage.getItem('countrylist'));

            let ls = obj.data.filter(x => x.name === e);

            if(ls.length > 0)
            {
                setStateList(ls[0].states.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0)));
            }

        }
        catch(err)
        {
            errorN(`Error Get States: ${err}. Please contact the administrator.`);
        }

    }

    useEffect(() => {


        if(localStorage.getItem('session') === null)
        {
            navigate('/')
        }
        else
        {
            loadCbo();
            loadData();
            loadDataAll();
        }

        //loadData().then(r => {});

        return () => {

        }
    },[])

    async function getClientSupplier(e) {
        try
        {
            let alist = await get(`/api/parties?type=${e}`)
            if (response.ok)
            {
               if(e === 'client')
                {
                    setClientList(alist);

                    form.setFieldsValue({
                        clientlist: alist
                    });
                }
                else
                {
                    setSupplierList(alist);

                    form.setFieldsValue({
                        supplierlist: alist
                    });
                }
            }
            else
            {
                errorN(`Allocation List- ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }
        }
        catch (err)
        {
            errorN(`Error GCS: ${err}. Please contact the administrator.`);
        }
    }

    const onChange = (e) => {
        setBookingType(e.target.value);

        if(e.target.value === 'client')
        {
            getClientSupplier('client');
        }
        else if(e.target.value === 'agency')
        {
            getClientSupplier('supplier');
        }
    }

    async function createfolio(bookingID) {
        //let bookingID = localStorage.getItem('tempbookingid');
        let customerID = '';
        let customerType = '';

        try
        {
            let a = await get(`/api/bookings/${bookingID}`)
            if (response.ok)
            {
                if(a.data.payor !== undefined && a.data.payor !== null)
                {
                    if(a.data.payor.guest !== undefined && a.data.payor.guest !== null)
                    {
                        if(a.data.payor.guest.id !== undefined && a.data.payor.guest.id !== null)
                        {
                            customerID = a.data.payor.guest.id;
                        }
                    }

                    if(a.data.payor.type !== undefined && a.data.payor.type !== null)
                    {
                        customerType = a.data.payor.type;
                    }
                }

                /*console.log('full booking', a);
                return;*/
                if(customerID === undefined || customerID === null || customerID === '' || customerID === 0 || customerID === 'undefined')
                {
                    let guest = {
                        designation: '',
                        gender: '',
                        type: customerType,
                        guestCompany: a.data.payor.guestCompany
                    };



                    let party = {
                        category : a.data.payor.category,
                        data : {
                            name : a.data.payor.name,
                            email : a.data.payor.email,
                            tel : a.data.payor.tel,
                            address : a.data.payor.address && Object.keys(a.data.payor.address).length !== 0 && a.data.payor.address.constructor === Object ? a.data.payor.address : {
                                line : '',
                                city : '',
                                postcode : '',
                                state : '',
                                country : ''
                            },
                            client : null,
                            company : null,
                            guest : guest,
                            staff : null,
                            supplier : null,
                            identificationType : a.data.payor.identification && Object.keys(a.data.payor.identification).length !== 0 && a.data.payor.identification.constructor === Object ? a.data.payor.identification.name : '',
                            identificationNo : a.data.payor.identification && Object.keys(a.data.payor.identification).length !== 0 && a.data.payor.identification.constructor === Object ? a.data.payor.identification.id : '',
                            nationality :a.data.payor.nationality.name,
                            belongsTo: null
                        }
                    };


                    let c = await post(`/api/parties`, party)
                    if (response.ok)
                    {
                        let x = response.headers.get('location');
                        customerID = x;
                    }
                    else
                    {
                        errorN(`Create Party - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                        return;
                    }
                }

                let g = {id: customerID, name: a.data.payor.name};
                a.data.payor.guest = g;

                let json = {
                    party: customerID,
                    booking: a
                }

                let b = await post(`/api/bookings/${bookingID}/folio`, json)
                if (response.ok)
                {
                    //props.loadData();
                    //setFoliocreated(true);
                    //clearForm();
                    successN('Create Folio Successful');
                    //loadArrival();
                    //loadBooking();

                    //reloadBooking();
                    loadDataAll();
                }
                else
                {
                    errorN(`Create Folio - ${response.status}: ${response.statusText}. Please contact the administrator.`);
                }

            }
            else
            {
                errorN(`Get Booking - ${response.status}: ${response.statusText}. Please contact the administrator.`);
            }

        }
        catch (err)
        {
            errorN(`Error BOOK: ${err}. Please contact the administrator.`);
        }

    }

    function senddata(){
        let folioid = localStorage.getItem('tempfolioid');
        let arrival = localStorage.getItem('temparrival');
        let departure = localStorage.getItem('tempdeparture');

        return {folioid:folioid,
            arrival:arrival,
            departure:departure
        }
    }

    const reloadBooking = () => {
        //props.loadData();
        loadBooking();
    }

    const reloadArrival = () => {
        //props.loadData();
        loadArrival();
    }

    const reloadDeparture = () => {
        //props.loadData();
        loadDeparture();
    }

    const formItemLayout = {
        labelCol: { span: 6 }, // Set the label width
        wrapperCol: { span: 18 }, // Set the control width
    };

    return (
        <>
        {/*<div style={{ backgroundImage:`url("/header-bg7.png")`, color:'black', fontSize: '18px', paddingLeft: '15px', paddingTop: '15px',paddingBottom: '15px',marginTop: '0px'}}>
        &nbsp;&nbsp;<PhoneOutlined />
        &nbsp;&nbsp;What's Today!
        </div>*/}
        <Layout>

            {/*<Spin spinning={loading}>*/}
            <Content style={{backgroundColor: 'white'}}>
                <Form
                    layout={formLayout}
                    form={form}
                    onFinish={handleFinish}

                >
                    <div style={{backgroundColor: 'white', padding: '5px', margin: '0px 0',height:'73vh'}}>
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={true || false}
                        useWindow={false}
                    >
                        <>
                            <Row>
                                {channelOn === true ?
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                                        <div style={{backgroundColor: 'white', padding: '5px', marginRight: '5px'}}>
                                            {/*<Divider plain orientation="left"><RightCircleOutlined /> Bookings</Divider>*/}
                                            <Divider style={{ paddingTop: 10 }} plain orientation="left"><RightCircleOutlined /> Bookings</Divider>
                                            <Space>
                                                <Form.Item
                                                    //label="Date"
                                                    name="dtDateRange1"
                                                    rules={[]}
                                                >
                                                <DatePicker allowClear={true} onChange={() => {loadBooking();}} />
                                                </Form.Item>
                                            </Space>

                                            <Table columns={columnsBooking} pagination={{ pageSize: 10}} dataSource={dataBooking}  size ={'small'} loading={loading} scroll={{ x: 'max-content' }} />
                                        </div>
                                    </Col>
                                    :
                                    null
                                }

                                <Col xs={24} sm={24} md={channelOn === true ? 8 : 12} lg={channelOn === true ? 8 : 12} xl={channelOn === true ? 8 : 12} >
                                    <div style={{backgroundColor: 'white', padding: '5px', marginRight: '5px'}}>
                                        <Divider style={{ paddingTop: 10 }} plain orientation="left"><RightCircleOutlined /> Arrivals</Divider>
                                        <Space>
                                            <Form.Item
                                                //label="Date"
                                                name="dtDateRange2"
                                                rules={[]}
                                            >
                                                <DatePicker allowClear={true} onChange={() => {loadArrival();}} />
                                            </Form.Item>

                                        </Space>

                                        <Table columns={columnsArrival} pagination={{ pageSize: 10 }} dataSource={dataArrival}  size ={'small'} loading={loading} scroll={{ x: 'max-content' }} />
                                    </div>
                                </Col>

                                <Col xs={24} sm={24} md={channelOn === true ? 8 : 12} lg={channelOn === true ? 8 : 12} xl={channelOn === true ? 8 : 12} >
                                    <div style={{backgroundColor: 'white', padding: '5px', marginRight: '5px'}}>
                                        <Divider style={{ paddingTop: 10 }} plain orientation="left"><LeftCircleOutlined /> Departures</Divider>
                                        <Space>
                                            <Form.Item
                                                //label="Date"
                                                name="dtDateRange3"
                                                rules={[]}
                                            >
                                                <DatePicker allowClear={true} onChange={() => {loadDeparture();}} />
                                            </Form.Item>
                                        </Space>

                                        <Table columns={columnsDeparture} pagination={{ pageSize: 10 }} dataSource={dataDeparture}  size ={'small'} loading={loading} scroll={{ x: 'max-content' }} />
                                    </div>
                                </Col>

                            </Row>
                        </>

                    </InfiniteScroll>

                    </div>
                </Form>
            </Content>
            {/*</Spin>*/}
        </Layout>


        </>
    );
}

export default WhatsToday
